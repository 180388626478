import * as React from 'react';
import { connect } from "react-redux";
import { IGenericUserProps, genericMapStateToProps } from '../../DataClasses/GenericUser';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import LoadingDataTable from '../Shared/LoadingDataTable';
import idssStyle from "../../Configuration/SharedStyling";
import {
    Grid, Typography, CardContent, Card, Button, FormControl, FormGroup, FormControlLabel, Checkbox, Fab, Fade, Stepper, Step, StepLabel, StepContent,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef } from 'mui-datatables';
import { AjaxError } from 'rxjs/ajax';
import OrganizationService from '../Services/OrganizationService';
import SubmissionsService from '../Services/SubmissionsService';
import { Warning as WarningIcon } from '@material-ui/icons';
import { SubmissionType, MessageType } from '../../DataClasses/SharedData';
import { RouteComponentProps } from 'react-router';
import { ISelectedSubmissions, ISelectedSubmissionsHolder, S3FileTypes, ISubmissionAllowance } from '../../DataClasses/SubmissionData';
import SharedUtility from '../../Utilities/SharedUtility';
import saveAs from "file-saver";
import UserManagerService from '../Services/UserManagerService';
import { SeedTime } from "../../Configuration/Setting";
import UserMessage from '../Shared/Message';
import { Redirect } from "react-router-dom";
import ProcessingDisplay from "../Shared/ProcessingDisplay";
import * as AuthorizationPolicies from "../../Configuration/AuthorizationPolicies";
const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    downloadOptionsContainer: {
        height: "150px"
    },
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    }
});

interface ILocalState {
    isLoading: boolean;
    currentStep: number;
    orgData: any[];
    selectedOrganizationIndexes: number[];
    submissionData: any[];
    selectedSubmissionIndexes: number[];
    filesToDownload: number[];
    downloadInProgress: boolean;
    isValidDownload: boolean;
    downloadType: SubmissionType;
    allowResultDownload: boolean;
    allowValidationDownload: boolean;
    canUserDownloadCAHPSReports: boolean;
    isHedisOnlyUser: boolean;
    userDownloadAccessibility: ISubmissionAllowance;
    redirect: boolean;
    allChecked: boolean;
    downloadFileTypes: { id: number, name: string, type: string }[];
    isDialogOpen: boolean;
    selectedRowsPerPage: number;
}

// const TotalSteps: number = 3;

const HEDISFileTypes: { id: number, name: string, type: string }[] = [
    { "id": S3FileTypes.HEDIS_ResultXml, "name": "Result File (XML)", type: "result" },
    { "id": S3FileTypes.HEDIS_ResultCSV, "name": "Comma Separated Values (CSV) Workbook", type: "result" },
    { "id": S3FileTypes.HEDIS_Validation, "name": "Validation Report (CSV)", type: "validation" },
    { "id": S3FileTypes.HEDIS_BULK_VALIDATION, "name": "Bulk Validation Report (CSV)", type: "validation" },
    { "id": S3FileTypes.HEDIS_GeneratedDataFilledWorkbook, "name": "Data-filled Excel Workbook (EXCEL)", type: "result" },
    { "id": S3FileTypes.HEDIS_ART_Excel, "name": "Audit Review Table (EXCEL)", type: "result" },
    { "id": S3FileTypes.HEDIS_ART_Csv, "name": "Audit Review Table (CSV)", type: "result" },
    { "id": S3FileTypes.SIGNED_ATTESTATION, "name": "Signed Attestation (PDF)", type: "result" },
];

const CAHPSFileTypes: { id: number, name: string, type: string }[] = [
    { "id": S3FileTypes.CAHPS_ErrorReport_SubComponent, "name": "Validation Report (TXT)", type: "validation" },
    { "id": S3FileTypes.CAHPS_PreviewResultsReport_SubComponent, "name": "Preview Results (EXCEL)", type: "result" },
    { "id": S3FileTypes.CAHPS_FinalDocuments_SubComponent_ZIP, "name": "Final CAHPS File (ZIP)", type: "result" }
];

const CAHPSFileTypes_HEDISOnlyUser: { id: number, name: string, type: string }[] = [
    { "id": S3FileTypes.CAHPS_FinalDocuments_SubComponent_ZIP, "name": "Final CAHPS File (ZIP)", type: "result" },
    { "id": S3FileTypes.SIGNED_ATTESTATION, "name": "Signed Attestation (PDF)", type: "result" }
];

const CAHPSFileTypes_AdminUser: { id: number, name: string, type: string }[] = [
    { "id": S3FileTypes.CAHPS_ErrorReport_SubComponent, "name": "Validation Report (TXT)", type: "validation" },
    { "id": S3FileTypes.CAHPS_PreviewResultsReport_SubComponent, "name": "Preview Results (EXCEL)", type: "result" },
    { "id": S3FileTypes.CAHPS_FinalDocuments_SubComponent_ZIP, "name": "Final CAHPS File (ZIP)", type: "result" },
    { "id": S3FileTypes.SIGNED_ATTESTATION, "name": "Signed Attestation (PDF)", type: "result" }
];

const HOSFileTypes: { id: number, name: string, type: string }[] = [
    { "id": S3FileTypes.HOS_Header, "name": "Header Validation Report (EXCEL)", type: "validation" },
    { "id": S3FileTypes.HOS_Detail, "name": "Detail Validation Report  (EXCEL)", type: "validation" }
];

type IStylesProps = WithStyles<typeof styles>;

class BulkDownloadView extends React.Component<IGenericUserProps & IStylesProps & RouteComponentProps<{ downloadType: SubmissionType.HEDIS | SubmissionType.CAHPS | SubmissionType.HOS }>, ILocalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: true,
            currentStep: 0,
            orgData: [],
            selectedOrganizationIndexes: [],
            submissionData: [],
            selectedSubmissionIndexes: [],
            filesToDownload: [],
            downloadInProgress: false,
            isValidDownload: false,
            downloadType: this.props.match.params.downloadType,
            allowValidationDownload: true,
            allowResultDownload: true,
            canUserDownloadCAHPSReports: false,
            isHedisOnlyUser: false,
            userDownloadAccessibility:
            {
                allowsHedisSubmission: false,
                allowsCahpsSubmission: false,
                allowsHosSubmission: false
            },
            redirect: false,
            allChecked: false,
            downloadFileTypes: [],
            isDialogOpen: false,
            selectedRowsPerPage: 10
        };
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.canUserDownloadCahpsReports();
        await this.getUserDownloadAccessibility();
        await this.isUserHEDISOnlyUser();
        await this.RefreshModel();
    }

    public async componentDidUpdate(prevProps) {
        if (this.props.match.params.downloadType !== prevProps.match.params.downloadType) {
            await this.ResetState();
        }
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;

        const orgListColumns = [
            {
                label: "Org ID",
                name: "OrgId",
                options: {
                    filter: true
                }
            },
            {
                label: "Org Name",
                name: "Name",
                options: {
                    filter: true
                }
            }
        ];

        const orgListingOptions: MUIDataTableOptions = {
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRowsOnClick: true,
            rowsPerPage: this.state.selectedRowsPerPage,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
            selectToolbarPlacement: "above" as const,
            rowsSelected: this.state.selectedOrganizationIndexes,
            onChangeRowsPerPage: (numberOfRows: number) => {
                this.setState({
                    selectedRowsPerPage: numberOfRows
                })
            },
            onRowSelectionChange: (affectedRows, allSelectedRows, rowsSelected) => {
                this.setState({
                    selectedOrganizationIndexes: allSelectedRows.map(item => item.dataIndex)
                });
            },
            customToolbarSelect: () => { return null }
        };

        const submissionListColumns: MUIDataTableColumnDef[] = [
            {
                label: "Sub ID",
                name: "submissionId",
                options: {
                    filter: true
                }
            },
            {
                label: "Product Line",
                name: "productLine",
                options: {
                    filter: true
                }
            },
            {
                label: "Component",
                name: "component",
                options: {
                    filter: true,
                    display: (this.state.downloadType === SubmissionType.CAHPS ? "true" : "false") as any,
                }
            },
            {
                label: "SpecialProject",
                name: "specialProject",
                options: {
                    filter: true,
                    display: "false" as any
                }
            },
            {
                label: "Org ID",
                name: "orgId",
                options: {
                    filter: true
                }
            },
            {
                label: "Org Name",
                name: "orgName",
                options: {
                    filter: true
                }
            },
            {
                label: "Is Uploaded",
                name: "isFileUploaded",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === 'Yes')
                            return <div className={`${classes.Default} ${classes.Success}`}>{value}</div>;
                        else
                            return <div className={`${classes.Default} ${classes.Warning}`}>{value}</div>;
                    }
                }
            },
            {
                label: "Submission Status",
                name: "SubmissionStatus",
                options: {
                    filter: true
                }
            },
            {
                label: "Validation Status",
                name: "validationStatus",
                options: {
                    filter: true,
                    display: "true",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === 'Error' || value === 'Warning' || value === 'Fatal' || value === 'SchemaError' || value === 'WarningsAndErrors')
                            return <div className={`${classes.Default} ${classes.Warning}`}>{value}</div>;
                        else if (value === "NoIssues")
                            return <div className={`${classes.Default} ${classes.Success}`}>{value}</div>;
                        else {
                            return <div>{value}</div>
                        }
                    }
                }
            }
        ];

        const submissionListingOptions = {
            sort: true,
            filterType: "multiselect" as any,
            print: false,
            download: false,
            viewColumns: false,
            rowsPerPage: this.state.selectedRowsPerPage,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
            selectToolbarPlacement: "above" as const,
            selectableRowsOnClick: true,
            rowsSelected: this.state.selectedSubmissionIndexes,
            onChangeRowsPerPage: (numberOfRows: number) => {
                this.setState({
                    selectedRowsPerPage: numberOfRows
                })
            },
            onRowSelectionChange: (affectedRows, allSelectedRows, rowsSelected) => {
                this.setState({
                    selectedSubmissionIndexes: allSelectedRows.map(item => item.dataIndex)
                });
            },
            isRowSelectable: (dataIndex, allRows) => {
                return (this.props.match.params.downloadType === SubmissionType.CAHPS && this.state.isHedisOnlyUser ? (this.state.submissionData[dataIndex].SubmissionStatus !== "Import" && this.state.submissionData[dataIndex].SubmissionStatus !== "Apply Mark Final") : this.state.submissionData[dataIndex].isFileUploaded === "Yes");
            },
            customToolbarSelect: () => { return null }
        };

        return (
            <Grid container={true} className={classes.gridRowFlex} spacing={2}>
                <Grid item={true} xs={12}>
                    <Typography className={classes.submissionTitle}>
                        Bulk Download ({this.state.downloadType.toUpperCase()})
                    </Typography>
                </Grid>
                {this.state.isLoading ?
                    <LoadingDataTable titleText={"*"} />
                    :
                    <Fade in={true} {...({ timeout: SeedTime })}>
                        <Grid item={true} xs={12}>
                            <Card>
                                <CardContent>
                                    <div className={classes.root}>
                                        <Stepper activeStep={this.state.currentStep} orientation="vertical">
                                            {this.state.downloadType === SubmissionType.HEDIS ?
                                                <Step key="SelectOrgStep">
                                                    <StepLabel>Select Organizations</StepLabel>
                                                    <StepContent>
                                                        <MUIDataTable
                                                            title={null}
                                                            data={this.state.orgData}
                                                            columns={orgListColumns}
                                                            options={orgListingOptions}
                                                        />
                                                        <div className={classes.actionsContainer}>
                                                            <div>
                                                                <Button
                                                                    onClick={() => this.SelectOrgAndContinue()}
                                                                    disabled={this.state.selectedOrganizationIndexes.length === 0}
                                                                    className={classes.button}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </StepContent>
                                                </Step>
                                                : null}
                                            <Step key="SelectSubStep">
                                                <StepLabel>
                                                    Select Submissions
                                                    {this.isDisplayingNote() ? <Typography className={`${classes.HighlightedNote450}`}>(Note: Not all files will download if Schema or Fatal errors are present)</Typography> : null}
                                                </StepLabel>
                                                <StepContent>
                                                    {this.state.submissionData.length > 0 ?
                                                        <MUIDataTable
                                                            title={null}
                                                            data={this.state.submissionData}
                                                            columns={submissionListColumns}
                                                            options={submissionListingOptions}
                                                        />
                                                        : <Typography variant='body1' color="inherit">
                                                            {this.state.downloadType === SubmissionType.HEDIS ? <>
                                                                <WarningIcon className={classes.mb1Neg} color="error" />
                                                                "The organization selected does not have any submissions, please go back and select another."
                                                            </> :
                                                                "No submissions found."}
                                                        </Typography>
                                                    }
                                                    <div className={classes.actionsContainer}>
                                                        <div>
                                                            {this.state.downloadType === SubmissionType.HEDIS ?
                                                                <Button
                                                                    onClick={() => this.GoBackToSelectOrg()}
                                                                    className={classes.button}
                                                                >
                                                                    Back
                                                                </Button>
                                                                : null}
                                                            <Button
                                                                onClick={() => this.SelectSubmissionsAndContinue()}
                                                                disabled={this.state.selectedSubmissionIndexes.length === 0}
                                                                className={classes.button}
                                                            >
                                                                Next
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </StepContent>
                                            </Step>
                                            <Step key="DownloadOptionStep">
                                                <StepLabel>Download Selection</StepLabel>
                                                <StepContent>
                                                    <FormControl style={{ paddingLeft: 10 }} className={!this.state.downloadInProgress && this.state.isValidDownload ? classes.hidden : ""}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id="checkAll"
                                                                    name="checkAll"
                                                                    disabled={this.state.downloadInProgress}
                                                                    onChange={this.handleCheckboxChange}
                                                                    checked={this.state.allChecked}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={<Typography variant="subtitle1" className={classes.primaryBlueMedium}>Download All</Typography>}
                                                            hidden={this.props.match.params.downloadType === SubmissionType.CAHPS && this.state.isHedisOnlyUser}
                                                        />
                                                        <FormGroup row>
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                className={classes.downloadOptionsContainer}
                                                            >
                                                                {this.state.downloadFileTypes
                                                                    .map(fileType =>
                                                                        <FormControlLabel key={fileType.id}
                                                                            control={
                                                                                <Checkbox
                                                                                    id={`fileType${fileType.id}`}
                                                                                    name="fileTypes"
                                                                                    checked={this.state.filesToDownload.find((f) => f === fileType.id) ? true : false}
                                                                                    disabled={this.state.downloadInProgress}
                                                                                    onChange={this.handleCheckboxChange}
                                                                                    value={fileType.id}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={<Typography variant="subtitle2">{fileType.name}</Typography>} />
                                                                    )
                                                                }
                                                            </Grid>
                                                        </FormGroup>
                                                    </FormControl>
                                                    <div className={classes.actionsContainer}>
                                                        <div>
                                                            <UserMessage text={this.getUserInfoMessage()} variant={MessageType.Information} />


                                                            <Typography variant='body2' color='primary'>
                                                            </Typography>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Button
                                                                                onClick={() => this.GoBackToSelectSubmissions()}
                                                                                disabled={this.state.downloadInProgress}
                                                                                className={this.state.isValidDownload ? classes.hidden : classes.button}
                                                                            >
                                                                                Back
                                                                            </Button>
                                                                        </td>
                                                                        <td>
                                                                            <Fab variant="extended" color="secondary" onClick={() => this.DownloadSelected()} disabled={this.state.downloadInProgress || this.state.filesToDownload.length === 0} className={this.state.isValidDownload ? classes.hidden : classes.fab}>
                                                                                Download Submissions
                                                                            </Fab>
                                                                        </td>

                                                                        <td>
                                                                            {!this.state.downloadInProgress && this.state.isValidDownload ?
                                                                                <Button onClick={() => this.ResetState()} className={classes.button}>
                                                                                    Reset
                                                                                </Button> : null}
                                                                        </td>
                                                                        <td>
                                                                            {!this.state.downloadInProgress && this.state.isValidDownload ?
                                                                                <Typography variant='body1' className={classes.greenColor}>Download Completed</Typography>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <Typography variant="subtitle2" className={classes.darkOrangeColor}>
                                                                                {this.state.downloadInProgress ? "Downloading.. This may take couple of minutes for larger downloads, please be patient" : null}
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.downloadInProgress || this.state.isValidDownload ?
                                                                                <ProcessingDisplay completed={!this.state.downloadInProgress && this.state.isValidDownload} />
                                                                                : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <Dialog
                                                                open={this.state.isDialogOpen}
                                                                onClose={() => this.CloseDialog()}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">{"No file is downloaded"}</DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">
                                                                        There is no file available for downloading. Please change the selection(s) and try again.
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={() => this.CloseDialog()} color="primary" autoFocus>
                                                                        Close
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                    </div>
                                                </StepContent>
                                            </Step>
                                        </Stepper>

                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Fade>
                }
            </Grid>
        );
    }
    private CloseDialog() {
        this.setState({ isDialogOpen: false });
    }
    private isDisplayingNote = () => {
        for (var i = 0, l = this.state.selectedSubmissionIndexes.length; i < l; i++) {
            if (this.state.submissionData[this.state.selectedSubmissionIndexes[i]].validationStatus === 'SchemaError' || this.state.submissionData[this.state.selectedSubmissionIndexes[i]].validationStatus === 'Fatal') { return true }
        }
        return false;
    }
    private getUserInfoMessage = () => {
        return (
            <Typography variant='body2' color='inherit'>
                In Chrome, to choose a name for the downloaded files, go to your browser settings and search for the setting “Ask where to save each file before downloading”.
            </Typography>
        );
    }

    private handleCheckboxChange = (e) => {
        var itemName = e.target.name;
        var checked = e.target.checked;
        var downloadFileTypes = this.state.downloadFileTypes;
        let filesToDownload = [];
        var allChecked = false;
        if (downloadFileTypes.length > 0) {
            if (itemName === "checkAll") {
                allChecked = checked;
                if (checked) {
                    downloadFileTypes.map(fileType => filesToDownload.push(fileType.id));
                }
            }
            else {
                var fileTypeId = parseInt(e.target.value);
                filesToDownload = [...this.state.filesToDownload];
                if (checked) {
                    filesToDownload.push(fileTypeId);
                } else {
                    const index = filesToDownload.findIndex((f) => fileTypeId === f);
                    filesToDownload.splice(index, 1);
                }
                allChecked = downloadFileTypes.length === filesToDownload.length;
            }
            this.setState({ allChecked: allChecked, filesToDownload: filesToDownload });
        }
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        if (this.props.match.params.downloadType === SubmissionType.HEDIS) {
            const orgRetVal = await OrganizationService.GetOrganizationsForSubmissionTypeByAction$(
                this.props.user,
                this.props.match.params.downloadType,
                "download"
            ).toPromise();


            if (orgRetVal instanceof AjaxError || !orgRetVal) {
                this.setRedirectState();
            }
            else {
                const orgData = orgRetVal.map(item => {
                    return {
                        OrgId: item.orgId,
                        Name: item.orgName
                    };
                });

                this.setState({
                    orgData: orgData
                });

                this.setState({ isLoading: false });
            }
        }
        else {
            await this.getSubmissions([]);
        }
    }

    private async getSubmissions(orgIds: number[]) {
        const subRetVal = await SubmissionsService.GetSubmissionsOfTypeForOrgsByAction$(
            this.props.user,
            orgIds,
            this.props.match.params.downloadType,
            "download"
        ).toPromise();

        if (subRetVal instanceof AjaxError || !subRetVal) {
            this.setRedirectState();
        }
        else {
            const subData: any[] = (subRetVal.response as any).map(item => {
                return {
                    submissionId: item.subId,
                    productLine: item.productLine,
                    component: item.component,
                    orgId: item.orgId,
                    orgName: item.orgName,
                    isFileUploaded: item.isFileUploaded ? 'Yes' : 'No',
                    SubmissionStatus: SharedUtility.GetStageCleanName(item.submissionStatus),
                    validationStatus: item.validationStatus,
                    specialProject: item.specialProject
                };
            });
            this.setState({
                submissionData: subData
            });
            this.setState({ isLoading: false });
        }
    }

    private async canUserDownloadCahpsReports() {
        var result = await UserManagerService.CanUserDownloadCAHPSReports$(this.props.user).toPromise();
        this.setState({ canUserDownloadCAHPSReports: result });
    }

    private async isUserHEDISOnlyUser() {
        var result = await UserManagerService.IsHEDISOnlyUser$(this.props.user, this.props.match.params.downloadType, "download").toPromise();
        this.setState({ isHedisOnlyUser: result });
    }

    private async getUserDownloadAccessibility() {
        var result = await SubmissionsService.GetUploadAvailabilities$(this.props.user).toPromise();
        this.setState({ userDownloadAccessibility: result });
    }

    private GoBackToSelectOrg() {
        //Set org id in state
        this.setState({
            currentStep: 0,
            submissionData: [],
            selectedSubmissionIndexes: []
        });
    }

    private SelectOrgAndContinue() {
        //Set org id in state
        this.setState({
            currentStep: 1,
            isValidDownload: false
        });

        this.LoadSubmissionsForOrg();
    }

    private GoBackToSelectSubmissions() {
        //Set org id in state
        const stepToUse = this.state.downloadType === SubmissionType.HEDIS ? 1 : 0;

        this.setState({
            currentStep: stepToUse,
            isValidDownload: false,
            filesToDownload: []
        });
    }

    private async SelectSubmissionsAndContinue() {

        if (this.state.selectedSubmissionIndexes && this.state.selectedSubmissionIndexes.length > 0) {
            const selectedSubmissionIds: number[] = this.state.selectedSubmissionIndexes.map(i => {
                return this.state.submissionData[i] != null ? this.state.submissionData[i].submissionId : 0;
            });
            const downloadableInfo = await SubmissionsService.GetSubmissionsDownloadableInfo$(
                this.props.user,
                selectedSubmissionIds,
                this.state.downloadType
            ).toPromise();
            if (downloadableInfo instanceof AjaxError || !downloadableInfo) {
                this.setRedirectState();
            }
            else {
                this.setState({ allowResultDownload: downloadableInfo[0], allowValidationDownload: downloadableInfo[1] });
                const stepToUse = this.state.downloadType === SubmissionType.HEDIS ? 2 : 1;
                this.setState({
                    currentStep: stepToUse
                });

                //get download file types
                this.GetFileTypesToDownload();
            }
        }
    }

    private async LoadSubmissionsForOrg() {
        this.setState({ isLoading: true });

        if (this.state.selectedOrganizationIndexes && this.state.selectedOrganizationIndexes.length > 0) {
            const selectedOrganizationIds: number[] = this.state.selectedOrganizationIndexes.map(i => {
                return this.state.orgData[i] != null ? this.state.orgData[i].OrgId : 0;
            });

            await this.getSubmissions(selectedOrganizationIds);
        }
        else {
            this.setState({ isLoading: false });
        }
    }

    private DownloadSelected() {
        const submissions: ISelectedSubmissions[] = this.state.selectedSubmissionIndexes.map(i => {
            let sub = this.state.submissionData[i];

            return {
                submissionId: sub.submissionId,
                productLine: sub.productLine,
                component: sub.component
            };
        });
        this.downloadBulkFiles({
            submissions: submissions,
            fileTypes: this.state.filesToDownload
        });
    }

    private async downloadBulkFiles(bulkSubs: ISelectedSubmissionsHolder) {
        this.setState({ downloadInProgress: true, isValidDownload: false });

        const retval = await SubmissionsService.GetBulkFiles$(
            this.props.user,
            bulkSubs
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            this.setState({ isValidDownload: retval.response.isValidDownload });
            if (retval.response.isValidDownload) { //Make sure file(s) available for download else show "No files found" message
                const data = SharedUtility.Str2ab$(atob(retval.response.fileContents));
                saveAs(
                    new Blob([data], { type: "application/octet-stream" }),
                    retval.response.fileName
                );
            }
            else {
                this.setState({ isDialogOpen: true });
            }
        }

        this.setState({ downloadInProgress: false });
    }
    private GetFileTypesToDownload_CAHPS() {
        if (this.state.isHedisOnlyUser) {
            return CAHPSFileTypes_HEDISOnlyUser;
        }
        else if (AuthorizationPolicies.IsGlobalAdmin(this.props.user)) {
            return CAHPSFileTypes_AdminUser;
        }
        return CAHPSFileTypes;
    }
    private GetFileTypesToDownload() {
        const downloadType = this.state.downloadType;
        var downloadFileTypes: { id: number, name: string, type: string }[] = [];
        switch (downloadType) {
            case SubmissionType.HEDIS:
                downloadFileTypes = HEDISFileTypes;
                break;
            case SubmissionType.CAHPS:
                downloadFileTypes = this.GetFileTypesToDownload_CAHPS();
                break;
            case SubmissionType.HOS:
                downloadFileTypes = HOSFileTypes;
                break;
            default:
                throw new Error("Missing or Invalid Download Type.");
        }
        var filteredDownloadFileTypes = downloadFileTypes.filter(x => (this.state.allowResultDownload && x.type === "result") || (this.state.allowValidationDownload && x.type === "validation"));
        this.setState({ downloadFileTypes: filteredDownloadFileTypes });
        return filteredDownloadFileTypes;
    }

    private async ResetState() {
        this.setState({
            currentStep: 0,
            orgData: [],
            selectedOrganizationIndexes: [],
            submissionData: [],
            selectedSubmissionIndexes: [],
            filesToDownload: [],
            downloadType: this.props.match.params.downloadType,
            allChecked: false,
            isValidDownload: false
        });
        await this.RefreshModel();
    }
}

// REDUX CONTAINER
const BulkDownloadViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(BulkDownloadView);

// STYLES CONTAINER
const BulkDownloadPage = withStyles(styles)(BulkDownloadViewWithState);

export default BulkDownloadPage;