import { User } from "oidc-client";
import { ValidationMessage, ValidationError} from "./SharedData";

//#region Submission Home

export interface ICurrentSubAuths {
    isGlobalAdmin: boolean,
    isOrgAdmin: boolean,
    isOrgSigner: boolean,
    isVendorSigner: boolean,
    isOrgUser: boolean,
    isAuditor: boolean,
    isViewer: boolean,
    isVendorAdmin: boolean,
    isVenderUser: boolean,
    isSubmissionUser: boolean
    user: User | null,
}
export interface ISubmissionKey {
    submissionId: number,
    productLine: string,
    reportingProduct: string,
    specialArea: string,
    specialProject: string,
    cmsContractNumber: string   
}
export interface ISubmissionHomePayload {
    productLine: string,
    reportingProduct: string,
    reportingUnitId: string,
    specialArea: string,
    specialProject: string,
    cmsContract: string,
    subId: number,
    orgId: number,
    orgName: string,
    vendorId: number,
    activeStepNumber: number,
    hedisSubInMarkFinalOrEarlier: boolean,
    component: string,
    fileUploadNotAllowedReason: string,
    isFileUploadAllowed: boolean,
    isFileUploaded: boolean,
    isFileProcessed: boolean,
    didValidationError: boolean,
    didValidationWarning: boolean,
    isPublicReported?: boolean,
    somc?: boolean,
    attestationModifiedDate?: Date,
    reportableEvents?: SubmissionAccreditationSanction,
    submissionDownloads: ISubmissionDocumentDownload[],
    validationStatus:string,
    isFinal:boolean,
    isAttested:boolean,
    isAccredited: boolean,
    hasSubmissionAccess: boolean,
    isAuditRequired: boolean,
    requiredFileName: string,
    isCahpsOnlySubmission: boolean,
    submissionIntention: string
}

export interface ISubmissionAttestationFlags {
    submissionId: number,
    component: string,
    isPublicReported?: boolean,
    somc?: boolean,
    sourceLastModified: Date
}

export interface ISubmissionAttestationReportingFlags {
    submissionId: number,
    component: string,
    isPublicReported: boolean,
    sourceLastModified: Date
}

export interface ISubmissionHomeText {
    uploadFileText: string,
    uploadFileType: string,
}

export interface ISubmissionDocumentDownload {
    key: string,
    originalName: string,
    displayName: string,
    explanation: string,
    uploadDay: string,
    uploadTime: string,
    uploadUser: string,
    isArchived: boolean,
    isAllDownloadsOnly: boolean,
    isLicense: boolean,
}


export interface IValidationResponsePayload {
    submissionId: number,
    urlString: string,
    headerUrlString: string,
    detailUrlString: string,
    validationText: string,
    validationErrors: ValidationError[]
}

export interface IValidationReportData{
    orgId: number,
    subId: number,
    orgName: string,
    component: string,
    productLine: string,
    measureCode:string,
    shortIndicatorName:string,
    indicatorKey:string,
    severity:string,
    message:string,
    ruleName:string
}

export interface ISubmissionCountOnMeasureReportData{
    measureCode: string,
    productLine: string,
    submissionCount: number
}

export interface ISubmissionHomeParamObject {
    currentSubId: string,
    component: string,
}

export interface IDetailedSubmission {
    metadatas: IMetaKeyValuePair[],
}

export interface IMetaKeyValuePair {
    key: string,
    value: string
}

export interface ISubmissionUserPayload {
    roleUser: IUser,
    salesforceUser: ISalesforceContact,
}

export interface IUser {
    userId: string,
    email: string,
    roleName: string,
    roleType:string,
    submissionAuditor:string
}

export interface ISalesforceContact {
    firstName: string,
    lastName: string,
}

export interface IUploadSection {
    uploadFiles: IFile[],
    uploadCompletionPercent: number,
    dropzoneStatusStyle: string,
    fileProcessingComplete: boolean,
    uploadSuccessful: boolean
    uploadValidationMessages: ValidationMessage[]
}

export interface IFile {
    name: string,
    path: string,
    preview: string,
}

export interface IUserAction {
    canViewArt: boolean,
    canUploadHedis: boolean,
    canUploadNonHedis:boolean,
    canPlanLock: boolean,
    canPlanUnlock: boolean,
    canAuditLock: boolean,
    canAuditUnlock: boolean,
    canMarkFinalHedis: boolean,
    canMarkFinalCahps: boolean,
    canMarkFinalHos: boolean,
    canSubmissionGenericAdmin:boolean,
    canAssignAuditDesignation: boolean,
    canSignAttestationHedis: boolean,
    canSignAttestationHos: boolean,
    canDownloadResultHedis: boolean,
    canDownloadResultCahps: boolean,
    canDownloadResultHos: boolean,
    canManageHedisUser: boolean,
    canManagePublicReporting:boolean,
    canViewDownloadErrorsHedis: boolean,
    canViewDownloadErrorsNonHedis: boolean,
    canViewResultReportsHedis:boolean
}

export enum SubmissionActionTypes
{
    PlanLock = "PlanLock",
    PlanUnlock = "PlanUnlock",
    AuditLock = "AuditLock",
    AuditUnlock = "AuditUnlock",
    MarkFinal = "MarkFinal",
    MarkFinalUnlock = "MarkFinalUnlock",
    Attestation = "Attestation",
    AttestationUndo = "AttestationUndo"
}

export enum SubmissionAccreditationSanction {
    Yes="Yes",
    Report="Report",
    No="No",
    NA="NA"
}

export enum ValidationStatusTypes {
    NotRun = "NotRun",
    NoIssues = "NoIssues",
    Warning = "Warning",
    Error = "Error",
    WarningsAndErrors = "WarningsAndErrors",
    SchemaError = "SchemaError",
    Fatal = "Fatal",
}

export enum S3FileTypes{
    none = 0,
    HEDIS_Audit = 1,
    HEDIS_GeneratedDataFilledWorkbook = 2,
    HEDIS_Validation = 3,
    HEDIS_ResultXml = 4,
    CAHPS_ErrorReport_SubComponent = 5,
    HOS_Header = 7,
    HOS_Detail = 8,
    CAHPS_PreviewCCCResultsReport = 9,
    CAHPS_PreviewGPResultsReport = 10,
    CAHPS_PreviewResultsReport_SubComponent = 11,
    Archive = 12,
    CAHPS_ErrorReport_FileName = 13,
    UploadedFile = 14,
    CAHPS_PreviewResultsReport_FileName = 16,
    CAHPS_Submission_File = 17,
    XML_Workbook_SubId = 18,
    CAHPS_FinalDocuments_SubComponent_ZIP = 19,
    CAHPS_DeIdentifiedCahpsFile = 20,
    HEDIS_ResultCSV = 21,
    HEDIS_ART_Excel=22,
    HEDIS_ART_Csv = 23,
    HOS_FATAL = 24,
    HEDIS_BULK_VALIDATION = 25,
    SIGNED_ATTESTATION = 26,
}

//#endregion

//#region Submission Listing 

export interface ISubmissionPayload {
    subId: number,
    orgId: number,
    cmsContractNumber: string,
    orgName: string,
    lockStatus: string,
    component: string,
    componentVendorId: number,
    componentVendorName: string,
    submissionStatus: string,
    received: string,
    specialArea: string,
    specialProject: string,
    reportingProduct: string,
    reportingUnitId: string,
    productLine: string,
    isFinal: boolean,
    validationStatus: string,
    attestationStatus: string,
    canEdit: boolean,
    hasSubmissionAccess: boolean,
    isPublicReported?: boolean
}

export interface ISelectedSubmissions {
    submissionId: number,
    productLine: string,
    component: string,
    stage?: string
}
export interface ISelectedSubmissionsHolder {
    submissions: ISelectedSubmissions[],
    fileTypes: number[],
}
export interface IOrgSummary{
    orgId: number,
    orgName: string,
}

export interface ISubmissionLockEvent {
    subId: number,
    orgId: number,
    orgName: string,
    component: string,
    productLine: string,
    lockApplied: string,
    lockStatus: string,
    lockAppliedBy: string,
    lockAppliedOn: Date
}
export interface ISubmissionAllowance{
    allowsHosSubmission: boolean,
    allowsCahpsSubmission: boolean,
    allowsHedisSubmission: boolean,
    allowsGenericUploads?: boolean
}

export interface ISubmissionsStatistics{
    organization:number,
    submission:number,
    commercial:number,
    medicaid:number,
    medicare:number,
    exchange:number,
    planLocked:number,
    auditLocked:number,
    pendingEsign:number,
    markFinal:number
}

export interface ISubmissionComponentStatus {
    subId: number,
    orgId: number,
    orgName: string,
    component: string,
    productLine: string,
    year: number,
    accountManager: string,
    reportingProduct: string,
    specialProject: string,
    specialArea: string,
    cmsContactNumber: string,
    reportingUnitId: string,
    scoringId: number | null,
    auditRequired: boolean | null,
    type: string,
    stage: string,
    validationStatus: string,
    attest: string,
    somc: string,
    pubRep: string,
    accreditationReporting: string

}

//#endregion