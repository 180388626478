import { User } from "oidc-client";
import { Observable } from "rxjs";
import { IOrganizationPayload } from "../../DataClasses/OrganizationData";
import { IAttestingVendor } from "../../DataClasses/AttestingVendor";
import { SubmissionType } from "../../DataClasses/SharedData";
import AjaxHelper from "./AjaxHelper";

class OrganizationService {

    //#region Submissions

    public static GetManagableOrganizationsAndVendors$(user: User): Observable<IOrganizationPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/organization/GetManagableOrganizationsAndVendors',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetOrganizationsForSubmissionType$(user: User, submissionType: SubmissionType): Observable<IOrganizationPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/organization/GetOrganizationsForSubmissionType?submissionType=' + submissionType,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetOrganizationsForSubmissionTypeByAction$(user: User, submissionType: SubmissionType, action: string): Observable<IOrganizationPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/organization/GetOrganizationsForSubmissionTypeByAction?submissionType=' + submissionType + '&action=' + action,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetAttestingVendor$(user: User, submissionType: SubmissionType, action: string): Observable<IAttestingVendor> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/organization/GetAttestingVendor?submissionType=' + submissionType + '&action=' + action,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
}

export default OrganizationService;