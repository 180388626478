import "@material-ui/core";
import {
    Paper, Button, TextField, Typography, Fade, Dialog, DialogTitle, DialogActions, DialogContent, TextareaAutosize, Table,TableBody, TableRow, TableCell  } from "@material-ui/core";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { connect } from "react-redux";
import LoadingDataTable from "../Shared/LoadingDataTable";
import MUIDataTable, { MUIDataTableOptions} from "mui-datatables";
import idssStyle from "../../Configuration/SharedStyling";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import { AjaxError } from "rxjs/ajax";
import { IdssLog } from '../../DataClasses/DbData';
import DbService from '../Services/DbService';
import { Redirect } from "react-router-dom";
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface ILogViewState {
    data: any[],
    isLoading: boolean,
    userNameFilter: string,
    subIdFilter: string,
    orgIdFilter: string,
    logLevelFilter: any,
    messageFilter: string,
    fromDateFilter: string,
    toDateFilter: string,
    redirect: boolean,
    openLogDialog: boolean,
    selectedLogId: number,
    selectedLogDate: string,
    selectedLogMessage: string,
    selectedLogException: string
}

const eventLevels = ['All', 'Fatal', 'Error', 'Warning', 'Information', 'Trace', 'Debug'];

const logColumns =
    [
        {
            name: "Id",
            options: {
                display: false,
            }
        },
        {
            name: "TimeStamp",
            options: {
                filter: false,
            }
        },
        {
            name: "Message",
            options: {
                filter: false,
            }
        },
        {
            name: "Exception",
            options: {
                display: false,
            }
        },
        {
            name: "EventName",
            options: {
                filter: false,
            }
        },
        {
            name: "SubmissionId",
            options: {
                filter: false
            }
        },
        {
            name: "OrganizationId",
            options: {
                filter: false,
            }
        },
        {
            name: "UserName",
            options: {
                filter: false,
            }
        },
        {
            name: "Level",
            options: {
                filter: false,
            }
        }
    ];

type IStylesProps = WithStyles<typeof styles>;


// VIEW
class LogsView extends React.Component<IGenericUserProps & IStylesProps, ILogViewState> {

    private subListingOptions: MUIDataTableOptions = {
        filter: false,
        search: false,
        sort: false,
        print: false,
        download: false,
        viewColumns: false,
        serverSide: true,
        selectableRows: 'none',
        rowsPerPage: 100,        
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
        page: 0,
        onChangePage: (currentPage: number) => {
            this.refreshModel(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows: number) => {
            this.subListingOptions.rowsPerPage = numberOfRows;
            this.refreshModel(0);
        },
        onRowClick: (rowData, rowState) => {
            let logId = parseInt(rowData[0]);
            this.getSelectedLogDetails(logId);
        },
        setRowProps: row => {
            return {
                style: { cursor: "pointer" }
            };
        }
    };

    constructor(props: any) {
        super(props);
        var weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        this.state = {
            data: [],
            isLoading: true,
            userNameFilter: "",
            subIdFilter: "",
            orgIdFilter: "",
            logLevelFilter: "",
            messageFilter: "",
            fromDateFilter: weekAgo.toLocaleString(),
            toDateFilter: (new Date()).toLocaleString(),
            redirect: false,
            openLogDialog: false,
            selectedLogDate: "",
            selectedLogMessage: "",
            selectedLogException: "",
            selectedLogId: -1
        };

    }

    public componentDidMount() {
        this.refreshModel(0);
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    private getSelectedLogDetails(logId: number) {
        var item = this.state.data.filter((x) => x[0] === logId)[0];
        if (item) {
            this.setState({
                openLogDialog: true,
                selectedLogId: logId,
                selectedLogDate: item[1] ?? "",
                selectedLogMessage: item[2] ?? "",
                selectedLogException: item[3] ?? "No exception details available to show"
            });
        }
        else {
            this.setState({
                openLogDialog: false,
                selectedLogId: -1,
                selectedLogDate: "",
                selectedLogMessage: "",
                selectedLogException: ""
            });
        }
    }

    private getLogDetails(mode: string) {
        var currentLogId = this.state.selectedLogId;
        var id = 0;
        if (currentLogId > 0) {
            var rowIndex = this.state.data.findIndex((x) => x[0] === currentLogId);
            if (rowIndex > -1) {
                if (mode === "previous") {                   
                    rowIndex--;
                }
                else {
                    rowIndex++;
                }
                if (rowIndex < 0 || !this.state.data[rowIndex]) {
                    rowIndex = 0;
                }
                id = this.state.data[rowIndex][0] ?? null;
                if (id) {
                    this.getSelectedLogDetails(id);
                }
            }
            
        }
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        const levelOption = eventLevels.map(m => ({
            label: m,
            value: m
        }));
            return (
                    <div>
                        <Paper className={classes.root} elevation={3} square={true}>
                            <Table>
                            <TableBody>
                            <TableRow>
                                <TableCell align="left">
                                <Typography variant="h5">
                                        Filters
                                </Typography>
                                </TableCell>
                                <TableCell align="left" >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        invalidDateMessage={
                                        this.state.fromDateFilter
                                            ? "Invalid Date Format"
                                            : ""
                                        }
                                        margin="normal"
                                        id="from-date-picker-inline"
                                        label="From Date"
                                        value={this.state.fromDateFilter}
                                        onChange={(e) => this.handleFromDateChange(e)}
                                        KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        }}
                                        autoOk={true}
                                    />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell align="left" >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy-MM-dd"
                                            invalidDateMessage={
                                            this.state.toDateFilter
                                                ? "Invalid Date Format"
                                                : ""
                                            }
                                            margin="normal"
                                            id="to-date-picker-inline"
                                            label="To Date"
                                            value={this.state.toDateFilter}
                                            onChange={(e) => this.handleToDateChange(e)}
                                            KeyboardButtonProps={{
                                            "aria-label": "change date",
                                            }}
                                            autoOk={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell align="left">
                                <Select
                                    options={levelOption}
                                    placeholder="Level"
                                    isMulti={false}
                                    matchProp="label"
                                    simpleValue={true}
                                    value={this.state.logLevelFilter}
                                    closeOnSelect={false}
                                    searchable={true}
                                    onChange={this.selectedLevelChange.bind(this)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}    
                                />
                                </TableCell>     
                                <TableCell align="center" rowSpan={2}>
                                    <Button variant="contained"
                                        className={classes.button}
                                        onClick={() => this.refreshModel(0)}
                                    >
                                        Filter
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">
                                <TextField id="filter-name"
                                    label="User Name"
                                    className={classes.textField}
                                    value={this.state.userNameFilter}
                                    onChange={e => this.setState({ userNameFilter: e.target.value })}
                                    onBlur={e => this.setState({ userNameFilter: e.target.value })}
                                    margin="normal"
                                />
                                </TableCell>
                                <TableCell align="left">
                                <TextField id="filter-subId"
                                    label="Submission Id"
                                    className={classes.textField}
                                    value={this.state.subIdFilter}
                                    onChange={e => this.setState({ subIdFilter: e.target.value })}
                                    onBlur={e => this.setState({ subIdFilter: e.target.value })}
                                    margin="normal"
                                />
                                </TableCell>
                                <TableCell align="left">
                                <TextField id="filter-orgId"
                                    label="Organization Id"
                                    className={classes.textField}
                                    value={this.state.orgIdFilter}
                                    onChange={e => this.setState({ orgIdFilter: e.target.value })}
                                    onBlur={e => this.setState({ orgIdFilter: e.target.value })}
                                    margin="normal"
                                />
                                </TableCell>
                                <TableCell align="left">
                                <TextField id="filter-message"
                                    label="Message Contains"
                                    className={classes.textField}
                                    value={this.state.messageFilter}
                                    onChange={e => this.setState({ messageFilter: e.target.value })}
                                    onBlur={e => this.setState({ messageFilter: e.target.value })}
                                    margin="normal"
                                />
                                </TableCell>
                                                          
                            </TableRow>
                            </TableBody>
                            </Table>
                        </Paper>
                     {this.state.isLoading?<LoadingDataTable titleText={"*"}/>
                        :  
                        <>
                     <Fade in={!this.state.isLoading}><div> 
                    <MUIDataTable
                        title={"Logs"}
                        data={this.state.data}
                        columns={logColumns}
                        options={this.subListingOptions}
                    />
                    </div></Fade>
                    <Dialog open={this.state.openLogDialog} style={{ minHeight: '250' }}>
                    <DialogTitle id="max-width-dialog-title">
                        Log details
                        <div style={{ float: 'right' }}>          
                        <span className={classes.copyIcon}
                                            onClick={this.getLogDetails.bind(this, 'previous')}>            
                            <ArrowBackIosIcon className={classes.arrow} />
                        </span>
                        &nbsp;
                        <span className={classes.copyIcon}
                                            onClick={this.getLogDetails.bind(this, 'next')}>               
                                        <ArrowForwardIosIcon className={classes.arrow} />
                        </span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography className={classes.primaryBlue}>
                                       {this.state.selectedLogDate}
                                    </Typography>
                                </td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td>
                                    <Typography className={classes.primaryBlue}>
                                        Message
                                    </Typography>
                                </td>
                            </tr>
                            <tr style={{ paddingBottom: '10px'}}>
                                <td>
                                    <TextareaAutosize
                                        name="validationText"
                                        className={classes.fullWidth}
                                        id="multiline-filled-static"
                                        disabled={true}
                                        rowsMax={25}
                                        value={this.state.selectedLogMessage}
                                        cols={300}            
                                    />                                       
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography className={classes.primaryBlue}>
                                        Exception <span className={classes.copyIcon}
                                            onClick={this.handleCopyException.bind(this)}>
                                            <FileCopyIcon />
                                        </span>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextareaAutosize
                                        name="validationText"
                                        className={classes.fullWidth}
                                        id="multiline-filled-static"
                                        disabled={true}
                                        rowsMax={25}
                                        value={this.state.selectedLogException}
                                        cols={300}    
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.disabledButton}
                            onClick={this.handleLogDialogClose.bind(this)}>
                            Close
                        </Button>
                    </DialogActions>
                    </Dialog>
                    </>
                   }
                </div>
            );
    }

    private selectedLevelChange(newValue) {
        this.setState({logLevelFilter: newValue});
    }
    private handleFromDateChange(date) {
        var weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        if (!isNaN(Date.parse(date)) || date===null || date==="") {
          this.setState({ fromDateFilter: date ? date.toISOString() : weekAgo.toLocaleString() });
        } else {
          this.setState({ fromDateFilter: date });
        }
    }

    private handleToDateChange(date) {
        if (!isNaN(Date.parse(date)) || date===null || date==="") {
          this.setState({ toDateFilter: date ? date.toISOString() : (new Date().toLocaleString()) });
        } else {
          this.setState({ toDateFilter: date });
        }
    }

    private handleLogDialogClose = () => {
        this.setState({
            openLogDialog: false
        });
    }

    private handleCopyException = () => {
        navigator.clipboard.writeText(this.state.selectedLogException)
    }

    private refreshModel = async (page: number) => {
            this.setState({ isLoading: true });
            this.subListingOptions.page = page;

            const countCall = DbService.GetLogCount$(this.props.user, this.state.subIdFilter.trim(), this.state.userNameFilter.trim(),
                this.state.orgIdFilter, (this.state.logLevelFilter==="" || this.state.logLevelFilter.value === "All") ? "" : this.state.logLevelFilter.value, this.state.messageFilter, this.state.fromDateFilter, this.state.toDateFilter);

            const countLogs = await countCall.toPromise();

            if (countLogs instanceof AjaxError) {
                this.setRedirectState();
            }
            else if (countLogs && countLogs>0) {
                this.subListingOptions.count = countLogs;
            

            const apiCall = DbService.GetLogs$(this.props.user, this.state.subIdFilter.trim(), this.state.userNameFilter.trim(),
                this.state.orgIdFilter, (this.state.logLevelFilter==="" || this.state.logLevelFilter.value === "All") ? "" : this.state.logLevelFilter.value, this.state.messageFilter,
                this.state.fromDateFilter, this.state.toDateFilter,
                (page + 1), this.subListingOptions.rowsPerPage);

            const foundLogs: IdssLog[] | AjaxError  = await apiCall.toPromise();

            if (foundLogs instanceof AjaxError || !foundLogs) {
                this.setRedirectState();
            }
            else {
                this.setState({
                    data: foundLogs.map(
                        item => {
                            return [
                                item.logId,
                                moment(item.timeStamp).format("MM/DD/YYYY h:mm:ss a"),
                                item.message,
                                item.exception,
                                item.eventName,
                                item.submissionId,
                                item.organizationId,
                                item.userName,
                                item.level
                            ]
                        }
                    )
                });                
            }
        }
        else {
            this.setState({data: []});
        }
        this.setState({ isLoading: false });
    }

}

// REDUX CONTAINER
const LogsViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(LogsView);

// STYLES CONTAINER
const LogsViewPage = withStyles(styles)(LogsViewWithState);

export default LogsViewPage;