import { ajax, AjaxResponse, AjaxError } from "rxjs/ajax";
import { Observable, EMPTY } from "rxjs";
import { User } from "oidc-client";
import { retry, catchError, timeout } from 'rxjs/operators';


class AjaxHelper {


    public static AjaxGet$(url: string, headers?: Record<string, string>): Observable<any> {
        return ajax.getJSON(
            url, headers
        ).pipe(retry(parseInt(process.env.REACT_APP_GENERAL_RETRYLOGICCOUNT)), catchError(this.handleError));
    }

    public static AjaxPost(url: string, body?: any, headers?: Record<string, string>): Observable<AjaxResponse<any>> {
        return ajax.post(
            url, body, headers
        ).pipe(timeout(30 * 60 * 1000)).pipe(retry(parseInt(process.env.REACT_APP_GENERAL_RETRYLOGICCOUNT)), catchError(this.handleError));
    }

    public static CreateHttpHeaders(user: User) {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.access_token
        }
    }

    private static handleError(error: AjaxError) {
        var errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\nUrl: ${error.request.url}`;
        //right now logging to the console... 
        console.log(errorMessage);
        return EMPTY;
    }
}
export default AjaxHelper;