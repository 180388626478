import "@material-ui/core";
import { Button, Fade, Grid, Menu, MenuItem, Typography, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, CircularProgress, Link, Divider, Fab, Box, TextField, InputLabel } from "@material-ui/core";
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { Autorenew, ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import * as React from "react";
import { connect } from "react-redux";
import { AjaxError } from "rxjs/ajax";
import { SeedTime } from "../../Configuration/Setting";
import idssStyle from "../../Configuration/SharedStyling";
import { genericMapStateToProps, IGenericUserProps } from "../../DataClasses/GenericUser";
import { ISelectedSubmissions } from "../../DataClasses/SubmissionData";
import SharedUtility from "../../Utilities/SharedUtility";
import SubmissionsService from "../Services/SubmissionsService";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import ProcessingDisplay from "../Shared/ProcessingDisplay";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

interface ISubmissionAdministrationState {
    isLoading: boolean,
    isProcessing: boolean,
    openDialog: boolean;
    dialogConfirmationText: string,
    selectedSubmissions: ISelectedSubmissions[],
    rowsSelected: number[],
    submissionData: any[],
    anchorEl: HTMLElement | null,
    selectedAction: string,
    statusMessage: string,
    redirect: boolean, 
    openBulkDialog: boolean,
    bulkSubmissionIds: string,
    bulkSelectedSubType: any,
    isBulkReprocessing: boolean,
    showBulkReprocessSuccess: boolean
}

type IStylesProps = WithStyles<typeof styles>;
const ITEM_HEIGHT: number = 48;
const HEDIS = "HEDIS";
const HOS = "HOS";
const CAHPS = "CAHPS";
const REPROCESS = "Reprocess";
const ROLLBACK = "Rollback";
const UNATTEST = "UnAttest";
const UNMARKFINAL = "UnMarkFinal";
const UNSUBMIT = "Unsubmit";
let distinctComponents = [];
let distinctStages = [];
let distinctSubIds = [];

const SubmissionActions = [
    { "Action": REPROCESS },
    { "Action": ROLLBACK },
    { "Action": UNATTEST },
    { "Action": UNMARKFINAL },
    { "Action": UNSUBMIT },
];

// VIEW
class SubmissionAdministrationView extends React.Component<
    IGenericUserProps & IStylesProps,
    ISubmissionAdministrationState
> {
    constructor(props) {
        super(props);

        this.state =
        {
            isLoading: true,
            openDialog: false,
            dialogConfirmationText: '',
            isProcessing: false,
            selectedSubmissions: [],
            rowsSelected: [],
            submissionData: [],
            statusMessage: '',
            anchorEl: null,
            selectedAction: "Available Actions",
            redirect: false,
            openBulkDialog: false,
            bulkSubmissionIds: '',
            bulkSelectedSubType: '',
            isBulkReprocessing: false,
            showBulkReprocessSuccess: false
        };
    }

    public componentDidMount() {
        this.refreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        const open = Boolean(this.state.anchorEl);

        const subListingOptions: MUIDataTableOptions = {
            filterType: "checkbox",
            sort: false,
            print: false,
            rowsPerPage: 20,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
            viewColumns: true,
            //responsive: "scrollFullHeight",
            selectableRows: "multiple",
            rowsSelected: this.state.rowsSelected,
            selectToolbarPlacement: 'none',
            caseSensitive: false,
            onRowSelectionChange: (rowsSelected, allRows) => {
                let selectedRows: ISelectedSubmissions[];
                selectedRows = [];
                allRows.forEach(row => {
                    if (this.state.statusMessage !== "") {
                        this.setStatusMessageState();
                    }
                    const dataRow = this.state.submissionData[row.dataIndex];
                    selectedRows.push({
                        submissionId: dataRow[1] || 0,
                        productLine: dataRow[4] ? dataRow[4].toString() : "",
                        component: dataRow[3] ? dataRow[3].toString() : "",
                        stage: dataRow[12] ? dataRow[12].toString() : "",
                    });
                });

                this.setState({
                    selectedSubmissions: selectedRows,
                    rowsSelected: allRows.map(row => row.dataIndex)
                });
            }
        };

        const subListingColumns: MUIDataTableColumnDef[] = [
            {
                name: "Org ID",
                options: {
                    filterType: 'multiselect',
                    filter: true
                }
            },
            {
                name: "Sub ID",
                options: {
                    filter: false
                }
            },
            {
                name: "Org Name",
                options: {
                    filter: false
                }
            },
            {
                name: "Component",
                options: {
                    filterType: 'multiselect',
                    filter: true
                }
            },
            {
                name: "Product Line",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    filterOptions: {
                        names: ['Commercial', 'Medicaid', 'Medicare', 'Exchange']
                    },
                    customFilterListOptions:
                    {
                        render: (value: any) => {
                            return "Product Line: " + value.toString();
                        }
                    }
                }
            },
            {
                name: "Validation Status",
                options: {
                    filterType: 'multiselect',
                    filter: true,
                    display: 'excluded'
                }
            },
            {
                name: "Reporting Product",
                options: {
                    filter: false
                }
            },
            {
                name: "Special Project",
                options: {
                    filter: false
                }
            },
            {
                name: "Special Area",
                options: {
                    filter: false
                }
            },
            {
                name: "CMS Contract",
                options: {
                    filter: false
                }
            },
            {
                name: "Reporting Unit Id",
                options: {
                    filter: false
                }
            },
            {
                name: "Received",
                options: {
                    filter: false
                }
            },
            {
                name: "Stage",
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    customFilterListOptions:
                    {
                        render: (value: any) => {
                            return "Stage: " + value.toString();
                        }
                    }
                }
            }
        ];

        return (
            <Grid container alignItems="flex-end" spacing={2} className={classes.mainPanel + " " + classes.root}>
                <Grid item={true} xs={3}>
                    <Typography className={classes.submissionTitle}>
                        Submissions
                    </Typography>
                </Grid>
                <Grid item={true} xs={9} alignItems="flex-end">
                    <Link href="#" onClick={(e)=>{this.setState({openBulkDialog: true})}}>
                        <i><u>Click Here for Bulk Reprocessing</u></i>
                    </Link>
                    <div>
                    <Dialog open={this.state.openBulkDialog} classes={{paper: classes.dialogM}} >
                        <DialogTitle id="max-width-dialog-title">
                                Bulk Reprocessing Submissions
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                        <Grid container spacing={2}> 
                            <Grid xs={6} item={true} key="subBulkSubTypeGrid" className={classes.flexGrow + " " + classes.fullWidth}>
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Select Submission Type</InputLabel>
                                    <Select
                                        options={this.getSubmissionTypeOptions()}
                                        placeholder="Submission Type"
                                        value={this.state.bulkSelectedSubType}
                                        onChange={this.onChangeBulkSubmissionType.bind(this)}
                                        searchable={true}
                                        isClearable ={true}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} 
                                    />
                                </Box>
                            </Grid>                                    
                            <Grid xs={12} item={true} key="subBulkReprocessGrid" className={classes.flexGrow + " " + classes.fullWidth}>
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <TextField
                                        id="subIdsTf"
                                        label="SubmissionIds (csv string)"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        className={classes.fullWidth}
                                        value = {this.state.bulkSubmissionIds}
                                        onChange = {this.onChangeBulkSubIds.bind(this)}
                                        error = {!(/^[0-9,]*$/.test(this.state.bulkSubmissionIds))}
                                    />
                                </Box>
                            </Grid>  
                            <Grid xs={12} item className={classes.flexGrow + " " + classes.fullWidth}>
                                {this.state.isBulkReprocessing?
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>                                               
                                                    <Typography className={classes.darkOrangeColor}>Reprocessing the submissions...</Typography>
                                                </td>
                                                <td>
                                                    <ProcessingDisplay completed={false} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> : null
                                }
                                {this.state.showBulkReprocessSuccess?
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>                                               
                                                    <Typography className={classes.greenColor}>Submissions reprocessed</Typography>
                                                </td>
                                                <td>
                                                    <ProcessingDisplay completed={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> : null
                                }
                            </Grid>                                   
                            </Grid>
                        </DialogContent>
                        <Divider/>
                        <DialogActions>                                 
                            {this.state.showBulkReprocessSuccess?
                            <Button
                                className={classes.disabledButton}
                                onClick={() => this.setState({bulkSubmissionIds:"", bulkSelectedSubType:"", showBulkReprocessSuccess:false, isBulkReprocessing:false})}
                                disabled={false}
                            >
                                Reset
                            </Button> :
                            <Fab className={classes.fab} size="medium" variant="extended" color="secondary"  onClick = {this.onBulkReprocessingSubmit.bind(this)}
                                    disabled={this.state.bulkSubmissionIds===""||this.state.bulkSelectedSubType===""||!(/^[0-9,]*$/.test(this.state.bulkSubmissionIds)) || this.state.isBulkReprocessing}>
                                <Autorenew className={classes.extendedIcon} />
                                    Reprocess
                            </Fab>}
                            <Button
                                className={classes.disabledButton}
                                onClick={() => this.setState({bulkSubmissionIds:"", bulkSelectedSubType:"", openBulkDialog: false})}
                                disabled={false}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    {this.state.isLoading ?
                        <LoadingDataTable titleText={"*"} count={7} />
                        :
                        <Fade in={true} {...({ timeout: SeedTime })}>
                            <div>
                                {this.renderActions(open)}
                                <MUIDataTable
                                    title={null}
                                    data={this.state.submissionData}
                                    columns={subListingColumns}
                                    options={subListingOptions}
                                />
                                {this.renderActions(open)}
                            </div>
                        </Fade>
                    }
                </Grid>

            </Grid>
        );
    }

    private async refreshModel() {
        this.setState({ isLoading: true });

        const retval = await SubmissionsService.GetSubmissionsForUser$(
            this.props.user, true
        ).toPromise();

        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            let submissionData: any[] = retval
                .map(item => {
                    return [
                        item.orgId,
                        item.subId,
                        item.orgName,
                        item.component.replace("DST", "HEDIS"),
                        item.productLine,
                        item.validationStatus,
                        item.reportingProduct,
                        item.specialProject,
                        item.specialArea,
                        item.cmsContractNumber,
                        item.reportingUnitId,
                        item.received,
                        SharedUtility.GetStageCleanName(item.submissionStatus)
                    ];
                });

            this.setState({
                submissionData: submissionData
            });
            this.setState({ isLoading: false });
        }
    }

    private onBulkReprocessingSubmit = ()=> {
        this.setState({isBulkReprocessing: true});
        var count=this.state.bulkSubmissionIds.split(',').length;
        var time = 8000;
        if(count<8) {
            time = count * 1000;
        }
        const response = SubmissionsService.BulkSubmissionActionByIdsStr$(this.props.user, this.state.bulkSubmissionIds, this.state.bulkSelectedSubType.value.replace("HEDIS", "DST"), REPROCESS).toPromise();
        if (response instanceof AjaxError || !response) {
            this.setRedirectState();
        }
        else {
            this.setState({isBulkReprocessing: true}, 
                () => setTimeout(() => {
                    this.setState({ isBulkReprocessing: false, showBulkReprocessSuccess: true });
                }, time));
        }
    }

    private onChangeBulkSubIds(e) {
        this.setState({bulkSubmissionIds: e.target.value});
    }
    private onChangeBulkSubmissionType(newValue){
        this.setState({
            bulkSelectedSubType: newValue===null?"":newValue,
        });
    }

    private getSubmissionTypeOptions(){
        return [
            {value: "HEDIS", label: "HEDIS"},
            {value: "AdultCAHPS", label: "AdultCAHPS"},
            {value: "ChildCAHPS", label: "ChildCAHPS"},
            {value: "ChildCAHPSCCC", label: "ChildCAHPSCCC"},
            {value: "HOS", label: "HOS"}
        ];
    }
    private handleButtonMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    private closeDialog() {
        this.setState({ openDialog: false });
        this.setSelectedActionState();
    }

    private handleButtonMenuClose = (action, event) => {

        var selectedSubmissions = this.state.selectedSubmissions;

        if (typeof action === 'string') {
            this.setSelectedActionState(action);
        }
        else {
            this.setSelectedActionState(null);
        }

        //validate selected options
        if (!this.validate(action, selectedSubmissions)) {
            return false;
        }

        //show confirmation dialog    
        this.setState({ openDialog: true, dialogConfirmationText: `Are you sure you want to perform the ${action} action?` });
    }

    private validate = (action, selectedSubmissions: ISelectedSubmissions[]): boolean => {
        this.getSelectedSubmissionDetails(selectedSubmissions);

        //1. check at least one submission is selected
        if (selectedSubmissions.length === 0) {
            this.setSelectedActionState();
            this.setStatusMessageState("Please select a submission");
            return false;
        }

        //2. check for multiple components       
        if (distinctComponents.length > 1) {
            this.setSelectedActionState();
            this.setStatusMessageState("Please select only one submission component type at a time");
            return false;
        }

        //3. check for multiple stages
        if (distinctStages.length > 1) {
            this.setSelectedActionState();
            this.setStatusMessageState("Please select only one submission stage at a time");
            return false;
        }

        var selectedComponent = distinctComponents[0];
        var selectedStage = distinctStages[0];
        var errorText = `${action} action cannot be performed for the selected stage ("${selectedStage}")`;

        //4. validate Unattest for CAHPS
        var defaultReturn = false;
        if (action === REPROCESS) {
            defaultReturn = true;
        }
        else if (action === UNATTEST) {
            defaultReturn = true;
            if (selectedComponent.includes(CAHPS)) {
                this.setSelectedActionState();
                this.setStatusMessageState(`This action ("${action}") is not applicable for CAHPS submissions`);
                return false;
            }
            else if ((selectedComponent === HEDIS && selectedStage !== "Complete") ||
                (selectedComponent === HOS && selectedStage !== "Apply Mark Final")) {
                this.setActionAndErrorMessage(errorText);
                return false;
            }
        } else if (action === UNSUBMIT) { //5. validate Unsubmit & selected stage
            defaultReturn = true;
            if ((selectedComponent.includes(CAHPS) && selectedStage !== "Apply Mark Final") ||
                (selectedComponent === HEDIS && selectedStage !== "Apply Plan Lock") ||
                (selectedComponent === HOS && selectedStage !== "Validations/Attestation")) {
                this.setActionAndErrorMessage(errorText);
                return false;
            }
        } else if (action === ROLLBACK && selectedStage !== "Complete") { //6. validate Rollback & selected stage
            defaultReturn = true;
            this.setActionAndErrorMessage(errorText);
            return false;
        } else if (action === UNMARKFINAL) { // //7. validate Unmarkfinal & selected stage
            defaultReturn = true;
            if (((selectedComponent.includes(CAHPS) || selectedComponent === HOS) && (selectedStage !== "Complete" && selectedStage !== "Finalize Attestation")) ||
                (selectedComponent === HEDIS && selectedStage !== "Finalize Attestation")) {
                this.setActionAndErrorMessage(errorText);
                return false;
            }
        }
        return defaultReturn;
    }

    private getSelectedSubmissionDetails = (selectedSubmissions: ISelectedSubmissions[]) => {
        //get distinct components
        distinctComponents = [...new Set(selectedSubmissions.map(sub => sub.component))]
        //get distinct stages
        distinctStages = [...new Set(selectedSubmissions.map(sub => sub.stage))];
        //get distinct subids
        distinctSubIds = [...new Set(selectedSubmissions.map(sub => sub.submissionId))];
    }

    private handleBulkSubmissionAction = () => {
        var action = this.state.selectedAction;
        var selectedComponent = distinctComponents[0];

        if (selectedComponent === HEDIS)
            selectedComponent = "DST";

        //do bulk process
        this.setState({ isProcessing: true });
        const retval = SubmissionsService.BulkSubmissionAction$(this.props.user, distinctSubIds, selectedComponent, action).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
            this.setPostProcessingState();
        }
        else {
            this.setPostProcessingState();
            this.setActionAndErrorMessage();
            this.setState({ rowsSelected: [], selectedSubmissions: [], submissionData: [] });
            this.refreshModel();
        }
    }

    private setActionAndErrorMessage = (message = null) => {
        this.setSelectedActionState();
        this.setStatusMessageState(message);
    }

    private setSelectedActionState = (action = null) => {
        this.setState({ anchorEl: null, selectedAction: action ?? "Available Actions" });
    }

    private setStatusMessageState = (message = null) => {
        this.setState({ statusMessage: message ?? "" });
    }

    private setPostProcessingState = () => {
        this.setState({ isProcessing: false, openDialog: false });
    }

    private renderActions = (open: boolean) => {
        const { classes } = this.props;
        return (

            <div className={classes.mt2 + ' ' + classes.mb2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body1' color='secondary'>
                    {this.state.statusMessage}
                </Typography>
                <span>
                    {this.state.isProcessing ? <span style={{ paddingTop: '20px' }}><CircularProgress color="primary" size={22} /></span> : null}
                    <Button
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={this.handleButtonMenuClick}
                        color="primary"
                    >
                        {this.state.selectedAction}  <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="long-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={open}
                        onClose={this.handleButtonMenuClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: 150,
                            },
                        }}
                    >
                        {SubmissionActions.map(item => (
                            <MenuItem key={item.Action} onClick={event => this.handleButtonMenuClose(item.Action, event)}>
                                {item.Action}
                            </MenuItem>
                        ))}
                    </Menu>
                </span>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.closeDialog.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {this.state.dialogConfirmationText}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleBulkSubmissionAction.bind(this)}
                            color="primary"
                            autoFocus>
                            Yes
                                                </Button>
                        <Button
                            onClick={this.closeDialog.bind(this)}
                            color="primary">
                            No
                                                </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

// REDUX CONTAINER
const SubmissionAdministrationPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(SubmissionAdministrationView);

// STYLES CONTAINER
const SubmissionAdministrationPage = withStyles(styles)(
    SubmissionAdministrationPageWithState
);

export default SubmissionAdministrationPage;
