import * as React from "react";
import { connect } from "react-redux";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import { Redirect } from "react-router-dom";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../../Services/ReportsService";
import { IValidationReportData } from "../../../DataClasses/SubmissionData";
import BackNavigation from '../../Shared/BackNavigation';
import LoadingDataTable from "../../Shared/LoadingDataTable";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableMeta } from "mui-datatables";
import { Tooltip, IconButton, Grow, Dialog, Typography, Divider, Box, InputLabel, DialogContent, DialogTitle, DialogActions, Button, Grid } from '@material-ui/core';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { ValidationRptError } from "../../../DataClasses/SharedData";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f2f2f2', // theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[16],
        fontSize: 11,
        fontStyle: 'oblique'
    },
}))(Tooltip);

interface IValidationReportState {
    isLoading: boolean;
    data: any[],
    redirect: boolean,
    validationDialogOpen: boolean,
    currentValidationItem: ValidationRptError
}

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class ValidationReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    IValidationReportState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            redirect: false,
            validationDialogOpen: false,
            currentValidationItem: null
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }

    public render() {
        const reportColumns =
            [
                {
                    name: "Org Id",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Sub Id",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Org Name",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Measure",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Indicator Key",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Indicator",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Severity",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Rule Name",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return this.validationColumnWithTooltip(value, tableMeta);
                        }
                    }
                },
                {
                    name: "Message",
                    options: {
                        display: 'excluded' as ('true' | 'false' | 'excluded'),
                        filter: false
                    }
                },
                {
                    name: "Details",
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div>

                                    <table>
                                        <tbody>
                                            <tr >
                                                <td>
                                                    <Tooltip title="Show Details" aria-label="validationDetail">
                                                        <IconButton size='small' aria-label="validationDetail" onClick={() => this.ShowValidationDialog(tableMeta)}>
                                                            <PageviewOutlinedIcon fontSize="inherit"></PageviewOutlinedIcon>
                                                        </IconButton >
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        },
                        download: false
                    }
                }
            ];
        const reportOptions: MUIDataTableOptions = {
            filterType: "multiselect" as any,
            sort: true,
            filter: true,
            print: false,
            downloadOptions: {
                filename: 'ValidationsReport.csv',
                separator: ',',
                filterOptions: {
                    useDisplayedColumnsOnly: false,
                    useDisplayedRowsOnly: true
                }
            },
            viewColumns: false,
            selectableRows: "none",
            caseSensitive: false,
            rowsPerPage: 15,
            rowsPerPageOptions: [15, 20, 25, 50, 75, 100, 500, 1000]
        };
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={this.state.validationDialogOpen} onClose={() => this.CloseValidationDialog()} className={classes.dialogM} TransitionComponent={Grow}>
                    <DialogTitle >
                        <Typography className={classes.primaryBlueMedium}>
                            Validation Details
                        </Typography>
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        {this.state.currentValidationItem ? <>{this.renderValaidationDetailGrid()}</> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.CloseValidationDialog()}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Grid container={true} className={classes.gridRowFlex} spacing={0}>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true}>
                                <BackNavigation text="Back to Reports" href="/reportspage" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <div className={classes.card}>
                            {this.state.isLoading ? <LoadingDataTable titleText={"*"} count={8} /> :
                                <MUIDataTable
                                    title={"Validation Report (HEDIS Only)"}
                                    data={this.state.data}
                                    columns={reportColumns}
                                    options={reportOptions}
                                />
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private renderValaidationDetailGrid = () => {
        const { classes } = this.props;
        return <div>
            <Grid xs={10} item={true} className={classes.flexGrow + " " + classes.fullWidth}>
                {this.state.currentValidationItem.orgId !== null ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Org Id</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.orgId}
                        </Typography>
                    </Box> : null
                }
                {this.state.currentValidationItem.subId !== null ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Sub Id</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.subId}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.orgName) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Org Name</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.orgName}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.measureCode) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Measure Code</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.measureCode}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.shortIndicatorName) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Short Indicator Name</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.shortIndicatorName}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.indicatorKey) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Indicator Key</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.indicatorKey}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.severity) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Severity</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.severity}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.ruleName) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Rule Name</InputLabel>
                        <Typography variant="subtitle1" display="block" gutterBottom>
                            {this.state.currentValidationItem.ruleName}
                        </Typography>
                    </Box> : null
                }
                {this.IsNonEmptyStr(this.state.currentValidationItem.message) ?
                    <Box flexGrow={1} className={classes.defaultMargin}>
                        <InputLabel shrink>Message</InputLabel>
                        <Typography variant="subtitle2" display="block" gutterBottom>
                            {this.state.currentValidationItem.message}
                        </Typography>
                    </Box> : null
                }
            </Grid></div>
    }
    private IsNonEmptyStr = (s: string) => {
        return (s && s.length > 0)
    }

    private ShowValidationDialog = (tableMeta: MUIDataTableMeta) => {
        this.setState({
            currentValidationItem: {
                orgId: tableMeta.rowData[0],
                subId: tableMeta.rowData[1],
                orgName: tableMeta.rowData[2],
                measureCode: tableMeta.rowData[3],
                indicatorKey: tableMeta.rowData[4],
                shortIndicatorName: tableMeta.rowData[5],
                severity: tableMeta.rowData[6],
                ruleName: tableMeta.rowData[7],
                message: tableMeta.rowData[8],
            },
            validationDialogOpen: true
        });
    }

    private CloseValidationDialog() {
        this.setState({
            validationDialogOpen: false,
        });
    }

    private validationColumnWithTooltip(value: string, tableMeta: MUIDataTableMeta) {
        return (
            <LightTooltip title={tableMeta.rowData[8]}>
                <div>{value}</div>
            </LightTooltip>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval: IValidationReportData[] | AjaxError = await ReportsService.GetValidationReportData$(this.props.user).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                data: retval.map(x => {
                    return [
                        x.orgId,
                        x.subId,
                        x.orgName,
                        x.measureCode,
                        x.indicatorKey,
                        x.shortIndicatorName,
                        x.severity,
                        x.ruleName,
                        x.message
                    ]
                })
            });
        }
        this.setState({ isLoading: false });
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }
    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }
}

// REDUX CONTAINER
const ValidationReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(ValidationReportView);

// STYLES CONTAINER
const ValidationReport = withStyles(styles)(
    ValidationReportPageWithState
);

export default ValidationReport;