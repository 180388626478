import * as React from 'react';
import { connect } from "react-redux";
import { IGenericUserProps, genericMapStateToProps } from '../../DataClasses/GenericUser';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import { SubmissionType } from '../../DataClasses/SharedData';
import { RouteComponentProps } from 'react-router';
import { Grid, Typography, Card, CardContent, Stepper, Step, StepLabel, StepContent, Fab, Fade } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { AjaxError } from 'rxjs/ajax';
import SubmissionsService from '../Services/SubmissionsService';
import { ISelectedSubmissions, ISelectedSubmissionsHolder } from '../../DataClasses/SubmissionData';
import LoadingDataTable from '../Shared/LoadingDataTable';
import { SeedTime } from "../../Configuration/Setting";
import { Redirect } from "react-router-dom";
import AdministrationService from "../Services/AdministrationService";
import { HOS_ENABLE_INTERIM_SUBMISSION } from "../Shared/Constants";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    }
});

interface ILocalState {
    isOrgsLoading: boolean;
    isSubsLoading: boolean;
    isSaved: boolean;
    currentStep: number;
    orgData: any[];
    selectedOrganizationIndexes: number[];
    submissionData: any[];
    selectedSubmissionIndexes: number[];
    markFinalType: SubmissionType;
    markFinalInProgress: boolean;
    redirect: boolean;
    enableHosInterimDataSubmission: boolean;
}

type IStylesProps = WithStyles<typeof styles>;

class BulkMarkFinalView extends React.Component<IGenericUserProps & IStylesProps & RouteComponentProps<{ markFinalType: SubmissionType.HEDIS | SubmissionType.CAHPS | SubmissionType.HOS }>, ILocalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOrgsLoading: true,
            isSubsLoading: false,
            isSaved: false,
            currentStep: 0,
            orgData: [],
            selectedOrganizationIndexes: [],
            submissionData: [],
            selectedSubmissionIndexes: [],
            markFinalType: this.props.match.params.markFinalType,
            markFinalInProgress: false,
            redirect: false,
            enableHosInterimDataSubmission: false
        };
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.RefreshModel();
    }

    public async componentDidUpdate(prevProps) {
        if (this.props.match.params.markFinalType !== prevProps.match.params.markFinalType) {
            this.ResetState();
        }
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;

        const submissionListColumns = [
            {
                label: "Sub ID",
                name: "submissionId",
                options: {
                    filter: true
                }
            },
            {
                label: "Product Line",
                name: "productLine",
                options: {
                    filter: true
                }
            },
            {
                label: "Component",
                name: "component",
                options: {
                    filter: true
                }
            },
            {
                label: "Org ID",
                name: "orgId",
                options: {
                    filter: true
                }
            },
            {
                label: "Org Name",
                name: "orgName",
                options: {
                    filter: true
                }
            },
            {
                label: "Validation Status",
                name: "validationStatus",
                options: {
                    display: (this.state.markFinalType !== SubmissionType.HOS ? "true" : "false") as any,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === 'NoIssues')
                            return <div className={`${classes.Default} ${classes.Success}`}>{value}</div>;
                        else
                            return value;
                    }
                }
            },
            {
                label: "IsAttested",
                name: "isAttested",
                options: {
                    display: (this.state.markFinalType === SubmissionType.HOS ? "true" : "false") as any,
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === "Yes")
                            return <div className={`${classes.Default} ${classes.Success}`}>{value}</div>;
                        else
                            return value;
                    }
                }
            },
            {
                label: "IsFinal",
                name: "isFinal",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === "No")
                            return <div className={`${classes.Default} ${classes.Warning}`}>{value}</div>;
                        else
                            return value;
                    }
                }
            },
        ];

        const submissionListingOptions = {
            sort: true,
            filter: true,
            filterType: "multiselect" as any,
            print: false,
            download: false,
            viewColumns: false,
            selectableRowsOnClick: true,
            rowsSelected: this.state.selectedSubmissionIndexes,
            onRowSelectionChange: (affectedRows, allSelectedRows, rowsSelected) => {
                this.setState({
                    selectedSubmissionIndexes: allSelectedRows.map(item => item.dataIndex)
                });
            },
            isRowSelectable: (dataIndex, allRows) => {
                return this.state.markFinalType === SubmissionType.HOS ? (this.state.submissionData[dataIndex].isAttested === "Yes" || this.state.enableHosInterimDataSubmission)
                    && (this.state.submissionData[dataIndex].validationStatus === "NoIssues" || this.state.submissionData[dataIndex].validationStatus === "Warning") &&
                    this.state.submissionData[dataIndex].isFinal === "No"
                    : this.state.submissionData[dataIndex].validationStatus === "NoIssues" && this.state.submissionData[dataIndex].isFinal === "No";
            },
            customToolbarSelect: () => { return null }
        };

        return <Grid container={true} className={classes.gridRowFlex} spacing={2}>
            <Grid item={true} xs={12}>
                <Typography className={classes.submissionTitle}>
                    Bulk Mark Final ({this.state.markFinalType.toUpperCase()})
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Card>
                    <CardContent>
                        <div className={classes.root}>
                            <Stepper activeStep={this.state.currentStep} orientation="vertical">

                                <Step key="SelectSubStep">
                                    <StepLabel>Select Submissions</StepLabel>
                                    <StepContent>
                                        {this.state.isSubsLoading ? <LoadingDataTable /> :
                                            <Fade in={true} {...({ timeout: SeedTime })}><div>
                                                <MUIDataTable
                                                    title={null}
                                                    data={this.state.submissionData}
                                                    columns={submissionListColumns}
                                                    options={submissionListingOptions}
                                                /></div></Fade>
                                        }
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                <Fab variant="extended" color="secondary" onClick={() => this.MarkFinalSelected()} disabled={this.state.selectedSubmissionIndexes.length === 0 || this.state.markFinalInProgress} className={classes.fab}>
                                                    Mark Final
                                                </Fab>
                                                {
                                                    this.state.isSaved ? <Typography variant='h6' color='primary'>
                                                        Your selections have been saved.
                                                    </Typography> : null
                                                }
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        //}
    }

    private async RefreshModel() {
        this.setState({ isSubsLoading: true });
        const subRetVal = await SubmissionsService.GetSubmissionsOfTypeForOrgsByAction$(
            this.props.user,
            [] as any,
            this.props.match.params.markFinalType,
            "markFinal"
        ).toPromise();

        if (subRetVal instanceof AjaxError || !subRetVal) {
            this.setRedirectState();
        }
        else {
            //get hos interim data submission config value
            const retval = await AdministrationService.GetConfigKey$(this.props.user, HOS_ENABLE_INTERIM_SUBMISSION).toPromise();

            if (!retval) {
                this.setRedirectState();
            } else {
                this.setState({ enableHosInterimDataSubmission: retval.toLowerCase() === 'true' });
            }

            const subData: any[] = (subRetVal.response as any).map(item => {
                return {
                    submissionId: item.subId,
                    productLine: item.productLine,
                    component: item.component,
                    orgId: item.orgId,
                    orgName: item.orgName,
                    validationStatus: item.validationStatus,
                    isAttested: item.isAttested ? 'Yes' : 'No',
                    isFinal: item.isFinal ? 'Yes' : 'No'
                };
            });

            this.setState({
                submissionData: subData
            });
        }
        this.setState({ isSubsLoading: false });
    }

    private async ResetState() {
        this.setState({
            currentStep: 0,
            orgData: [],
            selectedOrganizationIndexes: [],
            submissionData: [],
            selectedSubmissionIndexes: [],
            markFinalType: this.props.match.params.markFinalType,
            isSaved: false
        });
        await this.RefreshModel();
    }


    private MarkFinalSelected() {
        const submissions: ISelectedSubmissions[] = this.state.selectedSubmissionIndexes.map(i => {
            let sub = this.state.submissionData[i];

            return {
                submissionId: sub.submissionId,
                productLine: sub.productLine,
                component: sub.component
            };
        });

        switch (this.state.markFinalType) {
            case SubmissionType.HEDIS:
                throw new Error("Not Implemented");
            case SubmissionType.CAHPS:
            case SubmissionType.HOS:
                this.BulkMarkFinal({
                    submissions: submissions,
                    fileTypes: []
                });
                break;
        }
    }


    private async BulkMarkFinal(bulkSubs: ISelectedSubmissionsHolder) {
        this.setState({ markFinalInProgress: true });
        this.setState({ isSaved: false });
        const retval = await SubmissionsService.SubmissionsBulkAction$(
            this.props.user,
            bulkSubs,
            "MarkFinal"
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            this.RefreshModel();
            this.setState({ selectedSubmissionIndexes: [], isSaved: true }, () => this.setState({ markFinalInProgress: false }));
        }
    }
}

// REDUX CONTAINER
const BulkMarkFinalViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(BulkMarkFinalView);

// STYLES CONTAINER
const BulkMarkFinalPage = withStyles(styles)(BulkMarkFinalViewWithState);

export default BulkMarkFinalPage;