import "@material-ui/core";
import {
    Paper,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Grid,
    IconButton,
    Fade
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import SharedUtility from "../../Utilities/SharedUtility";
import MUIDataTable, {
    MUIDataTableColumnDef, MUIDataTableOptions
} from "mui-datatables";
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import {
    ISelectedSubmissions
} from "../../DataClasses/SubmissionData";
//import {
//    IGenericUserProps,
//    genericMapStateToProps
//} from "../../DataClasses/GenericUser";
import SubmissionsService from "../Services/SubmissionsService";
import { AjaxError } from "rxjs/ajax";
import * as AuthorizationPolicies from "../../Configuration/AuthorizationPolicies";
import { Edit } from '@material-ui/icons';
import { Link, LinkProps, RouteComponentProps } from 'react-router-dom';
import LoadingDataTable from "../Shared/LoadingDataTable";
import {SeedTime} from "../../Configuration/Setting";
import { Redirect } from "react-router-dom";
//import RootState from "../../State/Root/RootState";
import { User } from "oidc-client";
import * as actionTypes from '../../State/SubmissionListing/Action';

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),   
    step: {
        padding: '10px 22px',
        margin: theme.spacing(1)
    },
    selectedOrganizationId: {
        width: '50%',
        marginTop: theme.spacing(1)
    },
    bulkActionContainer: {
        flexGrow: 1,
        justifyContent: 'left',
        justifyItems: 'left'
    }
});

const TableColumns = [
    { "Id": 1, "Name": "Actions" },
    { "Id": 2, "Name": "Org ID" },
    { "Id": 3, "Name": "Sub ID" },
    { "Id": 4, "Name": "Org Name" },
    { "Id": 5, "Name": "Component" },
    { "Id": 6, "Name": "Product Line" },
    { "Id": 7, "Name": "Validation Status" },
    { "Id": 8, "Name": "Reporting Product" },
    { "Id": 9, "Name": "Special Project" },
    { "Id": 10, "Name": "Special Area" },
    { "Id": 11, "Name": "CMS Contract" },
    { "Id": 12, "Name": "Reporting Unit Id" },
    { "Id": 13, "Name": "Received" },
    { "Id": 14, "Name": "Stage" },
    { "Id": 15, "Name": "Survey Vendor" },
    { "Id": 16, "Name": "Public Reporting"}
];

interface ISubmissionsListingState {
    isLoading: boolean,
    isProcessing: boolean,
    isValidDownload: boolean,
    isAdmin: boolean,
    selectedSubmissions: ISelectedSubmissions[],
    rowsSelected: number[],
    tableData: any[],
    bulkAnchorEl: null | Element,
    tableFilters: undefined | IFilterInputParameters,
    redirect: boolean
}

interface IFilterInputParameters {
    productLine: string | undefined;
   // status: string | undefined;
    stage: string | undefined;
    orgId: string | undefined;
}

interface IComponentStoreProps {
    user: User | null;
    viewColumnsSelected: string[];
    showAllSubmissions: boolean;
    rowsPerPageSelected: number;
    storedFilterList: any;
}

interface IComponentDispatchProps {
    addColumn: (column: string) => void;
    removeColumn: (column: string) => void;
    setInitialColumns: (columnsList: string[]) => void;
    setAllSubmissions: (value: boolean) => void;
    setRowsPerPage: (value: number) => void;
    setTableFilter: (filterList: any) => void;
}

type IStylesProps = WithStyles<typeof styles>;
type IComponentProps = IStylesProps & IComponentStoreProps & IComponentDispatchProps

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link innerRef={ref as any} {...props} />
));
// VIEW
class AllSubmissionsView extends React.Component<
    IComponentProps & RouteComponentProps<IFilterInputParameters>,
    ISubmissionsListingState
    > {
      constructor(props) {
        super(props);

        let filters = props.match.params as IFilterInputParameters | undefined;
        if(filters && filters.orgId)
        {
            this.props.setTableFilter([[],[+filters.orgId],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);
            filters.orgId = undefined;
        }
        this.state =
            {
                isLoading: true,
                isProcessing: false,
                isValidDownload: true,
                isAdmin: AuthorizationPolicies.IsGlobalAdmin(this.props.user),
                selectedSubmissions: [],
                rowsSelected: [],
                tableData: [],
                bulkAnchorEl: null,
                tableFilters: filters,
                redirect: false
            };
    }

    public componentDidUpdate(prevProps) {
        let filters = this.props.match.params as IFilterInputParameters | undefined;
        if(filters && filters.orgId)
        {
            this.props.setTableFilter([[],[+filters.orgId],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);
            filters.orgId = undefined;
        }
        if (prevProps.match.params !== filters) {
            this.setState({ tableFilters: filters });
        }
    }

    public componentDidMount() {
        this.refreshModel(this.props.showAllSubmissions);

        //set all columns in state initially
        if (!this.props.viewColumnsSelected || this.props.viewColumnsSelected.length === 0) {
            var columnsList = TableColumns.map(c => c.Name);
            this.props.setInitialColumns(columnsList);
        }
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const pageFilters = this.state.tableFilters;
            const { classes } = this.props;
            const subListingOptions: MUIDataTableOptions = {
                filterType: "checkbox",
                sort: true,
                print: false,
                selectableRows: "none",
                downloadOptions:{
                    filename: 'Submissions.csv',
                    separator:',',
                    filterOptions:{
                        useDisplayedColumnsOnly:true,
                        useDisplayedRowsOnly:true
                    }
                },
                viewColumns: true,                
                rowsSelected: this.state.rowsSelected,
                selectToolbarPlacement: "none",
                caseSensitive: false,
                rowsPerPage: this.props.rowsPerPageSelected,
                rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
                onChangeRowsPerPage: (numberOfRows: number) => {
                    this.props.setRowsPerPage(numberOfRows);
                },
                onViewColumnsChange: (columnChanged, action) => {
                    if (action === "add") {
                        this.props.addColumn(columnChanged);
                    }
                    else if (action === "remove") {
                        this.props.removeColumn(columnChanged);
                    }
                },
                onFilterChange: (column, filterList, type) => {
                    this.props.setTableFilter(filterList);
                }
            };

            let hasStoredTableFilter = this.props.storedFilterList && this.props.storedFilterList.length > 0;
            const subListingColumns: MUIDataTableColumnDef[] = [

                {
                    name: this.getColumnName(1),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(1)),
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const submissionHomeText = "/submissionHome/" + value;
                            return (
                                value === "" ? null :
                                    <div>

                                        <table>
                                            <tbody>
                                                <tr >
                                                    <td>
                                                        <Tooltip title="Submission Home" aria-label="submissionHome">
                                                            <IconButton size='medium' aria-label="submissionHome" to={submissionHomeText} component={AdapterLink}>

                                                                <Edit fontSize="inherit"></Edit>

                                                            </IconButton >
                                                        </Tooltip>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                            );
                        },
                        download: false
                    }
                },
                {
                    name: this.getColumnName(2),
                    options: {
                        filter: true,
                        display: this.showColumn(this.getColumnName(2)),
                        filterType: pageFilters && pageFilters.orgId && pageFilters.orgId !== 'all' ? 'custom' : 'multiselect',
                        filterList: pageFilters && pageFilters.orgId && pageFilters.orgId !== 'all' ? [pageFilters.orgId] : (hasStoredTableFilter ? this.props.storedFilterList[1]: []),
                        customFilterListOptions: {
                            render: v => "Org Id: " + v.toString()
                        },
                        filterOptions: {
                            logic(id, filters) {
                                if (filters === undefined || filters.length === 0) {
                                    if (pageFilters && pageFilters.orgId && pageFilters.orgId !== 'all')
                                        return !(pageFilters.orgId.toString() === id.toString());
                                    return false;
                                }
                                return !(filters.includes(id));
                            }
                        }
                    }
                },
                {
                    name: this.getColumnName(3),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(3))
                    }
                },
                {
                    name: this.getColumnName(4),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(4)),
                    }
                },
                {
                    name: this.getColumnName(5),
                    options: {
                        filterType: 'multiselect',
                        filter: true,
                        filterList: hasStoredTableFilter ? this.props.storedFilterList[4] : [],
                        display: this.showColumn(this.getColumnName(5)),
                    }
                },
                {
                    name: this.getColumnName(6),
                    options: {
                        filter: true,
                        display: this.showColumn(this.getColumnName(6)),
                        filterType: 'multiselect',
                        filterList: this.state.tableFilters && this.state.tableFilters.productLine && this.state.tableFilters.productLine !== 'all' ? [this.state.tableFilters.productLine] : (hasStoredTableFilter ? this.props.storedFilterList[5]:[]),
                        filterOptions: {
                            names: ['Commercial', 'Medicaid', 'Medicare', 'Exchange']
                        },
                        customFilterListOptions: {
                            render: v => "Product Line: " + v.toString()
                        } 
                    }
                },
                {
                    name: this.getColumnName(7),
                    options: {
                        filterType: 'multiselect',
                        filter: true,
                        filterList: hasStoredTableFilter ? this.props.storedFilterList[6] : [],
                        display: 'excluded'
                    }
                },
                {
                    name: this.getColumnName(8),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(8)),
                    }
                },
                {
                    name: this.getColumnName(9),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(9)),
                    }
                },
                {
                    name: this.getColumnName(10),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(10)),
                    }
                },
                {
                    name: this.getColumnName(11),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(11)),
                    }
                },
                {
                    name: this.getColumnName(12),
                    options: {
                        filter: false,
                        display: this.showColumn(this.getColumnName(12)),
                    }
                },
                {
                    name: this.getColumnName(13),
                    options: {
                        filterType: 'multiselect',
                        filter: true,
                        display: this.showColumn(this.getColumnName(13)),
                    }
                },
                {
                    name: this.getColumnName(14),
                    options: {
                        filter: true,
                        display: this.showColumn(this.getColumnName(14)),
                        filterType: 'multiselect',
                        filterList: this.state.tableFilters && this.state.tableFilters.stage && this.state.tableFilters.stage !== 'all' ? this.state.tableFilters.stage.split('&') : (hasStoredTableFilter ? this.props.storedFilterList[13]:[]),
                        customFilterListOptions: {
                            render: v=>"Stage: "+v.toString()
                        }
                    }
                },
                {
                    name: this.getColumnName(15),
                    options: {
                        display: 'excluded',
                        filterType: 'multiselect',
                        filter: this.state.isAdmin,
                        filterList: hasStoredTableFilter ? this.props.storedFilterList[14] : []
                    }
                },
                {
                    name: this.getColumnName(16),
                    options: {
                        filterType: 'multiselect',
                        filter: true,
                        filterList: hasStoredTableFilter ? this.props.storedFilterList[15]: [],
                        customBodyRender(value: string): React.ReactNode{
                            if(value === "Not Set"){
                                return (<span style={{color: "red"}}>{value}</span>);
                            }
                            return value;
                        }
                    }
                }
            ];

            return (
                <Grid container={true} spacing={2} className={classes.mainPanel + " " + classes.root}>
                        <Grid item={true} xs={12}>
                            <Typography className={classes.submissionTitle}>
                            {'Submissions' + (this.state.tableFilters && this.state.tableFilters.orgId && this.state.tableFilters.orgId !== 'all' ? ' for Org ID ' + this.state.tableFilters.orgId : '')}
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <div>
                                {this.state.isAdmin ?
                                    <FormGroup>
                                        <FormControlLabel className={classes.noBottomMargin}
                                            control={
                                                <Checkbox
                                                    checked={this.props.showAllSubmissions}
                                                    onChange={async () => this.toggleGetAllUsers()}
                                                    color="primary"
                                                />
                                            }
                                            label="Show All Submissions"
                                        />
                                    </FormGroup> : ''
                                }

                                {!this.state.isValidDownload ?
                                    <Paper>
                                        <Typography variant="subtitle2" style={{ color: '#FF0000', paddingLeft: '15px' }}>
                                            No files available for download.
                                </Typography>
                                    </Paper> : ''}
                            </div>
                        </Grid>
                        
                        <Grid item={true} xs={12}>
                            {this.state.isLoading?
                                <LoadingDataTable titleText={"*"} count={7}/>
                            :
                            <Fade in={true} {...({ timeout: SeedTime})}>
                            <div>
                            <MUIDataTable
                                title={<span><Typography className={classes.primaryBlue + " " + classes.circleNumberText}>Available Submissions</Typography></span>}
                                data={this.state.tableData}
                                columns={subListingColumns}
                                options={subListingOptions}
                                />
                            </div>
                            </Fade>
                            }
                        </Grid>

                    </Grid>
            )
    }

    private getColumnName(id: number): string {
        return TableColumns.find(c => c.Id === id).Name;
    }

    private showColumn(name: string): boolean {
        if (!this.props.viewColumnsSelected || this.props.viewColumnsSelected.length === 0)
            return true;
        return this.props.viewColumnsSelected.some(c => c === name);
    }

    private async toggleGetAllUsers() {
        this.setState({ isLoading: true });
        await this.refreshModel(!this.props.showAllSubmissions);
        this.props.setAllSubmissions(!this.props.showAllSubmissions);
        this.setState({ isLoading: false });
    }

    //private async setRowsPerPage(rowPerPage: number) {
    //    this.props.setAllSubmissions(!this.props.showAllSubmissions);
    //}

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    private async refreshModel(showAllSubmissions: boolean) {
        this.setState({ isLoading: true });

        const retval = await SubmissionsService.GetSubmissionsForUser$(
            this.props.user,
            showAllSubmissions
        ).toPromise();

        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            let submissionData: any[] = retval
                .map(item => {
                    return [
                        item.canEdit ? item.subId + "/" + item.component + "/" + item.productLine : "",
                        item.orgId,
                        item.subId,
                        item.orgName,
                        item.component.replace("DST", "HEDIS"),
                        item.productLine,
                        item.validationStatus,
                        item.reportingProduct,
                        item.specialProject,
                        item.specialArea,
                        item.cmsContractNumber,
                        item.reportingUnitId,
                        item.received,
                        SharedUtility.GetStageCleanName(item.submissionStatus),
                        item.componentVendorName === null ? "None" : item.componentVendorName,
                        item.isPublicReported !== null? (item.isPublicReported === true? "Yes":"No"): "Not Set"
                    ];
                });


            this.setState({
                tableData: submissionData
            });
            this.setState({ isLoading: false });
        }
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.AuthenticationReducer.user,
        viewColumnsSelected: state.SubmissionListingReducer.selectedSubmissionColumns,
        rowsPerPageSelected: state.SubmissionListingReducer.rowsPerPageSelected,
        showAllSubmissions: state.SubmissionListingReducer.showAllSubmissions,
        storedFilterList: state.SubmissionListingReducer.storedFilterList
    };
} 

const mapDispatchToProps = (dispatch): IComponentDispatchProps => {
    return {
        addColumn: (column: string) => dispatch({ type: actionTypes.ADD_COLUMN, payload: column }),
        removeColumn: (column: string) => dispatch({ type: actionTypes.REMOVE_COLUMN, payload: column }),
        setInitialColumns: (columnsList: string[]) => dispatch({ type: actionTypes.SET_INITIAL_COLUMNS, payload: columnsList }),
        setAllSubmissions: (value: boolean) => dispatch({ type: actionTypes.SET_ALL_SUBMISSIONS, payload: value }),
        setRowsPerPage: (value: number) => dispatch({ type: actionTypes.SET_SUBMISSION_PER_PAGE, payload: value }),
        setTableFilter: (filterList: any) => dispatch({ type: actionTypes.SET_TABLE_FILTER, payload: filterList})
    };
}

// REDUX CONTAINER
const AvailableSubmissionsPageWithState = connect<IComponentStoreProps, IComponentDispatchProps>(
    mapStateToProps, mapDispatchToProps
)(AllSubmissionsView);

// STYLES CONTAINER
const AvailableSubmissionsPage = withStyles(styles)(
    AvailableSubmissionsPageWithState
);

export default AvailableSubmissionsPage;
