import { AjaxError } from "rxjs/ajax";

class ExceptionService {
    public static StandardAjaxExceptionHandler(error: AjaxError): string {
        switch (error.status) {
            case 400: //Invalid field
                if (error.response != null)
                    return JSON.stringify(error.response);
                else return "Some fields are not valid.  Please correct and try again.";
            case 401: //Not Authorized
                return "The action requested requires you to be logged in to the site.  You may not have logged in or your session has expired.  Please log in before continuing.";
            case 403: //Forbidden
                return "You do not have sufficient privileges to complete this action.";
            case 500: // INTERNAL SERVER ERROR
                return "An error occurred while getting or saving your information.  Please try again.  If this problem persists, please contact us.";
            case 404: // Page not found
                return "The requested page not found.";
            case 0: // Connection issue
                return "Not connected.\nPlease verify your network connection.";
            default: // EVERYTHING ELSE
                return "An unexpected error occurred. Please try again. If this problem persists, please contact us.";
        }
    }
}

export default ExceptionService;


