import { AppBar, Button } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import '@material-ui/core/styles';
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import * as React from "react";
import { connect } from "react-redux";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import idssStyle from "../../Configuration/SharedStyling";
import AccountMenu from "../Shared/AccountMenu";

interface INavMenuTextState {
    open: boolean;
}

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme), ...headerStyles(theme) });
const headerStyles = (theme: Theme) => createStyles({
    logo: {
        height: '80px',
        marginBottom: '10px',
    },
    marginLeft: {
        marginLeft: '10px',
    },
})

type IStylesProps = WithStyles<typeof styles>;

class NavMenuTextView extends React.Component<IGenericUserProps & IStylesProps, INavMenuTextState> {

    constructor(props: any) {
        super(props);
        this.state = { open: false };
    }

    public componentDidMount() {
    }

    public render() {
        const { classes, user } = this.props;
        return (
            <div>
                <AppBar position='relative' className={classes.appBar}>
                    <Toolbar>
                        <Typography className={classes.appTitle}>
                            Interactive Data Submission System (IDSS)
                        </Typography>
                        <div className={classes.flexGrow}></div>

                        <Button className={classes.marginLeft} href={process.env.REACT_APP_MEDIA_NCQA_MY + 'Questions'} target='_blank' color='primary' >
                            Ask a Question
                        </Button>
                        <AccountMenu user={user.profile.username} />
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

// REDUX CONTAINER
const NavMenuTextViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(NavMenuTextView);

// STYLES CONTAINER
const NavViewTextPage = withStyles(styles)(NavMenuTextViewWithState);


export default NavViewTextPage;



