import * as React from 'react';
import { connect } from 'react-redux';
import { AjaxError } from 'rxjs/ajax';
import LoadingDataTable from "../../../Shared/LoadingDataTable";
import SubmissionsService from '../../../Services/SubmissionsService';
import { ILiveCell } from "../../../../DataClasses/HdfData"
import { IGenericUserProps, genericMapStateToProps } from "../../../../DataClasses/GenericUser";
import { Theme, withStyles, WithStyles, createStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import idssStyle from "../../../../Configuration/SharedStyling";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Box, Tab, Typography  } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import equal from 'fast-deep-equal'
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    liveMeasureTable: {
        borderSpacing: 0,
        margin: theme.spacing(2),
        borderBottom: "none",
        border: '1px solid #4f758b',
        "& tbody td": {
            padding: theme.spacing(2),
            border: '1px solid #4f758b',
            margin: '0px'
        }
    },
    rotate_text:
    {
        'moz-transform': 'rotate(-90deg)',
        'moz-transform - origin': 'top left',
        'webkit-transform': 'rotate(-90deg)',
        'webkit-transform-origin': 'top left',
        'o-transform': 'rotate(-90deg)',
        'o-transform-origin': 'top left',
        position: 'relative',
        top: '20px',
    },
    tabs: {    
        "& .Mui-selected": {
          color: 'SteelBlue',
          fontWeight: 'bold'
        }
      }
});

interface ILocalProps {
    measureCode: string,
    measureName: string,
    submissionId: string,
    productLine: string,
    measurementYear: number
}

interface ILocalState {
    measureData: Array<Array<ILiveCell>>;
    isLoading: boolean;
    redirect: boolean;
    isRaceEthnicity: boolean;
    curTabValue: string;

    measureBaseData: Array<Array<ILiveCell>>;
    measureRaceData: Array<Array<ILiveCell>>;
    measureEthnicityData: Array<Array<ILiveCell>>;
}

type IStylesProps = WithStyles<typeof styles>;

const allDownloadsOptions: MUIDataTableOptions = {
    filter: false,
    sort: true,
    filterType: "dropdown",
    print: false,
    download: false,
    selectableRows: "multiple",
    viewColumns: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
    caseSensitive : false,
    customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
            if (col.text !== null && col.text.toString().indexOf(searchQuery) >= 0) {
                isFound = true;
            }
        });
        return isFound;
    },
    setTableProps: () => {
        return {
            padding: "none",
            // material ui v4 only
            size:  "small" ,
        };
    }
};

class LiveMeasureViewer extends React.Component<IGenericUserProps & ILocalProps & IStylesProps, ILocalState> {

    constructor(props: IGenericUserProps & IStylesProps & ILocalProps) {
        super(props);

        this.state = {
            isLoading: true,
            measureData: [],
            redirect: false,
            isRaceEthnicity: false,
            curTabValue: "1",

            measureBaseData: [],
            measureRaceData: [],
            measureEthnicityData:[]
        }
    }

    public async componentDidMount() {
        await this.RefreshModel();
    }

    public async componentDidUpdate(prevProps) {
        if (!equal(this.props.measureCode, prevProps.measureCode))
        {
            await this.RefreshModel();
        }
    } 

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MuiTableCell: {
                root: {
                    fontSize: '0.8125rem',
                    fontFamily: 'sans-serif',
                    border: '1px solid #E0E0E0',
                },
                head: {
                    color: "#4F758B",
                    fontWeight: 'bold'
                },
                paddingNone: {
                    paddingLeft: '2px',
                    paddingRight: '2px'
                }
            }
        }
    })

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        if (this.state.isLoading) {
            return (
                <div>
                     <LoadingDataTable titleText={"*"}/>
                </div>
            )
        }
        else {
            const { classes } = this.props;
            var allDataHeaders = this.state.measureData[0].map(t => t.text);
            var allDataTmp = this.state.measureData.slice(1);
            var allDownloadsColumns = this.state.measureData[0].map(x => {
                var retval =
                {
                    name: x.text,
                    label: x.shortDisplayText.replace(/([a-z])([A-Z])/g, '$1 $2').trim(),
                    draggableColumns:
                    {
                        enabled: true
                    },
                    resizableColumns: true,
                    fixedHeader: true,
                    options: {
                        selectedRows: {
                            text: "row(s) selected",
                            delete: "Delete",
                            deleteAria: "Delete Selected Rows"
                        },


                        setCellProps: (cellValue: string, rowIndex: number, columnIndex: number) => {
                            if (this.state.measureData.slice(1)[rowIndex][columnIndex].foregroundColor === '#4F758B') {
                                return {
                                    style: {
                                        backgroundColor: '#4F758B',
                                        fontWeight: this.state.measureData.slice(1)[rowIndex][columnIndex].isBold ? 'bold' : 'normal'
                                    }
                                };
                            }
                            else {
                                return {
                                    style: {
                                        backgroundColor: 'white',
                                                                                fontWeight: this.state.measureData.slice(1)[rowIndex][columnIndex].isBold ? 'bold' : 'normal'

                                    }
                                };
                            }

                        },
                            display: (x.isHiddenByDefault ? 'false' : 'true') as ('true' | 'false' | 'excluded'),
                            //hint: x.shortDisplayText !== x.mouseOverDisplayText ? x.mouseOverDisplayText : "",
                            filter: x.isFilterable,
                            filterOptions: {
                                names: [...new Set(allDataTmp.map(z => z[allDataHeaders.indexOf(x.text)]).filter(c => c.isFilterable === true).map(a => a.text))],
                                logic(input, filterVal) {
                                    return input.text !== filterVal[0];
                                }
                            },
                            setCellHeaderProps: () => ({
                                className: classes.centeredHeader,
                            }),
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    //#eeaf30 is the highlight yellow
                                    <div className={value.text !== "" && (value.foregroundColor !== 'White' && value.foregroundColor !== 'LightSteelBlue') ? (value.foregroundColor !== '#eeaf30' ? classes.tableCellNcqaBlue : classes.tableCellYellow) : ""}
                                        style={{
                                            width: value.text !== "" && (value.foregroundColor !== 'White' && value.foregroundColor !== 'LightSteelBlue') ? "80px" : "max-content", padding: '2px'
                                        }}>{value.text}</div>
                                )
                        },
                        width: "50px",
                        download: false
                    }
                }
                return retval;
            }

            );
            var data = this.state.measureData.slice(1)
            const dataTbl = (<div max-width='1024px'>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                        title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>{this.props.measureName} ({this.props.measureCode})</Typography>}
                        data={this.state.measureData.slice(1)}
                        columns={allDownloadsColumns}
                        options={allDownloadsOptions}
                        />
                    </MuiThemeProvider>
                </div>);
            return (
                this.state.isRaceEthnicity?
                <TabContext value={this.state.curTabValue}>
                    <Box sx={{ borderBottom: 0, borderColor: 'divider'}}>
                        <TabList onChange={this.handleTabChange} className={classes.tabs}>
                            <Tab label={this.props.measureCode} value="1"/>
                            <Tab label={this.props.measureCode + " by Race"} value="2" />
                            <Tab label={this.props.measureCode + " by Ethnicity"} value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {dataTbl}
                    </TabPanel>
                    <TabPanel value="2">
                        {dataTbl}
                    </TabPanel>
                    <TabPanel value="3">
                        {dataTbl}
                    </TabPanel>
                </TabContext>
                :
                <div max-width='1024px'>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                        title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>{this.props.measureName} ({this.props.measureCode})</Typography>}
                        data={data}
                        columns={allDownloadsColumns}
                        options={allDownloadsOptions}
                        />
                    </MuiThemeProvider>
                </div>

            )
        }
    }

    private handleTabChange = (event, index: string) => {
        let data = this.state.measureData;
        switch(index){
            case "1": 
                data = this.state.measureBaseData;
                break;
            case "2":
                data = this.state.measureRaceData;
                break;
            case "3":
                data = this.state.measureEthnicityData;
                break;
            default:
                break;
        }
        this.setState({ curTabValue: index, measureData: data });
    };

    private async RefreshModel() {
        this.setState({ isLoading: true });
        let retval = await SubmissionsService.GetLiveMeasure(this.props.user,
            this.props.submissionId,
            this.props.productLine,
            this.props.measureCode,
            this.props.measurementYear).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            let isRaceEthnicity = (retval.measureWhole === null);
            let measureData: Array<Array<ILiveCell>> = [];
            let measureBaseData: Array<Array<ILiveCell>> = [];
            let measureRaceData: Array<Array<ILiveCell>> = [];
            let measureEthnicityData: Array<Array<ILiveCell>> = [];
            if(isRaceEthnicity){
                measureBaseData = retval.measureBase;
                measureRaceData = retval.measureRace;
                measureEthnicityData = retval.measureEthnicity;
                measureData = retval.measureBase;
            }
            else{
                measureData = retval.measureWhole;
            }
            this.setState({
                measureData: measureData,
                isLoading: false,
                isRaceEthnicity,
                measureBaseData,
                measureRaceData,
                measureEthnicityData,
                curTabValue: "1"
            });
        }
    }
}

const LiveMeasureDisplayPageWithState = connect<IGenericUserProps>(genericMapStateToProps)(LiveMeasureViewer);

const LiveMeasureDisplayPage = withStyles(styles)(LiveMeasureDisplayPageWithState);


export default LiveMeasureDisplayPage;

