import { User } from "oidc-client";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs";
import { IAdministrationPayload, IAppInfoItem, ICachingKey } from "../../DataClasses/AdministrationData";
import AjaxHelper from "./AjaxHelper";
import { IVendorMeasure, IVendorSubOrgMapping } from "../../DataClasses/VendorMeasure";

class AdministrationService {
    public static GetAdminModel(user: User): Observable<IAdministrationPayload> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetAdminModel',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static ReprocessCahpsFile$(user: User, fileName: string, groupId: any): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/ReprocessCahpsFile?fileName=' + fileName + '&groupId=' + groupId,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static SetAllowedStatus$(user: User, key: string, status: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/SetAllowedStatus?key=' + key + '&status=' + status,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UpdateAllSubmissionStatuses(user: User): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/UpdateAllSubmissionStatuses',
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static ClearAllLicenseAgreements$(user: User): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/ClearAllLicenseAgreements',
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static SetMaintenanceMode(user: User, enable: boolean): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/SetMaintenanceMode?enable=' + enable,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static SetOffseasonMode(user: User, enable: boolean): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/SetOffseasonMode?enable=' + enable,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static SetLicenseAgreementText(user: User, text: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/SetLicenseAgreementText',
            {
                text: text
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }


    public static HasSignedLegalAgreement(user: User): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/HasSignedLegalAgreement',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static AddLicenseAgreementForUser(user: User): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/AddLicenseAgreementForUser',
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static NeedsSignerTitle(user: User): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/NeedsSignerTitle',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetAllAuditors$(user: User): Observable<string[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetAllAuditors',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static DoesAuditorExist$(user: User, userEmail: string): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/DoesAuditorExist?auditorEmail=' + userEmail,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static AddAuditor(user: User, userEmail: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/AddAuditor?auditorEmail=' + userEmail,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static DeleteAuditor(user: User, userEmail: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/DeleteAuditor?auditorEmail=' + userEmail,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetAppInfo$(user: User): Observable<IAppInfoItem[]>{
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetAppInfo',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetMetadataCacheList$(user: User): Observable<ICachingKey[]>{
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetMetadataCacheList',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetConfigKey$(user: User, key: string): Observable<string> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetConfigKey?key=' + key,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetVendorMeasures$(user: User, productYear: number): Observable<IVendorMeasure[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetVendorMeasures?productYear=' + productYear,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetSoftwareVendorList$(user: User, productYear: number): Observable<IVendorSubOrgMapping[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/GetValidSoftwareVendorList?productYear=' + productYear,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static ClearVendorMeasureCache$(user: User){
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/ClearVendorMeasureCache',
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static DownloadCache$(key: string, user: User): Observable<string> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/DownloadCache?key=' + key,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static ClearCaches$(cacheKeysCsv: string, user: User){
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Administration/ClearCaches?cacheKeysCsv=' + cacheKeysCsv,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
}

export default AdministrationService;