import * as React from "react";
import { connect } from "react-redux";
import {
    Tabs,
    Tab,
    Typography,
    AppBar,
    Grid,
    Card,
    Button,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CardContent,
    Fade,
    CircularProgress,
    ButtonGroup,
    Box
} from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
    WithStyles,
} from "@material-ui/core/styles";
import LoadingDisplay from "../Shared/Loading";
import AdministrationService from "../Services/AdministrationService";
import { AjaxError } from "rxjs/ajax";
import { IAdministrationPayload } from "../../DataClasses/AdministrationData";
import {
    IGenericUserProps,
    genericMapStateToProps,
} from "../../DataClasses/GenericUser";
import * as signalR from "@aspnet/signalr";
import request from "superagent";
import AlertDialog from "../Shared/AlertDialog";
import froalaBaseConfig from "../../Utilities/BaseFroalaConfig";
import FroalaEditor from "react-froala-wysiwyg";
import { Save as SaveIcon } from "@material-ui/icons";
import SubmissionAdministrationPage from "./SubmissionAdministration";
import AnnouncementsListPage from "./AnnouncementsList";
import AuditorsListPage from "./Auditors";
import idssStyle from "../../Configuration/SharedStyling";
import { Redirect } from "react-router-dom";
import SubmissionDataExtractPage from "./SubmissionDataExtract";
import LegacyFileConverterPage from "./LegacyFileConverter"
import SharedUtility from "../../Utilities/SharedUtility";
import saveAs from 'file-saver';
import SubmissionsService from "../Services/SubmissionsService";
import preval from 'preval.macro';
import VendorMeasureGuidListPage from "./VendorMeasureGuidList";
import AppInfoPage from "./AppInfo";
import CacheManagementPage from "./CacheManagement";

interface IAdministrationState {
    isLoading: boolean;
    payload: IAdministrationPayload;
    cahpsFileName: string;
    currentTabVal: number;
    cahpsProcessingState: number;
    selectedMatrixFile?: File;
    matrixFileTypeValid: boolean;
    isUploading: boolean;
    inputFileKey: string;
    showConfirmDialog: boolean;
    confirmDialogTitle: string;
    confirmDialogDescription: string;
    confirmDialogButtonText: string;
    confirmDialogConfirmHandler: any;
    frConfig: any;
    isLicenseAgreementSaving: boolean;
    selectedConfigurationOption: string;
    configurationValue: boolean;
    redirect: boolean;
    showHedis: boolean;
    isDownloadInProgress: boolean;
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ paddingTop: "15px" }}>
            {props.children}
        </Typography>
    );
}

export const AdministrationInitialState: IAdministrationState = {
    payload: {
        allowsHosSubmission: false,
        allowsCahpsSubmission: true,
        allowsHedisSubmission: true,
        enableMeasureGuidValidation: true,
        enableExperimentalValidation: true,
        isMaintenanceMode: false,
        isOffseasonMode: false,
        licenseAgreementText: "",
        enableGenericUpload: false,
        measurementYear: 0,
        enableHosInterimDataSubmission: false,
        measureModelDate: ""
    },
    isLoading: true,
    cahpsFileName: "",
    currentTabVal: 0,
    cahpsProcessingState: 0,
    matrixFileTypeValid: false,
    isUploading: false,
    inputFileKey: "inputKey",
    showConfirmDialog: false,
    confirmDialogTitle: "",
    confirmDialogDescription: "",
    confirmDialogButtonText: "",
    confirmDialogConfirmHandler: () => { },
    frConfig: {
        toolbarButtons: [
            "bold",
            "italic",
            "underline",
            "insertLink",
            "undo",
            "redo",
        ],
        attribution: false,
    },
    isLicenseAgreementSaving: false,
    selectedConfigurationOption: '',
    configurationValue: false,
    redirect: false,
    showHedis: false,
    isDownloadInProgress: false
};

const styles = (theme: Theme) =>
    createStyles({
        ...idssStyle(theme),
        headerRow: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
            width: "calc(100% + 100px)",
        },
        tabRoot: {
            flexGrow: 1,
            marginTop: "25px",
        },
        tableRoot: {
            width: "100%",
            marginTop: theme.spacing(3),
            overflowX: "auto",
        },
        table: {
            minWidth: 700,
        },
        tableCellYellow: {
            backgroundColor: "yellow",
        },
        row: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.default,
            },
        },
        right: {
            display: "flex",
            textAlign: "right",
        },
        progress: {
            verticalAlign: 'middle',
            paddingLeft: '5px'
        }
    });

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class Administration extends React.Component<
    IGenericUserProps & IStylesProps,
    IAdministrationState
> {
    constructor(props: any) {
        super(props);

        this.state = AdministrationInitialState;
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("backgroundHub")
            .build();

        connection.start().catch((err) => document.write(err));

        connection.on("callComplete", () => {
            this.setState({ cahpsProcessingState: 2 });
        });
    }

    public componentDidMount() {
        // This method runs when the component is first added to the page
        this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const tabValue = this.state.currentTabVal;
        if (this.state.isLoading) {
            return <LoadingDisplay />;
        } else {
            const { classes } = this.props;

            return (
                <div className={classes.tabRoot}>
                    <Box
                          sx={{
                            flexGrow: 1,
                            maxWidth: { xs: "65%", sm: "65%" }
                          }}
                        >
                    <AppBar position="static">
                        <Tabs value={tabValue} onChange={this.handleTabChange} variant="scrollable" scrollButtons="auto">
                            <Tab label="Administration Home" value={0} />
                            <Tab label="Cache Manager" value={9} />
                            <Tab label="Submission Reprocessing" value={1} />
                            <Tab label="Vendor Measures" value={8} />
                            <Tab label="Security Matrix" value={2} />
                            <Tab label="Announcements" value={3} />
                            <Tab label="License Agreement" value={4} /><br/>
                            <Tab label="Auditors" value={5} />
                            <Tab label="Data Extract" value={6} />
                            <Tab hidden={true} label="Legacy File Conversion" value={7} />
                            <Tab label="App Info" value={10} />
                        </Tabs>
                    </AppBar>
                    </Box>
                    {tabValue === 0 && this.renderAdminHomeTab()}
                    {tabValue === 1 && this.renderSubmissionReprocessingTab()}
                    {tabValue === 2 && this.renderSecurityMatrixTab()}
                    {tabValue === 3 && this.renderAnnouncementsTab()}
                    {tabValue === 4 && this.renderLicenseAgreementTab()}
                    {tabValue === 5 && this.renderAuditorsTab()}
                    {tabValue === 6 && this.renderSubmissionDataExtractTab()}
                    {tabValue === 7 && this.renderLegacyFileConversionTab()}
                    {tabValue === 8 && this.renderVendorMeasuresTab()}
                    {tabValue === 9 && this.renderCacheManagementTab()}
                    {tabValue === 10  && this.renderAppInfoTab()}
                </div>
            );
        }
    }

    private uploadMatrixFile(file: File) {
        this.setState({ isUploading: true });
        var apiBaseUrl =
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL +
            "/api/administration/uploadsecuritymatrix";
        const data = new FormData();
        data.append("File", file, file.name);
        const req = request.post(apiBaseUrl);
        req.set({ Authorization: "Bearer " + this.props.user.access_token });
        req.send(data).end((err: any, res: any) => {
            // send the request
            this.setState({
                inputFileKey: new Date().toString(),
                matrixFileTypeValid: false,
                isUploading: false,
            });
        });
    }

    private handleMatrixFileChange(e) {
        if (!e.target.files) {
            return;
        }
        this.setState({ selectedMatrixFile: e.target.files[0] });
        if (e.target.files[0].name.endsWith(".csv")) {
            this.setState({ matrixFileTypeValid: true });
        } else {
            this.setState({ matrixFileTypeValid: false });
        }
    }

    private async SetAllowedStatus(option: string, value: boolean) {
        this.setState({
            selectedConfigurationOption: option,
            configurationValue: value,
            showConfirmDialog: true,
            confirmDialogTitle: "Configuration Option",
            confirmDialogDescription:
                `Are you sure you want to ${value ? 'Enable' : 'Disable'} the ${option} option?. Note: You must logout & login to see the changes.`,
            confirmDialogButtonText: "Yes",
            confirmDialogConfirmHandler: async () => {
                this.SetStatus();
            },
        });
    }

    private async SetStatus() {
        var convertedStatus: string = String(this.state.configurationValue);
        const retval = await AdministrationService.SetAllowedStatus$(
            this.props.user,
            this.state.selectedConfigurationOption,
            convertedStatus
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            await this.RefreshModel();
        }
        this.CloseConfirmDialog();
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval = await AdministrationService.GetAdminModel(
            this.props.user
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({ payload: retval });
        }
        this.setState({ isLoading: false });
    }

    handleChange = () => (event) => {
        this.setState({ cahpsFileName: event.target.value });
    };

    public handleTabChange = (event, index: number) => {
        this.setState({ currentTabVal: index });
    };

    private async UpdateAllSubmissionStatuses() {
        const retval = await AdministrationService.UpdateAllSubmissionStatuses(
            this.props.user
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
    }

    private async ClearAllLicenseAgreements() {
        const retval = await AdministrationService.ClearAllLicenseAgreements$(
            this.props.user
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        this.CloseConfirmDialog();
    }

    private ShowMaintenanceModeConfirmDialog(enable: boolean) {
        this.setState({
            showConfirmDialog: true,
            confirmDialogTitle: "Emergency Maintenance Mode Confirmation",
            confirmDialogDescription:
                "Are you sure you want to " +
                (enable ? "enable" : "disable") +
                " Emergency Maintenance Mode?",
            confirmDialogButtonText: enable ? "Enable" : "Disable",
            confirmDialogConfirmHandler: async () => {
                this.SetMaintenanceMode(enable);
            },
        });
    }

    private ClearLicenseAgreementsDialog() {
        this.setState({
            showConfirmDialog: true,
            confirmDialogTitle: "Clear License Agreements",
            confirmDialogDescription:
                "Are you sure you want to clear all the license agreements for current year?",
            confirmDialogButtonText: "Yes",
            confirmDialogConfirmHandler: async () => {
                this.ClearAllLicenseAgreements();
            },
        });
    }

    private async SetMaintenanceMode(enable: boolean) {
        const retval = await AdministrationService.SetMaintenanceMode(
            this.props.user,
            enable
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        this.setState({
            payload: { ...this.state.payload, isMaintenanceMode: enable },
        });
        this.CloseConfirmDialog();
    }

    private ShowOffseasonModeConfirmDialog(enable: boolean) {
        this.setState({
            showConfirmDialog: true,
            confirmDialogTitle: "Offseason Mode Confirmation",
            confirmDialogDescription:
                "Are you sure you want to " +
                (enable ? "enable" : "disable") +
                " Offseason Mode?",
            confirmDialogButtonText: enable ? "Enable" : "Disable",
            confirmDialogConfirmHandler: async () => {
                this.SetOffseasonMode(enable);
            },
        });
    }

    private async SetOffseasonMode(enable: boolean) {
        const retval = await AdministrationService.SetOffseasonMode(
            this.props.user,
            enable
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        this.setState({
            payload: { ...this.state.payload, isOffseasonMode: enable },
        });
        this.CloseConfirmDialog();
    }

    private async downloadAllProductLineTemplates() {
        this.setState({ isDownloadInProgress: true });

        const retVal = await SubmissionsService.DownloadAllProductLineTemplates$(this.props.user).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {
            const data = SharedUtility.Str2ab$(atob(retVal.fileContents));
            saveAs(
                new Blob([data], { type: "application/octet-stream" }),
                retVal.fileName
            );
        }

        this.setState({ isDownloadInProgress: false });
    }

    private async SetLicenseAgreementText(text: string) {
        this.setState({ isLicenseAgreementSaving: true });
        const retval = await AdministrationService.SetLicenseAgreementText(
            this.props.user,
            text
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        this.setState({
            payload: { ...this.state.payload, licenseAgreementText: text },
            isLicenseAgreementSaving: false,
        });
    }

    private CloseConfirmDialog() {
        this.setState({
            selectedConfigurationOption: '',
            configurationValue: false,
            showConfirmDialog: false,
            confirmDialogTitle: "",
            confirmDialogDescription: "",
            confirmDialogButtonText: "",
            confirmDialogConfirmHandler: () => { },
        });
    }

    private renderAdminHomeTab = () => {
        const { classes } = this.props;
        return (
            <TabContainer>
                <Grid container={true} className={classes.root} spacing={3}>
                    <Grid key={1} item={true} xs={3} className={classes.gridDisplay}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography className={classes.primaryBlue}>
                                    Configuration Options
                                </Typography>
                                <FormControl>
                                    <FormLabel>Enable / Disable Options</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.payload.allowsHosSubmission}
                                                    onChange={async () =>
                                                        await this.SetAllowedStatus(
                                                            "HOS",
                                                            !this.state.payload.allowsHosSubmission
                                                        )
                                                    }
                                                />
                                            }
                                            label="HOS Submissions Enabled"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.payload.allowsCahpsSubmission}
                                                    onChange={async () =>
                                                        await this.SetAllowedStatus(
                                                            "CAHPS",
                                                            !this.state.payload.allowsCahpsSubmission
                                                        )
                                                    }
                                                />
                                            }
                                            label="CAHPS Submissions Enabled"
                                        />
                                        {!this.state.showHedis ? null :
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={this.state.payload.allowsHedisSubmission}
                                                        onChange={async () =>
                                                            await this.SetAllowedStatus(
                                                                "HEDIS",
                                                                !this.state.payload.allowsHedisSubmission
                                                            )
                                                        }
                                                    />
                                                }
                                                label="HEDIS Submissions Enabled"
                                            />
                                        }
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.payload.enableMeasureGuidValidation}
                                                    onChange={async () =>
                                                        await this.SetAllowedStatus(
                                                            "MEASUREGUIDVALIDATION",
                                                            !this.state.payload.enableMeasureGuidValidation
                                                        )
                                                    }
                                                />
                                            }
                                            label="Enable Measure Guid Validation"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.payload.enableExperimentalValidation}
                                                    onChange={async () =>
                                                        await this.SetAllowedStatus(
                                                            "EXPERIMENTALVALIDATION",
                                                            !this.state.payload.enableExperimentalValidation
                                                        )
                                                    }
                                                />
                                            }
                                            label="Enable Experimental Validation"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.payload.enableGenericUpload}
                                                    onChange={async () =>
                                                        await this.SetAllowedStatus(
                                                            "GENERICUPLOAD",
                                                            !this.state.payload.enableGenericUpload
                                                        )
                                                    }
                                                />
                                            }
                                            label="Enable Generic Uploads"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.payload.enableHosInterimDataSubmission}
                                                    onChange={async () =>
                                                        await this.SetAllowedStatus(
                                                            "HOS_INTERIM_SUBMISSION",
                                                            !this.state.payload.enableHosInterimDataSubmission
                                                        )
                                                    }
                                                />
                                            }
                                            label="Enable HOS Interim Data Submission"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                        <AlertDialog
                            showDialog={this.state.showConfirmDialog}
                            title={this.state.confirmDialogTitle}
                            description={this.state.confirmDialogDescription}
                            button1Text={this.state.confirmDialogButtonText}
                            button2Text="Cancel"
                            button1Handler={this.state.confirmDialogConfirmHandler}
                            button2Handler={() => this.CloseConfirmDialog()}
                        />
                    </Grid>
                    <Grid key={3} item={true} xs={3} className={classes.gridDisplay}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography
                                    className={classes.primaryBlue + " " + classes.marginBottom}
                                >
                                    Actions
                                </Typography>
                                <ButtonGroup orientation="vertical">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () =>
                                            await this.UpdateAllSubmissionStatuses()
                                        }
                                    >
                                        Reload Cache
                                    </Button>
                                    <div className="mb1">&nbsp;</div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.ClearLicenseAgreementsDialog()}
                                    >
                                        Clear License Agreements
                                    </Button>
                                    <div className="mb1">&nbsp;</div>
                                    {!this.state.payload.isMaintenanceMode && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    this.ShowMaintenanceModeConfirmDialog(true)
                                                }
                                            >
                                                Enable Maintenance Mode
                                            </Button>
                                            <div className="mb1">&nbsp;</div>
                                        </>
                                    )}
                                    {this.state.payload.isMaintenanceMode && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    this.ShowMaintenanceModeConfirmDialog(false)
                                                }
                                            >
                                                Disable Maintenance Mode
                                            </Button>
                                            <div className="mb1">&nbsp;</div>
                                        </>
                                    )}
                                    {!this.state.payload.isOffseasonMode && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.ShowOffseasonModeConfirmDialog(true)}
                                        >
                                            Enable Offseason Mode
                                        </Button>
                                    )}
                                    {this.state.payload.isOffseasonMode && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.ShowOffseasonModeConfirmDialog(false)}
                                        >
                                            Disable Offseason Mode
                                        </Button>
                                    )}
                                    <div className="mb1">&nbsp;</div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.downloadAllProductLineTemplates()}
                                        disabled={this.state.isDownloadInProgress}
                                    >
                                        Download All Submission Templates
                                        {this.state.isDownloadInProgress ? <span className={classes.progress}><CircularProgress color="primary" size={22} /></span> : null}
                                    </Button>

                                </ButtonGroup>

                                <AlertDialog
                                    showDialog={this.state.showConfirmDialog}
                                    title={this.state.confirmDialogTitle}
                                    description={this.state.confirmDialogDescription}
                                    button1Text={this.state.confirmDialogButtonText}
                                    button2Text="Cancel"
                                    button1Handler={this.state.confirmDialogConfirmHandler}
                                    button2Handler={() => this.CloseConfirmDialog()}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid key={4} item={true} xs={3} className={classes.gridDisplay}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography
                                    className={classes.primaryBlue + " " + classes.marginBottom}
                                >
                                    App Version
                                </Typography>
                                <Typography variant='body2' color="inherit"  >
                                    R4.0 Build: {preval`module.exports = new Date().toLocaleDateString().replace("\/", ".").replace("\/", ".");`}
                                </Typography>
                                <Typography
                                    className={classes.primaryBlue + " " + classes.marginBottom}
                                >
                                    App MeasurementYear
                                </Typography>
                                <Typography variant='body2' color="inherit"  >
                                    {this.state.payload.measurementYear}
                                </Typography>
                                <Typography
                                    className={classes.primaryBlue + " " + classes.marginBottom}
                                >
                                    MeasureModel Date
                                </Typography>
                                <Typography variant='body2' color="inherit"  >
                                    {this.state.payload.measureModelDate}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </TabContainer>
        );
    };

    private renderSubmissionReprocessingTab = () => {
        return (
            <TabContainer>
                <div>
                    <SubmissionAdministrationPage />
                </div>
            </TabContainer>
        );
    }

    private renderSecurityMatrixTab = () => {
        const { classes } = this.props;
        return (
            <TabContainer>
                <Grid container={true} className={classes.root} spacing={2}>
                    <Grid key={1} item={true} className={classes.gridDisplay}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography className={classes.primaryBlue}>
                                    Security Matrix
                                </Typography>
                                <span className="btn btn-info">
                                    <input
                                        type="file"
                                        name="file"
                                        key={this.state.inputFileKey}
                                        onChange={(e) => this.handleMatrixFileChange(e)}
                                    />
                                </span>
                                <br />
                                <label className="text-muted">
                                    * Consult IS about Matrix file format.{" "}
                                </label>
                                <div>
                                    {" "}
                                    <button
                                        className="btn btn-primary"
                                        disabled={
                                            !this.state.matrixFileTypeValid || this.state.isUploading
                                        }
                                        onClick={() =>
                                            this.uploadMatrixFile(this.state.selectedMatrixFile)
                                        }
                                    >
                                        {this.state.isUploading ? "Uploading..." : "Upload"}
                                    </button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </TabContainer>
        );
    };

    private renderLicenseAgreementTab = () => {
        const { classes } = this.props;
        return (
            <TabContainer>
                <div>
                    <FroalaEditor
                        config={{ ...froalaBaseConfig, ...this.state.frConfig }}
                        model={this.state.payload.licenseAgreementText}
                        onModelChange={(e) =>
                            this.setState({
                                payload: { ...this.state.payload, licenseAgreementText: e },
                            })
                        }
                    />
                    <FormControl className={classes.right}>
                        <Button
                            variant={"contained"}
                            onClick={() =>
                                this.SetLicenseAgreementText(
                                    this.state.payload.licenseAgreementText
                                )
                            }
                            startIcon={
                                this.state.isLicenseAgreementSaving ? (
                                    <Fade
                                        in={this.state.isLicenseAgreementSaving}
                                        style={{
                                            transitionDelay: this.state.isLicenseAgreementSaving
                                                ? "800ms"
                                                : "0ms",
                                        }}
                                        unmountOnExit={true}
                                    >
                                        <CircularProgress color="secondary" size={20} />
                                    </Fade>
                                ) : (
                                    <SaveIcon />
                                )
                            }
                        >
                            Save
                        </Button>
                    </FormControl>
                </div>
            </TabContainer>
        );
    };

    private renderAnnouncementsTab = () => {
        return (
            <TabContainer>
                <div>
                    <AnnouncementsListPage />
                </div>
            </TabContainer>
        );
    };

    private renderAuditorsTab = () => {
        return (
            <TabContainer>
                <div>
                    <AuditorsListPage />
                </div>
            </TabContainer>
        );
    };

    private renderSubmissionDataExtractTab = () => {
        return (
            <TabContainer>
                <div>
                    <SubmissionDataExtractPage />
                </div>
            </TabContainer>
        );
    };

    private renderLegacyFileConversionTab = () => {
        return (
            <TabContainer>
                <div>
                    <LegacyFileConverterPage />
                </div>
            </TabContainer>
        );
    };

    private renderVendorMeasuresTab = () => {
        return(
            <TabContainer>
                <div>
                    <VendorMeasureGuidListPage productYear ={this.state.payload.measurementYear + 1}/>
                </div>
            </TabContainer>
        );
    }

    private renderAppInfoTab = () => {
        return (
            <TabContainer>
                <div>
                    <AppInfoPage />
                </div>
            </TabContainer>
        );
    }

    private renderCacheManagementTab = () => {
        return (
            <TabContainer>
                <div>
                    <CacheManagementPage />
                </div>
            </TabContainer>
        );
    }
}

// REDUX CONTAINER
const AdministrationViewWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(Administration);

// STYLES CONTAINER
const AdministrationPage = withStyles(styles)(AdministrationViewWithState);

// REDUX CONTAINER

export default AdministrationPage;
