//import * as React from "react";

const PrivacyPolicyHtml = (
    <div>
        <h4>How does NCQA collect and use your information for the IDSS Platform? </h4>
        <p style={{ textDecoration: 'underline' }}>
            User Information:
        </p>
        <p>
            IDSS stands for the Interactive Data Submission System. This is NCQA’s web platform that organizations use to submit information to NCQA.
            The organization registers its name and contact information with NCQA and establishes an administrative user who manages all the users for that organization. Users must have a user ID, password, user name, and a unique e-mail address. Users must sign a license agreement to have access to IDSS.
        </p>
        <br />
        <p style={{ textDecoration: 'underline' }}>
            Organization Data:
        </p>
        <p>
            IDSS collects the nonsurvey HEDIS data which are used to calculate national performance statistics and benchmarks, and to set standards for measures included in NCQA’s Accreditation program.
        </p>
        <br />
        <h4>
            Does IDSS Platform use cookies?
        </h4>
        <p>
            The IDSS Platform uses a single cookie to manage the user’s session ID. The session ID lasts only as long as the user is logged into the IDSS Platform. The data collected in a session is used by NCQA on an aggregate basis for the purpose of future resource planning for the IDSS Platform.
        </p>
        <br />
        <h4>
            How does NCQA use your information?
        </h4>
        <p>
            NCQA will use the information you provide through the IDSS Platform as described in this Privacy Policy and in the written agreements between the organization and NCQA, including the Software License Agreement that users accept when accessing the IDSS Platform, the legal agreement for NCQA recognition or distinction, as applicable (also sometimes referred to as the Agreement for NCQA’s Recognition Program), and the policies and procedures governing the NCQA Recognition Program.
        </p>
        <br />
        <h4>
            How does NCQA secure my information?
        </h4>
        <p>
            The IDSS Platform resides on a secure site. Data transmission is secured through SSL. An organization may only access its own data through a password, which the organization maintains. NCQA stores the evaluation data and documents in an encrypted environment with access controlled by password and user rights assigned by the organization. NCQA staff and surveyors involved in the organization’s evaluation will have access to the information, as well as individuals performing system maintenance. Others may have access to the information as prescribed in the Agreement for NCQA Recognition Program and the policies and procedures governing NCQA’s Recognition Program.
        </p>
        <br />
        <h4>
            Whom can you contact if you have questions or concerns?
        </h4>
        <p>
            Contact <a href="mailto:customersupport@ncqa.org">customersupport@ncqa.org</a>, to report any known or suspected privacy or security breaches or to submit privacy-related questions or complaints. You may contact us in the following ways:
        </p>
        <br />
        <h6>
            NCQA
        </h6>
        <h6>
            1100 13th St., NW
        </h6>
        <h6>
            Suite 1000
        </h6>
        <h6>
            Washington, DC 20005
        </h6>
        <h6>888/275-7585</h6>
    </div>
);

export default PrivacyPolicyHtml