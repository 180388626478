import { AppBar, Grid, withWidth } from "@material-ui/core";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { WithWidth } from "@material-ui/core/withWidth";
//import * as classNames from "classnames";
import * as React from "react";
import CenterLinks from './CenterLinks';
import LogoAddress from './LogoAddress';

// STYLES
const styles = (theme: Theme) => createStyles({
    centerLinkBlock: {
        display: 'flex',
        flexGrow: 1
    },
    containerGrid: {
        flexWrap: 'nowrap',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    footer: {
        backgroundColor: '#4f758b',
        borderTop: '10px solid #496b80',
        zIndex: 0,
        padding: "50px 30px"
    },
    logoAddressBlock: {
        minWidth: '220px',
        [theme.breakpoints.up('md')]: {
            flexGrow: 0
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
        }
    },
    root: {
        flexGrow: 0
    },
    socialMediaBlock: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        minWidth: '220px',
        [theme.breakpoints.up('md')]: {
            flexGrow: 0
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            justifyContent: 'center'
        }
    },
})

type IStylesProps = WithStyles<typeof styles> & WithWidth;

class FooterView extends React.Component<IStylesProps> {
    public render() {
        const { classes } = this.props;

        return (
            <AppBar position='sticky' color='default' component='div' className={classes.footer}>
                {/* <Toolbar> */}
                <Grid container={true} className={classes.containerGrid}>

                    {/* Logo and Address */}
                    <Grid item={true} className={classes.logoAddressBlock}>
                        <LogoAddress />
                    </Grid>

                    {/* Center Links */}
                    <Grid item={true} className={classes.centerLinkBlock}>
                        <CenterLinks />
                    </Grid>
                </Grid>
                {/* </Toolbar> */}
            </AppBar>
        )
    }
}

// STYLES CONTAINER
const FooterWithWidth = withStyles(styles)(FooterView);
const Footer = withWidth()(FooterWithWidth);

export default Footer;