import { User } from "oidc-client";
import { Observable } from "rxjs";
import { IFilesUploadReportPayload, ISubmissionAccredStatus, ISurveyVenderSubmissionStatusReportPayload, IUserSubmissionTypeAccessibility } from "../../DataClasses/ReportData";
import { IEvent } from "../../DataClasses/DbData";
import AjaxHelper from "./AjaxHelper";

class ReportsService {
    public static GetCombinedReportCaller$(user: User): Observable<string> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetCombinedReport',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetUploadReportCaller(user: User, transactionNumber: string): Observable<string> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetUploadReport?transactionNumber=' + transactionNumber,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetFilesUploadReportCaller$(user: User, getAll: boolean): Observable<IFilesUploadReportPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/DB/FilesUploadReportDatas?getAll=' + getAll,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetHosSubmissionStatusReportCaller$(user: User, measurementYear: number): Observable<ISurveyVenderSubmissionStatusReportPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetHosVendorSubmissionStatus?measurementYear=' + measurementYear,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetCahpsSubmissionStatusReportCaller$(user: User, measurementYear: number): Observable<ISurveyVenderSubmissionStatusReportPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetCahpsVendorSubmissionStatus?measurementYear=' + measurementYear,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetAvailableMeasurementYearsCaller$(user: User): Observable<number[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetAvailableMeasurementYears',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetEventListCaller$(user: User): Observable<IEvent[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Events/GetEventsForUser?numberReturned=0&forAllSubs=true',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionEvents$(user: User, subId: number, component: string): Observable<IEvent[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Events/GetSubmissionEvents?subId=' + subId + '&component=' + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionsAccredStatus$(user: User): Observable<ISubmissionAccredStatus[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetSubmissionsAccredStatus',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionsDataSummary$(user: User): Observable<any> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionsDataSummary',
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static GetValidationReportData$(user: User): Observable<any> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetValidationReportData',
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static GetSubmissionCountOnMeasureReportData$(user: User): Observable<any> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionCountsOnMeasures',
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static GetUserLicenseAgreement$(user: User): Observable<string[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetUserLicenseAgreement',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetUserSubmissionTypeAccessibility$(user: User): Observable<IUserSubmissionTypeAccessibility> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/GetUserSubmissionTypeAccessibility',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static CanUserAccessAttestationTemplates$(user: User): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Reports/CanUserAccessAttestationTemplates',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
}

export default ReportsService;