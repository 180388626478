import { User } from "oidc-client";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs";
import { IUserRolePayload, IOrgUserPayload } from "../../DataClasses/UsersData";
import { IMenuActionAllowance } from "../../DataClasses/ActionAllowanceData";
import AjaxHelper from "./AjaxHelper";

class UserManagerService {

    //#region Global Manage Users

    public static GetRolesForUser$(user: User, userName: string): Observable<IUserRolePayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetUserOrgsAndRoles?userName=' + userName,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetManagableRolesForUser$(user: User, userName: string): Observable<IUserRolePayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetManagableRolesForUser?userName=' + userName,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetAccountRolesForCurrentUser$(user: User): Observable<IUserRolePayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetAccountRolesForCurrentUser',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetAllRolesPerSysGroup$(user: User, sysGroup: string): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllRolesPerSysGroup?sysGroup=' + sysGroup);
    }
    public static GetAllRolesPerSysGroupEditUser$(user: User, sysGroup: string, editUser: string): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllRolesPerSysGroup?sysGroup=' + sysGroup + '&editUser=' + editUser);
    }
    public static GetAllSysGroups$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllSysGroups');
    }
    public static GetAllOrgRoles$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllOrgRoles');
    }

    public static GetAllSubmissionRoles$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllSubmissionRoles');
    }
    public static GetAllSystemRoles$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllSystemRoles');
    }
    public static GetAllVendorRoles$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllVendorRoles');
    }

    public static AddNewUserToSystem$(user: User, userEmail: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/AddNewUserToSystem?userEmail=' + userEmail);
    }

    // Not at all sure this one works.
    public static DeleteUserRole$(user: User, userToDeleteId: string, userRoleInfo: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/DeleteUserRole?userId=' + userToDeleteId + '&userRoleInfo=' + userRoleInfo);
    }

    public static DeleteAllUserRoles$(user: User, userToDeleteId: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/DeleteAllUserRoles?userId=' + userToDeleteId);
    }

    public static DeleteAllOrgUsers$(user: User, orgId: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/DeleteAllOrgUsers?organizationId=' + orgId);
    }


    public static DeleteUserAccess$(user: User, userToDeleteId: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/DeleteUserAccess?userId=' + userToDeleteId);
    }

    public static DeleteManagableUserAccess$(user: User, userToDeleteId: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/DeleteManagableUserAccess?userName=' + userToDeleteId);
    }

    public static AddUserToRoles$(user: User, userName: string, selectedSysGroup: string, selectedRoles: string, selectedOrgs: string, inputSubIds: string, isNewUser: boolean): Observable<AjaxResponse<any> | AjaxError> {
        var funcStr = isNewUser ? 'AddNewUserToRoles' : 'AddUserToRoles';
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/' + funcStr,
            {
                userName: userName,
                sysGroup: selectedSysGroup,
                roles: selectedRoles,
                orgIds: selectedOrgs,
                subIds: inputSubIds
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UpdateSubUserEntities$(user: User, userName: string, organizationId: number, selectedSubs: number[]): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/UpdateSubUserEntities?userName=' + userName + '&orgId=' + organizationId + '&subIds=' + selectedSubs.join(','));
    }

    public static IsActionAuthorized$(user: User, actionName: string, entityType: string, entityId: number): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/IsActionAuthorized?actionName=' + actionName + '&entityType=' + entityType + '&entityId=' + entityId,
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static GetAllAuthorizedUserActions$(user: User, entityType: string, entityId: number): Observable<Map<string, boolean>> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetAllAuthorizedUserActions?entityType=' + entityType + '&entityId=' + entityId,
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    // #endregion

    //#region All Users
    public static GetAllUsers$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetAllUsers');
    }
    //#endregion

    //#region get Managable Users
    public static GetManagableUsers$(user: User): Observable<string[]> {
        return UserManagerService.BasicUMGet(user, '/api/UserManager/GetManagableUsers');
    }

    public static GetUsersForOrganization$(user: User, organizationId: number): Observable<IOrgUserPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetUsersForOrganization?organizationId=' + organizationId,
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static CanUserDownloadCAHPSReports$(user: User): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/CanUserDownloadCAHPSReports',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static IsHEDISOnlyUser$(user: User, submissionType: string, action: string): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/IsHEDISOnlyUser?submissionType=' + submissionType + '&action=' + action,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    private static BasicUMGet(user: User, callString: string): Observable<string[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + callString,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static BasicUMPost(user: User, callString: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + callString,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetMenuItemAvailabilities$(user: User): Observable<IMenuActionAllowance> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetMenuActionAvailabilities',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetAccessableSubIds$(user: User, userName): Observable<number[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/GetAccessableSubIds?userName=' + userName,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static BulkUserOperation$(user: User, bulkAction: string, sysGroupType: string, role: string, userEmails: string,
        entityIds: string, copyFromUserEmail: string, removeExistingRoles: boolean): Observable<AjaxResponse<any> | AjaxError> {
        var data = {
            bulkAction: bulkAction,
            sysGroupType: sysGroupType,
            role: role,
            userEmails: userEmails,
            entityIds: entityIds,
            copyFromUserEmail: copyFromUserEmail,
            removeExistingRoles: removeExistingRoles
        };
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/BulkUserOperation',
            data,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static LockUser$(user: User, userEmail: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/LockUser?userEmail=' + userEmail);
    }

    public static UnLockUser$(user: User, userEmail: string): Observable<AjaxResponse<any> | AjaxError> {
        return UserManagerService.BasicUMPost(user, '/api/UserManager/UnLockUser?userEmail=' + userEmail);
    }

    public static IsUserLockedOut$(user: User, userEmail: string): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/UserManager/IsUserLockedOut?userEmail=' + userEmail,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
}

export default UserManagerService;