import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Tabs, Tab, Typography, AppBar, Paper, TableHead, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import LoadingDisplay from "../Shared/Loading";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import SubmissionsService from "../Services/SubmissionsService";
import { AjaxError } from "rxjs/ajax";
import { IWrappedMeasureCategories } from "../../DataClasses/MeasureViewerData";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

interface IMeasureViewerState {
    payload: IWrappedMeasureCategories,
    submissionId: string,
    isLoading: boolean,
    productLine: string,
    redirect: boolean
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}


const styles = (theme: Theme) => createStyles({
    headerRow: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 14,
    },

    tabRoot: {
        //    backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
    tableRoot: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableCellYellow: {
        backgroundColor: 'yellow',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        }
    }
});


type IStylesProps = WithStyles<typeof styles>;


// VIEW
class MeasureViewer extends React.Component<IGenericUserProps & IStylesProps & RouteComponentProps<{ subId: string, component: string, productLine: string }>, IMeasureViewerState> {

    constructor(props: IGenericUserProps & IStylesProps & RouteComponentProps<{ subId: string, component: string, productLine: string }>) {
        super(props)
        this.state = {
            isLoading: true,
            payload: null,
            submissionId: props.match.params.subId,
            productLine: props.match.params.productLine,
            redirect: false
        }
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.RefreshModel(this.props.match.params.subId, this.props.match.params.productLine);
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        if (this.state.isLoading) {
            return <LoadingDisplay />;
        }
        else {
            const { payload } = this.state;
            const { classes } = this.props;
            let rows: any = [];
            if (payload != null) {
                payload.categories.forEach(p => {
                    p.measures.forEach(m => {
                        const tblKeyMeasure = m.measureCode + ".";
                        const linkurl = '/livemeasuredisplay/' + m.measureCode + '/' + this.state.submissionId + '/' + this.props.match.params.productLine + '/2018';
                        rows.push(
                            <TableRow key={tblKeyMeasure} className={classes.row}>
                                <TableCell component="th" scope="row">
                                    <Link to={linkurl}>
                                        <Typography variant='h6'>
                                            {m.hedisVol2MeasureName + "(" + m.measureCode + ")"}
                                        </Typography>
                                    </Link>

                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        );
                        m.indicators.forEach(i => {
                            const tblKey = m.measureCode + "." + i.indicatorName;
                            rows.push(
                                <TableRow key={tblKey} className={classes.row}>
                                    <TableCell component="th" scope="row">
                                        <Typography variant='body2'>
                                            {i.indicatorName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant='body2'>
                                            {i.benefitOffered}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCellYellow}>
                                        <Typography variant='body2'>
                                            {i.rate}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant='body2'>
                                            {i.auditDesignation}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant='body2'>
                                            {i.comment}
                                        </Typography>
                                    </TableCell>
                                </TableRow>);
                        }
                        );
                    }
                    );
                }
                );
            }
            return (
                <div className={classes.tabRoot}>
                    <AppBar position="static">
                        <Tabs value={0}>
                            <Tab label="Measure Viewer" />
                        </Tabs>
                    </AppBar>
                    <TabContainer>
                        <Typography variant="h4">
                            Measure Listing for: <b>{this.props.match.params.subId}</b>
                        </Typography>
                    </TabContainer>
                    {
                        !this.state.payload.submissionIsExtractable ?
                            <Typography variant="h4" color="error">
                                <b>This submission is a Test or Non Audited submission and will not be processed by HDF, and consequently has no data.</b>
                            </Typography> : null
                    }
                    <Paper className={classes.tableRoot}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headerRow}>
                                        Measure / Data Element
                                    </TableCell>
                                    <TableCell className={classes.headerRow}>
                                        Benefit Offered
                                    </TableCell>
                                    <TableCell className={classes.headerRow} >
                                        Rate
                                    </TableCell>
                                    <TableCell className={classes.headerRow}>
                                        Audit Designation
                                    </TableCell>
                                    <TableCell className={classes.headerRow}>
                                        Comment
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
            );
        }
    }

    private async RefreshModel(subId: string, productLine: string) {
        this.setState({ isLoading: true });
        const retval = await SubmissionsService.GetMeasureIndicatorsForSubmission$(this.props.user!, subId, productLine).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            this.setState({ payload: retval })
        }
        this.setState({ isLoading: false });
    }
}

// REDUX CONTAINER
const MeasurerViewerViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(MeasureViewer);

// STYLES CONTAINER
const MeasureViewerPage = withStyles(styles)(MeasurerViewerViewWithState);

// REDUX CONTAINER

export default MeasureViewerPage;


