import * as React from "react";
import { connect } from "react-redux";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import { Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import BackNavigation from "../../Shared/BackNavigation";
import { ISubmissionAccredStatus } from "../../../DataClasses/ReportData";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../../Services/ReportsService";
import LoadingDataTable from "../../Shared/LoadingDataTable";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface IAccreditationStatusReportState {
    data: any[];
    isLoading: boolean;
    redirect: boolean;
}

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class AccreditationStatusReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    IAccreditationStatusReportState
> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;
        const reportColumns =
            [
                {
                    name: "Org ID",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Sub ID",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Org Name",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Product Line",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Reporting Product",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Accred Status",
                    options: {
                        filter: true
                    }
                }
            ];
        const reportOptions: MUIDataTableOptions = {
            filter: true,
            filterType: "dropdown",
            print: false,
            viewColumns: false,
            selectableRows: "none",
            downloadOptions: {
                filename: 'AccreditationStatus.csv',
                separator: ',',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            },
            rowsPerPage: 20,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
        }
        return(
            <Grid container={true} className={classes.gridRowFlex} spacing={0}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <BackNavigation text="Back to Reports" href="/reportspage" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className={classes.card}>
                        {this.state.isLoading ? <LoadingDataTable titleText={"*"} count={8} /> :
                            <MUIDataTable
                                title={"Accreditation Status Report"}
                                data={this.state.data}
                                columns={reportColumns}
                                options={reportOptions}
                            />
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval: ISubmissionAccredStatus[] | AjaxError = await ReportsService.GetSubmissionsAccredStatus$(this.props.user).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                data: retval.map(item =>{
                    return [
                        item.organizationId,
                        item.submissionId,
                        item.orgName,
                        item.productLine,
                        item.reportingProduct,
                        item.isAccredited === true ? "Accredited" : "Not Accredited"
                    ]
                })
            });
        }
        this.setState({ isLoading: false });
    }
    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }
    private setRedirectState() {
        this.setState({ redirect: true });
    }
}

// REDUX CONTAINER
const AccreditationStatusReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(AccreditationStatusReportView);

// STYLES CONTAINER
const AccreditationStatusReportPage = withStyles(styles)(
    AccreditationStatusReportPageWithState
);

export default AccreditationStatusReportPage;