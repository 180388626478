import { User } from "oidc-client";
import { Action } from "redux";
import { action as createAction } from "typesafe-actions";
import RootAction from "../Root/RootAction";

// STORE
interface IStore {
    isLoadingUser: boolean,
    user: User | null;
}

// INITIAL STATE
export const StoreInitialState: IStore = {
    isLoadingUser: false,
    user: null
};

// PAYLOADS
export interface IError {
    message: string;
}

// CONSTANTS
enum Constants {
    USER_EXPIRED = 'USER_EXPIRED',
    REDIRECT_SUCCESS = 'REDIRECT_SUCCESS',
    USER_LOADED = 'USER_LOADED',
    SILENT_RENEW_ERROR = 'SILENT_RENEW_ERROR',
    SESSION_TERMINATED = 'SESSION_TERMINATED',
    USER_EXPIRING = 'USER_EXPIRING',
    USER_FOUND = 'USER_FOUND',
    LOADING_USER = 'LOADING_USER',
    USER_SIGNED_OUT = 'USER_SIGNED_OUT',
    LOAD_USER_ERROR = 'LOAD_USER_ERROR'
}

// ACTIONS
export interface ILoadingUser extends Action<Constants.LOADING_USER> { }
export interface ILoadUserError extends Action<Constants.LOAD_USER_ERROR> { }
export interface IRedirectSuccess extends Action<Constants.REDIRECT_SUCCESS> {
    payload: User
}
export interface ISessionTerminated extends Action<Constants.SESSION_TERMINATED> { }
export interface ISilentRenewError extends Action<Constants.SILENT_RENEW_ERROR> {
    payload: IError
}
export interface IUserExpired extends Action<Constants.USER_EXPIRED> { }
export interface IUserExpiring extends Action<Constants.USER_EXPIRING> { }
export interface IUserFound extends Action<Constants.USER_FOUND> {
    payload: User
}
export interface IUserSignedOut extends Action<Constants.USER_SIGNED_OUT> {
    type: Constants.USER_SIGNED_OUT
}

export type Actions = ILoadingUser
    | ILoadUserError
    | IRedirectSuccess
    | ISessionTerminated
    | ISilentRenewError
    | IUserExpired
    | IUserExpiring
    | IUserFound
    | IUserSignedOut


// ACTION CREATORS
export const userExpired = (): IUserExpired => createAction(Constants.USER_EXPIRED);
export const redirectSuccess = (user: User): IRedirectSuccess => createAction(Constants.REDIRECT_SUCCESS, user);
export const userFound = (user: User): IUserFound => createAction(Constants.USER_FOUND, user);
export const silentRenewError = (error: IError): ISilentRenewError => createAction(Constants.SILENT_RENEW_ERROR, error);
export const sessionTerminated = (): ISessionTerminated => createAction(Constants.SESSION_TERMINATED);
export const userExpiring = (): IUserExpiring => createAction(Constants.USER_EXPIRING);
export const loadingUser = (): ILoadingUser => createAction(Constants.LOADING_USER);
export const userSignedOut = (): IUserSignedOut => createAction(Constants.USER_SIGNED_OUT);
export const loadUserError = (): ILoadUserError => createAction(Constants.LOAD_USER_ERROR);

// REDUCERS
export const Reducer = (state = StoreInitialState, action: RootAction) => {
    switch (action.type) {
        case Constants.USER_EXPIRED:
        case Constants.SILENT_RENEW_ERROR:
        case Constants.SESSION_TERMINATED:
        case Constants.USER_SIGNED_OUT:
            return Object.assign({}, {...state}, {user: null, isLoadingUser: false});
        case Constants.REDIRECT_SUCCESS:
        case Constants.USER_FOUND:
            return Object.assign({}, { ...state }, { user: action.payload, isLoadingUser: false });
        case Constants.LOADING_USER:
            return Object.assign({}, {...state}, { isLoadingUser: true });
        default:
            return state;
    }
}

// EPICS