import * as React from "react";
import { Link } from "react-router-dom";

// VIEW
class LoginButton extends React.Component {
    public render() {
        return (
            <Link to='/login'>Login</Link>
        );
    }
}

export default LoginButton;