import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Paper, Fade } from "@material-ui/core";
import * as React from "react";
import '@material-ui/core/styles';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import { IGenericUserProps, genericMapStateToProps } from '../../DataClasses/GenericUser';
import { FindInPage as FindInPageIcon } from '@material-ui/icons';



const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    root: {
        flexGrow: 1
    },
    list: {
        flexGrow: 1
    },
    menuContainer: {
        textAlign: "right",
        paddingRight: "10px"
    }
});
type IStylesProps = WithStyles<typeof styles>;

interface ILocalState {
    IsDownloading: boolean;
    anchorEl: HTMLElement | null;
}


// VIEW
class HelpView extends React.Component<IGenericUserProps & IStylesProps, ILocalState> {


    constructor(props: any) {
        super(props);

        this.state = {
            IsDownloading: false,
            anchorEl: null
        };
    }

    public render() {
        const { classes } = this.props;

        return (
            <Grid container className={classes.root} spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
            >
                <Grid item xs={10} className={classes.mt2 + " " + classes.mb2}>
                    <Typography className={classes.submissionTitle}>
                        Help
                    </Typography>
                </Grid>
                <Fade in={true}>
                    <Grid item xs={12}>
                        <Paper>
                            <List className={classes.list} >
                                {this.renderLink("Help/2024_IDSS_FAQ.pdf", "HEDIS FAQ", "Frequently Asked Questions")}
                                {this.renderLink(process.env.REACT_APP_TEMPLATE_AND_MATERIALS, "IDSS Import Templates and Materials", "")}
                                {this.renderLink("Help/IDSS_User_Guide.pdf", "HEDIS User Guide", "")}
                                {this.renderLink("Help/CAHPS_User_Guide.pdf", "CAHPS User Guide", "")}
                                {this.renderLink("Help/HOS_User_Guide.pdf", "HOS User Guide", "")}
                                {this.renderLink("Help/IDSS_Signer_Instructions.pdf", "IDSS Signer Instructions", "")}
                            </List>
                        </Paper>
                    </Grid>
                </Fade>
            </Grid>
        );
    }

    private renderLink = (href, primaryText, secondaryText) => {
        return (
            <ListItem alignItems='flex-start' button divider component="a" href={href} target="_blank">
                <ListItemIcon>
                    <FindInPageIcon />
                </ListItemIcon>
                <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
        );
    }
}

// REDUX CONTAINER
const HelpPageWithState = connect<IGenericUserProps>(genericMapStateToProps)(HelpView);

// STYLES CONTAINER
const HelpViewPage = withStyles(styles)(HelpPageWithState);

export default HelpViewPage;


