import { ThemeOptions, createTheme  } from "@material-ui/core/styles";

const themeOptions: ThemeOptions = {
    palette: {
        background: {
            default: 'inherit'
        },
        primary: {
            main: "#0099CC",
        },
        secondary: {
            main: "#C8102E",
        },
    },
    typography: {
        fontFamily: 'sans-serif',
        fontSize: 13,
    },
    overrides: {
        MuiToolbar: {
            regular: {
                '@media (min-width: 600px)': {
                    minHeight: '56px'
                }
            }
        },
        MuiListItem: {
            root: {
                paddingTop: '8px',
                '&$selected, &:hover': {
                    backgroundColor: 'transparent',
                    color: '#0099CC',
                    fontWeight: 'bold',
                    '& div': {
                        backgroundColor: 'transparent',
                        color: '#0099CC',
                    },
                    '& p': {
                        backgroundColor: 'transparent',
                        color: '#0099CC',
                        fontWeight: 'bold',
                    }
                }
            }            
        },
        MuiListItemText: {
            primary: {
                fontSize: '15px',
                fontWeight: 'bold'
            },
            secondary: {
                color: '#000000',
                fontSize: '13px'
            }
        },
        MuiCardActions: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiStepIcon: {
            root: {
                color: '#808080',  //default color
                '&$completed': {
                    color: '#4F758B',
                },
                '&$active': {
                    color: '#4F758B',
                }
            }
        },
        MuiStepLabel: {
            label: {
                color: '#808080',  //default color
                '&$completed': {
                    color: '#4F758B',
                    fontWeight: 'bold'
                },
                '&$active': {
                    color: '#000000',
                    fontWeight: 'bold'
                }
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                "&$checked": {
                    "color": "#0099CC"
                }
            }
        },
        MuiRadio: {
            colorSecondary: {
                "&$checked": {
                    "color": "#0099CC"
                }
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#FFFFFF',
                borderBottom: '3px solid #E0E0E0'
            }
        },
        MUIDataTableHeadCell: {
            'toolButton': {
                backgroundColor: 'white',
                fontWeight: 'bold',
                color: '#4F758B',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: '#4F758B',
                        fontWeight: 'bold'
                    },
            }
        },
        MuiButton: {
            root: {
                backgroundColor: '#0099CC',
                color: '#FFFFFF',
                fontWeight: 'normal',
                '&$disabled': {
                    backgroundColor: '#D3D3D3',
                    color: '#707070',
                },
                '&:hover': {
                    backgroundColor: '#4F758B',
                    fontWeight: 'bold'
                },                
            },
            textPrimary: {
                color: '#FFFFFF',
                '&:hover': {
                    backgroundColor: '#4F758B',
                    color: '#FFFFFF',
                    fontWeight: 'bold'
                },
            },
    },

        MuiFab: {
            primary: {
                '&:hover': {
                    backgroundColor: '#4F758B',
                    color: '#FFFFFF',
                    //fontWeight: 'bold'
                }
            },
            secondary: {
                '&:hover': {
                    backgroundColor: '#8c0b20',
                    color: '#FFFFFF',
                    //fontWeight: 'bold'
                }
            },
        },

        MuiTableCell: {
            root: {
                margin: '0px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: '0px',
                paddingBottom: '0px',
                fontSize: '0.8125rem',
                fontFamily: 'sans-serif'
            },
            head: {
                color: "#4F758B",
                fontWeight: 'bold'
            },
            paddingNone: {
                padding: 1,
                '&:last-child': {
                    padding: 2
                }
            }
        },
        MuiTableRow: {
            root: {
               height: '34px'
            },
        }
    }
}

const myNcqaTheme = createTheme(themeOptions);

export default myNcqaTheme;