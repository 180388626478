import { User, UserManager } from "oidc-client";
import * as React from "react";
import { Route, Switch } from "react-router";
import { Store } from "redux";
import AppWithAuthentication from "../../Components/Root/AppWithAuthentication";
import * as AuthActions from "../../State/Authentication";
import Callback from "../OpenId Connect/Callback";
import Login from "../OpenId Connect/Login";
import Logout from "../OpenId Connect/Logout";
import SilentRenew from "../OpenId Connect/SilentRenew";
import TelemetryProvider from '../Logging/telemetry-provider';

// ATTRIBUTE PROPS
// It doesn't seem we can use the connect option with Redux since we need method events in WillMount and WillUnmount
interface IAuthenticationAppProps {
    store: Store
    userManager: UserManager
}

// VIEW
class App extends React.Component<IAuthenticationAppProps> {
    public componentDidMount() {
        // register the event callbacks
        this.props.userManager.events.addUserLoaded(this.onUserLoaded);
        this.props.userManager.events.addSilentRenewError(this.onSilentRenewError);
        this.props.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
        this.props.userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
        this.props.userManager.events.addUserUnloaded(this.onUserUnloaded);
        this.props.userManager.events.addUserSignedOut(this.onUserSignedOut);
    }

    public componentWillUnmount() {
        // unregister the event callbacks
        this.props.userManager.events.removeUserLoaded(this.onUserLoaded);
        this.props.userManager.events.removeSilentRenewError(this.onSilentRenewError);
        this.props.userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired);
        this.props.userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring);
        this.props.userManager.events.removeUserUnloaded(this.onUserUnloaded);
        this.props.userManager.events.removeUserSignedOut(this.onUserSignedOut);
    }

    public render() {
        return (
            <TelemetryProvider instrumentationKey="c791a1ad-c0f2-4547-9a7d-3c3019c44c6b">
                <Switch>
                    <Route exact={true} path='/silent_renew' component={SilentRenew} />
                    <Route exact={true} path='/callback' component={this.CallbackConfigured} />
                    <Route exact={true} path='/login' component={this.LoginConfigured} />
                    <Route exact={true} path='/logout' component={this.LogoutConfigured} />
                    <Route component={this.AppWithAuthenticationConfigured} />
                </Switch>
            </TelemetryProvider>
        )
    }

    // Create components with their arguments to meet best practices
    private CallbackConfigured = () => <Callback userManager={this.props.userManager} />;
    private LoginConfigured = () => <Login userManager={this.props.userManager} />;
    private LogoutConfigured = () => <Logout userManager={this.props.userManager} />;
    private AppWithAuthenticationConfigured = () => <AppWithAuthentication />;

    // event callback when the user has been loaded (on silent renew or redirect)
    private onUserLoaded = (user: User) => {
        this.props.store.dispatch(AuthActions.userFound(user));
    };

    // event callback when silent renew errored
    private onSilentRenewError = (error: AuthActions.IError) => {
        this.props.store.dispatch(AuthActions.silentRenewError(error));
    };

    // event callback when the access token expired
    private onAccessTokenExpired = () => {
        this.props.store.dispatch(AuthActions.userExpired());
    };

    // event callback when the user is logged out
    private onUserUnloaded = () => {
        this.props.store.dispatch(AuthActions.sessionTerminated());
    };

    // event callback when the user is expiring
    private onAccessTokenExpiring = () => {
        this.props.store.dispatch(AuthActions.userExpiring());
    }

    // event callback when the user is signed out
    private onUserSignedOut = () => {
        // Dispatch event to store
        this.props.store.dispatch(AuthActions.userSignedOut());

        // Redirect the user to the identity server logout page
        this.props.userManager.signoutRedirect();
    }
}

export default App;