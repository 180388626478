import * as React from "react";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import { Typography, Paper, Grid, Fab, CircularProgress } from "@material-ui/core";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import { Redirect } from "react-router-dom";
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import saveAs from 'file-saver';
import SharedUtility from '../../Utilities/SharedUtility';
import ProcessingDisplay from "../Shared/ProcessingDisplay";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    paperStyle: {
        flexGrow: 1,
        padding: theme.spacing(2, 2),
    },
});

type IStylesProps = WithStyles<typeof styles>;

export interface IConverterState {
    redirect: boolean,
    isProcessing: boolean,
    dropzoneStatusStyle: string,
    uploadCompletionPercent: number,
    selectedFileName: string
}

// VIEW
class LegacyFileConverterView extends React.Component<
    IGenericUserProps & IStylesProps,
    IConverterState
> {
    constructor(props: IGenericUserProps & IStylesProps) {
        super(props);
        this.state = {
            redirect: false,
            isProcessing: false,
            dropzoneStatusStyle: "",
            uploadCompletionPercent: 0,
            selectedFileName: ""
        };
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    //private setRedirectState() {
    //    this.setState({ redirect: true });
    //}

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;

        return (
            <>
                <Grid key={1} item={true} xs={6} className={classes.gridDisplay}>
                    <Paper className={classes.paperStyle}>
                        <Typography className={classes.primaryBlue + " " + classes.mb2}>
                            Legacy File Conversion
                        </Typography>
                        <Dropzone
                            accept={[".xml"]}
                            onDrop={this.onDrop.bind(this)}
                            onDropAccepted={() => this.setState({ dropzoneStatusStyle: classes.stripes })}
                            onDropRejected={() => this.setState({ dropzoneStatusStyle: classes.rejectStripes })}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    className={classes.DropzoneContainer + " " + classNames(classes.dropZone, this.state.dropzoneStatusStyle)}
                                >
                                    <input {...getInputProps()} />
                                    <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                                        <Grid item={true} xs={12}>
                                            <Typography className={classes.dropzoneText} variant="h6" color="textPrimary">
                                                Drag and drop file here
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Typography className={classes.dropzoneText} variant="h6" color="textPrimary">
                                                or
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Fab className={classes.squareFab + " " + classes.dropzoneBtn} variant="extended" color="primary">
                                                Select Legacy Xml File
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Dropzone>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        {this.state.uploadCompletionPercent > 0 ? <Typography>Status:  </Typography> : null}
                                    </td>
                                    <td>
                                        {this.state.uploadCompletionPercent > 0 && this.state.uploadCompletionPercent !== 100 ? <CircularProgress variant="determinate" value={this.state.uploadCompletionPercent} /> : ""}
                                        <Typography className={classes.darkOrangeColor}>{this.state.uploadCompletionPercent === 100 && !this.state.isProcessing ? "Processing" : null}</Typography>
                                        <Typography className={classes.greenColor}>{this.state.uploadCompletionPercent === 100 && this.state.isProcessing ? "Complete" : null}</Typography>
                                    </td>
                                    <td>
                                        {this.state.uploadCompletionPercent === 100 ? <ProcessingDisplay completed={this.state.isProcessing} /> : null}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </>
        );
    }

    private onDrop = (acceptedFiles, rejectedFiles) => {
        this.setProcessingState(true);

        const data = new FormData();
        if (acceptedFiles.length === 0) {
            alert('Please select a valid file');
            return;
        }

        acceptedFiles.forEach(file => {
            this.setState({ selectedFileName: file.name });
            data.append("files", file, file.name);
        });

        const req = request.post(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + "/api/submissions/TransformLegacyXmlToNewXml");
        req.set({ 'Authorization': 'Bearer ' + this.props.user.access_token });

        // show upload progress 
        req.on('progress', event => {
            const percent = Math.floor(event.percent);
            if (!isNaN(percent)) {
                if (percent >= 100) {
                    this.setState({ uploadCompletionPercent: 100 });
                } else {
                    this.setState({ uploadCompletionPercent: percent });
                }
            }
        });

        // send file(s) to server
        req.send(data)
            .end((err, res) => this.postUploadFiles(err, res));
    };

    private postUploadFiles = (err, res) => {
        this.setProcessingState(false);

        if (err) {
            alert("Legacy file conversion not Successful");
        }
        else {
            if (res.body !== "") {
                const data = SharedUtility.Str2ab$(res.body);
                saveAs(new Blob([data], { type: "application/octet-stream" }), `${this.state.selectedFileName}_V2.xml`);
            }
        }
        this.setState({
            uploadCompletionPercent: 0,
        });
    }

    private setProcessingState = (value: boolean) => {
        this.setState({ isProcessing: value });
    }
}

// REDUX CONTAINER
const LegacyFileConverterPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(LegacyFileConverterView);

// STYLES CONTAINER
const LegacyFileConverterPage = withStyles(styles)(LegacyFileConverterPageWithState);

export default LegacyFileConverterPage;
