import React from "react";
import { genericMapStateToProps, IGenericUserProps } from "../../DataClasses/GenericUser";
import idssStyle from "../../Configuration/SharedStyling";
import { connect } from "react-redux";
import {
    Theme,
    WithStyles,
    createStyles,
    withStyles,
  } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import AdministrationService from "../Services/AdministrationService";
import { AjaxError } from "rxjs/ajax";
import { IAppInfoItem } from "../../DataClasses/AdministrationData";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { Grid } from "@material-ui/core";
import LoadingDataTable from "../Shared/LoadingDataTable";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface IAppInfoStateStore {
  isLoading: boolean;
  redirect: boolean;
  data: IAppInfoItem[];
}

type IStylesProps = WithStyles<typeof styles>;

class AppInfoView extends React.Component<
  IGenericUserProps & IStylesProps,
  IAppInfoStateStore
> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            redirect: false,
            data: []
        }
    }

    public componentDidMount() {
        this.RefreshModel();
    }
    
    private redirectToErrorPage() {
    return <Redirect to='/errorpage' />;
    }

    public render() {
        if (this.state.redirect) {
          return this.redirectToErrorPage();
        }
        const tblColumns: MUIDataTableColumnDef[] = [
            {
              name: "key",
              label: "Key"
            },
            {
                name: "value",
                label: "Value"
            }
        ];
        const tblOption: MUIDataTableOptions = {
            filter: true,
            filterType: "dropdown",
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            rowsPerPage: 8,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
          };
        return (
           this.state.isLoading?
            <LoadingDataTable titleText={"*"} />
           :
            <div>
              <Grid item={true} xs={6}>
                <MUIDataTable
                    title={"App Settings"}
                    data={this.state.data.filter(x => x.type === 1)}
                    columns={tblColumns}
                    options={tblOption}
                  />
                </Grid>
                <br/>
                <MUIDataTable
                    title={"DB Info"}
                    data={this.state.data.filter(x => x.type === 3)}
                    columns={tblColumns}
                    options={tblOption}
                  />
                <br/>
                <MUIDataTable
                    title={"Sys Configuration"}
                    data={this.state.data.filter(x => x.type === 2)}
                    columns={tblColumns}
                    options={tblOption}
                /> 
            </div>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval = await AdministrationService.GetAppInfo$(
          this.props.user
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
          this.setRedirectState();
        } else {
          this.setState({ data: retval });
        }
        this.setState({ isLoading: false });
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }
}

// REDUX CONTAINER
const AppInfoPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
  )(AppInfoView);
  
  // STYLES CONTAINER
  const AppInfoPage = withStyles(styles)(
    AppInfoPageWithState
  );
  
  export default AppInfoPage;