export enum SubmissionType {
    HEDIS = "HEDIS",
    HOS = "HOS",
    CAHPS = "CAHPS"
}

export enum MessageType {
    Warning = 'Warning',
    Error = 'Error',
    Information = 'Information',
    Success = 'Success'
}

export interface ValidationMessage {
    message: string;
    severity: number;
}

export interface ValidationError {
    measureCode: string;
    shortIndicatorName: string;
    indicatorKey: string;
    severity: string;
    message: string;
    ruleName: string;
}

export interface ValidationRptError {
    orgId: number;
    subId: number;
    orgName: string;
    measureCode: string;
    shortIndicatorName: string;
    indicatorKey: string;
    severity: string;
    message: string;
    ruleName: string;
}

export interface KeyValuePair {
    key: string;
    value: string;
}