import { Grid, Typography, Paper, Button } from "@material-ui/core";
import * as React from "react";
import "@material-ui/core/styles";
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import {
  IGenericUserProps,
  genericMapStateToProps,
} from "../../DataClasses/GenericUser";
import AdministrationService from "../Services/AdministrationService";
import { AjaxError } from "rxjs/ajax";
import LoadingDisplay from "../Shared/Loading";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import froalaBaseConfig from "../../Utilities/BaseFroalaConfig";
import AlertDialog from "../Shared/AlertDialog";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    ...idssStyle(theme),
    root: {
      flexGrow: 1,
    },
  });
type IStylesProps = WithStyles<typeof styles>;

interface ILocalState {
  licenseAgreementText: string;
  isLoading: boolean;
  events: ILegalAgreementViewEvents;
  redirect: boolean;
}

export interface ILegalAgreementViewEvents {
  logoutConfirmDialogOpen: boolean;
}

// VIEW
class LegalAgreementSignView extends React.Component<
  IGenericUserProps & IStylesProps,
  ILocalState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      licenseAgreementText: "",
      isLoading: true,
      events: {
        logoutConfirmDialogOpen: false,
      },
      redirect: false
    };
  }

  public async componentDidMount() {
    // This method runs when the component is first added to the page
    await this.RefreshModel();
  }

  private redirectToErrorPage() {
    return <Redirect to='/errorpage' />;
  }

  private setRedirectState() {
    this.setState({ redirect: true });
  }

  public render() {
    if (this.state.redirect) {
      return this.redirectToErrorPage();
    }

    if (this.state.isLoading) {
      return <LoadingDisplay progressText={"*"} />;
    } else {
      const { classes } = this.props;
      return (
        <Grid
          container
          className={classes.root}
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Grid item xs={10}>
            <Typography variant="h4" color="inherit">
              IDSS User License Agreement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <FroalaEditorView
                model={this.state.licenseAgreementText}
                config={{ ...froalaBaseConfig }}
              />
            </Paper>
            <Button
              variant="contained"
              className={classes.minspacing}
              onClick={() => this.OpenLogoutConfirmDialog()}
            >
              I Disagree
            </Button>
            <Button
              variant="contained"
              className={classes.minspacing}
              onClick={() => this.AddAgreementForUser()}
            >
              I Agree
            </Button>

            <AlertDialog
              showDialog={this.state.events.logoutConfirmDialogOpen}
              title="Notice"
              description="You must accept the license agreement in order to use this tool."
              button1Text="Leave"
              button2Text="Cancel"
              button1Handler={() => this.LogOut()}
              button2Handler={() => this.CloseConfirmDialog()}
            />
          </Grid>
        </Grid>
      );
    }
  }

  private async RefreshModel() {
    this.setState({ isLoading: true });
    const adminModel = await AdministrationService.GetAdminModel(
      this.props.user
    ).toPromise();

    if (adminModel instanceof AjaxError || !adminModel) {
      this.setRedirectState();
    } else {
      this.setState({
        licenseAgreementText: adminModel.licenseAgreementText,
        isLoading: false,
      });
    }
  }

  private async AddAgreementForUser() {
    this.setState({
      isLoading: true,
    });

    const resp = await AdministrationService.AddLicenseAgreementForUser(
      this.props.user
    ).toPromise();
    if (resp instanceof AjaxError || !resp) {
      this.setRedirectState();
    } else {
      window.location.href = "dashboard";
    }
  }

  private LogOut = () => {
    window.location.href = "logout";
  };

  private OpenLogoutConfirmDialog = () => {
    this.setState({
      events: {
        ...this.state.events,
        logoutConfirmDialogOpen: true,
      },
    });
  };

  private CloseConfirmDialog = () => {
    this.setState({
      events: {
        ...this.state.events,
        logoutConfirmDialogOpen: false,
      },
    });
  };
}

// REDUX CONTAINER
const LegalAgreementSignWithState = connect<IGenericUserProps>(
  genericMapStateToProps
)(LegalAgreementSignView);

// STYLES CONTAINER
const LegalAgreementSignPage = withStyles(styles)(LegalAgreementSignWithState);

export default LegalAgreementSignPage;
