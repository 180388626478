import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import RootState from "../../State/Root/RootState";
import { Paper } from "@material-ui/core";

interface IManageUserProps {
    targetUser: string | null,
}



const mapStateToProps = (state: RootState): IManageUserProps => {
    return ({
        targetUser: state.DashboardReducer.announcement,
    });
};


// VIEW
class ManageUser extends React.Component<IManageUserProps & RouteComponentProps<{ userEmail: string }>> {

    public render() {
        return (
            <Paper >
                <h3>Manage User: {this.props.match.params.userEmail}</h3>
                <div>
                    <ul>
                        <li>
                            Here is something you might want to do
                        </li>
                        <li>
                            Here is something else
                        </li>
                        <li>
                            <Link to='/securepage'>A link</Link>
                        </li>
                    </ul>
                </div>
            </Paper>
        );
    }
}

// REDUX CONTAINER
const ManageUserPage = connect<IManageUserProps>(mapStateToProps)(ManageUser);

export default ManageUserPage;


