import * as React from "react";
import "@material-ui/core";
import { Theme, WithStyles, createStyles, withStyles, } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    Divider,
} from "@material-ui/core";
import idssStyle from "../../Configuration/SharedStyling";


const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

type IStylesProps = WithStyles<typeof styles>;

interface ITitleViewState {
    openEditDialog: boolean;
};

class UpdateYourTitleView extends React.Component<IStylesProps, ITitleViewState>
{
    constructor(props: any) {
        super(props);
        this.state = {
            openEditDialog: true
        };
    }


    public render() {
        return (

            <Dialog open={this.state.openEditDialog}>
                <DialogTitle id="max-width-dialog-title">
                    <i>  Update your title </i>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your title information is missing. Clicking "OK" will redirect you to my.ncqa.org and please fill out the title for attestation purposes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button href={process.env.REACT_APP_MEDIA_NCQA_MY} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        );
    }
}


// STYLES CONTAINER
const UpdateTitlePage = withStyles(styles)(UpdateYourTitleView);

export default UpdateTitlePage;