import * as React from 'react';
import { Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import { Link, LinkProps } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

type IStylesProps = WithStyles<typeof styles>;

interface ILocalProps {
    href: string;
    text: string;
}
const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link innerRef={ref as any} {...props} />
));
const BackNavigationComponent = (props: ILocalProps & IStylesProps) => {
    const { classes, href, text } = props;
    return (
        <Button className={classes.actionBlue} to={href} component={AdapterLink}>
            <ArrowBackIcon />
            {text}
        </Button>
    )
};

// STYLES CONTAINER
const BackNavigation = withStyles(styles)(BackNavigationComponent);

export default BackNavigation;