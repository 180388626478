export const UPLOAD_DOCUMENT_HEDIS = "UPLOAD_DOCUMENT_HEDIS";
export const UPLOAD_DOCUMENT_NONHEDIS = "UPLOAD_DOCUMENT_NONHEDIS";
export const DOWNLOAD_RESULTS_HEDIS = "DOWNLOAD_RESULTS_HEDIS";
export const DOWNLOAD_RESULTS_CAHPS = "DOWNLOAD_RESULTS_CAHPS";
export const DOWNLOAD_RESULTS_HOS = "DOWNLOAD_RESULTS_HOS";
export const VIEW_RESULTS_REPORTS_HEDIS = "VIEW_RESULTS_REPORTS_HEDIS";
export const VIEW_RESULTS_REPORTS_NONHEDIS = "VIEW_RESULTS_REPORTS_NONHEDIS";
export const VIEW_ART = "VIEW_ART";
export const ASSIGN_AUDIT_DESIGNATION = "ASSIGN_AUDIT_DESIGNATION";
export const SIGN_ATTESTATION_HEDIS = "SIGN_ATTESTATION_HEDIS";
export const SIGN_ATTESTATION_HOS = "SIGN_ATTESTATION_HOS";
export const PLAN_LOCK = "PLAN_LOCK";
export const AUDIT_LOCK = "AUDIT_LOCK";
export const UNLOCK_PLAN_LOCK = "UNLOCK_PLAN_LOCK";
export const UNLOCK_AUDIT_LOCK = "UNLOCK_AUDIT_LOCK";
export const MARK_FINAL_HEDIS = "MARK_FINAL_HEDIS";
export const MARK_FINAL_CAHPS = "MARK_FINAL_CAHPS";
export const MARK_FINAL_HOS = "MARK_FINAL_HOS";
export const SUBMISSION_GENERIC_ADMIN = "SUBMISSION_GENERIC_ADMIN";
export const VIEW_DOWNLOAD_ERRORS_HEDIS = "VIEW_DOWNLOAD_ERRORS_HEDIS";
export const VIEW_DOWNLOAD_ERRORS_NONHEDIS = "VIEW_DOWNLOAD_ERRORS_NONHEDIS";
export const VIEW_STATUS_HEDIS = "VIEW_STATUS_HEDIS";
export const VIEW_STATUS_NONHEDIS = "VIEW_STATUS_NONHEDIS";
export const MANAGE_PUBLIC_REPORTING = "MANAGE_PUBLIC_REPORTING";
export const VIEW_COMBINED_REPORT = "VIEW_COMBINED_REPORT";
export const DENY_UPLOAD = "DENY_UPLOAD";
export const MANAGE_ADMIN_VENDORSIGNER = "MANAGE_ADMIN_VENDORSIGNER";
export const MANAGE_HEDIS_USER = "MANAGE_HEDIS_USER";
export const MANAGE_NONHEDIS_USER = "MANAGE_NONHEDIS_USER";
export const HOS_ATTESTATIONITEM1 = "HOS_ATTESTATIONITEM1";
export const HOS_ATTESTATIONITEM2 = "HOS_ATTESTATIONITEM2";
export const HOS_ENABLE_INTERIM_SUBMISSION = "HOS_ENABLE_INTERIM_SUBMISSION";

export const ORG_ID = "ORG_ID";
export const SUB_ID = "SUB_ID";

export const COMMERCIAL = "COMMERCIAL";
export const MEDICAID = "MEDICAID";
export const MEDICARE = "MEDICARE";
export const EXCHANGE = "EXCHANGE";
export const LTSS = "LTSS";
