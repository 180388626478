import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import UserManagerService from "../Services/UserManagerService";
import AlertDialog from "../Shared/AlertDialog";
import ErrorDialog from "../Shared/ErrorDialog";
import { AjaxError } from "rxjs/ajax";
import { IUserRolePayload } from "../../DataClasses/UsersData";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../DataClasses/GenericUser";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IOrgSummary } from "../../DataClasses/SubmissionData";
import SubmissionsService from "../Services/SubmissionsService";
import Select from "react-select";
import ExceptionSerivce from "../Services/ExceptionService";
import { Redirect } from "react-router-dom";

interface IUserRolesState {
    data: any[];
    filterText: string;
    showDialog: boolean;
    targetUser: string;
    open: boolean;
    allOrgs: IOrgSummary[];
    allVendors: IOrgSummary[];
    allSysGroups: string[];
    selectedSysGroup: any;
    selectedOrgs: any;
    selectedRoles: any;
    allRoles: string[];
    inputSubIds: string;
    errorMsg: string;
    redirect: boolean
}

class UserRolesView extends React.Component<
    IGenericUserProps & RouteComponentProps<{ userEmail: string }>,
    IUserRolesState
> {
    private userOptions: MUIDataTableOptions = {
        filterType: "checkbox",
        sort: true,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        rowsPerPage: 15,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    };

    private userColumns = [
        {
            name: "Entity ID",
            options: {
                filter: true
            }
        },
        {
            name: "Role Type",
            options: {
                filter: true
            }
        },
        {
            name: "Role Name",
            options: {
                filter: true
            }
        },

        {
            name: "Action",
            options: {
                customBodyRender: value => {
                    return (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.deleteButtonClick(value)}
                            >
                                Delete
                            </Button>
                        </div>
                    );
                }
            }
        }
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            targetUser: this.props.match.params.userEmail,
            data: [],
            filterText: "",
            showDialog: false,
            open: false,
            allOrgs: [],
            allVendors: [],
            allSysGroups: [],
            selectedSysGroup: "",
            selectedOrgs: "",
            selectedRoles: "",
            allRoles: [],
            inputSubIds: "",
            errorMsg: "",
            redirect: false
        };
        // Bind functions
        this.handleUserInput = this.handleUserInput.bind(this);
        this.deleteButtonClick = this.deleteButtonClick.bind(this);
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.RefreshModel();
    }

    public handleUserInput(event: any) {
        this.setState({ filterText: event.target.value });
    }

    public async deleteButtonClick(userRoleInfo: string) {
        try {
            var response = await UserManagerService.DeleteUserRole$(
                this.props.user,
                this.state.targetUser,
                userRoleInfo
            ).toPromise();
            if (response instanceof AjaxError || !response) {
                this.setRedirectState();
            }
        } catch (ex) {
            const error = ex as AjaxError;
            this.setState({
                errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
            });
        }
        await this.RefreshRolesForUser();
    }

    public deleteAllButtonClick = () => {
        // show modal dialog by setting the state to true
        this.setModalDialogState(true);
    };

    addButtonClick = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({
            open: false,
            selectedOrgs: "",
            selectedRoles: "",
            selectedSysGroup: "",
            inputSubIds: ""
        });
    };

    handleSave = async () => {
        await UserManagerService.AddUserToRoles$(
            this.props.user,
            this.props.match.params.userEmail,
            this.state.selectedSysGroup.value,
            this.state.selectedRoles.map(p => p.value).join(","),
            this.state.selectedOrgs === ""
                ? ""
                : this.state.selectedOrgs.map(p => p.value).join(","),
            this.state.inputSubIds,
            false
        ).toPromise();
        await this.RefreshRolesForUser();
        this.setState({
            open: false,
            selectedOrgs: "",
            selectedRoles: "",
            selectedSysGroup: "",
            inputSubIds: ""
        });
    };

    public selectedOrgsChange(newValue): void {
        this.setState({ selectedOrgs: newValue });
        // this.setAddIdpBtnStatus(newValue, this.state.selectedDpVType);
    }
    public async selectedSysGroupChange(newValue) {
        this.setState({
            selectedSysGroup: newValue,
            selectedOrgs: "",
            selectedRoles: "",
            inputSubIds: ""
        });
        const rolesRetval = await UserManagerService.GetAllRolesPerSysGroup$(
            this.props.user,
            newValue.value
        ).toPromise();
        if (rolesRetval instanceof AjaxError || !rolesRetval) {
            this.setRedirectState();
        } else {
            this.setState({ allRoles: rolesRetval });
        }
    }
    public selectedRolesChange(newValue): void {
        this.setState({ selectedRoles: newValue });
    }
    public inputSubIdsChange(e: any): void {
        this.setState({ inputSubIds: e.target.value });
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const sysGroupOption = this.state.allSysGroups.map(m => ({
            label: m,
            value: m
        }));
        const orgOption = this.state.allOrgs.map(m => ({
            label: m.orgId + "-" + m.orgName,
            value: m.orgId
        }));
        const vendorOption = this.state.allVendors.map(m => ({
            label: m.orgId + "-" + m.orgName,
            value: m.orgId
        }));
        const roleOption = this.state.allRoles.map(m => ({
            label: m,
            value: m
        }));

        return (
            <Paper>
                <h3>Manage User: {this.props.match.params.userEmail}</h3>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.deleteAllButtonClick}
                >
                    Delete All
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.addButtonClick}
                >
                    Add
                </Button>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle id="max-width-dialog-title">
                        Add Roles to the User ( {this.props.match.params.userEmail}):
                    </DialogTitle>
                    <DialogContent>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <h4>
                                            Add Roles to the User ({" "}
                                            {this.props.match.params.userEmail})
                                        </h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "240px" }}>Select System Group:</td>
                                    <td style={{ width: "80%" }}>
                                        <Select
                                            options={sysGroupOption}
                                            placeholder="Select a System Group"
                                            isMulti={false}
                                            matchProp="label"
                                            simpleValue={true}
                                            value={this.state.selectedSysGroup}
                                            closeOnSelect={false}
                                            searchable={true}
                                            onChange={this.selectedSysGroupChange.bind(this)}
                                        />
                                    </td>
                                </tr>
                                <tr hidden={this.state.selectedSysGroup === ""}>
                                    <td>Select Role(s):</td>
                                    <td>
                                        <Select
                                            options={roleOption}
                                            placeholder="Select role(s)"
                                            isMulti={true}
                                            matchProp="label"
                                            simpleValue={true}
                                            value={this.state.selectedRoles}
                                            closeOnSelect={false}
                                            searchable={true}
                                            onChange={this.selectedRolesChange.bind(this)}
                                        />
                                    </td>
                                </tr>
                                <tr
                                    hidden={this.state.selectedSysGroup.value !== "organization"}
                                >
                                    <td style={{ width: "240px" }}>Select Organization(s):</td>
                                    <td style={{ width: "80%" }}>
                                        <Select
                                            options={orgOption}
                                            placeholder="Select organization(s) [OrgId-OrgName]"
                                            isMulti={true}
                                            matchProp="label"
                                            simpleValue={true}
                                            value={this.state.selectedOrgs}
                                            closeOnSelect={false}
                                            searchable={true}
                                            onChange={this.selectedOrgsChange.bind(this)}
                                        />
                                    </td>
                                </tr>
                                <tr hidden={this.state.selectedSysGroup.value !== "vendor"}>
                                    <td style={{ width: "240px" }}>Select Vendor(s):</td>
                                    <td style={{ width: "80%" }}>
                                        <Select
                                            options={vendorOption}
                                            placeholder="Select vendor(s) [OrgId-OrgName]"
                                            isMulti={true}
                                            matchProp="label"
                                            simpleValue={true}
                                            value={this.state.selectedOrgs}
                                            closeOnSelect={false}
                                            searchable={true}
                                            onChange={this.selectedOrgsChange.bind(this)}
                                        />
                                    </td>
                                </tr>
                                <tr hidden={this.state.selectedSysGroup.value !== "submission"}>
                                    <td style={{ width: "240px" }}>SubIds (comma separated):</td>
                                    <td>
                                        <input
                                            value={this.state.inputSubIds}
                                            onChange={e => this.inputSubIdsChange(e)}
                                            style={{ width: "100%" }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={this.handleSave}
                            disabled={
                                !(
                                    (this.state.selectedSysGroup.value === "organization" &&
                                        this.state.selectedRoles !== "" &&
                                        this.state.selectedOrgs !== "") ||
                                    (this.state.selectedSysGroup.value === "submission" &&
                                        this.state.selectedRoles !== "" &&
                                        this.state.inputSubIds !== "") ||
                                    (this.state.selectedSysGroup.value === "system" &&
                                        this.state.selectedRoles !== "") ||
                                    (this.state.selectedSysGroup.value === "vendor" &&
                                        this.state.selectedRoles !== "" &&
                                        this.state.selectedOrgs !== "")
                                )
                            }
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <AlertDialog
                    showDialog={this.state.showDialog}
                    title="Delete Confirmation"
                    description="Are you sure you want to delete all the roles for this user?"
                    button1Text="Yes"
                    button2Text="No"
                    button1Handler={this.buttonYesHandler}
                    button2Handler={this.buttonNoHandler}
                />
                <ErrorDialog
                    showError={this.state.errorMsg !== ""}
                    errorMsg={this.state.errorMsg}
                    buttonHandler={() => this.setState({ errorMsg: "" })}
                />

                <MUIDataTable
                    title={"Assigned Roles"}
                    data={this.state.data}
                    columns={this.userColumns}
                    options={this.userOptions}
                />
            </Paper>
        );
    }

    private async RefreshModel() {
        await this.RefreshRolesForUser();

        try {
            const allOrgsRetval = await SubmissionsService.GetOrgForSubmissionsSummary$(
                this.props.user
            ).toPromise();
            if (allOrgsRetval instanceof AjaxError || !allOrgsRetval) {
                this.setRedirectState();
            }
            else {
                this.setState({ allOrgs: allOrgsRetval });
            }
        } catch (ex) {
            const error = ex as AjaxError;
            this.setState({
                errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
            });
        }
        const allVendorsRetval = await SubmissionsService.GetOrgForVendorsSummary$(
            this.props.user
        ).toPromise();
        if (allVendorsRetval instanceof AjaxError || !allVendorsRetval) {
            this.setRedirectState();
        } else {
            this.setState({ allVendors: allVendorsRetval });
        }
        const allSysGroupRetval = await UserManagerService.GetAllSysGroups$(
            this.props.user
        ).toPromise();
        if (allSysGroupRetval instanceof AjaxError || !allSysGroupRetval) {
            this.setRedirectState();
        } else {
            this.setState({ allSysGroups: allSysGroupRetval });
        }
    }

    private async RefreshRolesForUser() {
        try {
            const retval: IUserRolePayload[] = await UserManagerService.GetRolesForUser$(
                this.props.user,
                this.state.targetUser
            ).toPromise();
            if (retval instanceof AjaxError || !retval) {
                this.setRedirectState();
            }
            else {
                this.setState({
                    data: retval.map(item => {
                        return [
                            item.entityId,
                            item.roleType,
                            item.roleName,
                            item.entityId + "," + item.roleType + "," + item.roleName
                        ];
                    })
                });
            }
        } catch (ex) {
            const error = ex as AjaxError;
            this.setState({
                errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
            });
        }
    }

    private buttonYesHandler = async () => {
        this.setModalDialogState(false);
        // dispatch delete all user roles action
        await UserManagerService.DeleteAllUserRoles$(
            this.props.user,
            this.state.targetUser
        ).toPromise();
        await this.RefreshRolesForUser();
    };

    private buttonNoHandler = () => {
        this.setModalDialogState(false);
    };

    private setModalDialogState(value: boolean) {
        this.setState({ showDialog: value });
    }
}

// REDUX CONTAINER
const UserRolesPage = connect<IGenericUserProps>(genericMapStateToProps)(
    UserRolesView
);

export default UserRolesPage;
