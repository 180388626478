import "@material-ui/core";
import { Button, Grid, FormGroup, FormControlLabel, Checkbox, Fade } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import { IFilesUploadReportPayload } from "../../../DataClasses/ReportData";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import ReportsService from "../../Services/ReportsService";
import { AjaxError } from "rxjs/ajax";
import { Link, LinkProps } from "react-router-dom";
import BackNavigation from '../../Shared/BackNavigation';
import * as AuthorizationPolicies from "../../../Configuration/AuthorizationPolicies";
import LoadingDataTable from "../../Shared/LoadingDataTable";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface IFilesUploadReportState {
    data: any[];
    isLoading: boolean;
    isSysAdmin: boolean;
    showAllItems: boolean;
    redirect: boolean
}

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056



type IStylesProps = WithStyles<typeof styles>;
const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);
// VIEW
class FilesUploadReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    IFilesUploadReportState
> {
    private uploadFilesOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        print: false,
        viewColumns: false,
        selectableRows: "none",
        downloadOptions: {
            filename: 'FilesUploadReport.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    }

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            isSysAdmin: AuthorizationPolicies.IsGlobalAdmin(this.props.user),
            showAllItems: true,
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel(this.state.showAllItems);
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        const uploadedFilesColumns =
            [
                {
                    name: "Org ID",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Sub ID",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Button to={"submissionHome/" + value + "/" + tableMeta.rowData[3].replace("HEDIS", "DST")} className={classes.linkButton} component={AdapterLink} >
                                    {value}
                                </Button>
                            );
                        }
                    }
                },
                {
                    name: "Org Name",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Component",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "FileName",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "ValidationStatus",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "CreateUser",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "CreateDateTime",
                    options: {
                        filter: false,
                    }
                },
            ];
        return (

            <Grid container={true} className={classes.gridRowFlex} spacing={2}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <BackNavigation text="Back to Reports" href="/reportspage" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className={classes.card}>
                        {this.state.isSysAdmin ? <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.showAllItems}
                                        onChange={async () => this.toggleRefreshModel(!this.state.showAllItems)}
                                        color="primary"
                                    />
                                }
                                label="Show All Submissions"
                            />
                        </FormGroup> : <div />}
                        {this.state.isLoading ? <LoadingDataTable titleText={"*"} count={10} /> :
                            <Fade in={!this.state.isLoading}><div>
                                <MUIDataTable
                                    title={"Uploaded Files"}
                                    data={this.state.data}
                                    columns={uploadedFilesColumns}
                                    options={this.uploadFilesOptions}
                                /></div></Fade>
                        }
                    </div>
                </Grid>
            </Grid >
        );
        //  }
    }
    private async toggleRefreshModel(showAll: boolean) {
        this.setState({ isLoading: true });
        await this.RefreshModel(showAll);
        this.setState({ showAllItems: showAll })
        this.setState({ isLoading: false });
    }
    private async RefreshModel(showAllItems: boolean) {
        this.setState({ isLoading: true });
        const retval: IFilesUploadReportPayload[] | AjaxError = await ReportsService.GetFilesUploadReportCaller$(
            this.props.user,
            showAllItems
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                data: retval.map(item => {
                    return [
                        item.organizationId,
                        item.submissionId,
                        item.organizationName,
                        item.component,
                        item.savedName,
                        item.validationStatus,
                        item.createUser,
                        item.createDate
                    ];
                })
            });
        }
        this.setState({ isLoading: false });
    }
}

// REDUX CONTAINER
const FilesUploadReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(FilesUploadReportView);

// STYLES CONTAINER
const FilesUploadReportPage = withStyles(styles)(
    FilesUploadReportPageWithState
);

export default FilesUploadReportPage;
