import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { AjaxError } from "rxjs/ajax";
import { connect } from "react-redux";
import AdministrationService from "../../../Components/Services/AdministrationService";
import { IGenericUserProps, genericMapStateToProps } from "../../../DataClasses/GenericUser";


interface IUnauthorizedState {
    isLoading: boolean,
    payload: IUnauthorizedPayload
}

interface IUnauthorizedPayload {
    isOffseasonMode: boolean,
    isMaintenanceMode: boolean
}

export const UnauthorizedInitialState: IUnauthorizedState = {
    isLoading: false,
    payload:
    {
        isOffseasonMode: false,
        isMaintenanceMode: false
    }
};

const styles = (theme: Theme) => createStyles({
    flex: {
        flexGrow: 1,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justify: 'center',
        background: 'white',
    },
})

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class UnauthorizedView extends React.Component<IGenericUserProps & IStylesProps, IUnauthorizedState>  {
    constructor(props) {
        super(props);

        this.state = UnauthorizedInitialState;
    }

    public componentDidMount() {
        // This method runs when the component is first added to the page
        this.RefreshModel();
    }

    render() {
        const { classes } = this.props;

        if (this.state.payload.isOffseasonMode) {
            return (<div className={classes.root}>
                <h3>Offseason</h3>
                <p>System is currently inactive until the beginning of the season. Please check back later.</p>
            </div>);
        }

        if (this.state.payload.isMaintenanceMode) { 
            return (<div className={classes.root}>
                <h3>Maintenance</h3>
                <p>System is currently undergoing scheduled maintenance. Please check back later.</p>
            </div>);
        }

        return (
            <div className={classes.root}>
                <h3>Unauthorized</h3>
                <p>You do not have access to the page you requested. If this is a mistake, please contact the system admin.</p>
            </div>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval = await AdministrationService.GetAdminModel(this.props.user).toPromise();
        if (retval instanceof AjaxError) {
            throw (new Error("Ajax Error"));
        }
        else {
            this.setState({
                payload: {
                    isMaintenanceMode: retval.isMaintenanceMode,
                    isOffseasonMode: retval.isOffseasonMode
                }
            })
        }
        this.setState({ isLoading: false });
    }


}

// REDUX CONTAINER
const UnauthorizedViewState = connect<IGenericUserProps>(genericMapStateToProps)(UnauthorizedView);

// STYLES CONTAINER
const Unauthorized = withStyles(styles)(UnauthorizedViewState);

// REDUX CONTAINER
export default Unauthorized;