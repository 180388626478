import * as React from "react";
import { connect } from "react-redux";
import { Grid, Select, FormControl, InputLabel, MenuItem, Button, Fade } from "@material-ui/core";
import idssStyle from "../../../Configuration/SharedStyling";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import BackNavigation from '../../Shared/BackNavigation';
import LoadingDataTable from "../../Shared/LoadingDataTable";
import { ISurveyVenderSubmissionStatusReportPayload } from "../../../DataClasses/ReportData";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../../Services/ReportsService";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Link, LinkProps } from "react-router-dom";
import SharedUtility from "../../../Utilities/SharedUtility";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface IHosVendorSubmissionStatusReportState {
    data: any[];
    isLoading: boolean;
    availableMeasurementYears: any[];
    selectedMeasurementYear: number;
    redirect: boolean;
}
const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class HosVendorSubmissionStatusReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    IHosVendorSubmissionStatusReportState
> {
    private reportOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        viewColumns: false,
        print: false,
        selectableRows: "none",
        downloadOptions: {
            filename: 'HosVendorSubmissionStatusReport.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    }
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            availableMeasurementYears: [],
            selectedMeasurementYear: -1,
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;
        const reportColumns =
            [
                {
                    name: "Sub ID",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Button to={"submissionHome/" + value + "/" + tableMeta.rowData[3].replace("HEDIS", "DST")} className={classes.linkButton} component={AdapterLink} >
                                    {value}
                                </Button>
                            );
                        }
                    }
                },
                {
                    name: "Org ID",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "CMSContract",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "Component",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Vendor",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Stage",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Result",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "LastUploadedOn",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "Attested",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "MarkedFinal",
                    options: {
                        filter: true,
                    }
                },
            ];

        return (

            <Grid container={true} className={classes.gridRowFlex} spacing={0}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <BackNavigation text="Back to Reports" href="/reportspage" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className={classes.card}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="measurementYearLb">MeasurementYear</InputLabel>
                            <Select
                                id="measureYearSelect"
                                value={this.state.availableMeasurementYears}
                                onChange={this.handleSelectedMeasurementYearChange}
                                fullWidth={true}
                            >
                                {this.state.availableMeasurementYears.map(m => (
                                    <MenuItem key={m} value={m}>
                                        {m}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {this.state.isLoading ? <LoadingDataTable titleText={"*"} count={8} /> :
                            <Fade in={!this.state.isLoading}><div>
                                <MUIDataTable
                                    title={"HOS Vendor Submission Status"}
                                    data={this.state.data}
                                    columns={reportColumns}
                                    options={this.reportOptions}
                                /></div>
                            </Fade>
                        }
                    </div>
                </Grid>
            </Grid >
        );
    }

    private handleSelectedMeasurementYearChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const measurementYear = parseInt(ev.target.value);
        this.setState({
            selectedMeasurementYear: measurementYear,
            isLoading: true
        });
        await this.LoadReportFor(measurementYear);
        this.setState({ isLoading: false });
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const yearsRetVal: number[] | AjaxError = await ReportsService.GetAvailableMeasurementYearsCaller$(this.props.user).toPromise();
        if (yearsRetVal instanceof AjaxError || !yearsRetVal) {
            this.setRedirectState();
        } else {
            this.setState({ availableMeasurementYears: yearsRetVal });
        }
        await this.LoadReportFor(this.state.selectedMeasurementYear);
        this.setState({ isLoading: false });
    }
    private async LoadReportFor(measurementYear: number) {
        const retval: ISurveyVenderSubmissionStatusReportPayload[] | AjaxError = await ReportsService.GetHosSubmissionStatusReportCaller$(
            this.props.user, measurementYear).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                data: retval.map(item => {
                    return [
                        item.submissionId,
                        item.organizationId,
                        item.cmsContractNumber,
                        item.component,
                        item.vendorName,
                        SharedUtility.GetStageCleanName(item.status),
                        item.result,
                        item.lastUploadedOn,
                        item.isAttested,
                        item.markedFinal
                    ]
                }
                )
            });
        }
    }
}

// REDUX CONTAINER
const HosVendorSubmissionStatusReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(HosVendorSubmissionStatusReportView);

// STYLES CONTAINER
const HosVendorSubmissionStatusReportPage = withStyles(styles)(
    HosVendorSubmissionStatusReportPageWithState
);

export default HosVendorSubmissionStatusReportPage;