import { Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import logoWhite from '../../Images/logo-white@2x.png';

// STYLES
const styles = (theme: Theme) => createStyles({
    address: {
        color: 'white',
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: 1.57,
        opacity: 0.5,
        fontWeight: 'lighter',
        paddingLeft: '0',
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(1),
        "& a": {
            color: 'white',
            textDecoration: 'none'
        }
    },
    logo: {
        height: '100px',
        marginBottom: theme.spacing(1),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            alignItems: 'left'
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        },
    }
})

type IStylesProps = WithStyles<typeof styles> & WithWidth;

class LogoAddressView extends React.Component<IStylesProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Grid container={true} className={classes.root}>
                <Grid item={true}>
                    <img src={logoWhite} alt="NCQA White Logo" className={classes.logo} />
                </Grid>
                <Grid item={true}>
                    <address className={classes.address}>
                        1100 13th St., NW, Third Floor <br />
                        Washington, D.C. 20005 <br />
                        <a href="tel:+12029553500">202.955.3500</a>
                    </address>
                </Grid>
            </Grid>
        )
    }
}

// STYLES CONTAINER
const LogoAddressContainer = withStyles(styles)(LogoAddressView);
const LogoAddress = withWidth()(LogoAddressContainer);
export default LogoAddress;