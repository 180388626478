import * as React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import LoadingDisplay from '../Shared/Loading';
import { CheckCircle as SuccessIcon, GetApp as GetAppIcon, } from '@material-ui/icons';
import { Grid, Table, TableBody, TableCell, TableRow, TableHead, Tooltip, Typography, Fab, TextareaAutosize, ListItem, ListItemIcon, Fade, CircularProgress, ListItemText, TableContainer, Paper } from '@material-ui/core';
import classNames from 'classnames'
import { IGenericUserProps } from '../../DataClasses/GenericUser';
import { SubmissionType, MessageType, ValidationMessage } from '../../DataClasses/SharedData';
import idssStyle from "../../Configuration/SharedStyling";
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import UserMessage from '../Shared/Message';
import SharedUtility from '../../Utilities/SharedUtility';
import { v4 as uuidv4 } from "uuid";
import { AjaxError } from "rxjs/ajax"
import DbService from '../Services/DbService';
import { Link } from 'react-router-dom';
import saveAs from 'file-saver';
import ReportService from "../Services/ReportsService";
import SubmissionsService from '../Services/SubmissionsService';
import { ISelectedSubmissions, ISelectedSubmissionsHolder } from '../../DataClasses/SubmissionData';
import ProcessingDisplay from '../Shared/ProcessingDisplay';

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    Border: {
        border: '1px solid black'
    },
    Success: {
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
        height: 22,
        width: 75,
        backgroundColor: '#008000'
    },
    Error: {
        backgroundColor: '#FF0000',
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
        height: 22,
        width: 75
    },
    Warning: {
        backgroundColor: '#ffae42',
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
        height: 22,
        width: 75
    },
    NotSubmitted: {
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
        height: 22,
        width: 90,
        backgroundColor: '#B0C4DE'
    },
    Submitted: {
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
        height: 22,
        width: 90,
        backgroundColor: '#FFA500'
    },
    Processed: {
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
        height: 22,
        width: 90,
        backgroundColor: '#008000'
    },
    Row: {
        height: '20px !important',
        alignItems: 'center',
        verticalAlign: 'center',
        justifyItems: 'center'
    },
    BoxDefault: {
        outline: '2px dashed #92b0b3',
        outlineOffset: '-10px',
        fontSize: '1.25rem',
        backgroundColor: '#c8dadf',
        position: 'relative',
        padding: '100px 20px',
        textAlign: 'center'
    },
    BoxDragOver: {
        outline: '2px dashed #92b0b3',
        outlineOffset: '-20px',
        fontSize: '1.25rem',
        outlineColor: '#c8dadf',
        backgroundColor: '#fff',
        position: 'relative',
        padding: '100px 20px',
        textAlign: 'center'
    },
    BoxIcon: {
        width: '100%',
        height: '80px',
        display: 'block',
        marginBottom: '40px'
    },
    validationMessageList: {
        margin: "0",
        listStyle: "none"
    },
    DropzoneContainerHeight: {
        minHeight: '300px'
    }
});

type IStylesProps = WithStyles<typeof styles>;

export interface IFile {
    fileId: string,
    fileName: string,
    submissionID: number,
    component: string,
    productLine: string,
    isProcessed: boolean,
    isValidated: boolean,
    errorMessage: string
}

export interface INonSurveyFile extends IFile {
    firstTierValidationStatus: string,
    firstTierValidationCount: number,
    secondTierValidationStatus: string,
    secondTierValidationCount: number,
    thirdTierValidationStatus: string,
    thirdTierValidationCount: number,
    validationStatus: string,
}

export interface ISurveyFile extends IFile {
    submissionDateTime: Date,
    submissionStatus: string,
    result: string,
}

enum StatusType {
    Error = "Error",
    Fatal = "Fatal",
    WarningsAndErrors = "WarningsAndErrors",
    SchemaError = "SchemaError",
    Success = "Success",
    Warning = "Warning",
    Submitted = "Submitted",
    NotSubmitted = "NotRun",
    Processed = "Processed",
    NoIssues = "NoIssues",
}

interface ILocalProps {
    uploadFileType: string,
    allowMultiple: boolean,
    acceptFilesOfType: string
}

// local state
interface ILocalState {
    isDownloadUploadReport: boolean,
    isDownloadValidationReport: boolean,
    processingText: string,
    transactionId: string,
    completed: number,
    disableDropzone: boolean,
    nonSurveyFiles: INonSurveyFile[],
    surveyFiles: ISurveyFile[],
    showDownloadLink: boolean,
    dropzoneActive: boolean,
    filesNotProcessed: string[],
    uploadValidationMessages: ValidationMessage[],
    processingFiles: boolean
}

class BulkUploadDropzoneView extends React.Component<IGenericUserProps & ILocalProps & IStylesProps, ILocalState> {

    constructor(props: IGenericUserProps & ILocalProps & IStylesProps) {
        super(props);

        this.state = {
            isDownloadUploadReport: false,
            isDownloadValidationReport: false,
            processingText: "",
            transactionId: "",
            completed: 0,
            disableDropzone: false,
            nonSurveyFiles: [],
            surveyFiles: [],
            showDownloadLink: false,
            dropzoneActive: false,
            filesNotProcessed: [],
            uploadValidationMessages: [],
            processingFiles: false
        }
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.mt2}>

                {this.state.uploadValidationMessages && this.state.uploadValidationMessages.length > 0 &&
                    <div className={classes.mb2}>
                        <UserMessage text={this.getValidationMessageList()} variant={MessageType.Error} />
                    </div>
                }

                <Grid container={true} justifyContent="center" alignItems="center" direction="row">
                    <Grid item={true} xs={12}>
                        {this.state.processingFiles ?
                            <div>
                                <LoadingDisplay size={300} />
                                <Typography variant='subtitle1' color='primary' className={classes.tabTitle}>
                                    Processing Status
                                </Typography>
                                <TextareaAutosize
                                    name="validationText"
                                    className={classes.fullWidth}
                                    id="multiline-filled-static"
                                    disabled={true}
                                    rowsMax={15}
                                    value={this.state.processingText}
                                />
                            </div>

                            :
                            <Dropzone
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDrop={this.onDrop}
                                multiple={this.props.allowMultiple}
                                disabled={this.state.disableDropzone}
                                accept={this.props.acceptFilesOfType}
                            >


                                {({ getRootProps, getInputProps, isDragActive }) => {
                                    return (
                                        <div
                                            {...getRootProps()}
                                            className={classes.DropzoneContainer + " " + classes.DropzoneContainerHeight + " " + classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                                        >
                                            <input {...getInputProps()} />
                                            <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                                                <Grid item={true} xs={12}>
                                                    <Typography className={classes.dropzoneText} variant="h6" color="textPrimary">
                                                        Drag and drop file here
                                                    </Typography>
                                                </Grid>
                                                <Grid item={true} xs={12}>
                                                    <Typography className={classes.dropzoneText} variant="h6" color="textPrimary">
                                                        or
                                                    </Typography>
                                                </Grid>
                                                <Grid item={true} xs={12}>
                                                    <Fab className={classes.squareFab + " " + classes.dropzoneBtn} variant="extended" color="primary">
                                                        Select File
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                }}
                            </Dropzone>
                        }
                    </Grid>
                </Grid>
                <div>
                    {this.props.uploadFileType === SubmissionType.HEDIS &&
                        this.state.nonSurveyFiles != null &&
                        this.state.nonSurveyFiles.length > 0 ?
                        <div className="panel panel-default">
                            <div className="panel-body">
                                {this.renderDownloadReportLink(() => this.getUploadReport(), this.state.isDownloadUploadReport, "Upload Report")}
                                {this.renderDownloadReportLink(() => this.downloadBulkFiles(), this.state.isDownloadValidationReport, "Validation Report")}
                                <TableContainer component={Paper}>
                                    <Table className='table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>File Name</TableCell>
                                                <TableCell>Submission ID</TableCell>
                                                <TableCell>Processing Status</TableCell>
                                                <TableCell>Overall Validation Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.nonSurveyFiles != null &&
                                                this.state.nonSurveyFiles.length > 0 &&
                                                this.state.nonSurveyFiles.map((file: INonSurveyFile) =>

                                                    <TableRow key={file.fileId} className={classes.Row}>
                                                        <TableCell><Link to={'/submissionHome/' + file.submissionID + "/" + file.component + "/" + file.productLine}>{file.fileName}</Link></TableCell>
                                                        <TableCell>{file.submissionID}</TableCell>
                                                        <TableCell>{(!file.isProcessed ? <LoadingDisplay size={25} /> :
                                                            <Tooltip title={'Processed'} aria-label={'Processed'}>
                                                                <SuccessIcon />
                                                            </Tooltip>
                                                        )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {file.isValidated && this.renderNonSurveyValidationStatus(file, classes)}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ verticalAlign: 'top' }}>
                                                    <ProcessingDisplay completed={true}></ProcessingDisplay>
                                                </TableCell>
                                                <TableCell style={{ verticalAlign: 'top' }}>
                                                    <Typography variant='body1' className={classes.greenColor}>Submission files processed. Please go to Submissions in the left panel to review individual submissions.</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        : ''}
                </div>
                <div>
                    {this.props.uploadFileType !== SubmissionType.HEDIS &&
                        this.state.surveyFiles != null &&
                        this.state.surveyFiles.length > 0 ?
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <TableContainer component={Paper}>
                                    <Table className='table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>File Name</TableCell>
                                                <TableCell>Submission ID</TableCell>
                                                <TableCell>Processing Status</TableCell>
                                                <TableCell>Submission Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.surveyFiles != null &&
                                                this.state.surveyFiles.length > 0 &&
                                                this.state.surveyFiles.map((file: ISurveyFile) =>
                                                    <TableRow key={file.fileId} className={classes.Row}>
                                                        <TableCell>{file.fileName}</TableCell>
                                                        <TableCell>{file.submissionID}</TableCell>
                                                        <TableCell>{(!file.isProcessed ? <LoadingDisplay size={25} /> :

                                                            <Tooltip title={'Processed'} aria-label={'Processed'}>
                                                                <SuccessIcon />
                                                            </Tooltip>
                                                        )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {file.isProcessed && this.renderSurveySubmissionStatus(file, classes)}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ verticalAlign: 'top' }}>
                                                    <ProcessingDisplay completed={true}></ProcessingDisplay>
                                                </TableCell>
                                                <TableCell style={{ verticalAlign: 'top' }}>
                                                    <Typography variant='body1' className={classes.greenColor}>Submission files processed. Please go to Submissions in the left panel to review individual submissions.</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        : ''}
                </div>
            </div>
        );
    }

    private onDragEnter = () => {
        this.enableDisableDropActive(true);
    }

    private onDragLeave = () => {
        this.enableDisableDropActive(false);
    }

    // upload the files (dropped in dropzone) to server
    private onDrop = (acceptedFiles, rejectedFiles) => {
        // set initial state
        this.setInitialState();

        const data = new FormData();
        if (acceptedFiles.length === 0) {
            this.setState({ uploadValidationMessages: [this.CreateValidationMessage('Please select a valid file')] });

            this.enableDropzone();
            this.enableDisableDropActive(false);
            return;
        }

        this.enableDisableDropActive(false);
        this.setState({
            processingFiles: true,
            uploadValidationMessages: []
        });

        acceptedFiles.forEach(file => {
            data.append("files", file, file.name);
        });
        const localTransactionId = uuidv4();
        this.setState({ transactionId: localTransactionId });

        const req = request.post(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + "/api/bulkupload/uploadfiles");
        req.query({ submissionType: this.props.uploadFileType });
        req.query({ transactionId: localTransactionId });
        req.set({ 'Authorization': 'Bearer ' + this.props.user.access_token });

        // show upload progress
        req.on('progress', event => {
            const percent = Math.floor(event.percent);
            if (!isNaN(percent)) {
                if (percent >= 100) {

                    this.setState({ completed: 100, uploadValidationMessages: [], processingText: "", });
                } else {
                    this.setState({ completed: percent });
                }
            }
        });

        setTimeout(
            () => this.spinWait(),
            1000
        );



        // send file(s) to server
        req.send(data).end();
        //    .end((err, res) => this.postUploadFiles(err, res));
    };

    private spinWait = async () => {


        const bulkCall = DbService.GetBulkTransactions$(this.props.user, this.state.transactionId);

        const transactionText = await bulkCall.toPromise();
        if (transactionText instanceof AjaxError || transactionText === null) {
            if (this.state.processingFiles) {
                setTimeout(
                    () => this.spinWait(),
                    2000
                );
            }
            return;
        }
        else {
            var finalStrings = [];

            if (transactionText != null && transactionText.length > 0) {
                finalStrings = transactionText.filter((x) => x.startsWith("{"));
            }
            if (finalStrings !== null && finalStrings.length !== 0) {
                var finalString = finalStrings[0];
                var result = JSON.parse(finalString);
                if (result.fatalError === true) {
                    this.setState({ uploadValidationMessages: [this.CreateValidationMessage("Unexpected error occurred during upload. Please contact NCQA support.")] });
                    this.enableDropzone();
                    this.enableDisableDropActive(true);
                    this.setState({ processingFiles: false });
                    return;
                }

                if (result.uploadDetail && result.uploadDetail.fileUploaded === false && !result.uploadDetail.validationResult.validationSuccessful) {
                    this.setState({ uploadValidationMessages: result.uploadDetail.validationResult.messages });
                }
                if (result.uploadDetail && result.uploadDetail.fileUploaded === true && !result.uploadDetail.validationResult.validationSuccessful) {
                    this.setState({ uploadValidationMessages: [this.CreateValidationMessage('Validations issue(s) found')] });
                }

                if (this.props.uploadFileType === SubmissionType.HEDIS) {
                    this.setState({ nonSurveyFiles: result.uploadDetail.nonSurveyFiles });
                }
                else {
                    this.setState({ surveyFiles: result.uploadDetail.surveyFiles });
                }

                this.enableDropzone();
                this.enableDisableDropActive(true);
                this.setState({ processingFiles: false });
            }
            else {
                var newText = transactionText.join("\n");
                this.setState({ processingText: newText });
            }
        }


        if (this.state.processingFiles) {
            setTimeout(
                () => this.spinWait(),
                2000
            );
        }

    }


    private renderDownloadReportLink = (onClick, isDownloading, primaryText) => {
        return (
            <ListItem alignItems='flex-start' button divider onClick={onClick}>
                <ListItemIcon>
                    {isDownloading ?
                        (<Fade in={isDownloading}
                            style={{ transitionDelay: "800ms" }}
                            unmountOnExit={true}>
                            <CircularProgress color="secondary" size={20} />
                        </Fade>)
                        : <GetAppIcon />}
                </ListItemIcon>
                <ListItemText classes={{ primary: this.props.classes.primaryBlue, secondary: this.props.classes.secondaryText }} primary={primaryText} />
            </ListItem>
        );
    }

    private async downloadBulkFiles() {

        this.setState({ isDownloadValidationReport: true });
        var submissionsToGet: ISelectedSubmissions[];
        if (this.state.nonSurveyFiles && this.state.nonSurveyFiles.length > 0) {
            submissionsToGet = this.state.nonSurveyFiles.map(i => {
                return {
                    submissionId: i.submissionID,
                    productLine: i.productLine,
                    component: i.component
                };
            });
        }
        if (this.state.surveyFiles && this.state.surveyFiles.length > 0) {
            submissionsToGet = this.state.surveyFiles.map(i => {
                return {
                    submissionId: i.submissionID,
                    productLine: i.productLine,
                    component: i.component
                };
            });
        }
        const desiredFiles: number[] = [3];// This is the validation file type
        const bulkSubs: ISelectedSubmissionsHolder = {
            submissions: submissionsToGet,
            fileTypes: desiredFiles
        }


        const retval = await SubmissionsService.GetBulkFiles$(
            this.props.user,
            bulkSubs
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            //   this.setRedirectState();
        }
        else {
            // this.setState({ isValidDownload: retval.response.isValidDownload });
            if (retval.response.isValidDownload) { //Make sure file(s) available for download else show "No files found" message
                const data = SharedUtility.Str2ab$(atob(retval.response.fileContents));
                saveAs(
                    new Blob([data], { type: "application/octet-stream" }),
                    retval.response.fileName
                );
            }
        }

        this.setState({ isDownloadValidationReport: false });
    }

    private getUploadReport = async () => {
        this.setState({ isDownloadUploadReport: true });
        const apiCall = ReportService.GetUploadReportCaller(this.props.user, this.state.transactionId);

        try {
            const userResponse = await apiCall.toPromise();
            if (typeof userResponse === 'string') {
                const data = SharedUtility.Str2ab$(atob(userResponse));
                saveAs(new Blob([data], {
                    type: "application/octet-stream"
                }), "UploadReport.xlsx");
            }
        }
        catch (ex) {
            //     handle error
        }
        finally {
            this.setState({ isDownloadUploadReport: false });
        }
    }

    private getValidationMessageList = () => {
        const { classes } = this.props;
        return (
            <ul className={classes.validationMessageList}>
                {this.state.uploadValidationMessages.map(function (valMessage, i) {
                    return (<li key={"message" + i} dangerouslySetInnerHTML={{ __html: SharedUtility.NewLinesToBreaks$(valMessage.message) }}></li>)
                })}
            </ul>
        );
    }

    private enableDropzone = () => {
        // enable dropzone
        this.setState({ disableDropzone: false });
    }

    private enableDisableDropActive(isActive) {
        this.setState({ dropzoneActive: isActive });
    }

    private setInitialState = () => {
        this.setState({
            completed: 0,
            nonSurveyFiles: [],
            surveyFiles: [],
            disableDropzone: true,
            showDownloadLink: false,
            filesNotProcessed: []
        });
    }

    private renderNonSurveyValidationStatus = (file: INonSurveyFile, classes) => {
        let div;
        if (file.isValidated) {
            switch (file.validationStatus) {
                case StatusType.Success:
                case StatusType.NoIssues:
                    div = <div className={classes.Success}>No issues</div>
                    break;
                case StatusType.WarningsAndErrors:
                    div = <div className={classes.Error} style={{ width: "170px" }}>Warnings and Errors</div>
                    break;
                case StatusType.Error:
                    div = <div className={classes.Error}>Error</div>
                    break;
                case StatusType.Fatal:
                    div = <div className={classes.Error}>Fatal</div>
                    break;
                case StatusType.SchemaError:
                    div = <div className={classes.Error}>Schema error</div>
                    break;
                case StatusType.Warning:
                    div = <div className={classes.Warning}>Warning</div>
                    break;
            }
        }
        return div;
    }

    private renderSurveySubmissionStatus = (file: ISurveyFile, classes) => {
        let retdiv = <div />
        if (file.isValidated) {
            switch (file.submissionStatus) {
                case StatusType.NotSubmitted:
                    retdiv = <div className={classes.NotSubmitted}>Not submitted</div>
                    break;
                case StatusType.Submitted:
                    retdiv = <div className={classes.Submitted}>Submitted</div>
                    break;
                case StatusType.NoIssues:
                case StatusType.Processed:
                    retdiv = <div className={classes.Processed}>Processed</div>
                    break;
                case StatusType.WarningsAndErrors:
                    retdiv = <div className={classes.Error} style={{ width: "170px" }}>Warnings and Errors</div>
                    break;
                case StatusType.Error:
                    retdiv = <div className={classes.Error}>Error</div>
                    break;
                case StatusType.Fatal:
                    retdiv = <div className={classes.Error}>Fatal</div>
                    break;
                case StatusType.SchemaError:
                    retdiv = <div className={classes.Error}>Schema error</div>
                    break;
                case StatusType.Warning:
                    retdiv = <div className={classes.Warning}>Warning</div>
                    break;
            }
        }
        return retdiv;

    }

    private CreateValidationMessage = (message: string): ValidationMessage => {
        return { message: message, severity: 4 };
    }
}

// STYLES CONTAINER
const BulkUploadDropzone = withStyles(styles)(BulkUploadDropzoneView);

export default BulkUploadDropzone;