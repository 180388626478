import * as React from "react";
import { Grid } from '@material-ui/core';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { connect } from "react-redux";

interface IHomeStateProps {
    defaultProp: string
}

const mapStateToProps = (): IHomeStateProps => ({
    defaultProp: ""
});

const styles = (theme: Theme) => createStyles({
    flex: {
        flexGrow: 1,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justify: 'center',
        background: 'white',
    },
})


type IStylesProps = WithStyles<typeof styles>;


// VIEW
class HomeView extends React.Component<IStylesProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Grid container={true} className={classes.root} >
                <Grid item={true}>
                    You are not logged in
                </Grid>
            </Grid>
        )
    }
}

// REDUX CONTAINER
const HomeViewState = connect<IHomeStateProps>(mapStateToProps)(HomeView);


// STYLES CONTAINER
const Home = withStyles(styles)(HomeViewState);

// REDUX CONTAINER

export default Home;


