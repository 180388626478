import { User } from "oidc-client";
import { AjaxError } from "rxjs/ajax";
import { Observable } from "rxjs";
import { IdssLog } from "../../DataClasses/DbData";
import AjaxHelper from "./AjaxHelper";

class DbService {

    public static GetLogs$(user: User, submissionId: string, userName: string, orgId: string, logLevel: string,
        message: string, fromDate: string, toDate: string, pageNumber: number, resultCount: number): Observable<Array<IdssLog> | AjaxError> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + `/api/DB/Logs?submissionId=${submissionId}&userName=${userName}&orgId=${orgId}&logLevel=${logLevel}&message=${message}&fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageNumber}&resultCount=${resultCount}`,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetLogCount$(user: User, submissionId: string, userName: string, orgId: string, logLevel: string,
        message: string, fromDate: string, toDate: string): Observable<number | AjaxError> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + `/api/DB/LogsCount?submissionId=${submissionId}&userName=${userName}&orgId=${orgId}&logLevel=${logLevel}&message=${message}&fromDate=${fromDate}&toDate=${toDate}`,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetBulkTransactions$(user: User, transactionId: string): Observable<string[] | AjaxError> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + `/api/DB/GetBulkTransactions?transaction=${transactionId}`,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
}

export default DbService;