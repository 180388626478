import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import RootState from "../../State/Root/RootState";
import { Tabs, Tab, Typography, AppBar } from "@material-ui/core";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';


interface ImportSubmissionProps {
    targetUser: string | null,
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
});

const mapStateToProps = (state: RootState): ImportSubmissionProps => {
    return ({
        targetUser: state.DashboardReducer.announcement,
    });
};

type IStylesProps = WithStyles<typeof styles>;


// VIEW
class ImportSubmissionData extends React.Component<IStylesProps & ImportSubmissionProps & RouteComponentProps<{ subId: string }>> {


    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={0}>
                        <Tab label="Import Data" />
                    </Tabs>
                </AppBar>
                <TabContainer>Import data for SubID {this.props.match.params.subId}</TabContainer>

            </div>
        );
    }
}

// REDUX CONTAINER
const ImportSubmissionViewWithState = connect<ImportSubmissionProps>(mapStateToProps)(ImportSubmissionData);

// STYLES CONTAINER
const ImportSubmissionDataPage = withStyles(styles)(ImportSubmissionViewWithState);

// REDUX CONTAINER

export default ImportSubmissionDataPage;


