import * as React from "react";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import { Grid, Typography, TextareaAutosize, Button, Radio, RadioGroup, FormControlLabel, FormControl, TextField, Checkbox } from "@material-ui/core";
import Select from "react-select";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import UserManagerService from "../Services/UserManagerService";
import { AjaxError } from "rxjs/ajax";
import { Redirect } from "react-router-dom";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import * as validator from "validator";
import AlertDialog from "../Shared/AlertDialog";
import { ICustomTextDialog } from "../../DataClasses/UsersData";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

type IStylesProps = WithStyles<typeof styles>;
interface ILocalState {
    selectedSystemGroupType: any;
    selectedRole: any;
    users: string;
    entityIds: string;
    allSystemGroupTypes: string[];
    allRoles: string[];
    redirect: boolean;
    isProcessing: boolean;
    errorMessages: string;
    bulkOptionSelected: string;
    copyFromUserEmail: string;
    removeExistingRoles: boolean;
    bulkUserOperationDialog: ICustomTextDialog;
    bulkUserOperationConfirmDialogOpen: boolean;
}

// VIEW
class BulkUserView extends React.Component<IGenericUserProps & IStylesProps, ILocalState> {
    constructor(props: IGenericUserProps & IStylesProps) {
        super(props);
        this.state = this.initialState;
        
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;

        return (<Grid container={true} spacing={2} className={classes.mainPanel + " " + classes.root}>
            <Grid item={true} xs={12}>
                <Typography className={classes.submissionTitle}>
                    Bulk User Management 
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Typography variant='body1' color='secondary'>
                    <span style={{ whiteSpace: 'pre-line' }}>{this.state.errorMessages}</span>
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <FormControl component="fieldset">
                    <Typography className={classes.primaryBlue}>
                        Select Bulk Update Option
                    </Typography>
                    <RadioGroup row aria-label="bulkOption" name="bulkOption" value={this.state.bulkOptionSelected} onChange={this.handleRadioChange.bind(this)}>
                        <FormControlLabel value="add" control={<Radio />} label="Add" />
                        <FormControlLabel value="remove" control={<Radio />} label="Remove" />
                        <FormControlLabel value="copy" control={<Radio />} label="Copy" />
                    </RadioGroup>                    
                </FormControl>
            </Grid>
            {this.state.bulkOptionSelected === "add" ? <>
                <Grid item={true} xs={12}>
                    <Typography className={classes.primaryBlue}>
                        Select System Group Type
                    </Typography>
                    <Select
                        options={this.getSysGroupOptions()}
                        placeholder="Select a System Group Type"
                        value={this.state.selectedSystemGroupType}
                        onChange={this.onSystemGroupTypeChange.bind(this)}
                        fullWidth={true}
                        searchable={true}
                        isClearable={true}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography className={classes.primaryBlue}>
                        Select Role
                    </Typography>
                    <Select
                        options={this.getRoleOptions()}
                        placeholder="Select a Role"
                        value={this.state.selectedRole}
                        onChange={this.onRoleChange.bind(this)}
                        fullWidth={true}
                        searchable={true}
                        isClearable={true}
                    />
                </Grid>
            </> : null}
            {this.state.bulkOptionSelected === "copy" ? <>
                <Grid item={true} xs={12}>
                    <Typography className={classes.primaryBlue}>
                        Enter copy from user email
                    </Typography>

                    <TextField value={this.state.copyFromUserEmail} variant="outlined" onChange={this.onCopyFromUserEmailChange.bind(this)} />
                </Grid>
            </> : null}
            <Grid item={true} xs={12}>
                <Typography className={classes.primaryBlue}>
                    Enter user email(s)
                </Typography>
                <TextareaAutosize
                    name="txtUsers"
                    value={this.state.users}
                    className={classes.fullWidth}
                    id="multiline-filled-static"
                    placeholder="Enter user emails (comma separated if you have more than one)"
                    rows={8}
                    cols={300}
                    onChange={this.onUsersChange.bind(this)}
                />
                {this.state.bulkOptionSelected === "copy" ? <>
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={this.state.removeExistingRoles}
                                    onChange={async () => this.setState({ removeExistingRoles: !this.state.removeExistingRoles })}
                                />
                            }
                            label="Remove existing roles before copying the new user roles"
                        />
                    </FormControl>
                </> : null}
            </Grid>
            {this.state.bulkOptionSelected === "add" ? <>
                <Grid item={true} xs={12}>
                    <Typography className={classes.primaryBlue}>
                        Enter organization/vendor id(s)
                    </Typography>
                    <TextareaAutosize
                        name="txtOrgIds"
                        value={this.state.entityIds}
                        className={classes.fullWidth}
                        id="multiline-filled-static"
                        placeholder="Enter organization/vendor ids (comma separated if you have more than one)"
                        rows={8}
                        cols={300}
                        onChange={this.onEntityIdsChange.bind(this)}
                    />
                </Grid>
                </> : null}
             <Grid item={true} xs={12}>
                <div style={{ float: 'right' }}>
                    <Button disabled={(this.state.bulkOptionSelected === "add" && (this.state.selectedSystemGroupType === null || this.state.selectedRole === null))
                        || this.state.isProcessing}
                        onClick={this.validate.bind(this)}
                        style={{ cursor: 'pointer'}}>
                        {!this.state.isProcessing ? "Submit" : "Saving..."}
                    </Button>
                    &nbsp;
                    <Button className={classes.disabledButton} onClick={this.resetState.bind(this)} style={{ cursor: 'pointer' }}>
                        Clear
                    </Button>
                </div>
            </Grid>
            <AlertDialog
                showDialog={this.state.bulkUserOperationConfirmDialogOpen}
                title={this.state.bulkUserOperationDialog.title}
                description={this.state.bulkUserOperationDialog.description}
                button1Text="Continue"
                button2Text="Cancel"
                button1Handler={this.handleBulkUserOperation}
                button2Handler={() => this.closeBulkUserOperationConfirmDialog()}
                isButton1Clicked={this.state.isProcessing}
            />
        </Grid>
        );
    }

    private closeBulkUserOperationConfirmDialog = () => {
        this.setState({ bulkUserOperationConfirmDialogOpen: false });
    }

    private async RefreshModel() {
        const sysGroupTypes = await UserManagerService.GetAllSysGroups$(
            this.props.user
        ).toPromise();
        if (sysGroupTypes instanceof AjaxError || !sysGroupTypes) {
            this.setRedirectState();
        } else {
            this.setState({ allSystemGroupTypes: sysGroupTypes });
        }
    }

    private getSysGroupOptions() {
        if (this.state.allSystemGroupTypes !== null && this.state.allSystemGroupTypes.length > 0) {
            const sysGroupTypes = this.state.allSystemGroupTypes;
            const sysGroupTypeOptions = sysGroupTypes.map(m => ({ value: m, label: m }));
            return sysGroupTypeOptions;
        }
    }

    private getRoleOptions() {
        if (this.state.allRoles !== null && this.state.allRoles.length > 0) {
            const roles = this.state.allRoles;
            const roleOptions = roles.map(m => ({ value: m, label: m }));
            return roleOptions;
        }
    }

    private async onSystemGroupTypeChange(sysGroupType) {
        this.setState({
            selectedSystemGroupType: sysGroupType,
            selectedRole: null
        });
        if (sysGroupType.value !== null && sysGroupType.value !== "") {
            this.getRolesForSystemGroupType(sysGroupType.value);
        }
    }

    private async onRoleChange(role) {
        this.setState({ selectedRole: role });
    }

    private async handleRadioChange(event) {
        this.setErrorMessage("");
        this.setState({ bulkOptionSelected: event.target.value });
    }

    private async onUsersChange(event) {
        this.setState({ users: event.target.value });
    }

    private async onCopyFromUserEmailChange(event) {
        this.setState({ copyFromUserEmail: event.target.value });
    }
    
    private async onEntityIdsChange(event) {
        this.setState({ entityIds: event.target.value });
    }

    private async getRolesForSystemGroupType(sysGroup: string) {
        const roles = await UserManagerService.GetAllRolesPerSysGroup$(
            this.props.user,
            sysGroup
        ).toPromise();
        if (roles instanceof AjaxError || !roles) {
            this.setRedirectState();
        } else {
            this.setState({ allRoles: roles });
        }
    }

    private handleBulkUserOperation = async () => {
        try {
            this.setState({ isProcessing: true });
            const retVal = await UserManagerService.BulkUserOperation$(
                    this.props.user,
                    this.state.bulkOptionSelected,
                    this.state.selectedSystemGroupType === null ? "" : this.state.selectedSystemGroupType.value,
                    this.state.selectedRole === null ? "" : this.state.selectedRole.value,
                    this.state.users,
                    this.state.entityIds,
                    this.state.copyFromUserEmail,
                    this.state.removeExistingRoles
                ).toPromise();            

            if (retVal instanceof AjaxError || !retVal) {
                this.setRedirectState();
            }
            else {
                this.resetState();
                this.setErrorMessage('Bulk user operation successful');
                this.closeBulkUserOperationConfirmDialog();
            }
        } catch (ex) {
        } finally {
            this.setState({ isProcessing: false });
        }
    }

    private validate() {
        var errorMessage = "";
        this.setErrorMessage(errorMessage);

        var bulkOptionSelected = this.state.bulkOptionSelected;
        if (!this.state.users || this.state.users.trim() === "") {
            errorMessage = "User email(s) should not be blank";
        }
        if (bulkOptionSelected === "add" && (!this.state.entityIds || this.state.entityIds.trim() === "")) {
            errorMessage = "Entity id(s) should not be blank";
        }
        if (bulkOptionSelected === "copy") {
            var copyFromUserEmail = this.state.copyFromUserEmail;
            if (!copyFromUserEmail || copyFromUserEmail.trim() === "") {
                errorMessage = "Copy from user email should not be blank";
            }
            else if (copyFromUserEmail.trim() !== "") {
                errorMessage = this.validateEmail(copyFromUserEmail.trim());
            }            
        }
        if (errorMessage !== "")
        {
            this.setErrorMessage(errorMessage);
            return false;
        }

        //validate user emails
        var emailErrors = this.validateUserEmails();            
        //validate entity Ids
        var entityIdErrors = this.validateEntityIds();
        //concat both errors array
        var errors = emailErrors.concat(entityIdErrors);
        if (errors.length > 0) {
            const flattenString = errors.join('\n');
            this.setErrorMessage(flattenString);
            return false;
        }

        this.setState({
            bulkUserOperationConfirmDialogOpen: true,
            bulkUserOperationDialog: {
                title: `Bulk user ${bulkOptionSelected} operation`,
                description: `Are you sure you want to perform the '${bulkOptionSelected}' bulk operation?`,               
            }
        });
        return true;
    }

    private setErrorMessage(message: string) {
        this.setState({ errorMessages: message });
    }

    private validateUserEmails(): string[] {
        var errors = [];
        if (this.state.users && this.state.users.trim() !== "") {
            var userEmails = this.state.users.split(',');
            if (userEmails.length > 0) {
                userEmails.forEach(e => {
                    var email = e.trim();
                    if (email !== "") {
                        var errorMessage = this.validateEmail(email);
                        if (errorMessage !== "")
                            errors.push(errorMessage);
                    }
                });
            }
        }
        return errors;
    }

    private validateEmail(email): string {
        var errorMessage = "";
        if (!validator.isLength(email, { min: 1, max: 75 })) {
            errorMessage = `Email - '${email}' can be no longer than 75 characters.`;
        } else if (!validator.isEmail(email)) {
            errorMessage = `Email - '${email}' is not in a valid format.`;
        }
        return errorMessage;
    }

    private validateEntityIds(): string[] {
        var errors = [];
        if (this.state.entityIds && this.state.entityIds.trim() !== "") {
            var entityIds = this.state.entityIds.split(',');
            if (entityIds.length > 0) {
                entityIds.forEach(e => {
                    var entityId = e.trim();
                    if (entityId !== "") {
                        if (!Number.isInteger(+entityId)) {
                            errors.push(`Entity Id - '${entityId}' is not valid.`);
                        }
                    }
                });
            }
        }
        return errors;
    }

    get initialState() {
        return {
            selectedSystemGroupType: null,
            selectedRole: null,
            users: "",
            entityIds: "",
            redirect: false,
            isProcessing: false,
            allSystemGroupTypes: [],
            allRoles: [],
            errorMessages: "",
            bulkOptionSelected: "add",
            copyFromUserEmail: "",
            bulkUserOperationDialog: {
                description: "",
                title: "",
            },
            bulkUserOperationConfirmDialogOpen: false,
            removeExistingRoles: false
        };
    }

    private resetState() {
        var allSystemGroupTypes = this.state.allSystemGroupTypes;

        this.setState(this.initialState);
        //retain all system group types
        this.setState({ allSystemGroupTypes: allSystemGroupTypes });
    }
}    

// REDUX CONTAINER
const BulkUserPageWithState = connect<IGenericUserProps>(genericMapStateToProps)(BulkUserView);

// STYLES CONTAINER
const BulkUserPage = withStyles(styles)(BulkUserPageWithState);

export default BulkUserPage;
