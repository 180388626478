import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from 'redux-persist/lib/storage/session'
import { Reducer as AuthenticationReducer } from "../Authentication";
import SubmissionListingReducer from "../SubmissionListing/Reducer";

const BaseRootReducer = combineReducers({
    AuthenticationReducer,
    SubmissionListingReducer
});

// Redux Persist
// For state that we want to persist through page refreshes
// Persist Authentication
const persistConfig: PersistConfig<any,any,any,any> = {
    key: 'root',
    stateReconciler: autoMergeLevel2,
    storage,
    whitelist: ['AuthenticationReducer']
   };

// Add persistance to the base root reducer   
const RootReducer = persistReducer(persistConfig, BaseRootReducer)   

export default RootReducer;