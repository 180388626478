import { Machine } from 'xstate';

export const allUsersMachineDefaultState = "default";

export interface AllUsersMachineSchema {
    states: {
        default: {};
        addUserDialogOpen: {};
        manageUserOpen: {};
        addRolesToUserDialogOpen: {};
        deleteAllAlertDialogOpen: {};
    };
}

// The events that the machine handles
// The context (extended state) of the machine
interface AllUsersMachineContext {
}

export type AllUsersMachineEvents =
    | { type: 'OpenUserDialog' }
    | { type: 'OpenManageUser' }
    | { type: 'CloseUserDialog' }
    | { type: 'CloseManageUser' }
    | { type: 'OpenDeleteAllAlertDialog' }
    | { type: 'OpenAddRolesToUserDialog' }
    | { type: 'CloseAddRolesToUserDialog' }
    | { type: 'CloseDeleteAllAlertDialog' }
    ;

// Although it's hard to tell here, strong typing the Machine provides strong type checking in the using class
export const allUsersMachine = Machine<AllUsersMachineContext, AllUsersMachineSchema, AllUsersMachineEvents>({
    id: 'allUsers',
    initial: 'default',
    states: {
        default: {
            on: {
                OpenUserDialog: 'addUserDialogOpen',
                OpenManageUser: 'manageUserOpen'
            }
        },
        addUserDialogOpen: {
            on: { CloseUserDialog: 'default' }
        },
        manageUserOpen: {
            on: {
                CloseManageUser: 'default',
                OpenDeleteAllAlertDialog: 'deleteAllAlertDialogOpen',
                OpenAddRolesToUserDialog: 'addRolesToUserDialogOpen',
                OpenUserDialog: 'addUserDialogOpen',
            }
        },
        addRolesToUserDialogOpen: {
            on: { CloseAddRolesToUserDialog: 'manageUserOpen' }
        },
        deleteAllAlertDialogOpen: {
            on: { CloseDeleteAllAlertDialog: 'manageUserOpen' }
        },
    }
});
