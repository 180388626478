import * as React from "react";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import {
    Grid,
    Typography,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Fade,
    CircularProgress,
    Button,
    InputLabel,
    Box,
    Divider
} from "@material-ui/core";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import UserManagerService from "../Services/UserManagerService";
import * as validator from "validator";
import { AjaxError } from "rxjs/ajax";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../DataClasses/GenericUser";
import ListIcon from '@material-ui/icons/List';
import AddCircle from '@material-ui/icons/AddCircle';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DoneIcon from '@material-ui/icons/Done';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import OrganizationService from "../Services/OrganizationService";
import { FormikHelpers, FormikErrors, FormikProps, Formik } from "formik";
import AlertDialog
    from "../Shared/AlertDialog";
import * as _ from 'lodash';
import * as AuthorizationPolicies from "../../Configuration/AuthorizationPolicies";
import {
    IOrgUserPayload,
    IUsersListViewStateStore,
    IAddUserForm,
    IEditUserForm,
    IEditOrganizationForm
} from "../../DataClasses/UsersData";
import Select from "react-select";
import { ISubmissionKey } from "../../DataClasses/SubmissionData";
import SubmissionsService from "../Services/SubmissionsService";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { SeedTime } from "../../Configuration/Setting";
import { Redirect } from "react-router-dom";
import AdministrationService from "../Services/AdministrationService";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

const primaryOrgListingOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    sort: true,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
};

const userRoleListingOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    sort: true,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
};

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999}) };

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class UsersListView extends React.Component<
    IGenericUserProps & IStylesProps,
    IUsersListViewStateStore
    > {
    constructor(props: IGenericUserProps & IStylesProps) {
        super(props);
        this.state = {
            userData: [],
            organizationData: [],
            isLoading: true,
            filterText: "",
            errorMsg: "",
            events: {
                addUserDialogOpen: false,
                deleteUserConfirmDialogOpen: false,
                editUserDialogOpen: false,
                deleteRoleConfirmDialogOpen: false,
                editOrganizationDialogOpen: false,
                deleteOrgUserConfirmDialogOpen: false,
                deleteAllUserConfirmDialogOpen: false,
                currentlyDeletingAllUsersForOrg: false
            },
            addUser: {
                email: "",
                selectedSystemGroup:"",
                selectedRole: "",
                selectedOrgs: []
            },
            editUser: {
                email: "",
                selectedSystemGroup:"",
                selectedOrgs: [],
                selectedRole: "",
                existingRoles: []
            },
            deleteUserDialog: {
                description: "",
                title: "",
                user: ""
            },
            deleteRoleDialog: {
                description: "",
                title: "",
                user: "",
                roleInfo: ""
            },
            editOrganization: {
                organizationId: 0,
                organizationName: "",
                orgType:"",
                selectedUser: "",
                selectedRole: "",
                existingUsers: [],
                currentlySubmittingRowIndex: null,
                userSearchText: ""
            },
            editSubmissions:{
                isEditingSubmissions:false,
                userName:"",
                submissionData:[],
                origSelectedSubIndexes:[],
                selectedSubIndexes:[],
                isChangeSaved:false
            },
            deleteOrgUserDialog: {
                description: "",
                title: "",
                user: "",
                roleInfo: ""
            },
            deleteAllUserDialog: {
                description: "",
                title: ""
            },
            selectableRoles: [],
            isProcessing: false,
            isOrgLoading: false,
            redirect: false,
            auditors: []
        };
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.RefreshModel();
        await this.GetAllAuditors();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes,user } = this.props;
            const primaryUserListingOptions: MUIDataTableOptions = {
                filterType: "checkbox",
                sort: true,
                filter: false,
                print: false,
                download: false,
                viewColumns: false,
                selectableRows: "none",
                rowsPerPage: 10,
                rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
            };

            const primaryUserListColumns = [
                {
                    name: "Email",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Actions",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div>
                                    <EditIcon className={classes.actionIcon} onClick={() => this.ShowUserEditDialog(tableMeta.rowData[0])}></EditIcon>
                                </div>
                            );
                        }
                    }
                },
                {
                    name: "",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if(user.profile.username===tableMeta.rowData[0])
                                return null;
                            return (
                                <div>
                                    <DeleteIcon className={classes.actionIcon} onClick={() => this.ShowUserDeleteDialog(tableMeta.rowData[0])}></DeleteIcon>
                                </div>
                            );
                        }
                    }
                }
            ];
            const primaryOrganizationListColumns = [
                {
                    label: "Org ID",
                    name: "OrgId",
                    options: {
                        filter: true
                    }
                },
                {
                    label: "Org Name",
                    name: "Name",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "OrgType",
                    options: {
                        display: "false" as any
                    }
                },
                {
                    name: "Actions",
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div>
                                    <EditIcon className={classes.actionIcon} onClick={() => this.ShowOrganizationEditDialog(tableMeta.rowData[0], tableMeta.rowData[1],tableMeta.rowData[2])}></EditIcon>
                                </div>
                            );
                        }
                    }
                }
            ];

            return (
                <Grid container={true} className={classes.gridRowFlex} spacing={2}>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} >
                                <Typography className={classes.submissionTitle}>
                                    User Management
                                </Typography>
                            </Grid>
                            <Grid color='default' item={true} className={classes.flexGrow} >

                            </Grid>
                            <Grid item={true} >
                                <Fab className={classes.fab} variant="extended" color="secondary" onClick={() => this.openAddUserDialog()}>
                                    <AddCircle className={classes.extendedIcon} />
                                    Add User
                                </Fab>
                                <Formik<IAddUserForm>
                                    initialValues={this.state.addUser}
                                    enableReinitialize={true}
                                    onSubmit={this.onAddUserFormSubmit}
                                    validate={this.validateAddUserForm}
                                >
                                    {props => this.getAddUserForm(props, classes)}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid key={1} item={true} xs={6} className={classes.gridDisplay}>
                        <div className={classes.card}>
                            {this.state.isLoading?<LoadingDataTable titleText={"*"}/>:
                            <Fade in={true} {...({ timeout: SeedTime })}><div>
                            <MUIDataTable
                                title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Users</Typography>}
                                data={this.state.userData}
                                columns={primaryUserListColumns}
                                options={primaryUserListingOptions}
                            /></div></Fade>}
                            <AlertDialog
                                showDialog={this.state.events.deleteUserConfirmDialogOpen}
                                title={this.state.deleteUserDialog.title}
                                description={this.state.deleteUserDialog.description}
                                button1Text="Delete User"
                                button2Text="Cancel"
                                button1Handler={this.DeleteUser}
                                button2Handler={() => this.CloseDeleteUserConfirmDialog()}
                                isButton1Clicked={this.state.isProcessing}
                            />
                            <Formik<IEditUserForm>
                                initialValues={this.state.editUser}
                                enableReinitialize={true}
                                onSubmit={this.onEditUserFormSubmit}
                                validate={this.validateEditUserForm}
                            >
                                {props => this.getEditUserForm(props, classes)}
                            </Formik>
                            <AlertDialog
                                showDialog={this.state.events.deleteRoleConfirmDialogOpen}
                                title={this.state.deleteRoleDialog.title}
                                description={this.state.deleteRoleDialog.description}
                                button1Text="Delete Role"
                                button2Text="Cancel"
                                button1Handler={this.DeleteRole}
                                button2Handler={() => this.CloseDeleteRoleConfirmDialog()}
                                isButton1Clicked={this.state.isProcessing}
                            />
                        </div>
                    </Grid>
                    <Grid key={2} item={true} xs={6} className={classes.gridDisplay}>
                        <div className={classes.card}>
                        {this.state.isLoading?<LoadingDataTable titleText={"*"}/>:
                           <Fade in={true} {...({ timeout: SeedTime*2 })}><div>
                            <MUIDataTable
                                title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Organizations</Typography>}
                                data={this.state.organizationData}
                                columns={primaryOrganizationListColumns}
                                options={primaryOrgListingOptions}
                            /></div></Fade>}
                            <Formik<IEditOrganizationForm>
                                initialValues={this.state.editOrganization}
                                enableReinitialize={true}
                                onSubmit={this.onEditOrganizationFormSubmit}
                                validate={this.validateEditOrganizationForm}
                            >
                                    {props => this.getEditOrganizationForm(props, classes)}
                            </Formik>
                            <AlertDialog
                                showDialog={this.state.events.deleteOrgUserConfirmDialogOpen}
                                title={this.state.deleteOrgUserDialog.title}
                                description={this.state.deleteOrgUserDialog.description}
                                button1Text="Remove User"
                                button2Text="Cancel"
                                button1Handler={this.DeleteOrgUser}
                                button2Handler={() => this.CloseDeleteOrgUserConfirmDialog()}
                                isButton1Clicked={this.state.isProcessing}
                            />
                            <AlertDialog
                                showDialog={this.state.events.deleteAllUserConfirmDialogOpen}
                                title={this.state.deleteAllUserDialog.title}
                                description={this.state.deleteAllUserDialog.description}
                                button1Text="Remove All"
                                button2Text="Cancel"
                                button1Handler={this.DeleteAllOrgUsers}
                                button2Handler={() => this.CloseDeleteAllUserConfirmDialog()}
                                isButton1Clicked={this.state.isProcessing}
                            />
                        </div>
                    </Grid>
                </Grid>
            );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const userRetVal = await UserManagerService.GetManagableUsers$(
            this.props.user
        ).toPromise();
        const orgRetVal = await OrganizationService.GetManagableOrganizationsAndVendors$(
            this.props.user
        ).toPromise();

        if (userRetVal instanceof AjaxError || orgRetVal instanceof AjaxError || !userRetVal || !orgRetVal) {
            this.setRedirectState();
        }
        else {
            const userData = userRetVal.map(item => {
                return [item];
            });
            const orgData = orgRetVal.map(item => {
                return {
                    OrgId: item.orgId,
                    Name: item.orgName,
                    OrgType: item.orgType
                };
            });
            this.setState({
                userData: userData,
                organizationData: orgData
            });

            this.setState({ isLoading: false });
        }
    }

    private openAddUserDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                addUserDialogOpen: true
            }
        });
    }

    private closeAddUserDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                addUserDialogOpen: false
            },
            addUser: {
                email: "",
                selectedSystemGroup:"",
                selectedRole: "",
                selectedOrgs: []
            }
        });
    }

    private getAddUserForm = (formikProps: FormikProps<IAddUserForm>, classes: any): JSX.Element => {
        const {
            handleSubmit,
            handleReset,
            handleBlur,
            touched,
            errors,
            values,
            isSubmitting,
            submitForm
        } = formikProps;

        const orgOption = (values.selectedSystemGroup&&values.selectedSystemGroup.value!=="")?
        this.state.organizationData.filter(x=>x.OrgType===values.selectedSystemGroup.value).map(m => ({
            label: m.OrgId + "-" + m.Name,
            value: m.OrgId
        })):[];

        const roleOption=this.state.selectableRoles.map(m=>({value:m, label:m}));

        return (
            <div>
                <form onSubmit={handleSubmit}>
                <Dialog open={this.state.events.addUserDialogOpen} onClose={handleReset} classes={{paper: classes.dialogM}} >
                        <DialogTitle id="max-width-dialog-title">
                                Add New User
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Grid xs={10} item={true} key="userRoleListInputsAdd" className={classes.flexGrow + " " + classes.fullWidth}>
                                    <Box flexGrow={1} className={classes.defaultMargin}>
                                        <InputLabel shrink>Email</InputLabel>
                                        <TextField
                                            autoFocus={false}
                                            fullWidth={true}
                                            name="email"
                                            margin="dense"
                                            type="text"
                                            required={true}
                                            error={errors.email && touched.email}
                                            helperText={
                                                errors.email && touched.email
                                                    ? errors.email
                                                    : undefined
                                            }
                                            onChange={this.onChangeAddNewUserEmail.bind(this)}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            disabled={isSubmitting}
                                        />
                                    </Box>
                                    <Box flexGrow={1} className={classes.defaultMargin}>
                                                <InputLabel shrink>Select System Group</InputLabel>
                                                <Select
                                                    options={this.GetSysGroupOptions()}
                                                    placeholder="Select a System Group"
                                                    value={values.selectedSystemGroup}
                                                    onChange={this.onChangeAddNewUserSysGroup.bind(this)}
                                                    fullWidth={true}
                                                    searchable={true}
                                                    isClearable ={true}
                                                />
                                    </Box>
                                    {this.state.addUser.selectedSystemGroup===""?<br/>:
                                    <div>
                                    <Box flexGrow={1} className={classes.defaultMargin}>
                                                <InputLabel shrink>Select Role</InputLabel>
                                                        <Select
                                                            options={!this.isSelectedUserAuditor(this.state.addUser.email) ? roleOption.filter(r => r.value.toLowerCase() !== "auditor") : roleOption}
                                                            placeholder="Select a Role"
                                                            value={values.selectedRole}
                                                            onChange={this.onChangeAddNewUserSelectedRole.bind(this)}
                                                            fullWidth={true}
                                                            searchable={true}
                                                            isClearable ={true}
                                                        />
                                                {errors.selectedRole ? <div>{errors.selectedRole}</div> : null}
                                    </Box>
                                    <Box flexGrow={1} className={classes.defaultMargin}>
                                                <InputLabel shrink>Select {this.state.addUser.selectedSystemGroup===null?"Organization":this.state.addUser.selectedSystemGroup.value}(s)</InputLabel>
                                                <Select
                                                    options={orgOption}
                                                    placeholder={"Search and Select..."}
                                                    isMulti={true}
                                                    matchProp="label"
                                                    simpleValue={true}
                                                    value={values.selectedOrgs}
                                                    closeOnSelect={false}
                                                    searchable={true}
                                                    onChange={this.selectedOrgsChange4AddNewUser.bind(this)}
                                                    styles={selectStyles}       
                                                    maxMenuHeight={150}                                       
                                                    />                                               
                                     </Box>
                                    </div>
                                }
                            </Grid>
                        </DialogContent>
                        <Divider/>
                        <DialogActions>
                            {this.showProgress()}
                            <Fab className={classes.fab} size="medium" variant="extended" color="secondary" onClick={submitForm} 
                                    disabled={!(!validator.isEmpty(values.email)&&validator.isEmail(values.email)&&values.selectedSystemGroup!==""&& values.selectedRole!==""&&values.selectedOrgs!==[]&&values.selectedOrgs.length>0) || this.state.isProcessing}>
                                <AddCircle className={classes.extendedIcon} />
                                    Add User
                            </Fab>
                            <Button
                                className={classes.disabledButton}
                                onClick={() => this.closeAddUserDialog()}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </div>
        );
    };

    private selectedOrgsChange4AddNewUser(newValue){
        this.setState({
            addUser:{
                ...this.state.addUser,
                selectedOrgs: newValue
            }
        })
    }
    private onChangeAddNewUserSelectedRole(newValue){
        this.setState({
            addUser:{
                ...this.state.addUser,
                selectedRole:newValue===null?"":newValue
            }
        })
    }

    async onChangeAddNewUserEmail(e){      
        if(this.state.addUser.selectedRole&&this.state.addUser.selectedRole.value.toLowerCase()==="auditor" && !this.isSelectedUserAuditor(e.target.value)){
            this.setState({
                addUser:{
                    ...this.state.addUser, 
                    selectedRole:"",
                    email:e.target.value
                }
            })
        }
        else{
            this.setState({
                addUser:{
                    ...this.state.addUser, 
                    email:e.target.value
                }
            });
        }
    }
    async onChangeAddNewUserSysGroup(newValue){
        this.setState({
            addUser:{
                ...this.state.addUser,
                selectedSystemGroup:newValue===null?"":newValue,
                selectedRole:"",
                selectedOrgs:[]
            }
        });
        if(newValue!==null&&newValue!==""){
            this.refreshSelectableRoles(newValue.value);
        }
    }

    private GetSysGroupOptions(){
        const sysGroupDup=this.state.organizationData.map(m =>m.OrgType);
        const sysGroupOption=sysGroupDup.filter((n, i) => sysGroupDup.indexOf(n) === i).map(m=>({value:m, label:m}));
        return sysGroupOption;
    }

    private validateAddUserForm = (values: IAddUserForm): FormikErrors<IAddUserForm> => {
        const errors: FormikErrors<IAddUserForm> = {};

        // EMAIL
        //1. Require
        // 2. Max length = 255
        // 3. Valid
        if (validator.isEmpty(values.email)) {
            errors.email = "Required";
        } else if (!validator.isLength(values.email, { min: 1, max: 75 })) {
            errors.email = "Email can be no longer than 75 characters";
        } else if (!validator.isEmail(values.email)) {
            errors.email = "Email is not in a valid format";
        }

        if (!values.selectedOrgs || values.selectedOrgs.length === 0) {
            errors.selectedOrgs = ["Please select an organization."];
        }

        return errors;
    };

    private onAddUserFormSubmit = async (
        values: IAddUserForm,
        formikHelpers: FormikHelpers<IAddUserForm>
    ): Promise<void> => {
        const { setSubmitting } = formikHelpers;
        try {
            this.setProcessingState(true);
            const retval = await UserManagerService.AddUserToRoles$(
                this.props.user,
                values.email,
                values.selectedSystemGroup.value.toLowerCase(),
                values.selectedRole.value,
                values.selectedOrgs && values.selectedOrgs.length > 0
                    ? values.selectedOrgs.map(x => x.value).join(",")
                    : "",
                "",
                true
            ).toPromise();
            if (retval instanceof AjaxError || !retval) {
                this.setRedirectState();
            }
            else {
                this.closeAddUserDialog();
            }
        } catch (ex) {
        } finally {
            setSubmitting(false);
            this.RefreshModel();
            this.setProcessingState(false);
        }
    }

    private setProcessingState = (value: boolean) => {
        this.setState({ isProcessing: value });
    }

    private showProgress = () => {
        return <> { this.state.isProcessing ? <span style={{ paddingTop: '10px' }}><CircularProgress color="primary" size={22} /></span> : null } </>
    }
    private RefreshRolesForUser = async (userEmail) => {
        const userRetVal = await UserManagerService.GetManagableRolesForUser$(
            this.props.user,
            userEmail
        ).toPromise();

        if (userRetVal instanceof AjaxError || !userRetVal) {
            this.setRedirectState();
        }
        else {
            let userRoles = userRetVal.map(item => {
                return [item];
            });

            userRoles = _.flatten(userRoles);

            this.setState({
                editUser: {
                    ...this.state.editUser,
                    existingRoles: userRoles
                }
            });
        }
    }

    private GetAllAuditors = async () => {
        const retVal = await AdministrationService.GetAllAuditors$(
            this.props.user
        ).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {
            this.setState({
                auditors: retVal
            });
        }
    }

    private isSelectedUserAuditor = (userEmail: string) => {
        var auditors = this.state.auditors;
        return auditors.some(a => a.toLowerCase() === userEmail.toLowerCase());
    }

    private ShowUserEditDialog = async (userEmail) => {
        await this.RefreshRolesForUser(userEmail);
        this.setState({
            events: {
                ...this.state.events,
                editUserDialogOpen: true
            },
            editUser: {
                ...this.state.editUser,
                email: userEmail,
                selectedOrgs: [],
                selectedRole: ""
            }
        });
    }

    private closeEditUserDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                editUserDialogOpen: false
            },
            editUser: {
                email: "",
                selectedSystemGroup:"",
                selectedOrgs: [],
                selectedRole: "",
                existingRoles: []
            },
            selectableRoles:[]
        });
    }

    private getEditUserForm = (formikProps: FormikProps<IEditUserForm>, classes: any): JSX.Element => {
        const {
            handleSubmit,
            handleReset,
            errors,
            values,
            isSubmitting,
        } = formikProps;


        const orgOption = (values.selectedSystemGroup&&values.selectedSystemGroup.value!=="")?
        this.state.organizationData.filter(x=>x.OrgType===values.selectedSystemGroup.value).map(m => ({
            label: m.OrgId + "-" + m.Name,
            value: m.OrgId
        })):[];

        const userRoleColumns = [
            {
                label: "ID",
                name: "entityId",
                options: {
                    filter: true
                }
            },
            {
                label: "Org/Vendor Name",
                name: "entityName",
                options: {
                    filter: true
                }
            },
            {
                label: "Role Type",
                name: "roleType",
                options: {
                    filter: true
                }
            },
            {
                label: "Role Name",
                name: "roleName",
                options: {
                    filter: true
                }
            },
            {
                name: "Actions",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(this.props.user.profile.username===values.email && tableMeta.rowData[3].toLowerCase().indexOf("admin")!==-1)
                            return null;
                        return (
                            <div>
                                <DeleteIcon className={classes.actionIcon} onClick={() => this.ShowRoleDeleteDialog(tableMeta.rowData[3], tableMeta.rowData[2], tableMeta.rowData[0])}></DeleteIcon>
                            </div>
                        );
                    }
                }
            }
        ];

        const roleOption=this.state.selectableRoles.map(m=>({value:m, label:m}));

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <Dialog open={this.state.events.editUserDialogOpen} onClose={handleReset} classes={{paper: classes.dialogM}} >
                        <DialogTitle id="max-width-dialog-title">
                                Edit: {values.email}
                        </DialogTitle>
                        <Divider />
                        <DialogContent>

                                <Grid xs={12} item={true} key="userRoleListInputsEdit" className={classes.flexGrow + " " + classes.fullWidth}>

                                            <Typography variant='h6' color='primary' className={classes.tabTitle}>
                                                Add Role
                                            </Typography>
                                            <Box flexGrow={1} className={classes.defaultMargin}>
                                                <InputLabel shrink>Select System Group</InputLabel>
                                                <Select
                                                    options={this.GetSysGroupOptions()}
                                                    placeholder="Select a System Group to Start Adding Role"
                                                    value={values.selectedSystemGroup}
                                                    onChange={this.onChangeAddRoleSysGroup.bind(this)}
                                                    fullWidth={true}
                                                    searchable={true}
                                                    isClearable ={true}
                                                />
                                            </Box>
                                            {this.state.editUser.selectedSystemGroup===""?<br/>:
                                            <div>
                                            <Box flexGrow={1} className={classes.defaultMargin}>
                                                <InputLabel shrink>Select Role</InputLabel>
                                                        <Select
                                                            options={!this.isSelectedUserAuditor(this.state.editUser.email) ? roleOption.filter(r => r.value.toLowerCase() !== "auditor") : roleOption}
                                                            placeholder="Select a Role"
                                                            value={values.selectedRole}
                                                            onChange={this.onChangeAddRoleSelectedRole.bind(this)}
                                                            fullWidth={true}
                                                            searchable={true}
                                                            isClearable ={true}
                                                        />
                                                {errors.selectedRole ? <div>{errors.selectedRole}</div> : null}
                                            </Box>
                                            <Box flexGrow={1} className={classes.defaultMargin}>
                                                <InputLabel shrink>Select {this.state.editUser.selectedSystemGroup===null?"Organization":this.state.editUser.selectedSystemGroup.value}(s)</InputLabel>
                                                <Select
                                                    options={orgOption}
                                                    placeholder={"Search and Select..."}
                                                    isMulti={true}
                                                    matchProp="label"
                                                    simpleValue={true}
                                                    value={values.selectedOrgs}
                                                    closeOnSelect={false}
                                                    searchable={true}
                                                    onChange={this.selectedOrgsChange4AddRole.bind(this)}
                                                    styles={selectStyles}       
                                                    maxMenuHeight={150}                                       
                                                    />
                                            </Box>
                                            <Box>
                                                <Fab className={classes.fab} variant="extended" color="secondary" onClick={this.onEditUserFormSubmit} disabled={!(values.selectedSystemGroup!=="" && values.selectedRole!==""&&values.selectedOrgs!==null&&values.selectedOrgs.length>0) || this.state.isProcessing}>
                                                    <AddCircle className={classes.extendedIcon} />
                                                    Add Role
                                                </Fab>
                                                {this.showProgress()}
                                            </Box>
                                            </div>
                                            }
                                </Grid>
                                <br/>
                                <Grid xs={12} item={true} key="userRoleListGrid">
                                    <div className={classes.card}>
                                        <MUIDataTable
                                            title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>User Roles</Typography>}
                                            data={values.existingRoles}
                                            columns={userRoleColumns}
                                            options={userRoleListingOptions}
                                        />
                                    </div>
                                </Grid>

                        </DialogContent>
                        <Divider/>
                        <DialogActions>
                            <Button
                                onClick={() => this.closeEditUserDialog()}
                                disabled={isSubmitting}                                
                            >
                                ClOSE
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </div>
        );
    };

    async onChangeAddRoleSysGroup(newValue) {
        this.setState({
            editUser:{
                ...this.state.editUser,
                selectedSystemGroup:newValue===null?"":newValue,
                selectedRole:"",
                selectedOrgs:[]
            }
        });
        if(newValue!==null&&newValue!==""){
            this.refreshSelectableRoles(newValue.value);
        }
    }
    async refreshSelectableRoles(sysGroup:string){
        const rolesRetval = await UserManagerService.GetAllRolesPerSysGroupEditUser$(
            this.props.user,
            sysGroup,
            this.state.editUser.email
          ).toPromise();
        if (rolesRetval instanceof AjaxError || !rolesRetval) {
            this.setRedirectState();
        } else {
            this.setState({ selectableRoles: rolesRetval });
        }
    }

    onChangeAddRoleSelectedRole(newValue){
        this.setState({
            editUser:{
                ...this.state.editUser,
                selectedRole:newValue===null?"":newValue
            }
        });
    }
    selectedOrgsChange4AddRole(newValue){
        this.setState({
            editUser:{
                ...this.state.editUser,
                selectedOrgs:newValue
            }
        });
    }
    private validateEditUserForm = (values: IEditUserForm): FormikErrors<IEditUserForm> => {
        const errors: FormikErrors<IEditUserForm> = {};

        if (validator.isEmpty(values.email)) {
            errors.email = "Required";
        }

        if (!values.selectedOrgs || values.selectedOrgs.length === 0) {
            errors.selectedOrgs = ["Please select an organization."];
        }

        if (validator.isEmpty(values.selectedRole.value)) {
            errors.selectedRole = "Required";
        }

        return errors;
    };

    onEditUserFormSubmit = async () => {  
        try {
            this.setProcessingState(true);
            var response = await UserManagerService.AddUserToRoles$(
                this.props.user,
                this.state.editUser.email,
                this.state.editUser.selectedSystemGroup.value.toLowerCase(),
                this.state.editUser.selectedRole.value,
                this.state.editUser.selectedOrgs && this.state.editUser.selectedOrgs.length > 0
                    ? this.state.editUser.selectedOrgs.map(x => x.value).join(",")
                    : "",
                "",
                false
            ).toPromise();
            if (response instanceof AjaxError || !response) {
                this.setRedirectState();
            }
            else {
                await this.RefreshRolesForUser(this.state.editUser.email);
                this.setState({
                    editUser: {
                        ...this.state.editUser,
                        selectedSystemGroup: "",
                        selectedOrgs: [],
                        selectedRole: ""
                    }
                });
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    };

    private ShowUserDeleteDialog = (userEmail) => {
        this.setState({
            events: {
                ...this.state.events,
                deleteUserConfirmDialogOpen: true
            },
            deleteUserDialog: {
                title: "Delete User " + userEmail + "?",
                description: "Are you sure you'd like to delete User " + userEmail + "?",
                user: userEmail
            }
        });
    }

    private CloseDeleteUserConfirmDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                deleteUserConfirmDialogOpen: false
            }
        });
    }

    private DeleteUser = async () => {
        try {
            this.setProcessingState(true);
            const deleteResp = await UserManagerService.DeleteManagableUserAccess$(
                this.props.user,
                this.state.deleteUserDialog.user
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                const userRetVal = await UserManagerService.GetManagableUsers$(
                    this.props.user
                ).toPromise();
                const userData = userRetVal.map(item => {
                    return [item];
                });
                this.setState({
                    userData: userData
                });
                this.CloseDeleteUserConfirmDialog();
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }

    private ShowRoleDeleteDialog = (roleName, roleType, entityId) => {
        this.setState({
            events: {
                ...this.state.events,
                deleteRoleConfirmDialogOpen: true
            },
            deleteRoleDialog: {
                title: `Delete Role ${roleName}?`,
                description: `Are you sure you want to delete ${roleName} role for ${this.state.editUser.email}?`,
                user: this.state.editUser.email,
                roleInfo: entityId + "," + roleType + "," + roleName
            }
        });
    }

    private DeleteRole = async () => {
        try {
            this.setProcessingState(true);
            const deleteResp = await UserManagerService.DeleteUserRole$(
                this.props.user,
                this.state.deleteRoleDialog.user,
                this.state.deleteRoleDialog.roleInfo
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                await this.RefreshRolesForUser(this.state.deleteRoleDialog.user);
                this.CloseDeleteRoleConfirmDialog();
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }

    private CloseDeleteRoleConfirmDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                deleteRoleConfirmDialogOpen: false
            }
        });
    }

    private ShowOrganizationEditDialog = async (organizationId, organizationName, orgType) => {
        this.setState({
            events: {
                ...this.state.events,
                editOrganizationDialogOpen: true
            }
        });

        this.setState({ isOrgLoading: true });
        try {
            await this.RefreshUsersForOrganization(organizationId);

            this.setState({                
                editOrganization: {
                    ...this.state.editOrganization,
                    organizationId: organizationId,
                    organizationName: organizationName,
                    orgType: orgType,
                    selectedUser: ""
                }
            });

            this.refreshSelectableRoles(orgType);
        } catch (ex) {
        } finally {
            this.setState({ isOrgLoading: false });
        }
    }

    private closeEditOrganizationDialog = () => {
        //reset params for submissions screen
        this.backToOrgUsersList();
        this.setState({
            events: {
                ...this.state.events,
                editOrganizationDialogOpen: false
            }          
        });
    }
    
    private getEditOrganizationForm = (formikProps: FormikProps<IEditOrganizationForm>, classes: any): JSX.Element => {
        const {
            handleSubmit,
            handleReset,
            values,
            isSubmitting
        } = formikProps;

        const userOption = this.state.userData.map(email => ({
            label: email[0],
            value: email[0]
        }));

        const roleOption=this.state.selectableRoles.map(m=>({value:m, label:m}));

        const orgUserColumns = [
            {
                label: "Email",
                name: "email",
                options: {
                    filter: true
                }
            },
            {
                label: "Role",
                name: "roleName",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                                <Select
                                options={!this.isSelectedUserAuditor(tableMeta.rowData[0]) ? roleOption.filter(r => r.value.toLowerCase() !== "auditor") : roleOption}
                                    value={roleOption.filter(x=>x.value===value)[0]}
                                    onChange={this.handleOrgUserRoleChange.bind(this, tableMeta)}
                                    fullWidth={true}
                                    isDisabled={tableMeta.rowIndex === this.state.editOrganization.currentlySubmittingRowIndex||(this.props.user.profile.username===tableMeta.rowData[0] && value.toLowerCase().indexOf("admin")!==-1)}
                                    searchable={true}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}                       
                            />     
                        );
                    }
                },
            },
            {
                label: "Submission Access",
                name: "roleName",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            value.toLowerCase()==="auditor"?
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                className={classes.button}
                                startIcon={<ListIcon />}
                                onClick={()=>this.btnClickManageSubmissions(tableMeta.rowData[0])}
                            >
                                &nbsp;Manage Submissions
                            </Button>:null
                        );
                    },
                    display: (this.state.editOrganization.orgType.toLocaleLowerCase()==="organization"?"true":"false") as any
                }               
            },
            {
                label: "Actions",
                name: "roleName",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(this.props.user.profile.username===tableMeta.rowData[0] && tableMeta.rowData[1].toLowerCase().indexOf("admin")!==-1)
                            return null;
                        return (
                            <div>
                                <DeleteIcon className={classes.actionIcon} onClick={() => this.ShowOrgUserDeleteDialog(tableMeta.rowData[0], tableMeta.rowData[1])}></DeleteIcon>
                            </div>
                        );
                    }
                }
            }
        ];

        const orgUserListingOptions: MUIDataTableOptions = {
            filterType: "checkbox",
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            searchText: this.state.editOrganization.userSearchText,
            onSearchChange: (searchText) => {
                this.setState({
                    editOrganization: {
                        ...this.state.editOrganization,
                        userSearchText: searchText
                    }
                });
            },
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
        };

        const submissionColumns = [
            {
                label: "Sub ID",
                name: "submissionId",
                options: {
                    filter: true
                }
            },
            {
                label: "Product Line",
                name: "productLine",
                options: {
                    filter: true
                }
            },
            {
                label: "Reporting Product",
                name: "reportingProduct",
                options: {
                    filter: true
                }
            },
            {
                label:"Special Area",
                name:"specialArea",
                options: {
                    filter: true
                }
            },
            {
                label:"Special Project",
                name:"specialProject",
                options: {
                    filter: true
                }
            },
            {
                label:"CMS Contract#",
                name:"cmsContractNumber",
                options: {
                    filter: true
                }
            }
        ]

        const submissionOptions: MUIDataTableOptions = {
            filterType: "checkbox",
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "multiple",
            selectableRowsOnClick: true,
            rowsSelected: this.state.editSubmissions.selectedSubIndexes,
            onRowSelectionChange: (affectedRows, selectedSub, rowsSelected) => {
                this.setState({
                    editSubmissions:{...this.state.editSubmissions, selectedSubIndexes: selectedSub.map(item => item.dataIndex)},
                });
            },          
            customToolbarSelect: () => { return null },
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
        };
        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <Dialog classes={{paper: classes.dialog7080}} 
                        open={this.state.events.editOrganizationDialogOpen}
                        onClose={handleReset}>
                        {this.state.isOrgLoading ? null : <DialogTitle id="max-width-dialog-title">Edit: {values.organizationName}</DialogTitle>}
                        <Divider />
                        {this.state.editSubmissions.isEditingSubmissions?
                        <DialogContent>
                            <Typography className={classes.tabTitle}>
                                Manage Submissions Access for Auditor: {this.state.editSubmissions.userName}
                            </Typography>
                            <MUIDataTable
                                title={"Available Submissions"}
                                data={this.state.editSubmissions.submissionData}
                                columns={submissionColumns}
                                options={submissionOptions}
                            />                           
                        </DialogContent>
                        :
                            <DialogContent>
                                {this.state.isOrgLoading ? <span style={{ textAlign: 'center' }}><CircularProgress color="primary" size={40} /></span> : <>
                                    <Grid xs={12} item={true} key="orgUserListInputs" className={classes.flexGrow + " " + classes.fullWidth}>
                                        <Typography variant='h6' color='primary' className={classes.tabTitle}>
                                            Add User
                                            </Typography>
                                        <Box flexGrow={1} className={classes.defaultMargin}>
                                            <InputLabel shrink>Select User</InputLabel>
                                            <Select
                                                options={userOption}
                                                placeholder="Select an Email Account to Start Adding User"
                                                value={values.selectedUser}
                                                onChange={this.onChangeSelectUserToEntity.bind(this)}
                                                fullWidth={true}
                                                searchable={true}
                                                isClearable={true}
                                                styles={selectStyles}
                                            />
                                        </Box>
                                        {this.state.editOrganization.selectedUser === "" ? <br /> :
                                            <div>
                                                <Box flexGrow={1} className={classes.defaultMargin}>
                                                    <InputLabel shrink>Select Role</InputLabel>
                                                    <Select
                                                        options={!this.isSelectedUserAuditor(this.state.editOrganization.selectedUser.value) ? roleOption.filter(r => r.value.toLowerCase() !== "auditor") : roleOption}
                                                        placeholder="Select a Role"
                                                        value={values.selectedRole}
                                                        onChange={this.onChangeSelectRoleToEntityUser.bind(this)}
                                                        fullWidth={true}
                                                        searchable={true}
                                                        isClearable={true}
                                                        styles={selectStyles}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Fab className={classes.fab} variant="extended" color="secondary" onClick={this.onEditOrganizationFormSubmit} disabled={values.selectedUser === "" || values.selectedRole === "" || this.state.isProcessing}>
                                                        <AddCircle className={classes.extendedIcon} />
                                                        Add User
                                                </Fab>
                                                    {this.showProgress()}
                                                </Box>
                                            </div>
                                        }
                                    </Grid>
                                    <br />
                                    <Grid xs={12} item={true} key="orgUsersListGrid">
                                        <div className={classes.card}>
                                            <MUIDataTable
                                                title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Assigned Users</Typography>}
                                                data={values.existingUsers}
                                                columns={orgUserColumns}
                                                options={orgUserListingOptions}
                                            />
                                            {AuthorizationPolicies.IsGlobalAdmin(this.props.user) && values.existingUsers.length > 0 &&
                                                <Box>
                                                    <Fab className={classes.fab} variant="extended" color="secondary" onClick={this.ShowAllUserDeleteDialog} disabled={this.state.events.currentlyDeletingAllUsersForOrg}>
                                                        <DeleteIcon className={classes.extendedIcon} />
                                                        Delete All Users
                                                </Fab>
                                                    <Fade
                                                        in={this.state.events.currentlyDeletingAllUsersForOrg}
                                                        style={{ transitionDelay: this.state.events.currentlyDeletingAllUsersForOrg ? "800ms" : "0ms" }}
                                                        unmountOnExit={true}
                                                    >
                                                        <CircularProgress color="secondary" size={20} />
                                                    </Fade>

                                                </Box>
                                            }
                                        </div>

                                    </Grid>
                                </>}
                        </DialogContent>
                        }
                        <Divider/>
                        <DialogActions>
                        {this.state.editSubmissions.isEditingSubmissions?
                            <div>                        
                            <Button
                                className={classes.disabledButton}
                                startIcon={<SkipPreviousIcon />}
                                onClick={() => this.backToOrgUsersList()}
                            >
                                BACK TO ORG USERS LIST {this.isSubmissionTouched()? ' WITHOUT SAVING':null}
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {this.state.editSubmissions.selectedSubIndexes.length<1?
                            <Fab className={classes.fab} variant="extended" size="medium" color="secondary" onClick={this.DeleteZeroSubAuditor.bind(this)} disabled={!this.isSubmissionTouched()} >
                                <DeleteIcon className={classes.extendedIcon} />
                                    Remove User's Auditor Role
                            </Fab>
                            :
                                (this.state.editSubmissions.isChangeSaved && !this.isSubmissionTouched()?
                                <Fab className={classes.fabGreen} variant="extended" size="medium" >
                                    <DoneIcon className={classes.extendedIcon} />
                                        Change Saved
                                </Fab>
                                :
                                <Fab className={classes.fab} variant="extended" size="medium" color="secondary" onClick={this.SaveSubmissionChange.bind(this)} disabled={!this.isSubmissionTouched()} >
                                    <SaveOutlinedIcon className={classes.extendedIcon} />
                                    Save Changes
                                </Fab>
                                )
                            }
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>   
                            : null
                        }
                            <Button                                
                                onClick={() => this.closeEditOrganizationDialog()}
                                disabled={isSubmitting}
                            >
                                CLOSE {this.isSubmissionTouched()? ' WITHOUT SAVING':null}
                            </Button>
                        </DialogActions>
                    </Dialog>                   
                </form>
            </div>
        );
    };
    private async DeleteZeroSubAuditor(){
        if(this.state.editSubmissions.selectedSubIndexes===null || this.state.editSubmissions.selectedSubIndexes.length<1){
            const deleteResp = await UserManagerService.DeleteUserRole$(
                this.props.user,
                this.state.editSubmissions.userName,
                this.state.editOrganization.organizationId.toString() + ","+this.state.editOrganization.orgType.toLowerCase()+",auditor"
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                this.backToOrgUsersList();
                this.RefreshUsersForOrganization(this.state.editOrganization.organizationId);
            }
        }
    }

    private async SaveSubmissionChange(){
        if(this.state.editSubmissions.selectedSubIndexes===null || this.state.editSubmissions.selectedSubIndexes.length<1){
            //do nothing - this is handled by delete ZeroSubAuditor
        }
        else{   
            const selectedSubs:number[]=this.state.editSubmissions.selectedSubIndexes.map(x=>{
                return this.state.editSubmissions.submissionData[x]===null?-1:this.state.editSubmissions.submissionData[x].submissionId;
            });
            var response = await UserManagerService.UpdateSubUserEntities$(this.props.user, this.state.editSubmissions.userName,this.state.editOrganization.organizationId, selectedSubs).toPromise();

            //add info pop up
            if (response instanceof AjaxError || !response) {
                this.setRedirectState();
            }
            else {
                await this.RefreshSubmissionList(this.state.editSubmissions.userName);
                this.setState({
                    editSubmissions: {
                        ...this.state.editSubmissions,
                        isChangeSaved: true
                    }
                });
            }
        }
    }

    private isSubmissionTouched(){
        if(this.state.editSubmissions.origSelectedSubIndexes.length!==this.state.editSubmissions.selectedSubIndexes.length)
            return true;
        for(var i=0; i<this.state.editSubmissions.origSelectedSubIndexes.length;i++){
            let found:boolean=false;
            for(var j=0;j<this.state.editSubmissions.selectedSubIndexes.length; j++){
                if(this.state.editSubmissions.origSelectedSubIndexes[i]===this.state.editSubmissions.selectedSubIndexes[j])
                    found=true;
            }
            if(!found) 
                return true;
        }
        return false;
    }
    private backToOrgUsersList=()=>{
        this.setState({
            editSubmissions:{
                isEditingSubmissions:false,
                userName:"",
                submissionData:[],
                origSelectedSubIndexes:[],
                selectedSubIndexes:[],
                isChangeSaved:false
            }
        });
    }
    private btnClickManageSubmissions= async (userName)=>{
        this.setState({
            editSubmissions:{
                ...this.state.editSubmissions,
                userName:userName,
                isEditingSubmissions:true
            }
        });
        this.RefreshSubmissionList(userName);
    }
    private async RefreshSubmissionList(userName){
        const subsRetVal: ISubmissionKey[]=await SubmissionsService.GetSubmissionKeysForOrg$(this.props.user, this.state.editOrganization.organizationId).toPromise();
        const accessibleSubIds: number[]=await UserManagerService.GetAccessableSubIds$(this.props.user, userName).toPromise();
        if (subsRetVal instanceof AjaxError || accessibleSubIds instanceof AjaxError || !subsRetVal || !accessibleSubIds) {
            this.setRedirectState();
        }
        else {
            let indexes: number[] = [];
            let indexesAll: number[] = [];
            let found: boolean = false;
            for (var i = 0; i < subsRetVal.length; i++) {
                indexesAll.push(i);
                for (var j = 0; j < accessibleSubIds.length; j++) {
                    if (accessibleSubIds[j] === subsRetVal[i].submissionId) {
                        found = true;
                        indexes.push(i);
                    }
                }
            }
            this.setState({
                editSubmissions: {
                    ...this.state.editSubmissions,
                    submissionData: subsRetVal,
                    origSelectedSubIndexes: found ? indexes : indexesAll,
                    selectedSubIndexes: found ? indexes : indexesAll
                }
            })
        }
    }
    onEditOrganizationFormSubmit = async () => {
        try {
            this.setProcessingState(true);
            var response = await UserManagerService.AddUserToRoles$(
                this.props.user,
                this.state.editOrganization.selectedUser.value,
                this.state.editOrganization.orgType.toLowerCase(),
                this.state.editOrganization.selectedRole.value,
                this.state.editOrganization.organizationId.toString(),
                "",
                false
            ).toPromise();
            if (response instanceof AjaxError || !response) {
                this.setRedirectState();
            }
            else {
                await this.RefreshUsersForOrganization(this.state.editOrganization.organizationId);
                this.setState({
                    editOrganization: {
                        ...this.state.editOrganization,
                        selectedUser: "",
                        selectedRole: ""
                    }
                });
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    };

    onChangeSelectUserToEntity(newValue){
        if(this.state.editOrganization.selectedRole&&this.state.editOrganization.selectedRole.value.toLowerCase()==="auditor"&&!this.isSelectedUserAuditor(newValue===null?"": newValue.value)){
            this.setState({
                editOrganization:{
                    ...this.state.editOrganization,
                    selectedRole:"",
                    selectedUser:newValue===null?"":newValue
                }
            });
        }
        else{
            this.setState({
                editOrganization:{
                    ...this.state.editOrganization,
                    selectedUser:newValue===null?"":newValue
                }
            });
        }
    }

    onChangeSelectRoleToEntityUser(newValue){
        this.setState({
            editOrganization:{
                ...this.state.editOrganization,
                selectedRole:newValue===null?"":newValue
            }
        })
    }
    async handleOrgUserRoleChange(tableMeta, newValue) {
        try {
            this.setProcessingState(true);

            this.setState({
                editOrganization: {
                    ...this.state.editOrganization,
                    currentlySubmittingRowIndex: tableMeta.rowIndex
                }
            });
            const deleteResp = await UserManagerService.DeleteUserRole$(
                this.props.user,
                tableMeta.rowData[0],
                this.state.editOrganization.organizationId + "," + this.state.editOrganization.orgType.toLowerCase() + "," + tableMeta.rowData[1]
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                const resp = await UserManagerService.AddUserToRoles$(
                    this.props.user,
                    tableMeta.rowData[0],
                    this.state.editOrganization.orgType.toLowerCase(),
                    newValue.value,
                    this.state.editOrganization.organizationId.toString(),
                    "",
                    false
                ).toPromise();

                if (resp instanceof AjaxError || !resp) {
                    this.setRedirectState();
                }
                else {
                    this.setState({
                        editOrganization: {
                            ...this.state.editOrganization,
                            currentlySubmittingRowIndex: null
                        }
                    });
                    await this.RefreshUsersForOrganization(this.state.editOrganization.organizationId);
                }
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }

    private validateEditOrganizationForm = (values: IEditOrganizationForm): FormikErrors<IEditOrganizationForm> => {
        const errors: FormikErrors<IEditOrganizationForm> = {};

        if (validator.isEmpty(values.selectedUser)) {
            errors.selectedUser = "Required";
        }

        if (validator.isEmpty(values.selectedRole)) {
            errors.selectedRole = "Required";
        }

        return errors;
    };

    private RefreshUsersForOrganization = async (organizationId) => {
        const userRetVal: IOrgUserPayload[] = await UserManagerService.GetUsersForOrganization$(
            this.props.user,
            organizationId
        ).toPromise();

        if (userRetVal instanceof AjaxError || !userRetVal) {
            this.setRedirectState();
        }
        else {
            let orgUsers = userRetVal.map(item => {
                return item;
            });

            this.setState({
                editOrganization: {
                    ...this.state.editOrganization,
                    existingUsers: orgUsers
                }
            });
        }
    }

    private ShowOrgUserDeleteDialog = (userName, roleName) => {
        this.setState({
            events: {
                ...this.state.events,
                deleteOrgUserConfirmDialogOpen: true
            },
            deleteOrgUserDialog: {
                title: "Delete User (" + userName + ")?",
                description: "Are you sure you'd like to delete " + roleName + " role for " + userName + "?",
                user: userName,
                roleInfo: this.state.editOrganization.organizationId + ","+this.state.editOrganization.orgType.toLocaleLowerCase()+"," + roleName
            }
        });
    }

    private DeleteOrgUser = async () => {
        try {
            this.setProcessingState(true);
            const deleteResp = await UserManagerService.DeleteUserRole$(
                this.props.user,
                this.state.deleteOrgUserDialog.user,
                this.state.deleteOrgUserDialog.roleInfo
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                await this.RefreshUsersForOrganization(this.state.editOrganization.organizationId);
                this.CloseDeleteOrgUserConfirmDialog();
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }

    private CloseDeleteOrgUserConfirmDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                deleteOrgUserConfirmDialogOpen: false
            }
        });
    }

    private ShowAllUserDeleteDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                deleteAllUserConfirmDialogOpen: true
            },
            deleteAllUserDialog: {
                title: "Delete All Users?",
                description: "Are you sure you'd like to delete all users for Organization (" + this.state.editOrganization.organizationName + ")?"
            }
        });
    }

    private DeleteAllOrgUsers = async () => {
        this.setState({
            events: {
                ...this.state.events,
                currentlyDeletingAllUsersForOrg: true,
                deleteAllUserConfirmDialogOpen: false
            }
        });
        try {
            this.setProcessingState(true);
            const deleteResp = await UserManagerService.DeleteAllOrgUsers$(
                this.props.user,
                this.state.editOrganization.organizationId.toString()
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                await this.RefreshUsersForOrganization(this.state.editOrganization.organizationId);
                this.setState({
                    events: {
                        ...this.state.events,
                        currentlyDeletingAllUsersForOrg: false
                    }
                });
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }

    private CloseDeleteAllUserConfirmDialog = () => {
        this.setState({
            events: {
                ...this.state.events,
                deleteAllUserConfirmDialogOpen: false
            }
        });
    }
}

// REDUX CONTAINER
const UserListsPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(UsersListView);

// STYLES CONTAINER
const UsersListPage = withStyles(styles)(UserListsPageWithState);

export default UsersListPage;
