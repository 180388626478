import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";

// STYLES
const styles = (theme: Theme) => createStyles({
    copyrightText: {
        color: 'white',
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: 1.57,
        opacity: 0.5,
        fontWeight: 'lighter',
        "& a": {
            color: 'white'
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(2)
    },
})

type IStylesProps = WithStyles<typeof styles> & WithWidth;

class CopyrightBlockView extends React.Component<IStylesProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Grid container={true} className={classes.root}>
                <Grid item={true}>
                    <Typography variant='caption' color="inherit" className={classes.copyrightText}>
                        Copyright &copy; National Committee for Quality Assurance. &nbsp;
                        <a href={process.env.REACT_APP_MEDIA_NCQA_TERMSOFUSE} target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

// STYLES CONTAINER
const CopyrightContainer = withStyles(styles)(CopyrightBlockView);
const CopyrightBlock = withWidth()(CopyrightContainer);
export default CopyrightBlock;