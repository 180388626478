import * as React from "react";
import { connect } from "react-redux";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import { Redirect } from "react-router-dom";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../../Services/ReportsService";
import { ISubmissionCountOnMeasureReportData } from "../../../DataClasses/SubmissionData";
import { Grid, Typography } from '@material-ui/core';
import BackNavigation from '../../Shared/BackNavigation';
import LoadingDataTable from "../../Shared/LoadingDataTable";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface ISubmissionCountOnMeasureReportState {
    isLoading: boolean;
    data: any[],
    redirect: boolean
}

type IStylesProps = WithStyles<typeof styles>;

class SubmissionCountOnMeasureReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    ISubmissionCountOnMeasureReportState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }

    public render() {
        const reportColumns =
            [
                {
                    name: "Measure",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Product Line",
                    options: {
                        filter: true
                    }
                },
                {
                    name: "Submission Count",
                    options: {
                        filter: true
                    }
                },
            ];
        const reportOptions: MUIDataTableOptions = {
            filterType: "multiselect" as any,
            sort: true,
            filter: true,
            print: false,
            downloadOptions: {
                filename: 'SubmissionCountOnMeasureReport.csv',
                separator: ',',
                filterOptions: {
                    useDisplayedColumnsOnly: false,
                    useDisplayedRowsOnly: true
                }
            },
            viewColumns: false,
            selectableRows: "none",
            caseSensitive: false,
            rowsPerPage: 15,
            rowsPerPageOptions: [15, 20, 25, 50, 75, 100, 500, 1000]
        };

        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        return (
            <div>
                <Grid container={true} className={classes.gridRowFlex} spacing={0}>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true}>
                                <BackNavigation text="Back to Reports" href="/reportspage" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={"auto"}>
                        <Typography className={`${classes.HighlightedNoteSmall}`}> * Note: Submissions with Plan Locked or later and Measures (or indicator level) with any Aduit Designation R (Reported) or NA (Small Denominator) are counted.</Typography>
                        <div className={classes.card}>
                            {this.state.isLoading ? <LoadingDataTable titleText={"*"} count={8} /> :
                                <MUIDataTable
                                    title={"Submission Count on Measure Report (HEDIS Only)"}
                                    data={this.state.data}
                                    columns={reportColumns}
                                    options={reportOptions}
                                />
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>)
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval: ISubmissionCountOnMeasureReportData[] | AjaxError = await ReportsService.GetSubmissionCountOnMeasureReportData$(this.props.user).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                data: retval.map(x => {
                    return [
                        x.measureCode,
                        x.productLine,
                        x.submissionCount
                    ]
                })
            });
        }
        this.setState({ isLoading: false });
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }
    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }
}

// REDUX CONTAINER
const SubmissionCountOnMeasureReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(SubmissionCountOnMeasureReportView);

// STYLES CONTAINER
const SubmissionCountOnMeasureReport = withStyles(styles)(
    SubmissionCountOnMeasureReportPageWithState
);

export default SubmissionCountOnMeasureReport;