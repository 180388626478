import { UserManager } from "oidc-client";
import * as React from "react";

// ATTRIBUTE PROPS
interface ILogoutProps {
    userManager: UserManager
}

class Logout extends React.Component<ILogoutProps> {
    constructor(props: ILogoutProps) {
        super(props);

        // Perform the redirect
        this.props.userManager.signoutRedirect();
    }

    public render() {
        return null;
    }
}

export default Logout;