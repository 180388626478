import { User } from "oidc-client";
import RootState from "../State/Root/RootState";


export interface IGenericUserProps {
    user: User | null;
}
export const genericMapStateToProps = (state: RootState): IGenericUserProps => {
    return {
        user: state.AuthenticationReducer.user
    };
}
