import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import {
    ICurrentSubAuths,
    ISubmissionHomePayload
} from "../../../../DataClasses/SubmissionData";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Fade
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";

const auditorListingOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    sort: true,
    print: false,
    viewColumns: false,
    downloadOptions: {
        filename: 'Auditors.csv',
        separator: ',',
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true
        }
    },
    selectableRows: "none",
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
};

export function renderAuditorsTable(data, auditorListingColumns) {
    return (
        <Fade in={true}><div>
            <MUIDataTable
                title={"Auditors"}
                data={data}
                columns={auditorListingColumns}
                options={auditorListingOptions}
            /></div>
        </Fade>
    );
}

export function renderSubmissionSummary(
    auths: ICurrentSubAuths,
    payload: ISubmissionHomePayload,
    classes
) {
    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.primaryBlue}>
                    Submission Details
                </Typography>
                <Divider></Divider>
                <Table size="small" aria-label="simple table">
                    <TableBody>
                        <TableRow key='type'>
                            <TableCell align="right">Submission Type</TableCell>
                            <TableCell align="left"><b>{payload ? payload.component.replace("DST", "HEDIS") : null}</b></TableCell>
                        </TableRow>
                        <TableRow key='cmsContract'>
                            <TableCell align="right">Submission Intention</TableCell>
                            <TableCell align="left"><b>{payload ? payload.submissionIntention : null}</b></TableCell>
                        </TableRow>
                        <TableRow key='specialArea'>
                            <TableCell align="right">Special Area</TableCell>
                            <TableCell align="left"><b>{payload ? payload.specialArea : null}</b></TableCell>
                        </TableRow>
                        <TableRow key='specialProject'>
                            <TableCell align="right">Special Project</TableCell>
                            <TableCell align="left"><b>{payload ? payload.specialProject : null}</b></TableCell>
                        </TableRow>
                        <TableRow key='publicReporting'>
                            <TableCell align="right">Public Reporting</TableCell>
                            <TableCell align="left"><b>{payload && payload.isPublicReported !== null? (payload.isPublicReported === true? 'Yes' : 'No') : 'Not Set'}</b></TableCell>
                        </TableRow>
                        {payload.productLine != "Exchange" ?
                            <TableRow key='accreditationStatus'>
                                <TableCell align="right">Accreditation Status</TableCell>
                                <TableCell align="left"><b>{payload ? (payload.isAccredited ? 'Accredited' : 'Not Accredited') : 'Not Accredited'}</b></TableCell>
                            </TableRow> : null
                        }
                        <TableRow key='cmsContract'>
                            <TableCell align="right">CMS Contract</TableCell>
                            <TableCell align="left"><b>{payload ? payload.cmsContract : null}</b></TableCell>
                        </TableRow>
                        <TableRow key='reportingUnitId'>
                            <TableCell align="right">Reporting Unit Id</TableCell>
                            <TableCell align="left"><b>{payload ? payload.reportingUnitId : null}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card >
    );
}

export function renderLoadingSkeleton(currentSubId: string, classes) {
    return (
        <Grid className={classes.mt2 + " " + classes.mb2} container={true} spacing={2}>
            <Grid item={true} xs={12}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} >
                        <Typography className={classes.submissionTitle}>
                            Submission {currentSubId}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item={true} xs={12}>
                <Grid item={true} >
                    <Typography className={classes.submissionSubtitle}>
                        <Skeleton className={classes.skeleton50s} variant="rect" />
                    </Typography>
                </Grid>
                <Grid item={true} >
                    <Typography className={classes.submissionSubtitle}>
                        <Skeleton className={classes.skeleton50} variant="rect" />
                    </Typography>
                </Grid>
                <Grid item={true} >
                    <Typography className={classes.submissionSubtitle}>
                        <Skeleton className={classes.skeleton50} variant="rect" />
                    </Typography>
                </Grid>
                <Grid item={true} >
                    <Typography className={classes.submissionSubtitle}>
                        <Skeleton className={classes.skeleton300} variant="rect" />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>)
}

export function renderTopDisplayGrid(
    payload: ISubmissionHomePayload,
    currentSubId: string,
    classes
) {
    return (
        <Grid className={classes.mt2 + " " + classes.mb2} container={true} spacing={2}>
            <Grid item={true} xs={12}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} >
                        <Typography className={classes.submissionTitle}>
                            Submission {currentSubId}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item={true} xs={12}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} >
                        <Typography className={classes.submissionSubtitle}>
                            <Link
                                to={"/availablesubmissionpage/all/all/" + payload.orgId}
                            >
                                {payload.orgName}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item={true} >
                        <Divider orientation="vertical" className={classes.dividerPadding} />
                    </Grid>
                    <Grid item={true} >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography className={classes.submissionSubtitle}>
                                            <Link
                                                to={"/availablesubmissionpage/all/all/" + payload.orgId}
                                            >
                                                {payload.orgId}
                                            </Link>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography className={classes.submissionSubtitle1}>
                                            Org ID
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item={true} >
                        <Divider orientation="vertical" className={classes.dividerPadding} />
                    </Grid>
                    <Grid item={true} >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography className={classes.submissionSubtitle}>
                                            {payload.productLine}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography className={classes.submissionSubtitle1}>
                                            Product Line
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item={true} >
                        <Divider orientation="vertical" className={classes.dividerPadding} />
                    </Grid>
                    <Grid item={true} >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography className={classes.submissionSubtitle}>
                                            {payload.reportingProduct}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography className={classes.submissionSubtitle1}>
                                            Reporting Product
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
