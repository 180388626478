import * as React from 'react';
import { IconButton, Typography, Link as MULink } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import Menu from 'material-ui-popup-state/HoverMenu'
import { Link, LinkProps } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem';
import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'


interface IAccountMenuProps {
    user: string;
}

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link innerRef={ref as any} {...props} />
));

const AccountMenu: React.FunctionComponent<IAccountMenuProps> = ({
    user }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
    return <div>
        <IconButton color="inherit" {...bindHover(popupState)}>
            <AccountCircle />
        </IconButton>
        {user}
        <Typography>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem><MULink href={"/accountpage"}>Account Page</MULink></MenuItem>
                <MenuItem><MULink href={process.env.REACT_APP_MEDIA_NCQA_MY + 'Account'} target='_blank' >Account Settings</MULink></MenuItem>
                <ListItem to="/logout" component={AdapterLink}>Logout</ListItem>
            </Menu>
        </Typography>
    </div>
};

export default AccountMenu;

