import { UserManager } from "oidc-client";
import * as React from "react";

// VIEW
class SilentRenew extends React.Component {
    constructor(props: any) {
        super(props);

        // Perform the silent renew
        // We don't want to spin up an instance of the existing UserManager
        // Because that will get a copy of the openid connect discovery document, which isn't needed for this call
        new UserManager({}).signinSilentCallback();
    }

    public render() {
        return null;
    }
}

export default SilentRenew;