import * as React from "react";
import { Grid, Typography, TextareaAutosize } from '@material-ui/core';
import request from 'superagent';
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import Dropzone from "react-dropzone";
import classNames from 'classnames';
import saveAs from 'file-saver';
import UserMessage from '../Shared/Message';
import { MessageType } from '../../DataClasses/SharedData';
import { connect } from 'react-redux';
import { genericMapStateToProps, IGenericUserProps } from "../../DataClasses/GenericUser";
import { v4 as uuidv4 } from "uuid";
import DbService from "../Services/DbService";
import { AjaxError } from "rxjs/ajax";
import LoadingDisplay from "../Shared/Loading";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

type IStylesProps = WithStyles<typeof styles>;

interface ILocalState {
    completed: number,
    processingFiles: boolean,
    transactionId: string,
    processingText: string
}

class GenericUploaderView extends React.Component<IGenericUserProps & IStylesProps, ILocalState> {
    constructor(props: IGenericUserProps & IStylesProps) {
        super(props);

        this.state = {
            processingFiles: false,
            completed: 0,
            transactionId: "",
            processingText: ""
        }
    }

    public render() {
        const { classes } = this.props;

        return <Grid container={true} spacing={2} alignItems="flex-start" alignContent="flex-start">
            <Grid item={true} xs={12}>
                <Typography variant='h4' color="inherit">
                    Generic Uploads
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <UserMessage text={this.getMessageContent()} variant={MessageType.Warning} />
            </Grid>
            <Grid item={true} xs={12} style={{ paddingLeft: '15px' }}>
            {this.state.processingFiles ?
                <LoadingDisplay size={43}></LoadingDisplay>
                :
                <Dropzone
                    onDrop={this.onDrop}
                    multiple={true}
                    accept={['.xml']}
                >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                            <div
                                {...getRootProps()}
                                className={classes.DropzoneContainer + " " + classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                            >
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p className={classes.secondaryText}>Drop files here...</p> :
                                        <p className={classes.secondaryText} style={{ cursor: 'pointer' }}>Try dropping some files here, or click to select files to upload.</p>
                                }
                            </div>
                        )
                    }}
                </Dropzone>
                }
                {this.state.processingText.length > 0 &&
                    <div>
                        <Typography variant='subtitle1' color='primary' className={classes.tabTitle}>
                            Processing Status
                        </Typography>
                        <TextareaAutosize
                            name="processingText"
                            className={classes.fullWidth}
                            id="multiline-filled-static"
                            disabled={true}
                            rowsMax={30}
                            value={this.state.processingText}
                        />
                    </div>
                }
            </Grid>
        </Grid>
    }

    private onDrop = (acceptedFiles, rejectedFiles) => {
        this.setState({
            processingFiles: true
        });

        const data = new FormData();
        if (acceptedFiles.length === 0) {
            alert('Please select a valid file');
            return;
        }

        acceptedFiles.forEach(file => {
            data.append("files", file, file.name);
        });

        const localTransactionId = uuidv4();
        this.setState({ transactionId: localTransactionId });

        const req = request.post(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + "/api/bulkupload/uploadgenericfile");
        req.query({ transactionId: localTransactionId });
        req.set({ 'Authorization': 'Bearer ' + this.props.user.access_token });

        // show upload progress 
        req.on('progress', event => {
            const percent = Math.floor(event.percent);
            if (!isNaN(percent)) {
                if (percent >= 100) {
                    this.setState({ completed: 100});
                } else {
                    this.setState({ completed: percent });
                }
            }
        });

        setTimeout(
            () => this.spinWait(),
            1000
        );

        // send file(s) to server
        req.send(data)
            .end((err, res) => this.postUploadFiles(err, res));
    };

    private spinWait = async () => {

        const bulkCall = DbService.GetBulkTransactions$(this.props.user, this.state.transactionId);

        const transactionText = await bulkCall.toPromise();
        if (transactionText instanceof AjaxError || transactionText === null) {
            if (this.state.processingFiles) {
                setTimeout(
                    () => this.spinWait(),
                    2000
                );
            }
            return;
        }
        else {
                var newText = transactionText.join("\n");
                this.setState({ processingText: newText });
        }

        if (this.state.processingFiles) {
            setTimeout(
                () => this.spinWait(),
                2000
            );
        }
    }

    private postUploadFiles = (err, res) => {
        this.setState({ processingFiles: false });

        if (err) {
            alert("Upload not Successful");
        }
        else {
            if (!res.body.didError) {
                const data = this.str2ab(atob(res.body.fileContents));
                saveAs(new Blob([data], { type: "application/octet-stream" }), res.body.fileName);
            }
        }
        this.setState({
            completed: 0,
        });
    }

    private str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    private getMessageContent() {
        return (
            <Typography variant='body2' color='inherit'>
                You may upload one or more submissions here at the same time.<br />
                Submissions must be xml.
            </Typography>
        );
    }
}
// REDUX CONTAINER
const GenericUploaderPageState = connect<IGenericUserProps>(genericMapStateToProps)(GenericUploaderView);

// STYLES CONTAINER
const GenericUploaderPage = withStyles(styles)(GenericUploaderPageState);

export default GenericUploaderPage;