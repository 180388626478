import * as React from "react";
import {
    Button, Divider, Grid, Dialog, DialogTitle, DialogActions, DialogContent
} from "@material-ui/core";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import CopyrightBlock from './CopyrightBlock';
import PrivacyPolicyHtml from './PrivacyPolicy';

// STYLES
const styles = (theme: Theme) => createStyles({
    centerLinks: {
        color: 'white',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        textTransform: 'none',
        fontWeight: 'normal',
        margin: theme.spacing(0, 1),
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'steelblue',
            color: 'white'
        }
    },
    divider: {
        backgroundColor: 'white',
        opacity: 0.3,
        margin: theme.spacing(1, 1, 0, 1)
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: '20px',
        marginLeft: theme.spacing(10)
    },
    linksContainer: {
        paddingRight: theme.spacing(6)
    }
});

type IStylesProps = WithStyles<typeof styles> & WithWidth;
interface ILocalState {
    showPrivacyPolicyDialog: boolean;
}
class CenterLinksView extends React.Component<IStylesProps, ILocalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showPrivacyPolicyDialog: false
        };
    }

    public render() {
        const { classes, width } = this.props;

        switch (width) {
            case 'xl':
            case 'lg':
                return (
                    <div className={classes.root}>
                        <Grid container={true} direction='row' justifyContent='flex-start' alignContent='flex-start' className={classes.linksContainer}>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_MAINPAGE} target='_blank' className={classes.centerLinks}>
                                NCQA Homepage
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_ABOUT} target='_blank' className={classes.centerLinks}>
                                About Us
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_CONTACT} target='_blank' className={classes.centerLinks}>
                                Contact Us
                            </Button>
                            <Button className={classes.centerLinks} onClick={() => this.setDialogState(true)}>
                                Privacy Policy
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_MY} target='_blank' className={classes.centerLinks}>
                                My NCQA
                            </Button>
                            {this.renderPrivacyPolicyDialog(width)}
                        </Grid>
                        <Divider className={classes.divider} />
                        <CopyrightBlock />
                    </div>
                );
            case 'md':
                return (
                    <div className={classes.root}>
                        <Grid container={true} direction='row' justifyContent='flex-start' alignContent='flex-start' className={classes.linksContainer}>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_MAINPAGE} target='_blank' className={classes.centerLinks}>
                                NCQA Homepage
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_ABOUT} target='_blank' className={classes.centerLinks}>
                                About Us
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_CONTACT} target='_blank' className={classes.centerLinks}>
                                Contact Us
                            </Button>
                            <Button className={classes.centerLinks} onClick={() => this.setDialogState(true)}>
                                Privacy Policy
                            </Button>
                            {this.renderPrivacyPolicyDialog(width)}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid container={true} direction='row' justifyContent='flex-start' alignContent='flex-start' className={classes.linksContainer}>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_MY} target='_blank' className={classes.centerLinks}>
                                My NCQA
                            </Button>
                        </Grid>
                        <Divider className={classes.divider} />
                        <CopyrightBlock />
                    </div>
                );
            case 'sm':
            case 'xs':
                return (
                    <div className={classes.root}>
                        <Grid container={true} direction='row' justifyContent='flex-start' alignContent='flex-start' className={classes.linksContainer}>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_MAINPAGE} target='_blank' className={classes.centerLinks}>
                                NCQA Homepage
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_ABOUT} target='_blank' className={classes.centerLinks}>
                                About Us
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_CONTACT} target='_blank' className={classes.centerLinks}>
                                Contact Us
                            </Button>
                            <Button className={classes.centerLinks} onClick={() => this.setDialogState(true)}>
                                Privacy Policy
                            </Button>
                            <Button href={process.env.REACT_APP_MEDIA_NCQA_MY} target='_blank' className={classes.centerLinks}>
                                My NCQA
                            </Button>
                            {this.renderPrivacyPolicyDialog(width)}
                        </Grid>
                        <Divider className={classes.divider} />
                        <CopyrightBlock />
                    </div>
                );
        }
    }

    private setDialogState(value) {
        this.setState({ showPrivacyPolicyDialog: value });
    }

    private renderPrivacyPolicyDialog(width) {
        return (<>
            <Dialog open={this.state.showPrivacyPolicyDialog} maxWidth={width}>
                <DialogTitle id="max-width-dialog-title">
                    Privacy Policy
                </DialogTitle>
                <DialogContent>
                    {PrivacyPolicyHtml}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setDialogState(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    }
}

// STYLES CONTAINER
const CenterLinksContainer = withStyles(styles)(CenterLinksView);
const CenterLinks = withWidth()(CenterLinksContainer);
export default CenterLinks;