import { User } from "oidc-client";
import { Observable } from "rxjs";
import AjaxHelper from "./AjaxHelper";

class EventsService {
    public static GetEventsStrings$(user: User): Observable<string[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Events/GetEventsStrings',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

}

export default EventsService;