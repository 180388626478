import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// REDUX PROPS
interface IDialogProps {
    showError: boolean,
    errorMsg: string,
    buttonHandler: any
}

const ErrorDialog = (props: IDialogProps) => (
    <div>
        <Dialog
            open={props.showError}
            onClose={props.buttonHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Error</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.errorMsg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.buttonHandler} color="primary" autoFocus={true}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    </div>
);

export default ErrorDialog;