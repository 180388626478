class SharedUtility {
   
    public static Str2ab$(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    public static IsNullOrUndefined$(obj:any): boolean {
        return obj === null || typeof obj === 'undefined';
    }

    public static ToLocaleDateString$(obj: Date | null): string {
        return obj != null ? new Date(obj).toDateString() : "";
    }

    public static NewLinesToBreaks$(text: string): string {
        if (text) {
            text = text.replace(/\n/g, "<br />");
        }
        return text;
    }

    public static GetStageCleanName(stageShortName: string): string {
        switch (stageShortName) {
            case 'MarkFinalHedisHos':
            case 'MarkFinalCahps': {
                return 'Apply Mark Final';
            }
            case 'PlanLock': {
                return 'Apply Plan Lock';
            }
            case 'AuditLock': {
                return 'Apply Audit Lock';
            }
            case 'AttestationHedis':
            case 'AttestationCahps':
                {
                    return 'Finalize Attestation';
                }
            case 'AttestationHOS':
            case 'AttestationHos':
                {
                    return 'Validations/Attestation';
                }
            default: {
                return stageShortName;
            }
        }
    }
}
export default SharedUtility;