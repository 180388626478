import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

const allDownloadsOptions: MUIDataTableOptions = {
    filter: true,
    filterType: "dropdown",
    print: false,
    downloadOptions: {
        filename: 'AllDownloads.csv',
        separator: ',',
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true
        }
    },
    selectableRows: "none",
    viewColumns: false,
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
};

export function renderAllDownloadsTable(data, downloadFunc, classes) {

    const allDownloadsColumns =
        [
            {
                name: "Name",
                options: {
                    filter: true,
                }
            },
            {
                name: "Date",
                options: {
                    filter: false
                }
            },
            {
                name: "User",
                options: {
                    filter: true,
                }
            },
            {
                name: "",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var tipTxt = "Download File";
                        return (
                            <div>
                                <Tooltip title={tipTxt} aria-label={tipTxt}>
                                    <IconButton onClick={() => downloadFunc(value)}>
                                        <GetAppIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    },
                    download: false
                }
            },
        ];

    return (<MUIDataTable
        title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>All Downloads</Typography>}
        data={data}
        columns={allDownloadsColumns}
        options={allDownloadsOptions}
    />);
}

