import React from "react";
import { genericMapStateToProps, IGenericUserProps } from "../../DataClasses/GenericUser";
import idssStyle from "../../Configuration/SharedStyling";
import { connect } from "react-redux";
import {
    Theme,
    WithStyles,
    createStyles,
    withStyles,
  } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import AdministrationService from "../Services/AdministrationService";
import { AjaxError } from "rxjs/ajax";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { Fab, Grid, IconButton, Tooltip} from "@material-ui/core";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { RemoveCircle, Delete } from "@material-ui/icons";
import GetAppIcon from '@material-ui/icons/GetApp';
import saveAs from 'file-saver';
import { ICachingKey } from "../../DataClasses/AdministrationData";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface ICacheManagementStateStore {
  isLoading: boolean;
  redirect: boolean;
  data: ICachingKey[];
  isActing: boolean;
  isClearingAllCache: boolean;
}

type IStylesProps = WithStyles<typeof styles>;

class CacheManagementView extends React.Component<
  IGenericUserProps & IStylesProps,
  ICacheManagementStateStore
> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            redirect: false,
            data: [],
            isActing: false,
            isClearingAllCache: false
        }
    }

    public componentDidMount() {
        this.setState({ isLoading: true });
        this.RefreshModel();
        this.setState({ isLoading: false });
    }
    
    private redirectToErrorPage() {
    return <Redirect to='/errorpage' />;
    }

    public render() {
        if (this.state.redirect) {
          return this.redirectToErrorPage();
        }
        const tblColumns: MUIDataTableColumnDef[] = [
            {
                name: "",
                label: "Key"
            },
            {
                name: "",
                label: "Expire On"
            },
            {
                name: "",
                label: "Download",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return this.customBodyRender_DownloadColumn(value);
                    }
                }
            },
            {
                name: "",
                label: "Clear Cache",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return this.customBodyRender_ClearCacheColumn(value)
                    }
                }
            }
        ];
        const tblOption: MUIDataTableOptions = {
            filter: true,
            filterType: "dropdown",
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            rowsPerPage: 8,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
            customToolbar: () => {
                return this.customToolbar_ClearAll(classes);
            }
          };
        const { classes } = this.props;
        return (
           this.state.isLoading?
            <LoadingDataTable titleText={"*"} />
           :
            <div>
              <Grid item={true} xs={6}>
                <MUIDataTable
                    title={"Metadata Cache Manager"}
                    data={this.state.data.map(x => [x.key, this.getLocalExpireTime(x.expireAfterMilliseconds), x.key, x.key])}
                    columns={tblColumns}
                    options={tblOption}
                  />
                </Grid>
            </div>
        );
    }

    private customToolbar_ClearAll(classes: any) {
        return (
            <Fab className={classes.fab} variant="extended" color="primary" size="small" onClick={async () =>
                await this.clearAllCache()} disabled={this.state.isClearingAllCache || this.state.isActing}>
                <RemoveCircle className={classes.extendedIcon} />
                {this.state.isClearingAllCache ? "Clearing Cache..." : "Clear All Cache"}
            </Fab>
        );
    }

    private customBodyRender_DownloadColumn(value: any) {
        return (
            <div>
                <table>
                    <tbody>
                        <tr >
                            <td>
                                <Tooltip title="Download" aria-label="Download">
                                    <IconButton size='medium' aria-label="Download" onClick={() => this.downloadCache(value)} disabled={this.state.isClearingAllCache || this.state.isActing}>
                                        <GetAppIcon fontSize="inherit"></GetAppIcon>
                                    </IconButton >
                                </Tooltip>

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        );
    }

    private customBodyRender_ClearCacheColumn(value: any) {
        return (
            <div>
                <table>
                    <tbody>
                        <tr >
                            <td>
                                <Tooltip title="Clear Cache" aria-label="Clear Cache">
                                    <IconButton size='medium' onClick={async () => await this.clearCache(value)} disabled={this.state.isClearingAllCache || this.state.isActing}>
                                        <Delete fontSize="inherit"></Delete>
                                    </IconButton >
                                </Tooltip>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        );
    }

    private async clearAllCache() {
        this.setState({isClearingAllCache: true});
        const keysCsv = this.state.data.map(x => x.key).join(',');
        await this.clearCache(keysCsv);
        alert("All the Metadata cache cleared!");
        this.RefreshModel();
        this.setState({isClearingAllCache: false});
    }
    private async clearCache(cacheKeysCsv: string){
        this.setState({isActing: true});
        await AdministrationService.ClearCaches$(cacheKeysCsv, this.props.user).toPromise();
        this.RefreshModel();
        this.setState({isActing: false});
    }
    private getLocalExpireTime(expireAfterMilliseconds: number){
        if(expireAfterMilliseconds < 1)
        {
            return 'N/A';
        }
        const now = Date.now();
        const later = now + expireAfterMilliseconds;
        const date = new Date(later);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are 0-based, so +1 is needed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
    
    private async RefreshModel() {
        const retval = await AdministrationService.GetMetadataCacheList$(
          this.props.user
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
          this.setRedirectState();
        } else {
          this.setState({ data: retval });
        }
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    private downloadCache = async (key: string) => {
        this.setState({ isActing: true });
        const apiCall = AdministrationService.DownloadCache$(key, this.props.user);

        try {
            const userResponse = await apiCall.toPromise();
            const fileName = key+ new Date().toLocaleString() +".json";
            if (typeof userResponse === 'string') {
                saveAs(new Blob([userResponse], {
                    type: "text/plain"
                }), fileName);
            }
        }
        catch (ex) {
            //     handle error
        }
        finally {
            this.setState({ isActing: false });
        }
    }
}

// REDUX CONTAINER
const CacheManagementPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
  )(CacheManagementView);
  
  // STYLES CONTAINER
  const CacheManagementPage = withStyles(styles)(
    CacheManagementPageWithState
  );
  
  export default CacheManagementPage;