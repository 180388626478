import * as React from "react";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Paper, Fade, CircularProgress } from "@material-ui/core";
import '@material-ui/core/styles';
import { connect } from "react-redux";
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import { GetApp as GetAppIcon, FindInPage as FindInPageIcon } from '@material-ui/icons';
import ReportService from "../Services/ReportsService";
import saveAs from 'file-saver';
import { Link, LinkProps } from 'react-router-dom';
import UserManagerService from "../Services/UserManagerService";
import { VIEW_COMBINED_REPORT, VIEW_RESULTS_REPORTS_HEDIS, VIEW_RESULTS_REPORTS_NONHEDIS, ORG_ID } from "../Shared/Constants";
import * as AuthorizationPolicies from "../../Configuration/AuthorizationPolicies";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../Services/ReportsService";
import SubmissionsService from "../Services/SubmissionsService";
import { ISubmissionAllowance } from "../../DataClasses/SubmissionData";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    root: {
        flexGrow: 1
    },
    list: {
        flexGrow: 1
    },
    menuContainer: {
        textAlign: "right",
        paddingRight: "10px"
    }
});
type IStylesProps = WithStyles<typeof styles>;

interface ILocalState {
    IsDownloadingCombinedReport: boolean;
    IsDownloadingLicenseAgreement: boolean;
    IsDownloadingOrganizationReport: boolean;
    anchorEl: HTMLElement | null;
    showAllReports: boolean;
    showCombinedReport: boolean;
    isNcqaAdmin: boolean;
    userDownloadAccessibility: ISubmissionAllowance;
    isLoading: boolean;
    canUserAccessAttestationTemplates: boolean;
    redirect: boolean;
}

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link innerRef={ref as any} {...props} />
));
// VIEW
class ReportsView extends React.Component<IGenericUserProps & IStylesProps, ILocalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            IsDownloadingCombinedReport: false,
            IsDownloadingLicenseAgreement: false,
            IsDownloadingOrganizationReport: false,
            anchorEl: null,
            showAllReports: false,
            showCombinedReport: false,
            isNcqaAdmin: false,
            userDownloadAccessibility:
            {
                allowsHedisSubmission: false,
                allowsCahpsSubmission: false,
                allowsHosSubmission: false
            },
            isLoading: true,
            canUserAccessAttestationTemplates: false,
            redirect: false
        };
    }

    public async componentDidMount() {
        this.setState({
            showAllReports: await this.IsViewReportsActionAuthorized(),
            showCombinedReport: await this.IsViewCombinedReportActionAuthorized(),
            isNcqaAdmin: AuthorizationPolicies.IsGlobalAdmin(this.props.user),
            userDownloadAccessibility: await SubmissionsService.GetReportsAvailabilities$(this.props.user).toPromise(),
            canUserAccessAttestationTemplates: await this.CanUserAccessAttestationTemplates()
        });
        this.setState({ isLoading: false })
    }

    private async IsViewReportsActionAuthorized() {
        return (await UserManagerService.IsActionAuthorized$(this.props.user, VIEW_RESULTS_REPORTS_HEDIS, ORG_ID, -1).toPromise() ||
            await UserManagerService.IsActionAuthorized$(this.props.user, VIEW_RESULTS_REPORTS_NONHEDIS, ORG_ID, -1).toPromise());  //quick fix for now, refactoring
    }

    private async IsViewCombinedReportActionAuthorized() {
        return await UserManagerService.IsActionAuthorized$(this.props.user, VIEW_COMBINED_REPORT, ORG_ID, -1).toPromise();
    }

    private async CanUserAccessAttestationTemplates() {
        return await ReportsService.CanUserAccessAttestationTemplates$(this.props.user).toPromise();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;

        return (
            <Grid container className={classes.root} spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
            >
                <Grid item xs={10} className={classes.mt2 + " " + classes.mb2}>
                    <Typography className={classes.submissionTitle}>
                        Reports
                    </Typography>
                </Grid>
                {this.state.showAllReports && !this.state.isLoading ?
                    <>
                        <Fade in={this.state.showAllReports && !this.state.isLoading}>
                            <Grid item xs={12}>
                                <Paper>
                                    <List className={classes.list}>
                                        {this.renderCombindedReportLink()}
                                        {this.renderDownloadReportLink(() => this.getLicenseAgreement(), this.state.IsDownloadingLicenseAgreement, "License Agreement", "IDSS License Agreement")}
                                        {this.renderNavigationReportLink('/filesuploadreportpage', "File Upload Report", "Provide the info about the files uploaded.")}
                                        {this.renderHosVendorSubmissionStatusReportLink()}
                                        {this.renderCahpsVendorSubmissionStatusReportLink()}
                                        {this.renderNavigationReportLink('/activityreportpage', "Activity Report", "Provide the info about the activitiies.")}
                                        {this.renderAdminReportsLinks()}
                                        {this.renderTemplatesLinks()}
                                        {this.renderValidationMetadata()}
                                    </List>
                                </Paper>
                            </Grid></Fade></>
                    :
                    <LoadingDataTable count={8} titleText={"*"} doubleRowSpace={true} width="50%" />
                }
            </Grid>
        );
    }

    private renderCombindedReportLink = () => {
        if (this.state.showCombinedReport)
            return this.renderDownloadReportLink(() => this.getCombinedReport(), this.state.IsDownloadingCombinedReport, "Combined Report", "The Combined Report gives the status of all submissions that you have access to.");
        return null;
    }

    private renderHosVendorSubmissionStatusReportLink = () => {
        if (this.state.userDownloadAccessibility.allowsHosSubmission)
            return this.renderNavigationReportLink('/hosvendorsubmissionstatusreportpage', "HOS Vendor Submission Status Report", "Provide the info about the HOS vendor submission status.");
        return null;
    }

    private renderCahpsVendorSubmissionStatusReportLink = () => {
        if (this.state.userDownloadAccessibility.allowsCahpsSubmission)
            return this.renderNavigationReportLink('/cahpsvendorsubmissionstatusreportpage', "CAHPS Vendor Submission Status Report", "Provide the info about the CAHPS vendor submission status.");
        return null;
    }

    private renderAdminReportsLinks = () => {
        if (this.state.isNcqaAdmin)
            return <>
                {this.renderNavigationReportLink('/submissionsdatasummaryreportpage', "Submissions Data Summary Report (Admin Only)", "Provide the submissions data summary.")}
                {this.renderNavigationReportLink('/validationreport', "Validation Report (Admin Only)", "Provide HEDIS validation result.")}
                {this.renderNavigationReportLink('/submissioncountonmeasurereport', "Submission Count on Measure Report (Admin Only)", "Provide HEDIS submission counts on measures.")}
                {this.renderNavigationReportLink('/accreditationstatusreport', "Accreditation Status Report (Admin Only)", "Provide Accreditation Status for submissions.")}
            </>;

        return null;
    }

    private renderTemplatesLinks = () => {
        if (this.state.canUserAccessAttestationTemplates)
            return <>
                {this.renderNavigationReportLink("Help/CommercialAttestation.pdf", "Commercial Attestation Template", "Commercial Attestation Template", true)}
                {this.renderNavigationReportLink("Help/ExchangeAttestation.pdf", "Exchange Attestation Template", "Exchange Attestation Template", true)}
                {this.renderNavigationReportLink("Help/MedicaidAttestation.pdf", "Medicaid Attestation Template", "Medicaid Attestation Template", true)}
                {this.renderNavigationReportLink("Help/MedicaidLtssAttestation.pdf", "Medicaid LTSS Attestation Template", "Medicaid LTSS Attestation Template", true)}
                {this.renderNavigationReportLink("Help/MedicareAttestation.pdf", "Medicare Attestation Template", "Medicare Attestation Template", true)}
            </>;
    }

    private renderValidationMetadata = () => {
        if (this.state.canUserAccessAttestationTemplates)
            return <>
                {this.renderNavigationReportLink("Help/MY2023_HEDIS_ValidationDictionary_FINAL_v2.csv", "Validation Metadata Dictionary", "Validation Metadata Dictionary", true)}
            </>;
    }

    private renderDownloadReportLink = (onClick, isDownloading, primaryText, secondaryText) => {
        return (
            <ListItem alignItems='flex-start' button divider onClick={onClick}>
                <ListItemIcon>
                    {isDownloading ?
                        (<Fade in={isDownloading}
                            style={{ transitionDelay: "800ms" }}
                            unmountOnExit={true}>
                            <CircularProgress color="secondary" size={20} />
                        </Fade>)
                        : <GetAppIcon />}
                </ListItemIcon>
                <ListItemText classes={{ primary: this.props.classes.primaryBlue, secondary: this.props.classes.secondaryText }} primary={primaryText}
                    secondary={secondaryText} />
            </ListItem>
        );
    }

    private renderNavigationReportLink = (href, primaryText, secondaryText, isStaticDownload = false) => {
        return (
            <ListItem alignItems='flex-start' button divider to={isStaticDownload ? "" : href} component={!isStaticDownload ? AdapterLink : "a"}
                href={isStaticDownload && href} target={isStaticDownload ? "_blank" : "_self"}>
                <ListItemIcon>
                    <FindInPageIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: this.props.classes.primaryBlue, secondary: this.props.classes.secondaryText }} primary={primaryText}
                    secondary={secondaryText} />
            </ListItem>
        );
    }

    private getCombinedReport = async () => {
        this.setState({ IsDownloadingCombinedReport: true });
        const apiCall = ReportService.GetCombinedReportCaller$(this.props.user);

        try {
            const userResponse = await apiCall.toPromise();
            if (typeof userResponse === 'string') {
                const data = this.str2ab(atob(userResponse));
                saveAs(new Blob([data], {
                    type: "application/octet-stream"
                }), "CombinedReport.xlsx");
            }
        }
        catch (ex) {
            //     handle error
        }
        finally {
            this.setState({ IsDownloadingCombinedReport: false });
        }
    }

    private getLicenseAgreement = async () => {
        this.setState({ IsDownloadingLicenseAgreement: true });

        try {
            const response: string[] | AjaxError = await ReportService.GetUserLicenseAgreement$(this.props.user).toPromise();
            if (response instanceof AjaxError || !response) {
                this.setRedirectState();
            } else {
                var fileName = response[0];
                var fileContent = response[1];

                if (fileName !== '' && typeof fileContent === 'string') {
                    const data = this.str2ab(atob(fileContent));
                    saveAs(new Blob([data], { type: "application/octet-stream" }), fileName);
                }
            }
        }
        catch (ex) {
        }
        finally {
            this.setState({ IsDownloadingLicenseAgreement: false });
        }
    }

    private str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }
}

const ReportsViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(ReportsView);

// STYLES CONTAINER
const ReportsPage = withStyles(styles)(ReportsViewWithState);

export default ReportsPage;


