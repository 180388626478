import { UserManager } from "oidc-client";
import * as React from "react";
import { parse } from "querystring";

// ATTRIBUTE PROPS
interface IProps {
    userManager: UserManager;
}

class Login extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        // Check to see if there is a redirect url to maintain via state
        const queryString = parse(window.location.search.replace("?", ""));
        const redirectUrl = queryString.redirectUrl;

        if (redirectUrl) {
            // Perform the redirect
            this.props.userManager.signinRedirect({
                state: redirectUrl
            });
        } else {
            this.props.userManager.signinRedirect();
        }
    }

    public render() {
        return null;
    }
}

export default Login;