import * as React from "react";
import "@material-ui/core";
import {
    Theme,
    WithStyles,
    createStyles,
    withStyles,
  } from "@material-ui/core/styles";
import idssStyle from "../../Configuration/SharedStyling";
import { connect } from "react-redux";
import {
    IGenericUserProps,
    genericMapStateToProps,
  } from "../../DataClasses/GenericUser";
import {IVendor, IVendorMeasure, IVendorSubOrgMapping } from "../../DataClasses/VendorMeasure";
import { Redirect } from "react-router-dom";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, Grid, Link, Paper, Typography } from "@material-ui/core";
import request from "superagent";
import { v4 as uuidv4 } from "uuid";
import AdministrationService from "../Services/AdministrationService";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { SeedTime } from "../../Configuration/Setting";
import { lastValueFrom } from 'rxjs';

const styles = (theme: Theme) => createStyles({
     ...idssStyle(theme),
     paperStyle: {
        flexGrow: 1,
        padding: theme.spacing(2, 2),
    }
});

interface LocalProps {
    productYear: number
}

interface IVendorMeasureGuidListStateStore {
    payload: IVendorMeasure[];
    isLoading: boolean;
    redirect: boolean;
    inputFileKey: string;
    fileTypeValid: boolean;
    isUploading: boolean;
    selectedFile?: File;
    validationTxt: string;
    transactionId: string;
    isClearingCache: boolean;
    vendorSubOrgList: IVendorSubOrgMapping[];
    showVendorMapping: boolean;
  }

type Props = LocalProps & WithStyles<typeof styles>;
  
const SubIdColumnRender = ({ value, tableMeta }) => {
    return (
      <a href={'/submissionHome/' + value + "/DST/" + tableMeta.rowData[3]}>{value}</a>
    );
  }

class VendorMeasureGuidListView extends React.Component<
  IGenericUserProps & Props,
  IVendorMeasureGuidListStateStore
> {
    constructor(props: any) {
        super(props);
        this.state = {
            payload: [],
            isLoading: true,
            redirect: false,
            inputFileKey: "inputKey",
            fileTypeValid: false,
            isUploading: false,
            validationTxt: "",
            transactionId: "",
            isClearingCache: false,
            vendorSubOrgList: [],
            showVendorMapping: false
        }
    }
    
    public async componentDidMount() {
        await this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }
    private getVendorsTableData() {
        if(this.state.showVendorMapping === true){
            return this.state.vendorSubOrgList
        }
        let retval: IVendor[] = [];
        for(const m of this.state.vendorSubOrgList){
            if(!retval.some(x => x.vendorOrgId === m.vendorOrgId)){
                retval.push({vendorOrgId: m.vendorOrgId, vendorName: m.vendorName} as IVendor)
            }
        }
        return retval;
    }
    
    private getVendorsTableColumns(){
        let retval: MUIDataTableColumn[] = [
            {
                name: "vendorOrgId",
                label: "VENDOR_Id"
            },
            {
                name: "vendorName",
                label: "VENDOR_NAME"
            },
        ];
        if(!this.state.showVendorMapping){
            return retval;
        }
        const renderSubIdColumn = (value, tableMeta, updateValue) => {
                    return <SubIdColumnRender value={value} tableMeta={tableMeta} />;
                };
        return retval.concat([
            {
                name: "submissionId",
                label: "SubmissionId",
                options: {
                    customBodyRender: renderSubIdColumn
                }
            },
            {
                name: "productLine",
                label: "ProductLine"
            },
            {
                name: "reportingProduct",
                label: "ReportingProduct"
            },
            {
                name: "orgId",
                label: "OrgId"
            },
            {
                name: "orgName",
                label: "OrgName"
            }
        ]);
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        
        const vmColumns = [
            {
                name: "vendorName",
                label: "VENDOR_NAME"
            },
            {
                name: "measureCode",
                label: "MEASURE_ID"
            },
            {
                name: "productLine",
                label: "PRODUCT_LINE"
            },
            {
                name: "measureGuid",
                label: "GUID"
            },
            {
                name: "importedBy",
                label: "ImportedBy",
                options:{
                    download: false
                }
            },
            {
                name: "importedOn",
                label: "ImportedOn",
                options:{
                    download: false
                }
            },
        ];
        let now = new Date();
        let nowAsLocaleString = now.toLocaleString();
        
        const vmOptions: MUIDataTableOptions ={
            filter: true,
            filterType: "dropdown",
            print: false,
            download: true,
            selectableRows: "none",
            downloadOptions:{
                filename: "VendorMeasure_" + nowAsLocaleString +".csv"
            }
        };
        let downloadVendorFileName = this.state.showVendorMapping && this.state.vendorSubOrgList.length>0 ?"VendorsSubOrgList.csv": "VendorsList.csv";
        const vlOptions: MUIDataTableOptions ={
            filter: true,
            filterType: "dropdown",
            print: false,
            download: true,
            selectableRows: "none",
            downloadOptions:{
                filename: downloadVendorFileName
            },
            setRowProps:  (row, dataIndex, rowIndex) => {
                let vName = row[1];
                if(vName === undefined || vName.length === 0 || vName.includes("Not Found")){
                    return {
                        style: { background: "yellow" },
                      }; 
                }
            }
        };
        return (
            <Paper className={classes.paperStyle}>
                <Typography className={classes.primaryBlue}>
                    Vendor Measures  (Current Product Year: {this.props.productYear})
                </Typography>
                <Grid container spacing={0}>
                    <Grid xs={12}>
                        <span className="btn btn-info">
                            <input
                                type="file"
                                name="file"
                                key={this.state.inputFileKey}
                                onChange={(e) => this.handleUploadFileChange(e)}
                            />
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                        <button
                            className="btn btn-primary"
                            disabled={
                                !this.state.fileTypeValid || this.state.isUploading
                            }
                            onClick={async () =>
                                await this.uploadVendorMeasureFile(this.state.selectedFile)
                            }
                        >
                            {this.state.isUploading ? "Uploading..." : "Upload"}
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                      
                        <Link
                            component="button"                           
                            onClick={this.handelLoadVendorList}
                            >
                            View/Download Vendor List
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                            component="button"
                            onClick={this.handleClearVendorMeasureCache}
                            disabled = {this.state.isClearingCache}
                            >
                           {this.state.isClearingCache ? "Clearing Cache..." : "Clear Vendor Measures Cache"}
                        </Link>                       
                        <Dialog open={this.state.vendorSubOrgList && this.state.vendorSubOrgList.length>0} onClose={this.clearVendorSubOrgList} classes={{paper: classes.dialogL}} >
                        <DialogTitle id="max-width-dialog-title">
                            <Grid container spacing={1}>
                                 <Grid xs={6}>
                                    <Typography variant='h6' color='primary' className={classes.tabTitle}>
                                        Vendor List
                                    </Typography>
                                </Grid>
                                <Grid xs={6}> 
                                    <Checkbox
                                        checked={this.state.showVendorMapping}
                                        onChange={this.toggleVendorMapping}
                                        color="primary"
                                    />
                                    <small>Show Vendor Org/Submission Mapping</small>
                                </Grid>
                             </Grid>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <MUIDataTable
                                title={""}
                                data={this.getVendorsTableData()}
                                columns={this.getVendorsTableColumns()}
                                options={vlOptions}
                            />
                        </DialogContent>
                        <Divider/>
                        <DialogActions>
                            <Button
                                onClick={this.clearVendorSubOrgList}                              
                            >
                                ClOSE
                            </Button>
                        </DialogActions>
                        </Dialog>
                        </span>
                        <br />
                        <label className="text-muted">
                            * .csv file contain columns: VENDOR_Name, MEASURE_ID, PRODUCT_LINE and GUID. New upload will overwrite the existing set.
                        </label>
                        {this.state.isLoading ? (
                        <LoadingDataTable titleText={"*"} />
                        ) : (
                        <Fade in={true} {...{ timeout: SeedTime }}>
                         <div>
                        <MUIDataTable
                            title={"Vendor Measures In Database"}
                            data={this.state.payload}
                            columns={vmColumns}
                            options={vmOptions}
                        />
                        </div>
                        </Fade>
                        )}
                    </Grid>
                </Grid>
            </Paper>                        
        );
    }
    clearVendorSubOrgList = () => {
        this.setState({ vendorSubOrgList: [] });
      };
    toggleVendorMapping = () => {
        this.setState( 
          {
            showVendorMapping: !this.state.showVendorMapping 
          });
      };
    handleClearVendorMeasureCache = async () => {
        await this.clearVendorMeasureCache();
      };
    handelLoadVendorList = () => {
        this.LoadVendorList();
      }
    private async LoadVendorList() {
        const vl$ = AdministrationService.GetSoftwareVendorList$(this.props.user, this.props.productYear);
        const vl = await lastValueFrom(vl$);
        this.setState({vendorSubOrgList: vl});
    }
    private async clearVendorMeasureCache() {
        this.setState({isClearingCache: true});
        await AdministrationService.ClearVendorMeasureCache$(this.props.user).toPromise();
        alert("Vendor measure cache cleared!");
        this.setState({isClearingCache: false});
    }
    private async uploadVendorMeasureFile(file: File) {
        this.setState({ isUploading: true });
        const localTransactionId = uuidv4();
        this.setState({ transactionId: localTransactionId });
        var apiBaseUrl =
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL +
            "/api/administration/uploadvendormeasures";
        const data = new FormData();
        data.append("File", file, file.name);
        const req = request.post(apiBaseUrl);
        req.set({ Authorization: "Bearer " + this.props.user.access_token });
        req.send(data).end(async (err: any, res: any) => {

            alert(res.body.toString());

            await this.RefreshModel();

            this.setState({
                inputFileKey: new Date().toString(),
                fileTypeValid: false,
                isUploading: false,
            });
        });
    }

    private handleUploadFileChange(e) {
        if (!e.target.files) {
            return;
        }
        this.setState({ selectedFile: e.target.files[0] });
        if (e.target.files[0].name.endsWith(".csv")) {
            this.setState({ fileTypeValid: true });
        } else {
            this.setState({ fileTypeValid: false });
        }
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        let data = await AdministrationService.GetVendorMeasures$(this.props.user, this.props.productYear).toPromise();
        this.setState({payload: data, isLoading: false});
    }
}

const VendorMeasureGuidListPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
  )(VendorMeasureGuidListView);
  
  // STYLES CONTAINER
  const VendorMeasureGuidListPage = withStyles(styles)(
    VendorMeasureGuidListPageWithState
  );
  
  export default VendorMeasureGuidListPage;

