import { User } from "oidc-client";
import { AjaxError } from "rxjs/ajax";
import AdministrationService from "../Components/Services/AdministrationService";

export interface IUmRole {
    ApplicationName: string;
    ApplicationId: string;
    RoleName: string;
    RoleId: string;
    SystemGroupTypeName: string;
    SystemGroupTypeId: string;
    SystemGroupTypeEntityId: string;
}

export interface IUserProfile {
    sub: string;
    email?: string;
    username?: string;
    createdon?: string;
    createdby?: string;
    modifiedon?: string;
    modifiedby?: string;
    SfId?: string;
    given_name?: string;
    family_name?: string;
    UM_Role?: IUmRole | IUmRole[];
}

export type PolicyName = 'user' | 'admin';

export const IsLoggedInPolicy = (user: User | null): boolean => (user ? true : false);

export const IsUserPolicy = async (user: User | null): Promise<boolean> => IsUser(user) && (await IsSystemAccessibleForUser(user));
export const IsGlobalAdminPolicy = async (user: User | null): Promise<boolean> => IsGlobalAdmin(user) && (await IsSystemAccessibleForUser(user));
export const IsOrgAdminPolicy = async (user: User | null, entityId: string): Promise<boolean> => IsAdminForOrg(user, entityId) && (await IsSystemAccessibleForUser(user));

export const IsOrgOrGlobalAdmin = async (user: User | null): Promise<boolean> => (IsGlobalAdmin(user) || IsOrgAdmin(user)) && (await IsSystemAccessibleForUser(user));


// SystemGroupType Names
const system = "system";
const organizationGroupTypeName = "organization";

// RoleNames 
const admin = "sysadmin";
const orgadmin = "orgadmin";

export const IsUser = (user: User | null): boolean => (user ? true : false);
export const IsGlobalAdmin = (user: User | null): boolean => IsUserAdmin(user, admin, system, '-1');
export const IsOrgAdmin = (user: User | null): boolean => HasOrgAdminRole(user);

export const IsAdminForOrg=(user:User|null, entityId: string):boolean=>IsUserAdmin(user,orgadmin,organizationGroupTypeName,entityId);

export const UserEmail = (user: User | null): string => user != null ? user.profile.username : '';
export const UserFirstName = (user: User | null): string => user != null ? user.profile.given_name : '';
export const UserLastName = (user: User | null): string => user != null ? user.profile.family_name : '';

const IsSystemAccessibleForUser = async(user: User | null): Promise<boolean> => {
    return IsGlobalAdmin(user) || !(await IsInSysAdminOnlyMode(user));
}

const IsUserAdmin = (user: User | null, role: string, sysGroup: string, entityId: string): boolean => {
    if (user && user.profile) {
        const profile = user.profile as IUserProfile;
        if (profile.UM_Role && profile.UM_Role instanceof Array) {
            const satisfyingRoles = profile.UM_Role.filter(x => x.SystemGroupTypeName === sysGroup && x.SystemGroupTypeEntityId === entityId && x.RoleName.toLowerCase() === role);
            return satisfyingRoles.length > 0;
        }
        if (profile.UM_Role && !(profile.UM_Role instanceof Array)) {
            const satisfyRole = profile.UM_Role.RoleName.indexOf(role) !== -1;
            return satisfyRole;
        }
    }
    return false;
}

const HasOrgAdminRole = (user: User | null): boolean => {
    if (user && user.profile) {
        const profile = user.profile as IUserProfile;
        if (profile.UM_Role && profile.UM_Role instanceof Array) {
            const satisfyingRoles = profile.UM_Role.filter(x =>  x.SystemGroupTypeName === organizationGroupTypeName && x.RoleName.toLowerCase() === orgadmin);
            return satisfyingRoles.length > 0;
        }
        if (profile.UM_Role && !(profile.UM_Role instanceof Array)) {
            const satisfyRole = profile.UM_Role.RoleName.indexOf(orgadmin) !== -1;
            return satisfyRole;
        }
    }
    return false;
}

const IsInSysAdminOnlyMode = async (user: User | null): Promise<boolean> => {
    //TODO: READ OFF USER IF YOU CAN, NO AJAX CALL
    const retval = await AdministrationService.GetAdminModel(user).toPromise();
    if (retval instanceof AjaxError) {
        throw (new Error("Ajax Error"));
    }

    return (retval.isOffseasonMode || retval.isMaintenanceMode);
}

export const HasSignedLegalAgreement = async (user: User | null): Promise<boolean> => {
    //TODO: READ OFF USER IF YOU CAN, NO AJAX CALL
    const retval = await AdministrationService.HasSignedLegalAgreement(user).toPromise();
    return retval;
}
export const NeedsSignerTitle = async (user: User | null): Promise<boolean> => {
    //TODO: READ OFF USER IF YOU CAN, NO AJAX CALL
    const retval = await AdministrationService.NeedsSignerTitle(user).toPromise();
    return retval;
}