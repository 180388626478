import { User } from "oidc-client";
import { AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs";
import { ISubmissionPayload, ISelectedSubmissionsHolder, ISubmissionHomePayload, IValidationResponsePayload, ISubmissionUserPayload, IOrgSummary, SubmissionActionTypes, ISubmissionAttestationFlags, ISubmissionAllowance, ISubmissionLockEvent, ISubmissionsStatistics, ISubmissionAttestationReportingFlags, ISubmissionKey, IDetailedSubmission } from "../../DataClasses/SubmissionData";
import { ILiveMeasure, IMeasureCodesForFilter, IWrappedMeasureCategories } from "../../DataClasses/MeasureViewerData";
import { AjaxError } from 'rxjs/ajax';
import { IFilesUploadReportPayload } from "../../DataClasses/ReportData";
import { SubmissionType } from "../../DataClasses/SharedData";
import { KeyValuePair } from "../../DataClasses/SharedData";
import { IArtDefault } from "../../DataClasses/ArtDefault";
import AjaxHelper from "./AjaxHelper";
const querystring = require('querystring');

class SubmissionsService {

    //#region Submissions

    public static GetSubmissionSummary$(user: User, currentSubId: string, component: string): Observable<ISubmissionHomePayload> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionSummary?subId=' + currentSubId + '&component=' + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetDetailedSubmission$(user: User, currentSubId: string, component: string): Observable<IDetailedSubmission> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetDetailedSubmission?submissionId=' + currentSubId + '&component=' + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetVendorMeasureGuids$(user: User, vendorId: string): Observable<KeyValuePair[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetVendorMeasureGuids?vendorId=' + vendorId,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionsForOrg$(user: User, orgId: number): Observable<ISubmissionHomePayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionsForOrg?orgId=' + orgId,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetSubmissionKeysForOrg$(user: User, orgId: number): Observable<ISubmissionKey[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionKeysForOrg?orgId=' + orgId,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionsOfTypeForOrgsByAction$(user: User, orgIds: number[], submissionType: SubmissionType, action: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionsOfTypeForOrgsByAction',
            {
                'OrgIds': orgIds,
                'SubmissionType': submissionType.toString(),
                'Action': action,
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionsDownloadableInfo$(user: User, subIds: number[], submissionType: SubmissionType): Observable<boolean[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionsDownloadableInfo?submissionIds=' + subIds.join(',') + "&submissionType=" + submissionType.toString(),
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetSubmissionsOfTypeForOrgs$(user: User, orgIds: number[], submissionType: SubmissionType): Observable<ISubmissionHomePayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionsOfTypeForOrgs?orgIds=' + orgIds.join(',') + "&submissionType=" + submissionType.toString(),
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetAllSubmissions$(user: User): Observable<ISubmissionPayload[]> {
        return this.GetSubmissions(user, '/api/Submissions/GetAllSubmissions');
    }

    public static GetSubmissionLockEvents$(user: User, measurementYear: number): Observable<ISubmissionLockEvent[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionLockEvents?measurementYear=' + measurementYear,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetValidationData$(user: User, currentSubId: string, homePayload: ISubmissionHomePayload): Observable<IValidationResponsePayload> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL +
            '/api/Submissions/GetValidationData?subId=' +
            currentSubId + '&productLine=' + homePayload.productLine +
            '&submissionComponent=' + homePayload.component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionAuditors$(user: User, orgId: number, subId: number): Observable<ISubmissionUserPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionAuditors?orgId=' + orgId + '&subId=' + subId,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionsForUser$(user: User, getAllSubmissions: boolean): Observable<ISubmissionPayload[]> {
        return this.GetSubmissions(user, '/api/Submissions/GetSubmissionsForUser?getAllSubmissions=' + getAllSubmissions);
    }

    public static GetBulkFiles$(user: User, bulkSubs: ISelectedSubmissionsHolder): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetBulkFilesNCQAUseOnly',
            {
                'Submissions': bulkSubs.submissions,
                'FileTypes': bulkSubs.fileTypes,
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static SubmissionsBulkAction$(user: User, bulkSubs: ISelectedSubmissionsHolder, action: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/ExecuteBulkSubmissionAction?action=' + action,
            bulkSubs,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static SubmissionsBulkAttestation$(user: User, bulkSubs: ISelectedSubmissionsHolder, attester: string, vendorName: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/ExecuteBulkSubmissionAttestation?attester=' + attester + '&vendorName=' + vendorName,
            bulkSubs,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UpdateAuditor$(user: User, orgId: number, submissionId: number, userName: string, accountLevel: string, action: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/UpdateAuditor?orgId=' + orgId + '&submissionId=' + submissionId + '&userName=' + userName + '&accountLevel=' + accountLevel + '&action=' + action,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    private static GetSubmissions(user: User, callString: string): Observable<ISubmissionPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + callString,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    //#endregion

    //#region Submission Measure

    public static GetMeasureIndicatorsForSubmission$(user: User, subId: string, productLine: string): Observable<IWrappedMeasureCategories> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetMeasureIndicatorsForSubmission?subId=' + subId + '&productLine=' + productLine,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetLiveMeasure(user: User, submissionId: string, productLine: string, measure: string, measurementYear: number): Observable<ILiveMeasure| AjaxError> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + `/api/Submissions/GetLiveMeasure?submissionId=${submissionId}&productLine=${productLine}&measure=${measure}&measurementYear=${measurementYear}`,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    //#endregion

    public static GetOrgForSubmissionsSummary$(user: User): Observable<IOrgSummary[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetOrgForSubmissionsSummary',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetOrgForVendorsSummary$(user: User): Observable<IOrgSummary[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetOrgForVendorsSummary',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static ExecutePlanAction$(user: User, submissionId: string, component: string, actionType: SubmissionActionTypes): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/ExecutePlanAction?submissionId=' + submissionId + '&component=' + component + '&action=' + actionType,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UnSubmitFile$(user: User, submissionId: number, component: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/UnSubmitFile?submissionId=' + submissionId + '&component=' + component,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static ReprocessSubmission$(user: User, submissionId: number, component: string, groupId: any): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/ReprocessSubmission?submissionId=' + submissionId + '&component=' + component + '&groupId=' + groupId,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetUploadedDocsForSubmission$(user: User, submissionId: number, component: string): Observable<IFilesUploadReportPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionUploadedDocuments?submissionId=' + submissionId + '&component=' + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionLink$(user: User, submissionId: number, component: string, isFileUploaded: boolean, isValidated: boolean, stageNumber: number, key: string, productLine: string): Observable<string> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL +
            '/api/Submissions/GetSubmissionDownloadString?component=' + component +
            '&submissionId=' + submissionId +
            '&productLine=' + productLine +
            '&isFileUploaded=' + isFileUploaded +
            '&isValidated=' + isValidated +
            '&stageNumber=' + stageNumber +
            '&key=' + key,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UpdateSubmissionAttestationFlags$(user: User, attestationFlags: ISubmissionAttestationFlags): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/UpdateSubmissionAttestationFlags?' + querystring.stringify(attestationFlags),
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UpdateSubmissionAttestationReportingFlags$(user: User, attestationReportingFlags: ISubmissionAttestationReportingFlags): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/UpdateSubmissionAttestationReportingFlags?' + querystring.stringify(attestationReportingFlags),
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UndoSubmissionAttestation$(user: User, submissionId: string, component: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/UndoSubmissionAttestation?submissionId=' + submissionId + "&component=" + component,
            {
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }


    public static GetUploadAvailabilities$(user: User): Observable<ISubmissionAllowance> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetBulkUploadAvailabilities',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetReportsAvailabilities$(user: User): Observable<ISubmissionAllowance> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetReportsAvailabilities',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static UpdateAuditDesignations$(user: User, subId: string, component: string, auditDesignations: IWrappedMeasureCategories): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/UpdateAuditDesignations?submissionId=' + subId + "&component=" + component,
            auditDesignations,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetAuditDesignations$(user: User, subId: string, component: string): Observable<IWrappedMeasureCategories> {
        return AjaxHelper.AjaxGet$(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetAuditDesignations?submissionId=' + subId + "&component=" + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetMeasureCodesForFilter$(user: User, productLine: string): Observable<IMeasureCodesForFilter>{
        return AjaxHelper.AjaxGet$(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetMeasureCodesForFilter?productLine=' + productLine,
        AjaxHelper.CreateHttpHeaders(user)
        )
    }
    public static GetSubmissionsStatistics$(user: User): Observable<ISubmissionsStatistics> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionsStatistics',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetESignPDF$(user: User, submissionId: number, component: string): Observable<string> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetESignPDF?submissionId=' + submissionId + "&component=" + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static SignPDF$(user: User, submissionId: number, component: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/SignPDF?submissionId=' + submissionId + "&component=" + component,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetPendingSubmissions$(user: User): Observable<ISubmissionPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetPendingSubmissions',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetArtDefaults$(user: User): Observable<IArtDefault[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetArtDefaults',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static IsSubmissionAuditorWritable$(user: User, orgId: number, subId: number, component: string): Observable<boolean> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/IsSubmissionAuditorWritable?orgId=' + orgId + '&submissionId=' + subId + '&component=' + component,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static BulkSubmissionAction$(user: User, subIds: number[], component: string, action: string): Observable<AjaxResponse<any>> {
        var subIdStr = subIds.join(',');
        return this.BulkSubmissionActionByIdsStr$(user, subIdStr, component, action);
    }

    public static BulkSubmissionActionByIdsStr$(user: User, subIds: string, component: string, action: string): Observable<AjaxResponse<any>> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/BulkSubmissionAction?subIds=' + subIds + '&component=' + component + '&action=' + action,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetMeasureDataDownloadCaller$(user: User, subId: string, measureCode: string) {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetMeasureDataDownload?subId=' + subId + '&measureCode=' + measureCode,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetFilteredArtDownloads$(user: User, subId: string, filterValue: string) {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetFilteredArtDownload?subId=' + subId + '&filterValue=' + filterValue,
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetSubmissionDataExtractStatistics$(user: User): Observable<Map<string, number>> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetSubmissionDataExtractStatistics',
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static ExtractSubmissions$(user: User, dataExtractionType: string): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/ExtractSubmissions?dataExtractionType=' + dataExtractionType,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static VerifyDataExtraction$(user: User, dataExtractionType: string): Observable<string | AjaxError> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/VerifyDataExtraction?dataExtractionType=' + dataExtractionType,
            AjaxHelper.CreateHttpHeaders(user)
        );
    }

    public static DownloadAllProductLineTemplates$(user: User): Observable<any> {
        return AjaxHelper.AjaxGet$(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/DownloadAllProductLineTemplates',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetHosSubmissionsAttestationLanguage$(user: User): Observable<Map<string, string>> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Submissions/GetHosSubmissionsAttestationLanguage',
            AjaxHelper.CreateHttpHeaders(user)
        );
    }  
}

export default SubmissionsService;