import { Card, CardContent, Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import idssStyle from "../../Configuration/SharedStyling";

interface ILoadingProps {
    count?: number,
    titleText?: string,
    doubleRowSpace?: boolean,
    width?: string
}

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    cardContent: {
        padding: '0',
        '& table': {
            margin: theme.spacing(2, 0)
        },
        '& ul': {
            margin: theme.spacing(2)
        },
    },
    cardTitle: {
        margin: theme.spacing(2)
    },
    skeleton: {
        margin: theme.spacing(1, 2),
    },
    skeleton2: {
        margin: theme.spacing(2, 2),
    }
});
type IStylesProps = WithStyles<typeof styles>;
const LoadingDataTableRaw = (props: ILoadingProps & IStylesProps) => {
    return (<Card className={props.classes.card}>
        <CardContent className={props.classes.cardContent}>
            {props.titleText == null ? null :
                <Typography className={props.classes.primaryBlue + " " + props.classes.cardTitle}>
                    {props.titleText === "*" ?
                        <Skeleton width={"30%"} />
                        : props.titleText}
                </Typography>
            }
            <Skeleton count={props.count || 6} duration={2} className={props.doubleRowSpace! ? props.classes.skeleton2 : props.classes.skeleton} width={props.width! ? props.width : "90%"} />
        </CardContent>
    </Card>
    )
};

const LoadingDataTable = withStyles(styles)(LoadingDataTableRaw);

export default LoadingDataTable;