import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import idssStyle from "../../Configuration/SharedStyling";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});
type IStylesProps = WithStyles<typeof styles>;
// REDUX PROPS
interface IDialogProps {
    showDialog: boolean,
    title: string,
    description: string,
    button1Text: string,
    button2Text: string,
    button1Handler: any;
    button2Handler: any;
    isButton1Clicked?: boolean;
}

const AlertDialogRaw = (props: IDialogProps & IStylesProps) => (
    <div>
        <Dialog
            open={props.showDialog}
            onClose={props.button2Handler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.isButton1Clicked && props.isButton1Clicked === true ? <span style={{ paddingTop: '10px' }}><CircularProgress color="primary" size={22} /></span> : null}
                <Button onClick={props.button1Handler} disabled={props.isButton1Clicked && props.isButton1Clicked === true ? true : false}>
                    {props.button1Text}
                </Button>
                <Button onClick={props.button2Handler} className={props.classes.disabledButton} autoFocus={true}
                    disabled={props.isButton1Clicked && props.isButton1Clicked === true ? true : false}>
                    {props.button2Text}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
);

const AlertDialog = withStyles(styles)(AlertDialogRaw);

export default AlertDialog;