import { Theme } from "@material-ui/core";
import createStyles from '@material-ui/core/styles/createStyles';
const drawerWidth = 240;

const idssStyles = (theme: Theme) => createStyles({
    appLogo: {
        height: '50px',
        paddingLeft: '4px'
    },
    subNavLink: {
        marginLeft: '57px'
    },
    footerContainer: {
        padding: 0,
        marginTop: theme.spacing(2)
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: '100%'
    },
    gridDisplay: {
        display: "flex",
    },
    headerRow: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 14,
    },
    primaryBlue: {
        color: '#4F758B',
        fontSize: '1.2em',
        fontWeight: 'bold'
    },
    primaryBlueSmall: {
        color: '#4F758B',
        fontSize: '0.9em',
        fontWeight: 'bold'
    },
    primaryBlueMedium: {
        color: '#4F758B',
        fontSize: '1.0em',
        fontWeight: 'bold'
    },
    actionBlue: {
        backgroundColor: '#0099CC',
        color: '#FFFFFF',
        "&:hover": {
            backgroundColor: '#4F758B',
            color: 'White'
        }
    },
    disabledButton: {
        backgroundColor: '#D3D3D3',
        color: '#707070',
        "&:hover": {
            backgroundColor: '#D3D3D3'
        }
    },
    linkButton: {
        backgroundColor: 'White',
        color: 'SteelBlue',
        "&:hover": {
            backgroundColor: '#D3D3D3'
        }
    },
    linkButtonClear: {
        backgroundColor: 'White',
        color: 'SteelBlue',
        "&:hover": {
            backgroundColor: 'White',
        }
    },
    activeLink: {
        color: '#FFFFFF',
        fontSize: '15px',
        fontWeight: 'bold'
    },
    darkGray: {
        color: '#A9A9A9'
    },
    submissionTitle: {
        color: "#000000",
        fontSize: '30px',
        fontWeight: 'bold'
    },
    submissionSubtitle: {
        color: "#303030",
        fontSize: '15px',
        fontWeight: 'bold'
    },
    submissionSubtitle1: {
        color: "#707070",
        fontSize: '12px',
        fontWeight: 'bold'
    },
    secondaryText: {
        color: "#707070",
        fontSize: '14px',
        paddingTop: '5px'
    },
    copyIcon: {
        color: "#0099CC",
        cursor: 'pointer',
        alignItems: 'center',
    },
    noBottomMargin: {
        marginBottom: '0px'
    },
    muiTableTitleMargin: {
        marginLeft: theme.spacing(-1)
    },
    marginTop: {
        marginTop: '10px'
    },
    dividerPadding: {
        marginLeft: '60px'
    },
    marginBottom: {
        marginBottom: '10px'
    },
    submissionHomeContent: {
        margin: '0px'
    },
    bodyCopy: {
        color: 'rgb(0, 0, 0, 87%)',
    },
    inactiveGray: {
        color: 'rgb(0, 0, 0, 12%)',
    },
    supportingGray: {
        color: 'rgb(0, 0, 0, 54%)',
    },
    backgroundGray: {
        color: '#F5F5F5',
    },
    flexGrow: {
        flexGrow: 1
    },
    fullWidth: {
        width: '100%'
    },
    mainPanel: {
        flexGrow: 1,
        minHeight: '72vh'
    },
    flexRoot: {
        display: 'flex',
    },
    tableRoot: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    tableCellYellow: {
        backgroundColor: 'yellow',
        textAlign: 'center'
    },
    tableCellNcqaBlue: {
        backgroundColor: '#4F758B',
        textAlign: 'left'
    },
    tableCellGray: {
        backgroundColor: '#F5F5F5',
    },
    fab: {
        margin: theme.spacing(1),
    },
    squareFab: {
        margin: theme.spacing(1),
        borderRadius: "5px"
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        }
    },
    button: {
        marginRight: 1,
    },
    link: {
        spacing: 1,
    },
    paper: {
        margin: 10,
    },
    minspacing: {
        margin: 5,
    },
    noflex: {
        flexGrow: 0,
    },
    gridRowFlex: {
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    gridRowCenter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    gridColumnFlexEnd: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    loginGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justify: 'center',
    },
    measureHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        "margin-bottom": theme.spacing(1),
        "margin-top": theme.spacing(1)
    },
    leftMeasure: {
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: '15px',
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    rightMeasure: {
        fontWeight: 'bold',
        textAlign: 'right',
        paddingRight: '15px',
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    arrow: {
        paddingBottom: '3px',
        verticalAlign: 'middle'
    },
    greenColor: {
        color: 'green',
    },
    redColor: {
        color: 'red',
    },
    darkOrangeColor: {
        color: 'darkOrange',
    },
    dropZone: {
        position: 'relative',
        width: '100%',
        minHeight: '250px',
        backgroundColor: '#F0F0F0',
        border: 'dashed',
        borderColor: '#C8C8C8',
        cursor: 'pointer',
        boxSizing: 'border-box',
    },
    stripes: {
        border: 'solid',
        backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
        animation: 'progress 2s linear infinite !important',
        backgroundSize: '150% 100%',
    },
    rejectStripes: {
        border: 'solid',
        backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
        animation: 'progress 2s linear infinite !important',
        backgroundSize: '150% 100%',
    },
    dropzoneTextStyle: {
        textAlign: 'center'
    },
    uploadIconSize: {
        width: 51,
        height: 51,
        color: '#909090'
    },
    dropzoneParagraph: {
        fontSize: 24
    },
    dropzoneThumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    dropzoneThumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },

    dropzoneThumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },

    dropzoneImg: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },

    menu: {
        width: 200,
    },
    minimumSpacing: {
        paddingLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        minHeight: '72vh',
        background: '#f7f7f7',
    },
    submissionsDataHighlight: {
        display: 'inline-block',
        fontWeight: "bolder",
        fontSize: '13px'
    },
    Default: {
        color: theme.palette.common.white,
        textAlign: 'center',
        verticalAlign: 'center',
        display: 'inline-block',
        width: '130px',
        padding: '4px',
        fontWeight: "bold",
        fontSize: '13px'
    },
    ValidationDefault: {
        color: theme.palette.common.white,
        textAlign: 'center',
        verticalAlign: 'center',
        display: 'inline-block',
        width: '120px',
        padding: '4px',
        fontWeight: "bold",
        fontSize: '13px'
    },
    GrayedOut: {
        backgroundColor: theme.palette.grey[400]
    },
    Error: {
        backgroundColor: '#FF0000'
    },
    Warning: {
        backgroundColor: 'orange'
    },
    Success: {
        backgroundColor: '#008000'
    },
    HighlightedNote450: {
        color: theme.palette.common.black,
        width: '450px',
        padding: '4px',
        fontWeight: "bold",
        fontStyle: "italic",
        backgroundColor: 'yellow',
        fontSize: '13px'
    },
    HighlightedNote: {
        color: theme.palette.common.black,
        padding: '4px',
        fontWeight: "bold",
        fontStyle: "italic",
        backgroundColor: 'yellow',
        fontSize: '13px'
    },
    HighlightedNoteSmall: {
        color: theme.palette.common.black,
        padding: '2px',
        fontWeight: "bold",
        fontStyle: "italic",
        backgroundColor: 'yellow',
        fontSize: '12px'
    },
    appBar: {
        backgroundColor: '#4f758b'
    },
    toolbar: theme.mixins.toolbar,
    fixMinHeight: {
        display: 'flex'
    },
    logo: {
        height: '80px',
        marginBottom: '10px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    maxWidth: {
        flexGrow: 1,
    },
    dialogPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minHeight: '90%',
        maxHeight: '90%',
        minWidth: '80%',
        maxWidth: '80%',
    },
    defaultMargin: {
        margin: '10px'
    },
    actionIcon: {
        cursor: 'pointer'
    },
    hidden: {
        display: 'none'
    },
    mt1: {
        "margin-top": theme.spacing(1)
    },
    ml1: {
        "margin-left": theme.spacing(1)
    },
    mr1: {
        "margin-right": theme.spacing(1)
    },
    mb1: {
        "margin-bottom": theme.spacing(1)
    },
    mt2: {
        "margin-top": theme.spacing(2)
    },
    ml2: {
        "margin-left": theme.spacing(2)
    },
    mr2: {
        "margin-right": theme.spacing(2)
    },
    mb2: {
        "margin-bottom": theme.spacing(2)
    },
    mt1Neg: {
        "margin-top": "-5px"
    },
    ml1Neg: {
        "margin-left": "-5px"
    },
    mr1Neg: {
        "margin-right": "-5px"
    },
    mb1Neg: {
        "margin-bottom": "-5px"
    },
    widgetNum: {
        textAlign: 'left',
        verticalAlign: 'bottom',
        width: '130px',
        height: '55px',
        paddingLeft: '25px',
        paddingTop: '10px',
        fontWeight: "bold",
        fontSize: '30px'
    },
    widgetNumVsRBorder: {
        textAlign: 'left',
        verticalAlign: 'bottom',
        width: '120px',
        height: '55px',
        paddingLeft: '25px',
        paddingTop: '10px',
        fontWeight: "bold",
        fontSize: '30px',
        borderRight: '1px solid lightgrey'
    },
    widgetTxt: {
        textAlign: 'left',
        verticalAlign: 'top',
        width: '120px',
        paddingLeft: '25px',
        fontWeight: "bold",
        fontSize: '13px',
        color: '#0099CC',
        textDecoration: 'none'
    },
    widgetTxtBlk: {
        textAlign: 'left',
        verticalAlign: 'top',
        width: '120px',
        paddingLeft: '25px',
        fontWeight: "bold",
        fontSize: '13px',
        color: '#000000',
        textDecoration: 'none'
    },
    widgetTxtBlkVsRBorder: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingLeft: '25px',
        width: '120px',
        fontWeight: "bold",
        fontSize: '13px',
        color: '#000000',
        textDecoration: 'none',
        borderRight: '1px solid lightgrey'
    },
    rightBorder: {
        borderRight: '1px solid lightgrey'
    },
    dashboardTitleGrid: {
        height: '70px',
        marginBottom: theme.spacing(2)
    },
    circleNumber: {
        backgroundColor: '#4f758b',
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        marginLeft: '-10px',
        marginRight: '10px',
        color: 'white',
        paddingTop: '4px',
        //paddingLeft: '8px',
        textAlign: 'center'
    },
    circleNumberText: {
        display: 'inline-block'
    },
    collapseBtnSmall: {
        height: '40px',
        background: 'none',
        border: 'none',
        padding: '3px'
    },
    collapseBtn: {
        height: '50px',
        background: 'none',
        border: 'none',
        paddingTop: '18px'
    },
    DropzoneContainer: {
        border: '2px dashed grey',
        flexGrow: 1,
        backgroundColor: '#f0f0f0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dropzoneText: {
        fontSize: "1.2em",
        width: '100%',
        textAlign: 'center'
    },
    dropzoneBtn: {
        padding: theme.spacing(0, 4),
        height: theme.spacing(5)
    },
    dialogM: {
        minHeight: '80%',
        maxHeight: '80%',
        minWidth: '60%',
        maxWidth: '60%',
    },
    dialog7080: {
        minHeight: '80%',
        maxHeight: '80%',
        minWidth: '70%',
        maxWidth: '70%',
    },
    dialogL: {
        minHeight: '90%',
        maxHeight: '90%',
        minWidth: '95%',
        maxWidth: '95%',
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    headerIcon: {
        fontSize: 28,
        color: "rgba(255, 255, 255, 0.35)",
    },
    headerIconCollapse: {
        color: "white",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 5,
        [theme.breakpoints.down("sm")]: {
            width: drawerWidth,
        },
    },
    sidebarList: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    sidebarLogo: {
        marginTop: '0px',
        paddingTop: '0px',
        marginBottom: '1px',
        paddingBottom: '5px',
    },
    appTitle: {
        fontSize: '1.10rem'
    },
    root: {
        display: "flex",
        maxWidth: "100vw",
        overflowX: "hidden",
        padding: theme.spacing(3, 2),
        background: '#f7f7f7',
    },
    content: {
        flexGrow: 1,
        width: `calc(100vw - ${theme.spacing(7) + 20})`,
        marginLeft: theme.spacing(7) + 20,
        minHeight: "100vh"
    },
    contentShift: {
        width: `calc(100vw - ${theme.spacing(3) + 240}px)`,
        marginLeft: '240px',
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    fakeToolbar: {
        ...theme.mixins.toolbar,
    },
    tabTitle: {
        margin: "10px 5px",
        color: 'SteelBlue',
        fontWeight: 'bold'
    },
    skeleton300: {
        margin: theme.spacing(1, 2),
        width: "98%",
        height: 300
    },
    skeleton50: {
        margin: theme.spacing(1, 2),
        width: "98%",
        height: 50
    },
    skeleton50s: {
        margin: theme.spacing(1, 2),
        width: "60%",
        height: 50
    },
    errorBlock: {
        paddingTop: '30px',
        width: '100%',
        textAlign: 'left',
        color: '#A9A9A9',
        fontSize: 25,
        fontWeight: 700,
        lineHeight: 1.1
    },
    browserSupport: {
        width: '100%',
        textAlign: 'center',
        color: '#A9A9A9',
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1.1
    },
    centeredHeader: {
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
    },
})
export default idssStyles;