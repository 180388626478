import { User } from "oidc-client";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import RootState from "../../State/Root/RootState";
import ErrorBoundary from "../../Components/Pages/Errors/ErrorBoundary";
import LoadingDisplay from "../Shared/Loading";
import Unauthorized from "../Pages/Errors/Unauthorized";
import { HasSignedLegalAgreement, NeedsSignerTitle } from "../../Configuration/AuthorizationPolicies";
import LegalAgreementSignPage from "../Pages/LegalAgreementSign";
import UpdateYourTitle from "../Pages/UpdateYourTitle";
import ScrollToTop from '../../Hooks/ScrollToTop'

// ATTRIBUTE PROPS
interface IProps {
    authenticationPolicy: (user: User | null) => boolean;
    authorizationPolicy: (user: User | null) => Promise<boolean>;
}

// REDUX PROPS
interface IReduxProps {
    user: User | null;
}

interface IAuthorizedRouteState {
    isAuthorized?: boolean;
    hasSignedLegalAgreement?: boolean;
    needsSignerTitle: boolean;
}

const mapStateToProps = (state: RootState): IReduxProps => ({
    user: state.AuthenticationReducer.user
});

// REACT VIEW
class AuthorizedRouteView extends React.Component<
    RouteProps & IProps & IReduxProps, IAuthorizedRouteState
    > {

    constructor(props) {
        super(props);

        this.state = { isAuthorized: null, hasSignedLegalAgreement: null, needsSignerTitle:true };
    }

    // Do asynchronous action here
    async componentDidMount() {
        try {
            const isAuthorized = await this.props.authorizationPolicy(this.props.user);
            const hasSignedLegalAgreement = await HasSignedLegalAgreement(this.props.user);
            const needsSignerTitle = await NeedsSignerTitle(this.props.user);
            this.setState({ isAuthorized, hasSignedLegalAgreement, needsSignerTitle })
        } catch (err) {
            // error handling
        }
    }

    public render() {
        const { component, render, user, authenticationPolicy, ...rest } = this.props;

        const isAuthenticated = authenticationPolicy(user);

        if (!isAuthenticated) {
            // Redirect to unAuthorized path (or home if not specified) if not authenticated, or no component/render defined
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        search: `?redirectUrl=${window.location.pathname}`
                    }}
                />
            );
        }

        // Determine if the user is authorized
        if (this.state.isAuthorized != null) {

            if (this.state.isAuthorized) {
                if (this.state.hasSignedLegalAgreement) {
                    if (!this.state.needsSignerTitle) {
                        // Render function is defined
                        if (render) {
                            return (
                                <ErrorBoundary>
                                    <ScrollToTop />
                                    <Route {...rest} render={render} />
                                </ErrorBoundary>

                            );
                        }

                        // Component is defined
                        if (component) {
                            return (<ErrorBoundary>
                                <ScrollToTop />
                                <Route {...rest} component={component} />
                            </ErrorBoundary>);
                        }
                    }
                    else {
                        return (<UpdateYourTitle></UpdateYourTitle>)
                    }
                }
                else {
                    return (<LegalAgreementSignPage></LegalAgreementSignPage>)
                }
            }
            else {
                return (<Unauthorized></Unauthorized>);
            }
        }
        return (<LoadingDisplay progressText={"*"}></LoadingDisplay>);
    }
}

// REDUX CONTAINER
const AuthorizedRoute = connect<IReduxProps>(mapStateToProps)(
    AuthorizedRouteView
);

export default AuthorizedRoute;
