import * as React from "react";
import { connect } from "react-redux";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import { Grid, Typography } from "@material-ui/core";
import UserManagerService from "../../Services/UserManagerService";
import { AjaxError } from "rxjs/ajax";
import { IUserRolePayload } from "../../../DataClasses/UsersData";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import LoadingDataTable from "../../Shared/LoadingDataTable";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });
interface IAccountPageState {
    data: IUserRolePayload[];
    isLoading: boolean;
    redirect: boolean;
}
type IStylesProps = WithStyles<typeof styles>;

// VIEW
class AccountPageView extends React.Component<
    IGenericUserProps & IStylesProps,
    IAccountPageState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }


    public render() {
        const { classes } = this.props;
        const userRoleColumns = [
            {
                label: "Org ID",
                name: "entityId",
                options: {
                    filter: true
                }
            },
            {
                label: "Org Name",
                name: "entityName",
                options: {
                    filter: true
                }
            },
            {
                label: "Role Type",
                name: "roleType",
                options: {
                    filter: true
                }
            },
            {
                label: "Role Name",
                name: "roleName",
                options: {
                    filter: true
                }
            }
        ];
        const userRoleListingOptions: MUIDataTableOptions = {
            filterType: "checkbox",
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
        };
        return(
            <Grid container={true} className={classes.gridRowFlex} spacing={2}>
            <Grid item={true} xs={12}>
                <Typography className={classes.submissionTitle}>
                    Account Page
                </Typography>
            </Grid>
            <Grid xs={12} item={true} key="userRoleListGrid">
                {this.state.isLoading?
                <LoadingDataTable titleText={"*"} count={7}/>
                :
                <div className={classes.card}>
                    <MUIDataTable
                        title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Your Account Roles</Typography>}
                        data={this.state.data}
                        columns={userRoleColumns}
                        options={userRoleListingOptions}
                    />
                </div>
                }
            </Grid>
            </Grid>
        );
    }
    private setRedirectState() {
        this.setState({ redirect: true });
    }
    private async RefreshModel() {
        this.setState({ isLoading: true });
        const userRetVal = await UserManagerService.GetAccountRolesForCurrentUser$(this.props.user).toPromise();
        if (userRetVal instanceof AjaxError || !userRetVal) {
            this.setRedirectState();
        } 
        else {
            this.setState({
                data: userRetVal
            });
        }
        this.setState({ isLoading: false });
    }
}

// REDUX CONTAINER
const AccountPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(AccountPageView);

// STYLES CONTAINER
const AccountPage = withStyles(styles)(
    AccountPageWithState
);

export default AccountPage;