import * as React from "react";
import { connect } from "react-redux";
import {
    Theme,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Card,
    ListItem,
    ListItemText,
    List,
    CardContent,
    Fab,
    Divider,
    IconButton,
    Tooltip,
    Fade,
} from "@material-ui/core";
import idssStyle from "../../Configuration/SharedStyling";
import AddCircle from "@material-ui/icons/AddCircle";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { Link, LinkProps } from "react-router-dom";
import {
    ISubmissionAllowance,
    ISubmissionsStatistics,
    ISubmissionPayload,
} from "../../DataClasses/SubmissionData";
import SubmissionsService from "../../Components/Services/SubmissionsService";
import {
    IGenericUserProps,
    genericMapStateToProps,
} from "../../DataClasses/GenericUser";
import { AjaxError } from "rxjs/ajax";
import AnnouncementsService from "../Services/AnnouncementsService";
import EventsService from "../Services/EventsService";
import Grow from "@material-ui/core/Grow";
import Skeleton from "@material-ui/lab/Skeleton";
import froalaBaseConfig from "../../Utilities/BaseFroalaConfig";
import { IAnnouncementPayload } from "../../DataClasses/AnnouncementData";
import SharedUtility from "../../Utilities/SharedUtility";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Edit as EditIcon } from "@material-ui/icons";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { Redirect } from "react-router-dom";
import UserMessage from '../Shared/Message';
import { MessageType } from '../../DataClasses/SharedData';
import ConfettiExplosion from 'react-confetti-explosion';
import Alert from '@material-ui/lab/Alert';

const styles = (theme: Theme) =>
    createStyles({
        ...idssStyle(theme),

        recentEventsList: {
            listStyle: "none",
            paddingLeft: theme.spacing(2),
            "& li": {
                borderBottom: "1px solid #f0f0f0",
                padding: theme.spacing(1, 0),
            },
        },
        dashboardCardContent: {
            padding: "0",
            "& table": {
                margin: theme.spacing(2, 0),
            },
            "& ul": {
                margin: theme.spacing(2),
            },
        },
        cardTitle: {
            margin: theme.spacing(2),
        },
        announcementContainer: {
            padding: theme.spacing(1, 2),
        },
        skeleton: {
            margin: theme.spacing(1, 2),
            width: "90%",
            height: 150,
        },
    });

interface ILocalState {
    isLoadingStatistic: boolean;
    isExploding: boolean;
    subsStatistics: ISubmissionsStatistics;

    isLoadingAnnouncements: boolean;
    announcements: any[];

    isLoadingEvents: boolean;
    events: string[];

    uploadavailabilities: ISubmissionAllowance;

    pendingSubmissions: any[];
    isLoadingPendingSubmissions: boolean;
    redirect: boolean;
}

type IStylesProps = WithStyles<typeof styles>;
const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);

const pendingSubmssionsColumns = [
    {
        label: "Org ID",
        name: "OrgId",
        options: {
            filter: true,
        },
    },
    {
        label: "Sub ID",
        name: "SubId",
        options: {
            filter: true,
        },
    },
    {
        label: "Org Name",
        name: "Name",
        options: {
            filter: true,
        },
    },
    {
        label: "Component",
        name: "Component",
        options: {
            filter: true,
        },
    },
    {
        label: "Stage",
        name: "Stage",
        options: {
            filter: true,
        },
    },
    {
        name: "Actions",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                var submissionLink = "/submissionHome/" + value;
                return value === "" ? null : (
                    <Tooltip title="Submission Home" aria-label="submissionHome">
                        <IconButton
                            size="medium"
                            aria-label="submissionHome"
                            style={{ padding: "0px 12px 0px 12px" }}
                        >
                            <Link to={submissionLink}>
                                <EditIcon fontSize="inherit" color="action"></EditIcon>
                            </Link>
                        </IconButton>
                    </Tooltip>
                );
            },
        },
    },
];

const pendingSubmissionsListingOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    sort: true,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
};

// VIEW
class DashboardView extends React.Component<
    IGenericUserProps & IStylesProps,
    ILocalState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            subsStatistics: {
                organization: 0,
                submission: 0,
                commercial: 0,
                medicaid: 0,
                medicare: 0,
                exchange: 0,
                planLocked: 0,
                auditLocked: 0,
                pendingEsign: 0,
                markFinal: 0,
            },
            announcements: [],
            events: [],
            uploadavailabilities: {
                allowsHedisSubmission: false,
                allowsCahpsSubmission: false,
                allowsHosSubmission: false,
                allowsGenericUploads: false
            },
            isExploding: true,
            isLoadingStatistic: true,
            isLoadingPendingSubmissions: true,
            isLoadingEvents: true,
            isLoadingAnnouncements: true,
            pendingSubmissions: [],
            redirect: false
        };
    }

    //public componentDidUpdate() {
     //   if (this.state.isLoadingStatistic === false && this.state.isExploding === true) {
     //       this.setState({ isExploding: false });
     //   }
    //}

    public componentDidMount() {
        // This method runs when the component is first added to the page
        // dispatch(Announcement.getDashboardEpic);
        this.RefreshModel();
    }



    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        var seedTime = 400;
        var addTime = 200;
        var h = 35;
        var w = 40;
        var notDoneSubmissions = this.state.subsStatistics.planLocked + this.state.subsStatistics.auditLocked + this.state.subsStatistics.pendingEsign + this.state.subsStatistics.markFinal;
        var shouldShowDoneDialog = this.state.subsStatistics.submission > 0 && notDoneSubmissions === 0 && this.state.isLoadingPendingSubmissions === false && this.state.pendingSubmissions && this.state.pendingSubmissions.length === 0 ;
        var shouldShowConfetti = shouldShowDoneDialog && this.state.isExploding;
        return (
            <Grid
                container={true}
                spacing={2}
                className={classes.mainPanel + " " + classes.root}
            >
                <Grid item={true} xs={12} className={classes.dashboardTitleGrid}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <Typography className={classes.submissionTitle}>
                                Dashboard
                            </Typography>
                        </Grid>
                        <Grid
                            color="default"
                            item={true}
                            className={classes.flexGrow}
                        ></Grid>
                        {this.state.uploadavailabilities.allowsGenericUploads ? (
                            <Fade in={true} {...{ timeout: seedTime }}>
                                <Grid item={true}>
                                    <Fab
                                        to="genericuploads"
                                        className={classes.fab}
                                        variant="extended"
                                        color="secondary"
                                        component={AdapterLink}
                                    >
                                        <AddCircle className={classes.extendedIcon} />
                                        Generic Uploads
                                    </Fab>
                                </Grid>
                            </Fade>
                        ) : null}
                        {this.state.uploadavailabilities.allowsHedisSubmission ? (
                            <Fade in={true} {...{ timeout: seedTime }}>
                                <Grid item={true}>
                                    <Fab
                                        to="bulkupload/HEDIS"
                                        className={classes.fab}
                                        variant="extended"
                                        color="secondary"
                                        component={AdapterLink}
                                    >
                                        <AddCircle className={classes.extendedIcon} />
                                        HEDIS Uploads
                                    </Fab>
                                </Grid>
                            </Fade>
                        ) : null}
                        {this.state.uploadavailabilities.allowsCahpsSubmission ? (
                            <Fade in={true} {...{ timeout: seedTime * 2 }}>
                                <Grid item={true}>
                                    <Fab
                                        to="bulkupload/CAHPS"
                                        className={classes.fab}
                                        variant="extended"
                                        color="secondary"
                                        component={AdapterLink}
                                    >
                                        <AddCircle className={classes.extendedIcon} />
                                        CAHPS Uploads
                                    </Fab>
                                </Grid>
                            </Fade>
                        ) : null}
                        {this.state.uploadavailabilities.allowsHosSubmission ? (
                            <Fade in={true} {...{ timeout: seedTime * 3 }}>
                                <Grid item={true}>
                                    <Fab
                                        to="bulkupload/HOS"
                                        className={classes.fab}
                                        variant="extended"
                                        color="secondary"
                                        component={AdapterLink}
                                    >
                                        <AddCircle className={classes.extendedIcon} />
                                        HOS Uploads
                                    </Fab>
                                </Grid>
                            </Fade>
                        ) : null}
                    </Grid>
                </Grid>

                <Grid item={true} xs={8}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                    >
                        <Grid
                            item={true}
                            xs={12}
                            className={classes.flexGrow + " " + classes.fullWidth}
                        >
                            <Card className={classes.card}>
                                <CardContent className={classes.dashboardCardContent}>
                                    <Typography
                                        className={classes.primaryBlue + " " + classes.cardTitle}
                                    >
                                        Overview
                                    </Typography>
                                    <Divider />
                                    <Fade in={true}>
                                        <div>
                                            <table>
                                                <tbody>
                                                    {this.state.isLoadingStatistic ? (
                                                        <tr>
                                                            <td className={classes.widgetNumVsRBorder}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td className={classes.widgetNumVsRBorder}>
                                                                <Grow in={!this.state.isLoadingStatistic}>
                                                                    <div>
                                                                        {this.state.subsStatistics.organization}
                                                                    </div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: seedTime }
                                                                        : {})}
                                                                >
                                                                    <div>
                                                                        {this.state.subsStatistics.commercial}
                                                                    </div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: seedTime * 2 }
                                                                        : {})}
                                                                >
                                                                    <div>{this.state.subsStatistics.medicaid}</div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: seedTime * 3 }
                                                                        : {})}
                                                                >
                                                                    <div>{this.state.subsStatistics.medicare}</div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: seedTime * 4 }
                                                                        : {})}
                                                                >
                                                                    <div>{this.state.subsStatistics.exchange}</div>
                                                                </Grow>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td className={classes.widgetTxtBlkVsRBorder}>
                                                            Organizations
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/Commercial/all"
                                                            >
                                                                Commercial
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/Medicaid/all"
                                                            >
                                                                Medicaid
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/Medicare/all"
                                                            >
                                                                Medicare
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/Exchange/all"
                                                            >
                                                                Exchange
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    {this.state.isLoadingStatistic ? (
                                                        <tr>
                                                            <td className={classes.widgetNumVsRBorder}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Skeleton variant="circle" width={w} height={h} />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td className={classes.widgetNumVsRBorder}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: addTime }
                                                                        : {})}
                                                                >
                                                                    <div>
                                                                        {this.state.subsStatistics.submission}
                                                                    </div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: addTime + seedTime }
                                                                        : {})}
                                                                >
                                                                    <div>
                                                                        {this.state.subsStatistics.planLocked}
                                                                    </div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: addTime + seedTime * 2 }
                                                                        : {})}
                                                                >
                                                                    <div>
                                                                        {this.state.subsStatistics.auditLocked}
                                                                    </div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: addTime + seedTime * 3 }
                                                                        : {})}
                                                                >
                                                                    <div>
                                                                        {this.state.subsStatistics.pendingEsign}
                                                                    </div>
                                                                </Grow>
                                                            </td>
                                                            <td className={classes.widgetNum}>
                                                                <Grow
                                                                    in={!this.state.isLoadingStatistic}
                                                                    {...(!this.state.isLoadingStatistic
                                                                        ? { timeout: addTime + seedTime * 4 }
                                                                        : {})}
                                                                >
                                                                    <div>{this.state.subsStatistics.markFinal}</div>
                                                                </Grow>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td className={classes.rightBorder}>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage"
                                                            >
                                                                Submissions
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/all/Apply%20Plan%20Lock"
                                                            >
                                                                Need Plan Lock
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/all/Apply%20Audit%20Lock"
                                                            >
                                                                Need Audit Lock
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/all/Finalize%20Attestation"
                                                            >
                                                                Need Attestation
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className={classes.widgetTxt}
                                                                to="/availablesubmissionpage/all/Apply%20Mark%20Final"
                                                            >
                                                                Need Mark Final
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {shouldShowConfetti ?
                                                <div>
                                                    <ConfettiExplosion />
                                                </div>
                                                : <></>
                                            }
                                            {shouldShowDoneDialog ?
                                                <div>
                                                    <Alert severity="success">All submissions are Complete!</Alert>
                                                </div>
                                                : <></>
                                            }
                                        </div>
                                  
                                    </Fade>
                                </CardContent>
                            </Card>
                            <Divider className={classes.mb2} />
                            {!this.state.isLoadingPendingSubmissions ? (
                                <Fade in={!this.state.isLoadingPendingSubmissions}>
                                    {this.state.pendingSubmissions.length > 0 ?

                                        <MUIDataTable
                                            title={
                                                <Typography
                                                    className={
                                                        classes.primaryBlue +
                                                        " " +
                                                        classes.muiTableTitleMargin
                                                    }
                                                >
                                                    Pending Submissions
                                                </Typography>
                                            }
                                            data={this.state.pendingSubmissions}
                                            columns={pendingSubmssionsColumns}
                                            options={pendingSubmissionsListingOptions}
                                        /> : <UserMessage text={this.getMessageContent()} variant={MessageType.Success} />
                                    }
                                </Fade>
                            ) : (
                                <LoadingDataTable titleText={"Pending Submissions"} />
                            )}
                        </Grid>

                        <Grid
                            item={true}
                            xs={12}
                            className={classes.flexGrow + " " + classes.fullWidth}
                        >
                            <Card className={classes.card}>
                                <CardContent className={classes.dashboardCardContent}>
                                    <React.Fragment>
                                        <Typography
                                            className={classes.primaryBlue + " " + classes.cardTitle}
                                        >
                                            Recent Activity
                                            <IconButton
                                                aria-label="detail"
                                                color="primary"
                                                to="/activityreportpage"
                                                component={AdapterLink}
                                            >
                                                <FindInPageOutlinedIcon />
                                            </IconButton>
                                        </Typography>
                                        {!this.state.isLoadingEvents ? (
                                            <ul className={classes.recentEventsList}>
                                                {this.state.events.map((item, i) => (
                                                    <li key={"event" + i}>
                                                        <Typography variant="body2" color="textPrimary">
                                                            {item}
                                                        </Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <LoadingDataTable />
                                        )}
                                    </React.Fragment>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item={true} xs={4}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                    >
                        <Fade in={true} {...{ timeout: seedTime }}>
                            <Grid item={true} xs={12} className={classes.flexGrow}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.dashboardCardContent}>
                                        <Typography
                                            className={classes.primaryBlue + " " + classes.cardTitle}
                                        >
                                            Announcements
                                        </Typography>
                                        <Divider />
                                        {!this.state.isLoadingAnnouncements ? (
                                            this.state.isLoadingStatistic === null ||
                                                this.state.announcements.length === 0 ? (
                                                <div
                                                    className={classes.announcementContainer}
                                                    key={"noAnnouncements"}
                                                >
                                                    No Announcements at this time
                                                </div>
                                            ) : (
                                                <>
                                                    {this.state.announcements.map((item, i) => (
                                                        <React.Fragment key={i}>
                                                            <div
                                                                className={classes.announcementContainer}
                                                                key={"announcementContainer" + i}
                                                            >
                                                                {item.startDate && (
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        color="textSecondary"
                                                                    >
                                                                        {item.startDate}
                                                                    </Typography>
                                                                )}
                                                                <FroalaEditorView
                                                                    model={item.text}
                                                                    config={{ ...froalaBaseConfig }}
                                                                />
                                                            </div>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            )
                                        ) : (
                                            <Skeleton
                                                className={classes.skeleton}
                                                variant="rect"
                                                width={210}
                                                height={118}
                                            />
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Fade>
                        <Fade in={true} {...{ timeout: seedTime * 2 }}>
                            <Grid item={true} xs={12} className={classes.flexGrow}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.dashboardCardContent}>
                                        <Typography
                                            className={classes.primaryBlue + " " + classes.cardTitle}
                                        >
                                            Communications
                                        </Typography>
                                        <List component="nav">
                                            <Divider />

                                            {this.renderLink("Help/IDSS_MY2023_Release_and_Important_Information.pdf", "Release Letter", "")}
                                            <Divider />
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private renderLink = (href, primaryText, secondaryText) => {
        return (
            <ListItem alignItems='flex-start' button divider component="a" href={href} target="_blank">
                <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
        );
    }

    private getMessageContent() {
        return (
            <Typography variant='body2' color='inherit'>
                Congratulations! All your submissions are received!
            </Typography>
        );
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    private async RefreshModel() {
        this.setState({ isLoadingStatistic: true });
        //Announcements
        const retval1: IAnnouncementPayload[] = await AnnouncementsService.GetAnnouncements$(
            this.props.user
        ).toPromise();
        if (retval1 instanceof AjaxError || !retval1) {
            this.setRedirectState();
        } else {
            this.setState({
                announcements: retval1.map((i) => {
                    return {
                        text: i.text,
                        startDate: SharedUtility.ToLocaleDateString$(i.startDate),
                    };
                }),
                isLoadingAnnouncements: false,
            });
        }
        //Statistics
        const retval0 = await SubmissionsService.GetSubmissionsStatistics$(
            this.props.user
        ).toPromise();
        if (retval0 instanceof AjaxError || !retval0) {
            this.setRedirectState();
        } else {
            this.setState({ subsStatistics: retval0, isLoadingStatistic: false });
        }

        //bulk upload
        const retval2 = await SubmissionsService.GetUploadAvailabilities$(
            this.props.user
        ).toPromise();
        if (retval2 instanceof AjaxError || !retval2) {
            this.setRedirectState();
        } else {
            this.setState({ uploadavailabilities: retval2 });
        }

        //Pending Submissions
        const retval4: ISubmissionPayload[] = await SubmissionsService.GetPendingSubmissions$(
            this.props.user
        ).toPromise();
        if (retval4 instanceof AjaxError || !retval4) {
            this.setRedirectState();
        } else {
            this.setState({
                pendingSubmissions: retval4.map((item) => {
                    return [
                        item.orgId,
                        item.subId,
                        item.orgName,
                        item.component.replace("DST", "HEDIS"),
                        SharedUtility.GetStageCleanName(item.submissionStatus),
                        item.hasSubmissionAccess
                            ? item.subId + "/" + item.component + "/" + item.productLine
                            : "",
                    ];
                }),
                isLoadingPendingSubmissions: false,
            });
        }

        //events
        const retval3 = await EventsService.GetEventsStrings$(
            this.props.user
        ).toPromise();
        if (retval3 instanceof AjaxError || !retval3) {
            this.setRedirectState();
        } else {
            this.setState({
                events: retval3,
                isLoadingEvents: false,
            });
        }
    }
}

// REDUX CONTAINER
const DashboardViewWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(DashboardView);

// STYLES CONTAINER
const Dashboard = withStyles(styles)(DashboardViewWithState);

export default Dashboard;
