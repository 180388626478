import { User } from "oidc-client";
import { ajax, AjaxError, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs";
import { IAnnouncementPayload } from "../../DataClasses/AnnouncementData";
import AjaxHelper from "./AjaxHelper";

class AnnouncementsService {
    public static GetAnnouncements$(user: User): Observable<IAnnouncementPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Announcements/GetAnnouncements',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static GetAnnouncementsDetail$(user: User): Observable<IAnnouncementPayload[]> {
        return AjaxHelper.AjaxGet$(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Announcements/GetAnnouncementsDetail',
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static SaveAnnoucement$(user: User, id: number, displayOrder: string, startDate: string, stopDate: string, text: string): Observable<AjaxResponse<any> | AjaxError> {
        return ajax.post(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Announcements/SaveAnnouncement',
            {
                id: id,
                displayOrder: displayOrder,
                startDate: startDate,
                stopDate: stopDate,
                text: text
            },
            AjaxHelper.CreateHttpHeaders(user)
        )
    }

    public static DeleteAnnouncement$(user: User, id: number): Observable<AjaxResponse<any> | AjaxError> {
        return AjaxHelper.AjaxPost(
            process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + '/api/Announcements/DeleteAnnouncement?id=' + id,
            {},
            AjaxHelper.CreateHttpHeaders(user)
        )
    }
}

export default AnnouncementsService;