import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IGenericUserProps, genericMapStateToProps } from "../../../../DataClasses/GenericUser";
import { connect } from "react-redux";
import {
    Grid, Button, Stepper, Typography, Step, StepLabel, CardContent, Card, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import idssStyle from "../../../../Configuration/SharedStyling";
import { Check as CheckIcon, Edit as EditIcon, CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import { ISubmissionAttestationReportingFlags } from '../../../../DataClasses/SubmissionData';
import SubmissionsService from '../../../Services/SubmissionsService';
import { AjaxError } from "rxjs/ajax";
import PDFViewer from 'pdf-viewer-reactjs'
import saveAs from 'file-saver';
import SharedUtility from "../../../../Utilities/SharedUtility";
import { SlideProps } from '@material-ui/core/Slide';
import Slide from '@material-ui/core/Slide';
import UserMessage from "../../../Shared/Message";
import { MessageType } from "../../../../DataClasses/SharedData";
import ConfettiExplosion from 'react-confetti-explosion';

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    optionButton: {
        width: "50%"
    },
    optionCard: {
        textAlign: "center",
        alignItems: "center",
        "& p": {
            textAlign: "left",
            padding: theme.spacing(2, 1)
        }
    },
    progress: {
        verticalAlign: 'middle',
        paddingLeft: '5px'
    }
});

type IStylesProps = WithStyles<typeof styles>;

interface ILocalProps {
    submissionId: number,
    component: string,
    title: string,
    description: string,
    isPublicReporting?: boolean,
    canManagePublicReporting: boolean,
    canEsignAttestation: boolean,
    isYesAllowed: boolean,
    isLtss: boolean,
    onComplete: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    onCancel: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

interface ILocalState {
    activeStep: number,
    isPublicReporting?: boolean,
    isSaving: boolean,
    encodedPreviewPDF: string,
    encodedSignedPDF: string,
    isDownloadingDocument: boolean,
    attestationModifiedDate?: Date,
    isEsignInProgress: boolean,
    isLoading: boolean,
    isExploding: boolean,
    lastClickedTime: number,
    isGeneratingPdf: boolean,
    showEsignDialog: boolean,
    publicReportingDialogOpen: boolean
}


class AttestationStepsView extends React.Component<IGenericUserProps & IStylesProps & ILocalProps, ILocalState> {
    constructor(props: IGenericUserProps & IStylesProps & ILocalProps) {
        super(props);

        this.state = {
            activeStep: 0,
            isPublicReporting: this.props.isPublicReporting,
            isSaving: false,
            encodedPreviewPDF: null,
            encodedSignedPDF: null,
            isDownloadingDocument: false,
            isEsignInProgress: false,
            isLoading: false,
            isExploding: false,
            lastClickedTime: new Date().getTime(),
            isGeneratingPdf: false,
            showEsignDialog: false,
            publicReportingDialogOpen: false
        };
    }

    public async componentDidMount() {
        await this.RefreshModel();
    }

    public render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        return (<div className={classes.root}>
            <Stepper activeStep={this.state.activeStep} alternativeLabel >
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {this.state.encodedSignedPDF ? (
                    <Card elevation={4}>
                        <CardContent>
                            {/*<Typography className={classes.instructions} color="primary" variant="h6">*/}
                            {/*    <CheckIcon />*/}
                            {/*    Signature Applied*/}
                            {/*</Typography>*/}
                            {this.state.isExploding && <ConfettiExplosion />}

                            <div className={classes.instructions}>
                                <UserMessage text={this.getUserInfoMessage()} variant={MessageType.Warning} />
                            </div>

                            <Button onClick={this.downloadSignedDocument} disabled={!this.state.encodedSignedPDF} startIcon={<CloudDownloadIcon />}>Download Document</Button> {'  '}
                            <Button onClick={this.handleFinish} disabled={!this.state.encodedSignedPDF} startIcon={<CheckIcon />}>Finish</Button>
                        </CardContent>
                    </Card>
                ) : (
                    <div>
                        <Typography className={classes.instructions}>{this.getStepContent(this.state.activeStep)}</Typography>
                        <div>
                            <Button
                                disabled={this.state.activeStep === 0 || this.state.isGeneratingPdf}
                                onClick={this.handleBack}
                                className={classes.backButton}
                            >
                                Back
                            </Button>
                            {this.state.activeStep === this.REVIEW_AND_SIGN_STEP ? (
                                <Tooltip title={(this.isCurrentStepInValid() || !this.props.canEsignAttestation) ? "Only available for Signer" : ""}>
                                    <span>
                                        <Button onClick={this.downloadPreviewDocument} disabled={!this.state.encodedPreviewPDF} startIcon={<CloudDownloadIcon />}>Download Draft Document</Button> {'  '}


                                        <Button
                                            onClick={this.showEsignDialog.bind(this)}
                                            color="primary"
                                            variant="contained"
                                            startIcon={<EditIcon />}
                                            disabled={(this.isCurrentStepInValid() || !this.props.canEsignAttestation)}
                                        >
                                            Sign
                                        </Button>
                                        <Dialog
                                            open={this.state.showEsignDialog}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            TransitionComponent={Transition}
                                        >
                                            <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>Esign Confirmation</Typography></DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <Typography className={classes.secondaryText}>
                                                        By clicking "SIGN", you agree and consent:<br></br>
                                                        <ul style={{ listStyleType: "lower-roman" }}>
                                                            <li>To contract electronically with NCQA as related to the Attestation; </li>
                                                            <li>You are duly authorized to enter into and bind your organization, as applicable, to the Attestation; and </li>
                                                            <li>That by clicking “SIGN,” you and/or your organization agree to be bound by the Attestation.</li>
                                                        </ul>
                                                    </Typography>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={this.hideEsignDialog.bind(this)} className={classes.disabledButton}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={() => this.handleSign()} autoFocus disabled={this.state.isEsignInProgress}>
                                                    Sign
                                                </Button>
                                                {this.state.isEsignInProgress ? <span className={classes.progress}><CircularProgress color="primary" size={22} /></span> : null}
                                            </DialogActions>
                                        </Dialog>
                                    </span>
                                </Tooltip>)
                                : this.state.activeStep === this.PUBLIC_REPORTING_STEP ? (
                                    <span>
                                    <Button variant="contained" color="primary" onClick={() => this.props.isYesAllowed === true ? this.setState({publicReportingDialogOpen: true}): this.handleNext(this.REVIEW_AND_SIGN_STEP)} 
                                        disabled={!this.props.canManagePublicReporting || this.state.isGeneratingPdf || !this.isEnableNext()}
                                        className={classes.button}>
                                        Next
                                    </Button>
                                    <Dialog
                                        open={this.state.publicReportingDialogOpen}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>Public Reporting Confirmation</Typography></DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <Typography className={classes.secondaryText}>
                                                    You have selected {this.getPublicsReportingDisplay()} to Public Reporting in HPR and QC. Are you sure? <br/>
                                                    Please read the public reporting option details carefully. After the submission deadline, no corrections can be made. 
                                                </Typography>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => this.cancelPublicReporting()} className={classes.disabledButton}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => this.handleNext(this.REVIEW_AND_SIGN_STEP)} autoFocus disabled={this.state.isEsignInProgress}>
                                                Save
                                            </Button>
                                            {this.state.isEsignInProgress ? <span className={classes.progress}><CircularProgress color="primary" size={22} /></span> : null}
                                        </DialogActions>
                                    </Dialog>
                                    </span>)
                                    : ((!this.props.canManagePublicReporting || this.state.isGeneratingPdf) && !this.state.isGeneratingPdf) ?
                                        <Typography color="secondary" variant="h6">
                                            You must select or reselect your option to proceed
                                        </Typography> : null
                            }
                            {this.state.isGeneratingPdf ? <span className={classes.progress}><CircularProgress color="primary" size={22} /></span> : null}

                        </div>
                    </div>
                )}
            </div>
        </div>);
    }

    private async RefreshModel() {

    }

    private showEsignDialog() {
        this.setState({ showEsignDialog: true });
    }

    private hideEsignDialog() {
        this.setState({ showEsignDialog: false });
    }

    private PUBLIC_REPORTING_STEP: number = 0;
    private REVIEW_AND_SIGN_STEP: number = 1;
    private getSteps() {
        return ['Public Reporting', 'Review and Sign'];
    }

    private getStepContent(stepIndex: number) {
        switch (stepIndex) {
            case this.PUBLIC_REPORTING_STEP:
                return this.getPublicReportingStep();
            case this.REVIEW_AND_SIGN_STEP:
                return this.getReviewAndSignStep();
            default:
                return 'Unknown stepIndex';
        }
    }

    private getUserInfoMessage = () => {
        return (
            <Typography variant='body2' color='inherit'>
                Your signature has been applied. Click "FINISH" to complete the attestation process and finalize the submission. You may download your signed document now, or come back later to download it from the All Downloads tab.
            </Typography>
        );
    }

    private getPublicReportingStep = () => {
        const { classes } = this.props;
        let noText = "No"; //It's about the display of the Yes option, however, the text defaults to No for this case.
        if (!this.props.isYesAllowed) {
            noText = "Acknowledge";
        }
        return (
                <Grid key="publicReportingStep" container={true} spacing={2}>
                    {this.props.isYesAllowed ?
                        <Grid key="yesOption" item={true} xs={6} className={classes.gridDisplay}>
                            <Card className={classes.optionCard} elevation={4}>
                                <CardContent>
                                    <Button
                                        onClick={() => this.handlePublicReportingOption(true)}
                                        color={this.state.isPublicReporting ? "primary" : "default"}
                                        variant="contained"
                                        className={classes.button + " " + classes.optionButton}
                                        startIcon={this.state.isPublicReporting ? <CheckIcon /> : null}
                                        disabled={!this.props.canManagePublicReporting || !this.props.isYesAllowed}
                                    >
                                        Yes
                                    </Button>
                                    <Typography variant='body2' color='textPrimary'>
                                        I agree that the Data, including Organization or product identifying data, <strong>will</strong> be publicly reported by NCQA and <strong>will</strong> be included in NCQA published products and reports <strong>(e.g., <em>Quality Compass®, NCQA’s Health Plan Report Card, NCQA’s Health Plan Ratings, or similar products</em>)</strong> and the publications of licensees of NCQA. NCQA and its licensees may also report and include the Data as part of roll-up or composite results and reporting categories which may include assigning designations or summary conclusions to Organization's performance based on the Data. Organization acknowledges that NCQA may also choose, at its option, not to publicly report certain Data.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        : null}
                    <Grid key="noOption" item={true} xs={6} className={classes.gridDisplay}>
                        <Card className={classes.optionCard} elevation={4}>
                            <CardContent>
                                <Button
                                    onClick={() => this.handlePublicReportingOption(false)}
                                    color={this.state.isPublicReporting === false ? "primary" : "default"}
                                    startIcon={this.state.isPublicReporting === false ? <CheckIcon /> : null}
                                    variant="contained"
                                    className={classes.button + " " + classes.optionButton}
                                    disabled={!this.props.canManagePublicReporting}
                                >
                                    {noText}
                                </Button>
                                {this.props.isYesAllowed ?
                                    <Typography variant='body2' color='textPrimary'>
                                        Except as otherwise provided in this ATTESTATION and in an Accreditation contract with NCQA, the Data will not be included in NCQA published products and reports <strong>(e.g., <em>Quality Compass®, NCQA’s Health Plan Report Card, NCQA’s Health Plan Ratings, or similar products</em>)</strong>. I understand that by indicating that I <strong>do not</strong> wish to publicly report the Data, the Organization will be rated by NCQA in its annual rating of health plans at the bottom of the list under the heading of health plans that did not provide HEDIS data or did not permit their HEDIS data to be made public. I understand that NCQA may include the Data in published aggregate statistics and benchmarks without my further permission.
                                    </Typography> :
                                    !this.props.isLtss ?
                                        <Typography variant='body2' color='textPrimary'>
                                            You also acknowledge and agree that NCQA may use and include the Data (to include plan-specific identifiers) in published products and reports <strong>(e.g., NCQA’s <em>Quality Compass, NCQA’s Health Plan Report Card, NCQA’s Health Plan Ratings, or similar products</em>)</strong> if the Data (to include plan-specific identifiers) is made publicly available by a third party with the right to publish said Data (to include plan-specific identifiers).
                                        </Typography> :
                                        <Typography variant='body2' color='textPrimary'>
                                            You acknowledge and agree that NCQA may: (1) use the Data for research purposes, so long as all plan-specific identifiers are removed in publications; (2) release the Data to third parties for research, so long as such third parties have entered into a confidentiality agreement with NCQA and all plan-specific identifiers are removed in publications; (3) include/use the aggregate statistics and benchmarks of the Data in NCQA published products and reports <strong>(e.g., NCQA’s <em>Quality Compass, NCQA’s Health Plan Report Card, NCQA’s Health Plan Ratings, or similar products</em>)</strong> and (4) be used for scoring organizations seeking NCQA’s LTSS Accreditation or Distinction programs that voluntarily choose to submit performance results on the HEDIS LTSS Measures instead of providing a documented process or completing file review for certain requirements.
                                        </Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

        );
    }

    private getReviewAndSignStep = () => {
        const { classes } = this.props;
        if (SharedUtility.IsNullOrUndefined$(this.state.encodedPreviewPDF)) {
            return (
                <Card className={classes.optionCard} elevation={4}>
                    <CardContent>
                        <CircularProgress color="secondary" size={20} />
                    </CardContent>
                </Card>
            )
        }
        return (
            <Card className={classes.optionCard} elevation={4}>
                <CardContent>
                    <PDFViewer
                        document={{
                            base64: this.state.encodedPreviewPDF
                        }}

                    />
                </CardContent>
            </Card>
        );
    }

    private handlePublicReportingOption = (value: boolean) => {
        this.setState({ isPublicReporting: value });
    };

    private handleSave = async () => {
        const attestationFlags: ISubmissionAttestationReportingFlags = {
            submissionId: this.props.submissionId,
            component: this.props.component,
            isPublicReported: this.state.isPublicReporting,
            sourceLastModified: this.state.attestationModifiedDate
        };

        const updateAttestationResult = await SubmissionsService.UpdateSubmissionAttestationReportingFlags$(this.props.user,
            attestationFlags).toPromise();

        if (updateAttestationResult instanceof AjaxError) {
            throw (new Error("Ajax Error"));
        }
    }

    private handleSign = async () => {
        this.setState({ isEsignInProgress: true });

        const signDocResult = await SubmissionsService.SignPDF$(this.props.user, this.props.submissionId, this.props.component).toPromise();
        if (signDocResult) {
            this.setState({
                encodedSignedPDF: signDocResult.response.toString()
            });
            // await this.handleSave();  This doesn't seem to be needed, it happens at an earlier step, and may be broken..  Keeping it as a comment for now.
            this.setState({ activeStep: 3 });
            this.setState({ isEsignInProgress: false });
        }
    };

    private isEnableNext(){
        if(this.props.isYesAllowed) {
            return this.state.isPublicReporting !== undefined && this.state.isPublicReporting !== null;
        }
        return this.state.isPublicReporting === false;
    }
    private cancelPublicReporting = () => {
        this.setState({isPublicReporting: undefined, publicReportingDialogOpen: false});
    }

    private getPublicsReportingDisplay =() => {
        if (this.state.isPublicReporting === undefined || this.state.isPublicReporting === null){
            return "NOTHING";
        }
        if(this.state.isPublicReporting === true){
            return "YES";
        }
        else{
            return "NO";
        }
    }

    private handleNext = async (targetStep: number) => {
        this.setState({publicReportingDialogOpen: false});
        await this.handleSave();

        if (targetStep === this.REVIEW_AND_SIGN_STEP) {
            this.setState({ isGeneratingPdf: true });
            const retval = await SubmissionsService.GetESignPDF$(this.props.user, this.props.submissionId, this.props.component).toPromise();

            this.setState({
                isGeneratingPdf: false,
                activeStep: targetStep,
                encodedPreviewPDF: retval
            });
        }
    };

    private handleBack = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (this.state.activeStep === 0)
            this.props.onCancel(event);
        else {
            this.setState({ activeStep: this.state.activeStep - 1 });
        }
    };

    private  handleFinish = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        this.setState({ isExploding: true });
        this.sleep(10000);
        this.props.onComplete(event);
    };

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private isCurrentStepInValid = (): boolean => {
        switch (this.state.activeStep) {
            case this.PUBLIC_REPORTING_STEP:
                return SharedUtility.IsNullOrUndefined$(this.state.isPublicReporting);
            case this.REVIEW_AND_SIGN_STEP:
                return SharedUtility.IsNullOrUndefined$(this.state.encodedPreviewPDF);
            default:
                return false;
        }
    }

    private downloadSignedDocument = async (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        this.setState({ isDownloadingDocument: true });

        try {
            if (this.state.encodedSignedPDF) {
                const data = this.str2ab(atob(this.state.encodedSignedPDF));
                saveAs(new Blob([data], { type: "application/pdf" }), this.props.submissionId + "_HEDIS_Signed.pdf");
            }
        }
        catch (ex) {
            console.log(ex);
        }
        finally {
            this.setState({ isDownloadingDocument: false });
        }
    }

    private downloadPreviewDocument = async (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        this.setState({ isDownloadingDocument: true });

        try {
            if (this.state.encodedPreviewPDF) {
                const data = this.str2ab(atob(this.state.encodedPreviewPDF));
                saveAs(new Blob([data], { type: "application/pdf" }), this.props.submissionId + "_HEDIS_Draft.pdf");
            }
        }
        catch (ex) {
            console.log(ex);
        }
        finally {
            this.setState({ isDownloadingDocument: false });
        }
    }

    private str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }
}

// REDUX CONTAINER
const AttestationStepsWithState = connect<IGenericUserProps>(genericMapStateToProps)(AttestationStepsView);

// STYLES CONTAINER
const AttestationSteps = withStyles(styles)(AttestationStepsWithState);

// REDUX CONTAINER
export default AttestationSteps;