import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { connect } from "react-redux";
import { getAppInsights } from "../../Logging/TelemetryService";

interface IErrorBoundryStateProps {
    hasError: boolean
} 

const mapStateToProps = (): IErrorBoundryStateProps => ({
    hasError: false
});

const styles = (theme: Theme) => createStyles({
    flex: {
        flexGrow: 1,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justify: 'center',
        background: 'white',
    },
})

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class ErrorBoundaryView extends React.Component<IStylesProps, IErrorBoundryStateProps> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });

        const appInsights = getAppInsights();
        if (appInsights)
            appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }

    componentWillUnmount() {
        this.setState({ hasError: false });
    }
}

// REDUX CONTAINER
const ErrorBoundaryViewState = connect<IErrorBoundryStateProps>(mapStateToProps)(ErrorBoundaryView);

// STYLES CONTAINER
const ErrorBoundary = withStyles(styles)(ErrorBoundaryViewState);

// REDUX CONTAINER
export default ErrorBoundary;