import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { genericMapStateToProps, IGenericUserProps } from "../../DataClasses/GenericUser";
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid } from "@material-ui/core";
import BulkUploadDropzone from './BulkUploadDropzone';
import idssStyle from "../../Configuration/SharedStyling";
import { SubmissionType } from '../../DataClasses/SharedData';
import UserMessage from '../Shared/Message';
import { MessageType } from '../../DataClasses/SharedData';

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        paddingRight: theme.spacing(2)
    }
});

type IStylesProps = WithStyles<typeof styles>;

class BulkUploadView extends React.Component<IGenericUserProps & IStylesProps & RouteComponentProps<{ uploadType: SubmissionType }>> {
    public render() {
        const { classes } = this.props;
        const uploaderType: SubmissionType = this.props.match.params.uploadType;

        return (
            <div className={classes.root}>
                <Grid container={true} spacing={2} alignItems="flex-start" alignContent="flex-start">
                    <Grid item={true} xs={12}>
                        <Typography variant='h4' color="inherit">
                            {this.getHeaderText(uploaderType)}
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        {this.renderByType(uploaderType)}
                    </Grid>
                </Grid>
            </div>
        );
    }

    public renderByType(uploaderType: SubmissionType) {
        const fileTypes: string[] = this.getSupportedFilesForTypes(uploaderType);
        return (
            <>
                <UserMessage text={this.getMessageContent(fileTypes[0])} variant={MessageType.Warning} />
                <BulkUploadDropzone user={this.props.user} uploadFileType={uploaderType} allowMultiple={true} acceptFilesOfType={fileTypes.join(',')} />
            </>
        );
    }
    private getMessageContent(primaryFileType: string) {
        return (
            <Typography variant='body2' color='inherit'>
                You may upload one or more submissions here at the same time. Up to 50 maximum. <br />
                All submissions must be .xml, Files can be bundled in a single zip file. The zip file can also contain nested regular folders containing xml files.<br />
                All zip/nested folders can be named as desired.<br />
                {primaryFileType === ".xml" ? "Submission filenames must be of the format <<Productline>><<SubmissionId>>.xml; For ex. Commercial1234.xml" : ""}
            </Typography>
        );
    }

    private getSupportedFilesForTypes(uploaderType: SubmissionType): string[] {
        switch (uploaderType) {
            case SubmissionType.HEDIS:
                return ['.xml', '.zip'];
            case SubmissionType.HOS:
                return ['.txt', '.zip'];
            case SubmissionType.CAHPS:
                return ['.csv', '.zip'];
            default:
                return [];
        }
    }

    private getHeaderText(uploaderType: SubmissionType): string {
        switch (uploaderType) {
            case SubmissionType.HEDIS:
                return 'HEDIS File Upload';
            case SubmissionType.HOS:
                return 'HOS File Upload';
            case SubmissionType.CAHPS:
                return 'CAHPS File Upload';
            default:
                return '';
        }
    }
}

// REDUX CONTAINER
const BulkUploadPageState = connect<IGenericUserProps>(genericMapStateToProps)(BulkUploadView);

// STYLES CONTAINER
const BulkUploadPage = withStyles(styles)(BulkUploadPageState);

export default BulkUploadPage;
