import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import LoadingDataTable from "../../../Shared/LoadingDataTable";
import { IGenericUserProps, genericMapStateToProps } from "../../../../DataClasses/GenericUser";
import { connect } from "react-redux";
import SubmissionsService from "../../../Services/SubmissionsService";
import { AjaxError } from "rxjs/ajax";
import { IWrappedMeasureCategories, IMeasure, IIndicatorRateData, IMeasureCodesForFilter, IMeasureViewerPayload } from "../../../../DataClasses/MeasureViewerData";
import { Paper, Typography, Button, Fab, Select, MenuItem, Fade, CircularProgress, Grid, Link, FormControl, Tooltip } from "@material-ui/core";
import idssStyle from "../../../../Configuration/SharedStyling";
import LiveMeasureDisplayPage from "./SubmissionHome.LiveMeasureDisplay";
import { ArrowBack as ArrowBackIcon} from '@material-ui/icons';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { EXCHANGE, MEDICAID, MEDICARE, LTSS } from "../../../Shared/Constants";
import { IArtRow } from "../../../../DataClasses/ArtDefault";
import { SeedTime } from "../../../../Configuration/Setting";
import { Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton/IconButton";
import saveAs from 'file-saver';
import SharedUtility from "../../../../Utilities/SharedUtility";
import UserMessage from "../../../Shared/Message";
import { MessageType } from "../../../../DataClasses/SharedData";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    artTabRoot: {
        flexGrow: 1,
        padding: theme.spacing(1, 2)
    },
    measureTable: {
        border: '1px solid #eeeeee',
        borderTop: 'none',
        boxShadow: '0px 1px 1px 1px #eeeeee',
        borderCollapse: 'collapse',
        borderSpacing: '0',
        '& tbody tr': {
            borderBottom: '1px solid #eeeeee',
        },
        '& tbody td': {
            verticalAlign: 'middle'
        },
        '& thead tr': {
            borderBottom: '1px solid #eeeeee',
        },
        '& thead th': {
            color: '#4f758b',
            textAlign: 'left',
            padding: theme.spacing(2),
            fontSize: '1.1em'
        },
        '& thead th:nth-child(1)': {
            width: '40%'
        },
        '& thead th:nth-child(2)': {
            width: '20%',
            textAlign: 'center'
        },
        '& thead th:nth-child(3)': {
            paddingLeft: theme.spacing(1)
        },
        '& thead th:nth-child(4)': {
            textAlign: 'center'
        },

    },
    measureLink: {
        color: '#0099cc',
        textTransform: 'inherit',
        textAlign: 'left',
        '&:hover': {
            color: '#0099cc'
        }
    },
    rateCell: {
        backgroundColor: '#eeaf30',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(1),
    },
    rateCellGray: {
        backgroundColor: 'gray',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(1),
    },
    benefitCell: {
        textAlign: 'center'
    },
    auditDesCell: {
        textAlign: 'center'
    },
    categoryRow: {
        backgroundColor: '#eeeeee',
        color: '#4f758b',
        fontSize: '1.1em',
        fontWeight: 'bold',
        '& td': {
            padding: theme.spacing(1, 2)
        }
    },
    measureCode: {
        color: 'black',
        textTransform: 'uppercase'
    },
    measureRowTitleCell: {
        paddingLeft: theme.spacing(1)
    },
    indicatorRowTitleCell: {
        padding: theme.spacing(1, 1, 1, 4),
    },
    tableActionsContainer: {
        float: "right",
        textAlign: "right",
        padding: theme.spacing(1, 2)
    },
    iconMargin: {
        margin: theme.spacing(1)

    }
});

type IStylesProps = WithStyles<typeof styles>;

interface ILocalProps {
    subId: string,
    productLine: string,
    component: string,
    specialProject: string,
    isReadOnly: boolean,
    canViewResultsReportsHedis: boolean,
    isAuditRequired: boolean
}

interface ILocalState {
    isLoading: boolean,
    origData: IWrappedMeasureCategories,
    data: IWrappedMeasureCategories,
    hasChanges: boolean,
    isSaving: boolean,
    measurementYear: number,
    selectedDisplayMeasure: IMeasure,
    redirect: boolean,
    origMeasures: IMeasure[],
    measures: IMeasure[],
    previousMeasure: string,
    nextMeasure: string,
    isDownloadingMeasureData: boolean,
    selectedFilterVal: string,
    measureCodesForFilter: IMeasureCodesForFilter,
    selectedAuditFilterVal: string
}

class AuditReviewToolView extends React.Component<IGenericUserProps & IStylesProps & ILocalProps, ILocalState> {

    private commentOptions = [
        { value: 'BR', label: 'Biased Rate' },
        { value: 'NB', label: 'No Benefit' },
        { value: 'NQ', label: 'Not Required' },
        { value: 'NR', label: 'Not Reported' },
        { value: 'R', label: 'Reported' }
    ];

    private auditDesignationOptions = [
        { value: 'ALL', label: 'ALL' },
        { value: 'BR', label: 'Biased Rate' },
        { value: 'NQ', label: 'Not Required' },
        { value: 'NR', label: 'Not Reported' },
        { value: 'R', label: 'Reported' },
    ];

    private readOnlyAuditDesignations: string[] = ["R"];
    private ecdsCategory = 'Electronic Clinical Data Systems';

    constructor(props: IGenericUserProps & IStylesProps & ILocalProps) {
        super(props);

        this.state = {
            isLoading: true,
            origData: null,
            data: null,
            hasChanges: false,
            isSaving: false,
            measurementYear: parseInt(process.env.REACT_APP_GENERAL_MEASUREYEAR),
            selectedDisplayMeasure: null,
            redirect: false,
            origMeasures: [],
            measures: [],
            previousMeasure: '',
            nextMeasure: '',
            isDownloadingMeasureData: false,
            selectedFilterVal: 'ALL',
            measureCodesForFilter: null,
            selectedAuditFilterVal: 'ALL'
        };
    }

    public async componentDidMount() {
        await this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    private isFilteringAll() {
        return this.state.selectedFilterVal.toUpperCase() === 'ALL' && this.state.selectedAuditFilterVal.toUpperCase() === 'ALL';
    }

    private getUserInfoMessage = () => {
        return (
            <Typography variant='body2' color='inherit'>
                Must set filter to ALL to save Audit Designation / Comment changes
            </Typography>
        );
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;
        if (this.state.isLoading) {
            return <LoadingDataTable titleText={"*"} />;
        }
        else if (this.state.selectedDisplayMeasure) {
            return (
                <Fade in={true} {...({ timeout: SeedTime })}>
                    <Paper className={classes.artTabRoot}>
                        <Button onClick={() => this.ClearDisplayMeasure()}>
                            <ArrowBackIcon /> Back to {(this.props.isAuditRequired ? "Audit Review Table" : "Measure Review Table")}
                        </Button>
                        {this.state.measures && this.state.selectedDisplayMeasure ?
                            <>
                                <Grid container={true} className={classes.measureHeader}>
                                    <Grid item={true} xs={1} className={classes.leftMeasure}>
                                        <Link onClick={() => this.setSelectedMeasure(this.state.previousMeasure)}>
                                            <ArrowBackIosIcon className={classes.arrow} /><span style={{ marginLeft: '-6px' }}>{this.state.previousMeasure}</span>
                                        </Link>
                                    </Grid>
                                    <Grid item={true} xs={9}>
                                        <FormControl style={{ maxWidth: 700 }}>
                                            <Select
                                                name="measures"
                                                value={this.state.selectedDisplayMeasure.measureCode}
                                                fullWidth={true}
                                                onChange={ev => this.handleMeasureChange(ev)}>
                                                {this.state.measures.map((measure, index) => {
                                                    return (
                                                        <MenuItem key={measure.measureCode} value={measure.measureCode}>
                                                            {measure.hedisVol2MeasureName} ({measure.measureCode})
                                                        </MenuItem>
                                                    );
                                                })};
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={1}>
                                        <Tooltip title="Export To Excel">
                                            <IconButton aria-label="Download" className={classes.iconMargin} onClick={() => this.downloadMeasureData()}>
                                                <CloudDownloadOutlinedIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item={true} xs={1} className={classes.rightMeasure}>
                                        <Link onClick={() => this.setSelectedMeasure(this.state.nextMeasure)}>
                                            <span style={{ marginRight: '-2px' }}>{this.state.nextMeasure}</span><ArrowForwardIosIcon className={classes.arrow} />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </> : null}
                        <LiveMeasureDisplayPage
                            submissionId={this.props.subId}
                            measureCode={this.state.selectedDisplayMeasure.measureCode}
                            measureName={this.state.selectedDisplayMeasure.hedisVol2MeasureName}
                            measurementYear={this.state.measurementYear}
                            productLine={this.props.productLine}
                        />
                    </Paper>
                </Fade>
            );
        }
        else {
            const { data } = this.state;
            if (data != null) {
                return (
                    <Fade in={true} {...({ timeout: SeedTime })}>
                        <Paper className={classes.artTabRoot}>
                            <table className={classes.measureTable}>
                                <thead>
                                    
                                    <tr>
                                        { this.props.productLine !== "Exchange" ?
                                            <th style={{ whiteSpace: "nowrap" }}>
                                                <span color='primary' className={classes.tabTitle}>
                                                    Filter:
                                                </span> &nbsp;      
                                                <Select
                                                    name="filter"
                                                    value={this.state.selectedFilterVal}
                                                    onChange={ev => this.handleFilterChange(ev)}
                                                >
                                                    {this.getFilterOptions().map(x => {
                                                        return (
                                                            <MenuItem key={x} value={x}>
                                                                {x}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                <span color='primary' className={classes.tabTitle}>
                                                    Audit Designation:
                                                </span> &nbsp;    
                                                <Select
                                                    name="auditFilter"
                                                    value={this.state.selectedAuditFilterVal}
                                                    onChange={ev => this.handleAuditDesignationFilterChange(ev)}
                                                >
                                                    {this.getAuditDesignationFilterOptions().map(x => {
                                                        return (
                                                            <MenuItem key={x.value} value={x.value}>
                                                                {x.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            <br/><br/><span className={classes.measureHeader}>* Indicates HPR measure</span>
                                        </th>
                                        : null}
                                            <th>
                                            <Tooltip title="Export filtered ART To Excel and csv">
                                                <IconButton aria-label="Download" className={classes.iconMargin} onClick={() => this.downloadFilteredArt()} disabled={this.state.isDownloadingMeasureData}>
                                                    <CloudDownloadOutlinedIcon fontSize="large" />
                                                </IconButton>
                                            </Tooltip>
                                            </th>
                                            <th></th>
                                            <th></th>
                                            <th className={classes.tableActionsContainer}>
                                            {
                                                !this.props.isReadOnly && ((this.isFilteringAll() &&
                                                <div>
                                                <Fab className={classes.squareFab} variant="extended" color="secondary" onClick={() => this.SaveModel()} disabled={!this.state.hasChanges || this.state.isSaving}>
                                                    Save
                                                </Fab>
                                                {this.state.isSaving ?
                                                    <Fade in={this.state.isSaving}
                                                        style={{ transitionDelay: "800ms" }}
                                                        unmountOnExit={true}>
                                                        <CircularProgress color="secondary" size={20} />
                                                    </Fade> : null
                                                }
                                                </div>)
                                                ||
                                                <UserMessage text={this.getUserInfoMessage()} variant={MessageType.Information} />
                                                )
                                            }
                                            </th>
                                    </tr>
                                    <tr>
                                        <th>Measure/Data Element</th>
                                        <th>Benefit Offered</th>
                                        <th>Rate</th>
                                        <th>Status</th>
                                        <th>Audit Designation / Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.categories.map((c, cIndex) => {
                                        let isEcdsCategory = (c.categoryName === this.ecdsCategory);
                                        return (
                                            <React.Fragment key={cIndex}>
                                                {this.renderCategoryRow(c.categoryName)}
                                                <React.Fragment>
                                                    {c.measures.map((m, mIndex) => {
                                                        return (
                                                            <React.Fragment key={mIndex}>
                                                                {this.renderMeasureRow(m, isEcdsCategory, cIndex, mIndex)}
                                                                {m.indicators.length > 1 ? m.indicators.map((i, iIndex) => {
                                                                    return this.renderIndicatorRow(m.measureCode, isEcdsCategory, i, cIndex, mIndex, iIndex);
                                                                }) : null}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                                {!this.props.isReadOnly && this.isFilteringAll() && <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className={classes.tableActionsContainer}>
                                            <Fab className={classes.squareFab} variant="extended" color="secondary" onClick={() => this.SaveModel()} disabled={!this.state.hasChanges || this.state.isSaving}>
                                                Save
                                            </Fab>
                                            {this.state.isSaving ?
                                                <Fade in={this.state.isSaving}
                                                    style={{ transitionDelay: "800ms" }}
                                                    unmountOnExit={true}>
                                                    <CircularProgress color="secondary" size={20} />
                                                </Fade> : null
                                            }
                                        </th>
                                    </tr>
                                </tfoot>
                                }
                            </table>
                        </Paper>
                    </Fade>
                );
            }
        }
    }

    private handleFilterChange (event: React.ChangeEvent<{ name?: string, value: any }>){
        let filterVal = event.target.value;

        this.getFilteredData(filterVal, this.state.selectedAuditFilterVal);
    }

    // PBI 177031 - Modified existing filter code to work with the new audit designation drop down.
    private getFilteredData(filterVal: string, auditFilterVal: string){
        let curData: IWrappedMeasureCategories = { ...this.state.origData }; // cover case: ALL
        let curMeasures: IMeasure[] = [];
        let cats: IMeasureViewerPayload[] = [];
        let auditcats: IMeasureViewerPayload[] = [];
        let measureCodes: string[] = [];
        switch(filterVal.toUpperCase()){
            case "HPR":
                measureCodes = this.state.measureCodesForFilter.hpr;
                break;
            case "PBP":
                measureCodes = this.state.measureCodesForFilter.cmspbp;
                break;
            case "CMS":
                measureCodes = this.state.measureCodesForFilter.cmsma;
                break;
            default:
                break;
        }

        // filter by measures if selected
        if (measureCodes.length > 0) {
            this.state.origData.categories.forEach((cat, i) => {
                let curCat = { ...cat };
                let measures = cat.measures.filter(x => measureCodes.includes(x.measureCode.toUpperCase()));
                if (measures.length > 0) {
                    curCat.measures = measures;
                    cats.push(curCat);
                }
            });
            if (cats.length > 0) {
                curData.categories = cats;
            }
        }

        // filter by audit designation if selected
        if (auditFilterVal != 'ALL') {
            curData.categories.forEach((c, i) => {
                let curCat = { ...c };
                let measures = c.measures;

                // proper fix for filtering measures with no indicators.
                measures = c.measures.filter(item => (item.indicators.find(child => child.auditDesignation.toString() === auditFilterVal.toString())) || (item.indicators.length <= 0 && item.auditDesignation.toString() === auditFilterVal.toString()))

                if (measures.length > 0) {
                    curCat.measures = measures;
                    auditcats.push(curCat);
                }
            });
            if (auditcats.length > 0) {
                curData.categories = auditcats;
            }
            else
                curData.categories = []; // set to empty if no match for filters.
        }

        curData.categories.forEach((c, i) => {
            c.measures.forEach((m, j) => {
                var measure = {} as IMeasure;
                measure.measureCode = m.measureCode;
                measure.hedisVol2MeasureName = m.hedisVol2MeasureName;
                curMeasures.push(measure);
            });
        });

        this.setState({
            selectedFilterVal: filterVal,
            selectedAuditFilterVal: auditFilterVal,
            data: curData,
            measures: curMeasures
        });
    }

    private getFilterOptions() {
        let items: string[] = [ "ALL", "HPR"];
        if(this.props.productLine.toUpperCase() === "MEDICARE") {
            items = items.concat(["CMS", "PBP"]);
        }
        return items;
    }

    private getAuditDesignationFilterOptions() {
        return this.auditDesignationOptions;
    }

    private handleAuditDesignationFilterChange(event: React.ChangeEvent<{ name?: string, value: any }>) {
        let auditFilterVal = event.target.value;

        this.getFilteredData(this.state.selectedFilterVal, auditFilterVal);
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval: IWrappedMeasureCategories = await SubmissionsService.GetAuditDesignations$(this.props.user, this.props.subId, this.props.component).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            //loop thru categories/measures to get all the measures
            var measures: IMeasure[] = [];
            retval.categories.forEach((c, i) => {
                c.measures.forEach((m, j) => {
                    var measure = {} as IMeasure;
                    measure.measureCode = m.measureCode;
                    measure.hedisVol2MeasureName = m.hedisVol2MeasureName;
                    measures.push(measure);
                });
            });
            const measCodesForFilter: IMeasureCodesForFilter = await SubmissionsService.GetMeasureCodesForFilter$(this.props.user, this.props.productLine).toPromise();
            this.setState(
                {
                    origData: retval,
                    data: retval,
                    origMeasures: measures,
                    measures: measures,
                    measureCodesForFilter: measCodesForFilter
                }
            )
        }
        this.setState({ isLoading: false });
    }

    private renderCategoryRow = (categoryName: string) => {
        const { classes } = this.props;
        return (<tr className={classes.categoryRow} key={categoryName + "Cat"}>
            <td colSpan={5}>
                {categoryName}
            </td>
        </tr>);
    }

    private renderMeasureRow = (measure: IMeasure, isEcdsCategory: boolean, cIndex: number, mIndex: number) => {
        const { classes } = this.props;
        const benefitOffered = measure.benefitOffered && measure.benefitOffered.length > 0 ? (measure.benefitOffered === "Y" ? "Yes" : "No") : null;

        let rate: string = null;
        let displayrate: string = null;
        let auditDesignation: string = measure.auditDesignation;
        let comment: string = measure.comment;
        let status: string = measure.status;
        //single rate measure
        if (measure.indicators.length === 1) {
            rate = measure.indicators[0].rate;
            displayrate = measure.indicators[0].displayRate;
            auditDesignation = measure.indicators[0].auditDesignation;
            comment = measure.indicators[0].comment;
            status = measure.indicators[0].status;
        }
        let artRow: IArtRow = {
            measureCode: measure.measureCode, benefitOffered: benefitOffered, isEcdsMeasure: isEcdsCategory,
            rate: rate, displayrate: displayrate, denominator: null, auditDesignation: auditDesignation, comment: comment
        };
        return (
            <tr key={measure.measureCode + "Measure"}>
                <td className={classes.measureRowTitleCell}>
                    <Button onClick={() => this.SelectDisplayMeasure(measure)} className={classes.measureLink} style={{ fontWeight: 'bold', backgroundColor: 'transparent' }} disabled={!this.props.canViewResultsReportsHedis}>
                        {
                            this.state.measureCodesForFilter["hpr"].lastIndexOf(measure.measureCode) >= 0 && !(this.props.productLine === "Exchange") ? "* " : ""
                        }
                        {measure.hedisVol2MeasureName}&nbsp;<span className={classes.measureCode}>({measure.measureCode})</span>
                    </Button>
                </td>
                <td className={classes.benefitCell}>
                    <Typography align='center' variant='body2'>
                        {benefitOffered}
                    </Typography>
                </td>
                {measure.indicators.length <= 1 ?
                    measure.isMeasureLevelAudit ?
                        <td className={classes.rateCellGray}>
                            <Typography align='center' variant='body2' style={{ fontWeight: 'bold' }}>
                                { displayrate }
                            </Typography>
                        </td>
                        :
                        <td className={classes.rateCell}>
                            <Typography align='center' variant='body2' style={{ fontWeight: 'bold' }}>
                                { displayrate }
                            </Typography>
                        </td>
                    : <td></td>
                }
                <td className={classes.auditDesCell}>
                    <Typography align='center' variant='body2'>
                        {status}
                    </Typography>
                </td>
                <td className={classes.auditDesCell}>
                    {!this.props.isReadOnly ?
                        measure.indicators.length <= 1 ?
                            this.showComment(artRow, cIndex, mIndex, null, true)
                            : null
                        : <Typography variant='body2'>
                            {auditDesignation}-{comment}
                        </Typography>
                    }
                </td>
            </tr>
        );
    }

    private renderIndicatorRow = (measureCode: string, isEcdsCategory: boolean, indicator: IIndicatorRateData, cIndex: number, mIndex: number, iIndex: number) => {
        const { classes } = this.props;
        const benefitOffered = indicator.benefitOffered && indicator.benefitOffered.length > 0 ? (indicator.benefitOffered === "Y" ? "Yes" : "No") : null;
        const rate: string = indicator.rate;
        const displayrate: string = indicator.displayRate;
        const denominator: string = indicator.denominator;
        const artRow: IArtRow = {
            measureCode: measureCode, benefitOffered: benefitOffered, isEcdsMeasure: isEcdsCategory,
            rate: rate, displayrate: displayrate, denominator: denominator, auditDesignation: indicator.auditDesignation, comment: indicator.comment
        };
        return (
            <tr key={indicator.indicatorName + "Indicator"}>
                <td className={classes.indicatorRowTitleCell}>
                    <Typography variant='body2'>
                        {indicator.indicatorName}
                    </Typography>
                </td>
                <td className={classes.benefitCell}>
                    <Typography variant='body2'>
                    </Typography>
                </td>
                <td className={classes.rateCell}>
                    <Typography align='center' variant='body2' style={{ fontWeight: 'bold' }}>
                        { displayrate }
                    </Typography>
                </td>
                <td className={classes.auditDesCell}>
                    <Typography align='center' variant='body2'>
                        {indicator.status}
                    </Typography>
                </td>
                <td className={classes.auditDesCell}>
                    {!this.props.isReadOnly ?
                        this.showComment(artRow, cIndex, mIndex, iIndex, false)
                        :
                        <Typography variant='body2'>
                            {indicator.auditDesignation}-{indicator.comment}
                        </Typography>
                    }
                </td>
            </tr>
        );
    }

    private handleCommentChange = async (event: React.ChangeEvent<{ name?: string, value: any }>,
        cIndex: number,
        mIndex: number,
        iIndex: number,
        isMeasure: boolean): Promise<void> => {

        let categories = this.state.data.categories.slice();
        let element = null;
        if (!isMeasure) {
            element = categories[cIndex].measures[mIndex].indicators[iIndex];
        }
        else {
            element = categories[cIndex].measures[mIndex];
        }
        var selectedAuditDesignation = event.target.value;
        var selectedComment = this.commentOptions.filter(c => c.value === selectedAuditDesignation)[0].label;
        element.auditDesignation = selectedAuditDesignation;
        element.status = selectedAuditDesignation; // This is slightly wrong, but is close enough.  The UI isn't really setting status, it's just displaying it.  The Status will be recalculated by the nuget package on reload.
        element.comment = selectedComment;

        //update the single rate indicator audit designation and comment
        if (categories[cIndex].measures[mIndex].indicators.length === 1) {
            var indicator = categories[cIndex].measures[mIndex].indicators[0];
            indicator.auditDesignation = selectedAuditDesignation;
            indicator.comment = selectedComment;
            indicator.status = selectedAuditDesignation; // This is slightly wrong, but is close enough.  The UI isn't really setting status, it's just displaying it.  The Status will be recalculated by the nuget package on reload.
        }

        this.setState({
            data: {
                ...this.state.data,
                categories: categories
            },
            hasChanges: true
        });
    }

    private GetCommentsListFor(artRow: IArtRow): any[] {
        var productLine = this.props.productLine.toUpperCase();
        var isLTSS = (productLine === MEDICAID && this.props.specialProject.toUpperCase() === LTSS);
        var options = (isLTSS ? this.commentOptions :
            this.commentOptions.filter(c => !this.readOnlyAuditDesignations.includes(c.value)));
        var benefitOffered = artRow.benefitOffered;
        var isEcdsMeasure = artRow.isEcdsMeasure;
        var rate = artRow.rate;
        var denominator = artRow.denominator;
        var isNB = (benefitOffered === "No");

        if (isLTSS) {
            var auditDesignations = ["R"];
            //show R for reported & denominator = 0
            if (rate || (!rate && denominator === "0")) {
                options = options.filter(c => auditDesignations.includes(c.value));
            }
            else if (!rate && !denominator) {
                //show NQ, NR & BR for not reported measures
                options = options.filter(c => c.value !== "R");
            }
        }
        //remove not required for Exchange and ECDS measures
        else if (productLine === EXCHANGE
            || (productLine !== MEDICARE && isEcdsMeasure)) {
            options = options.filter(c => c.value !== "NQ");
        }

        //remove no benefit if the benefit is not offered
        if (!isNB) {
            options = options.filter(c => c.value !== "NB");
        }
        return options;
    }

    private showComment(artRow: IArtRow, cIndex: number, mIndex: number, iIndex: number, isMeasure: boolean) {
        var productLine = this.props.productLine.toUpperCase();
        var isLTSS = (productLine === MEDICAID && this.props.specialProject.toUpperCase() === LTSS);
        var readOnlyAuditDesignations = this.readOnlyAuditDesignations;
        if (isLTSS) {
            readOnlyAuditDesignations = this.readOnlyAuditDesignations.filter(ad => ad !== "R");
        }
        return (!readOnlyAuditDesignations.includes(artRow.auditDesignation) ?
            <Select
                name="comment"
                value={artRow.auditDesignation || ""}
                fullWidth={true}
                onChange={ev => this.handleCommentChange(ev, cIndex, mIndex, iIndex, isMeasure)}
            >
                <MenuItem key="blank" value="" disabled>
                </MenuItem>
                {this.GetCommentsListFor(artRow).map(opt => {
                    return (
                        <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    );
                })}
            </Select> :
            <Typography variant='body2'>
                {artRow.auditDesignation}-{artRow.comment}
            </Typography>
        );
    }

    private SaveModel = async () => {
        if (!this.state.hasChanges)
            return;

        this.setState({ isSaving: true });
        const retval = await SubmissionsService.UpdateAuditDesignations$(this.props.user,
            this.props.subId,
            this.props.component,
            this.state.data).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
            this.setState({
                isSaving: false
            });
        }
        else {
            this.setState({
                isSaving: false,
                hasChanges: false
            });
        }
    }

    private SelectDisplayMeasure = (measure: IMeasure) => {
        this.setState({
            selectedDisplayMeasure: measure
        });
        this.getPreviousMeasure(measure.measureCode);
        this.getNextMeasure(measure.measureCode);
    }

    private ClearDisplayMeasure = () => {
        this.setState({
            selectedDisplayMeasure: null
        });
    }

    private getPreviousMeasure = (measureCode: string) => {
        if (measureCode) {
            var selectedMeasure = measureCode;
            var measures = this.state.measures;
            var index = this.getSelectedMeasureIndex(measureCode);
            if (index === 0) { //return last measure
                selectedMeasure = measures[measures.length - 1].measureCode;
            }
            else {
                selectedMeasure = measures[index - 1].measureCode;
            }
            this.setState({ previousMeasure: selectedMeasure });
            return selectedMeasure;
        }
    }

    private getNextMeasure = (measureCode: string) => {
        if (measureCode) {
            var selectedMeasure = measureCode;
            var measures = this.state.measures;
            var index = this.getSelectedMeasureIndex(measureCode);
            if (index === measures.length - 1) { //return first measure
                selectedMeasure = measures[0].measureCode;
            }
            else {
                selectedMeasure = measures[index + 1].measureCode;
            }
            this.setState({ nextMeasure: selectedMeasure });
            return selectedMeasure;
        }
    }

    private getSelectedMeasureIndex = (measureCode: string): number => {
        var measures = this.state.measures;
        var measureIndex = -1;
        if (measures) {
            measures.forEach((measure, index) => {
                if (measure.measureCode === measureCode) {
                    measureIndex = index;
                }
            });
        }
        return measureIndex;
    }

    private setSelectedMeasure = (measureCode: string) => {
        var measureName = '';
        this.state.measures.forEach((measure, index) => {
            if (measure.measureCode === measureCode) {
                measureName = measure.hedisVol2MeasureName;
            }
        });
        var selectedMeasure = {} as IMeasure;
        selectedMeasure.measureCode = measureCode;
        selectedMeasure.hedisVol2MeasureName = measureName;

        this.setState({
            selectedDisplayMeasure: selectedMeasure
        });
        this.getPreviousMeasure(measureCode);
        this.getNextMeasure(measureCode);
    }

    private handleMeasureChange = async (event: React.ChangeEvent<{ name?: string, value: any }>): Promise<void> => {
        var measureCode = event.target.value;
        this.setSelectedMeasure(measureCode);
    }

    private downloadFilteredArt = async () => {
        this.setState({isDownloadingMeasureData: true});
        const retVal = await SubmissionsService.GetFilteredArtDownloads$(this.props.user, this.props.subId, this.state.selectedFilterVal).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {
            const data = SharedUtility.Str2ab$(atob(retVal.fileContents));
            saveAs(
                new Blob([data], { type: "application/octet-stream" }),
                retVal.fileName
            );
        }
        this.setState({isDownloadingMeasureData: false});
    }
    private downloadMeasureData = async () => {
        this.setState({ isDownloadingMeasureData: true });
        const apiCall = SubmissionsService.GetMeasureDataDownloadCaller$(this.props.user, this.props.subId, this.state.selectedDisplayMeasure.measureCode);
        try {
            const userResponse = await apiCall.toPromise();
            if (typeof userResponse === 'string') {
                const data = this.str2ab(atob(userResponse));
                saveAs(new Blob([data], {
                    type: "application/octet-stream"
                }), "DATAWORKSHEET" + this.props.subId + "_" + this.state.selectedDisplayMeasure.measureCode + ".xlsx");
            }
        }
        catch (ex) {
            //     handle error
        }
        finally {
            this.setState({ isDownloadingMeasureData: false });
        }
    }
    private str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }
}


// REDUX CONTAINER
const AuditReviewToolWithState = connect<IGenericUserProps>(genericMapStateToProps)(AuditReviewToolView);

// STYLES CONTAINER
const AuditReviewToolViewTab = withStyles(styles)(AuditReviewToolWithState);

// REDUX CONTAINER

export default AuditReviewToolViewTab;