import { connect } from "react-redux";
import * as React from "react";
import Main from "../Root/Main";
import Header from "./Header";
import Sidebar from './Sidebar';
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Button, CssBaseline, Typography } from '@material-ui/core';
import Footer from '../Footer/Footer';
import { RouteComponentProps, withRouter } from "react-router";
import idssStyle from "../../Configuration/SharedStyling";
import RootState from "../../State/Root/RootState";
import logo from '../../Images/logo@2x.png';
import * as AuthorizationPolicies from "../../Configuration/AuthorizationPolicies";
import { User } from "oidc-client";
import { ISubmissionAllowance } from "../../DataClasses/SubmissionData";
import { IMenuActionAllowance } from "../../DataClasses/ActionAllowanceData";
import { AjaxError } from "rxjs/ajax";
import SubmissionsService from "../Services/SubmissionsService";
import UserManagerService from "../Services/UserManagerService";
import { Link, LinkProps } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { IAdministrationPayload } from "../../DataClasses/AdministrationData";
import AdministrationService from '../Services/AdministrationService';

//import LoginButton from "../Authentication/LoginButton";
import classNames from 'classnames'
import { Alert } from "@material-ui/lab";


const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

// REDUX PROPS

interface IAppWithAuthProps {
    user: User | null,
    isNcqaAdmin: boolean;
    isUserAdmin: boolean;
}


interface IAppWithAuthState {
    isSidebarOpened: boolean
    uploadavailabilities: ISubmissionAllowance,
    menuItemAvailabilities: IMenuActionAllowance,
    redirect: boolean,
    isIE: boolean;
    adminConfigOptions: IAdministrationPayload;
}

const mapStateToProps = (state: RootState): IAppWithAuthProps => {
    return ({
        user: state.AuthenticationReducer.user,
        isNcqaAdmin: AuthorizationPolicies.IsGlobalAdmin(state.AuthenticationReducer.user),
        isUserAdmin: AuthorizationPolicies.IsOrgAdmin(state.AuthenticationReducer.user),
    });
};

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);

type IStylesProps = WithStyles<typeof styles>;


// VIEW
class AppWithAuthentication extends React.Component<IStylesProps & IAppWithAuthProps, IAppWithAuthState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isSidebarOpened: true,
            uploadavailabilities:
            {
                allowsHedisSubmission: false,
                allowsCahpsSubmission: false,
                allowsHosSubmission: false
            },
            menuItemAvailabilities:
            {
                allowsBulkMarkFinalCahps: false,
                allowsBulkMarkFinalHos: false,
                allowsBulkAttestationHos: false,
                allowsUserManagement: false,
                allowsBulkDownloadHedis: false,
                allowsBulkDownloadHos: false,
                allowsBulkDownloadCahps: false
            },
            redirect: false,
            isIE: false,
            adminConfigOptions: {
                allowsHosSubmission: false,
                allowsCahpsSubmission: false,
                allowsHedisSubmission: false,
                enableMeasureGuidValidation: false,
                enableExperimentalValidation: false,
                isOffseasonMode: false,
                isMaintenanceMode: false,
                licenseAgreementText: '',
                enableGenericUpload: false,
                measurementYear: -1,
                enableHosInterimDataSubmission: false,
                measureModelDate: ''
            }
        };
    }

    public componentDidMount() {
        const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
        const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
        if (isIE) {
            this.setState({ isIE: true });
        }
        else {
            this.RefreshModel();
        }
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {       
        const { classes } = this.props;

        if (this.state.isIE) {
            return (<Grid container={true} className={classes.loginGrid}>
                <Grid item={true} className={classes.flexGrow}>
                    <img src={logo} alt="IDSS logo" />
                </Grid>
                <Grid item={true} className={classes.flexGrow + " " + classes.browserSupport}>
                    <h3>Internet Explorer is not supported. Please use some other browser to access IDSS website.</h3>
                </Grid>               
            </Grid>
            );
        }
        else {
            if (this.state.redirect) {
                return this.redirectToErrorPage();
            }
            if (this.props.user)
                return (
                    <div>
                        <CssBaseline />
                        <Sidebar {...this.props} menuItemAvailabilities={this.state.menuItemAvailabilities} uploadavailabilities={this.state.uploadavailabilities}
                            toggleSidebarAction={() => this.toggleSidebarOpen()} adminConfigOptions={this.state.adminConfigOptions} />
                        <div className={(classNames(classes.content, {
                            [classes.contentShift]: this.state.isSidebarOpened
                        }))}>
                            <Header />
                            <Main />
                            <Footer />
                        </div>
                    </div>
                )
            else
                return (<Grid container={true} className={classes.loginGrid}>
                    <Grid item={true} className={classes.flexGrow}>
                        <img src={logo} alt="IDSS logo" />
                    </Grid>
                    <Grid item={true} className={classes.flexGrow}>
                        <Typography variant="h4" gutterBottom>
                            Welcome to IDSS
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.flexGrow}>
                        <Button to={"/login"} variant='contained' color="inherit" component={AdapterLink}>
                            Login
                    </Button>
                    </Grid>
                    &nbsp;
                    <Alert severity="error">HEDIS (not CAHPS) Submission deadline extended to June 28, 2024 @ 5pm EST</Alert>
                    <a href="https://www.ncqa.org/blog/weve-extended-the-deadline-for-hedis-reporting/">View Blog Post Here</a>
                </Grid>
                );
        }
    }

    toggleSidebarOpen = () => {
        this.setState({
            isSidebarOpened: !this.state.isSidebarOpened
        });
    }

    private async RefreshModel() {
        if (this.props.user) {
            //bulk upload
            const retval = await SubmissionsService.GetUploadAvailabilities$(this.props.user).toPromise();
            if (retval instanceof AjaxError || !retval) {
                this.setRedirectState();
            }
            else {
                this.setState({ uploadavailabilities: retval })
            }
            //
            const itemAv = await UserManagerService.GetMenuItemAvailabilities$(this.props.user).toPromise();
            if (itemAv instanceof AjaxError || !itemAv) {
                this.setRedirectState();
            }
            else {
                this.setState({ menuItemAvailabilities: itemAv })
            }

            //get admin config options
            const adminRetval = await AdministrationService.GetAdminModel(this.props.user).toPromise();
            if (adminRetval instanceof AjaxError || !adminRetval) {
                this.setRedirectState();
            } else {
                this.setState({ adminConfigOptions: adminRetval });
            }
        }
    }


}
const AppWithAuthenticationPropState = withRouter(connect<IAppWithAuthProps, {}, RouteComponentProps<{}>>(mapStateToProps)(AppWithAuthentication));

// STYLES CONTAINER
const AppWithAuthenticationState = withStyles(styles)(AppWithAuthenticationPropState);

// REDUX CONTAINER
export default AppWithAuthenticationState;

