import {
    AppBar, Button, Card, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel, FormGroup, Grid, LinearProgress, Paper, Step, StepLabel, Stepper, Tab, Tabs, Typography, CardActions, GridList, GridListTile, Divider, Tooltip, TextareaAutosize,
    Fade, Box, InputLabel, Grow
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { SlideProps } from '@material-ui/core/Slide';
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
// import { TransitionProps } from "@material-ui/core/transitions/transition";
// import { SlideProps  } from '@material-ui/core/transitions';

import classNames from 'classnames';
import * as React from "react";
import Dropzone from 'react-dropzone';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { AjaxError } from "rxjs/ajax";
import request from 'superagent';
import { v4 as uuidv4 } from "uuid";
import idssStyle from "../../Configuration/SharedStyling";
import { genericMapStateToProps, IGenericUserProps } from "../../DataClasses/GenericUser";
import { ISubmissionAttestationFlags, ICurrentSubAuths, ISubmissionHomePayload, ISubmissionHomeText, ISubmissionUserPayload, IUploadSection, IValidationResponsePayload, SubmissionActionTypes, IUserAction, ValidationStatusTypes } from "../../DataClasses/SubmissionData";
import { AllowableFileTypes, AllowableFileTypesText, AuditLockButtonMainText, AuditLockButtonTitle, AuditLockButtonUndoMainText, AuditLockButtonUndoTitle, MarkFinalButtonMainText, MarkFinalButtonMainTextUndo, MarkFinalButtonTitle, MarkFinalButtonTitleUndo, PlanLockButtonMainText, PlanLockButtonMainTextUndo, PlanLockButtonTitle, PlanLockButtonTitleUndo, ProductTranslator, AuditLockNoActionText, MarkFinalNoActionText, AttestationButtonMainText, AttestationButtonTitle, AttestationButtonMainTextUndo, AttestationButtonTitleUndo, AttestationNoActionText, ClearMeasureButtonTitle, ClearMeasureButtonMainText } from '../../Utilities/ProductTypeTranslation';
import * as Renderings from "../Pages/Subcomponents/SubmissionHome/SubmissionHome.Renderings";
import * as AllDownloadsTab from "../Pages/Subcomponents/SubmissionHome/SubmissionHome.AllDownloads";
import AuditReviewToolViewTab from "../Pages/Subcomponents/SubmissionHome/SubmissionHome.AuditReviewTool";
import AttestationSteps from "../Pages/Subcomponents/SubmissionHome/SubmissionHome.AttestationSteps";
import ExceptionSerivce from '../Services/ExceptionService';
import SubmissionsService from '../Services/SubmissionsService';
import UserManagerService from "../Services/UserManagerService";
import ErrorDialog from '../Shared/ErrorDialog';
import LoadingDisplay from "../Shared/Loading";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import { IFilesUploadReportPayload } from "../../DataClasses/ReportData";
import success from '../../Images/CheckMarkCircle.png';
import * as AuthorizationPolicies from "../../Configuration/AuthorizationPolicies";
import { InsertDriveFile, AddCircle, Delete } from "@material-ui/icons";
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { Fab, IconButton } from '@material-ui/core';
import SharedUtility from "../../Utilities/SharedUtility";
import {
    VIEW_ART, UPLOAD_DOCUMENT_HEDIS, UPLOAD_DOCUMENT_NONHEDIS, AUDIT_LOCK, UNLOCK_AUDIT_LOCK, PLAN_LOCK, UNLOCK_PLAN_LOCK, MARK_FINAL_HEDIS, SUBMISSION_GENERIC_ADMIN,
    MARK_FINAL_CAHPS, MARK_FINAL_HOS, ASSIGN_AUDIT_DESIGNATION, SIGN_ATTESTATION_HEDIS, SIGN_ATTESTATION_HOS, DOWNLOAD_RESULTS_HEDIS, DOWNLOAD_RESULTS_CAHPS, DOWNLOAD_RESULTS_HOS, MANAGE_HEDIS_USER,
    MANAGE_PUBLIC_REPORTING, SUB_ID, VIEW_DOWNLOAD_ERRORS_HEDIS, VIEW_DOWNLOAD_ERRORS_NONHEDIS, VIEW_RESULTS_REPORTS_HEDIS, HOS_ENABLE_INTERIM_SUBMISSION
} from "../Shared/Constants";
import { MessageType, ValidationMessage, ValidationError, KeyValuePair } from '../../DataClasses/SharedData';
import UserMessage from '../Shared/Message';
import Moment from 'react-moment';
import ReportsService from "../Services/ReportsService";
import { IEvent } from "../../DataClasses/DbData";
import saveAs from 'file-saver';
import LoadingDataTable from '../Shared/LoadingDataTable';
import { SeedTime } from "../../Configuration/Setting";
import { Redirect } from "react-router-dom";
import AdministrationService from "../Services/AdministrationService";
import ProcessingDisplay from "../Shared/ProcessingDisplay";
import ConfettiExplosion from 'react-confetti-explosion';
const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// const Transition = React.forwardRef<unknown, TransitionProps> => function Transition(props, ref) {
//     return <Slide direction="up" {...props} />;
// };

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    validationMessageList: {
        margin: "0",
        listStyle: "none"
    }
});

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f2f2f2', // theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[16],
        fontSize: 11,
        fontStyle: 'oblique'
    },
}))(Tooltip);

interface ISubmissionHomeState {
    submissionData: ISubmissionHomePayload,
    currentSubId: string,
    isMainTabLoading: boolean,
    isGenericActionHappening: boolean,
    isAuditorTabLoading: boolean,
    validation: IValidationResponsePayload,
    currentTabVal: number,
    component: string,
    auths: ICurrentSubAuths,
    users: any[],
    textLookup: ISubmissionHomeText,
    errorMsg: string,
    uploadSection: IUploadSection,
    allAccounts: string[],
    selectedAccount: any,
    selectedAccountLevel: any,
    dialogStatus: IDialogs,
    isHistoryTabLoading: boolean,
    isAllDownloadsTabLoading: boolean,
    history: any[],
    allDownloads: any[],
    isSubmissionActivitiesLoading: boolean,
    isDetailedSubmissionDetailLoading: boolean,
    submissionActivities: any[],
    detailedSubmissionMeta: any[],
    isFlagUpdateSuccessful: boolean,
    isAdminUpdatingAttestFlags: boolean,
    isSysAdmin: boolean,
    isOrgAdmin: boolean,
    attestation: IAttestationState,
    userAction: IUserAction,
    showAllDownloads: boolean,
    redirect: boolean,
    validationResult: any,
    validationDialogOpen: boolean,
    currentValidationItem: ValidationError,
    isVendorMeasureGuidsLoading: boolean,
    vendorMeasureGuids: any[],
    enableHosInterimDataSubmission: boolean
}

interface IAttestationState {
    attestationStarted: boolean,
    attestationTitle: string,
    attestationText: string,
    attestationSigned: boolean
}

interface IDialogs {
    planLockOpen: boolean,
    planLockTitle: string,
    planLockText: string,
    planLockUndoOpen: boolean,
    planLockUndoTitle: string,
    planLockUndoText: string,
    auditLockOpen: boolean,
    auditLockTitle: string,
    auditLockText: string,
    auditLockUndoOpen: boolean,
    auditLockUndoTitle: string,
    auditLockUndoText: string,
    auditLockNoActionText: string,

    markFinalOpen: boolean,
    markFinalTitle: string,
    markFinalText: string,
    markFinalUndoOpen: boolean,
    markFinalUndoTitle: string,
    markFinalUndoText: string,
    markFinalNoActionText: string,

    attestationUndoOpen: boolean,
    attestationUndoTitle: string,
    attestationUndoText: string,
    attestationNoActionText: string,

    hosAttestationUndoOpen: boolean,

    clearMeasuresOpen: boolean,
    clearMeasuresTitle: string,
    clearMeasuresText: string
}

interface TabMetadata {
    Label: string,
    AdminOnly: boolean,
    HedisOnly: boolean,
}

const SUBMISSION_TAB = 0;
const AUDIT_REVIEW_TOOL_TAB = 1;
const AUDITORS_TAB = 2;
const ALL_DOWNLOADS_TAB = 3;
const UPLOAD_HISTORY_TAB = 4;
const CLEAR_ALL_MEASURES = 5;
const ADMIN_TAB = 6;
const TabInfo: TabMetadata[] = [
    { Label: 'EDIT SUBMISSION', AdminOnly: false, HedisOnly: false },
    { Label: 'AUDITOR REVIEW TABLE', AdminOnly: true, HedisOnly: true },
    { Label: 'AUDITORS', AdminOnly: true, HedisOnly: false },
    { Label: 'ALL DOWNLOADS', AdminOnly: false, HedisOnly: false },
    { Label: 'UPLOAD HISTORY', AdminOnly: false, HedisOnly: false },
    { Label: 'CLEAR ALL MEASURES', AdminOnly: false, HedisOnly: true },
    { Label: 'ADMIN', AdminOnly: true, HedisOnly: false },
];

const IMPORT_SUBMISSION_STEP = 1;
const MARK_FINAL_CAHPS_STEP = 2;
const PLAN_LOCK_STEP = 3;
const AUDIT_LOCK_STEP = 4;
const ATTESTATION_HOS_STEP = 5;
const MARK_FINAL_HEDIS_HOS_STEP = 6;
const ATTESTATION_HEDIS_STEP = 7;
const ATTESTATION_CAHPS_STEP = 8;
const COMPLETE_STEP = 9;
const SubmissionStepsHedis = [
    { "Name": "Import Submission", "Number": IMPORT_SUBMISSION_STEP },
    { "Name": "Apply Plan Lock", "Number": PLAN_LOCK_STEP },
    { "Name": "Apply Audit Lock", "Number": AUDIT_LOCK_STEP },
    { "Name": "Apply Mark Final", "Number": MARK_FINAL_HEDIS_HOS_STEP },
    { "Name": "Finalize Attestation", "Number": ATTESTATION_HEDIS_STEP },
    { "Name": "Submission Finalized", "Number": COMPLETE_STEP }
];
const SubmissionStepsCahps = [
    { "Name": "Import Submission", "Number": IMPORT_SUBMISSION_STEP },
    { "Name": "Apply Mark Final", "Number": MARK_FINAL_CAHPS_STEP },
    { "Name": "Finalize Attestation", "Number": ATTESTATION_CAHPS_STEP },
    { "Name": "Submission Finalized", "Number": COMPLETE_STEP }
];
const SubmissionStepsHos = [
    { "Name": "Import Submission", "Number": IMPORT_SUBMISSION_STEP },
    { "Name": "Validations/Attestation", "Number": ATTESTATION_HOS_STEP },
    { "Name": "Apply Mark Final", "Number": MARK_FINAL_HEDIS_HOS_STEP },
    { "Name": "Submission Finalized", "Number": COMPLETE_STEP }
];

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class SubmissionHome extends React.Component<IGenericUserProps & IStylesProps & RouteComponentProps<{ subId: string, component: string }>, ISubmissionHomeState> {
    private uploadFilesOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        print: false,
        downloadOptions: {
            filename: 'UploadHistory.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        selectableRows: "none",
        viewColumns: false,
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    }
    private submissionActivityOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        print: false,
        viewColumns: false,
        selectableRows: "none",
        downloadOptions: {
            filename: 'ActivityReport.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    }
    private detailedSubmissionOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        print: false,
        viewColumns: false,
        selectableRows: "none",
        downloadOptions: {
            filename: 'SubmissionInfo.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    }
    private vendorMeasureGuidOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        print: false,
        viewColumns: false,
        selectableRows: "none",
        downloadOptions: {
            filename: `VendorMeasureGuids.csv`,
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100]
    }
    constructor(props: IGenericUserProps & IStylesProps & RouteComponentProps<{ subId: string, component: string }>) {
        super(props);
        const component = props.match.params.component;


        this.state =
        {
            submissionData:
            {
                productLine: "",
                reportingProduct: "",
                reportingUnitId: "",
                specialArea: "",
                specialProject: "",
                subId: Number(props.match.params.subId),
                orgId: -1,
                orgName: "",
                cmsContract: "",
                vendorId: -1,
                activeStepNumber: -1,
                component: "",
                isFileProcessed: false,
                isFileUploaded: false,
                didValidationError: false,
                didValidationWarning: false,
                isFileUploadAllowed: false,
                fileUploadNotAllowedReason: "",
                submissionDownloads: [],
                isPublicReported: null,
                somc: null,
                attestationModifiedDate: null,
                isAccredited: false,
                hasSubmissionAccess: false,
                isAuditRequired: false,
                validationStatus: "",
                isFinal: null,
                isAttested: null,
                requiredFileName: null,
                isCahpsOnlySubmission: false,
                hedisSubInMarkFinalOrEarlier: false,
                submissionIntention: "",
            },
            currentSubId: props.match.params.subId,
            isMainTabLoading: true,
            isAuditorTabLoading: true,
            isGenericActionHappening: false,
            uploadSection:
            {
                uploadFiles: [],
                dropzoneStatusStyle: "",
                uploadCompletionPercent: 0,
                fileProcessingComplete: false,
                uploadValidationMessages: [],
                uploadSuccessful: false
            },
            validation:
            {
                submissionId: -1,
                urlString: "",
                headerUrlString: "",
                detailUrlString: "",
                validationText: "",
                validationErrors: []
            },
            currentTabVal: 0,
            component: component,
            auths: {
                isGlobalAdmin: false,
                isOrgAdmin: false,
                isOrgSigner: false,
                isVendorSigner: false,
                isOrgUser: false,
                isAuditor: false,
                isViewer: false,
                isVendorAdmin: false,
                isVenderUser: false,
                isSubmissionUser: false,
                user: props.user,
            },
            users: [],
            textLookup:
            {
                uploadFileText: ProductTranslator.getProductTypeName(component, new AllowableFileTypesText()),
                uploadFileType: ProductTranslator.getProductTypeName(component, new AllowableFileTypes()),
            },
            errorMsg: "",
            allAccounts: [],
            selectedAccount: "",
            selectedAccountLevel: { label: "Submission Level", value: "submission" },
            dialogStatus:
            {
                planLockOpen: false,
                planLockTitle: ProductTranslator.getProductTypeName(component, new PlanLockButtonTitle()),
                planLockText: ProductTranslator.getProductTypeName(component, new PlanLockButtonMainText()),
                planLockUndoOpen: false,
                planLockUndoTitle: ProductTranslator.getProductTypeName(component, new PlanLockButtonTitleUndo()),
                planLockUndoText: ProductTranslator.getProductTypeName(component, new PlanLockButtonMainTextUndo()),
                auditLockOpen: false,
                auditLockUndoTitle: ProductTranslator.getProductTypeName(component, new AuditLockButtonUndoTitle()),
                auditLockUndoText: ProductTranslator.getProductTypeName(component, new AuditLockButtonUndoMainText()),
                auditLockNoActionText: ProductTranslator.getProductTypeName(component, new AuditLockNoActionText()),
                auditLockUndoOpen: false,
                markFinalOpen: false,
                markFinalUndoOpen: false,
                auditLockText: ProductTranslator.getProductTypeName(component, new AuditLockButtonMainText()),
                auditLockTitle: ProductTranslator.getProductTypeName(component, new AuditLockButtonTitle()),
                markFinalText: ProductTranslator.getProductTypeName(component, new MarkFinalButtonMainText()),
                markFinalTitle: ProductTranslator.getProductTypeName(component, new MarkFinalButtonTitle()),
                markFinalUndoText: ProductTranslator.getProductTypeName(component, new MarkFinalButtonMainTextUndo()),
                markFinalUndoTitle: ProductTranslator.getProductTypeName(component, new MarkFinalButtonTitleUndo()),
                markFinalNoActionText: ProductTranslator.getProductTypeName(component, new MarkFinalNoActionText()),
                attestationUndoOpen: false,
                attestationUndoTitle: ProductTranslator.getProductTypeName(component, new AttestationButtonTitleUndo()),
                attestationUndoText: ProductTranslator.getProductTypeName(component, new AttestationButtonMainTextUndo()),
                attestationNoActionText: ProductTranslator.getProductTypeName(component, new AttestationNoActionText()),
                hosAttestationUndoOpen: false,
                clearMeasuresOpen: false,
                clearMeasuresTitle: ProductTranslator.getProductTypeName(component, new ClearMeasureButtonTitle()),
                clearMeasuresText: ProductTranslator.getProductTypeName(component, new ClearMeasureButtonMainText())
            },
            attestation: {
                attestationStarted: false,
                attestationSigned: false,
                attestationTitle: ProductTranslator.getProductTypeName(component, new AttestationButtonTitle()),
                attestationText: ProductTranslator.getProductTypeName(component, new AttestationButtonMainText()),
            },
            isHistoryTabLoading: true,
            isAllDownloadsTabLoading: true,
            allDownloads: [],
            history: [],
            isSubmissionActivitiesLoading: true,
            isDetailedSubmissionDetailLoading: true,
            submissionActivities: [],
            detailedSubmissionMeta: [],
            isFlagUpdateSuccessful: false,
            isAdminUpdatingAttestFlags: false,
            isSysAdmin: AuthorizationPolicies.IsGlobalAdmin(this.props.user),
            isOrgAdmin: false,
            userAction: {
                canViewArt: false,
                canUploadHedis: false,
                canUploadNonHedis: false,
                canPlanLock: false,
                canPlanUnlock: false,
                canAuditLock: false,
                canAuditUnlock: false,
                canMarkFinalHedis: false,
                canMarkFinalCahps: false,
                canMarkFinalHos: false,
                canSubmissionGenericAdmin: false,
                canAssignAuditDesignation: false,
                canSignAttestationHedis: false,
                canSignAttestationHos: false,
                canDownloadResultHedis: false,
                canDownloadResultCahps: false,
                canDownloadResultHos: false,
                canManageHedisUser: false,
                canManagePublicReporting: false,
                canViewDownloadErrorsHedis: false,
                canViewDownloadErrorsNonHedis: false,
                canViewResultReportsHedis: false
            },
            showAllDownloads: true,
            redirect: false,
            validationResult: {},
            validationDialogOpen: false,
            currentValidationItem: null,
            isVendorMeasureGuidsLoading: false,
            vendorMeasureGuids: [],
            enableHosInterimDataSubmission: false
        };
    }

    public async componentDidMount() {
        await this.RefreshModel(this.state.currentTabVal);

        //get all user actions and update the state
        var userActions = await this.GetAllAuthorizedUserActions();
        if (userActions != null) {
            this.SetUserActions(userActions);
        }

        //get hos interim data submission config value
        const retval = await AdministrationService.GetConfigKey$(this.props.user, HOS_ENABLE_INTERIM_SUBMISSION).toPromise();
        if (!retval) {
            this.setRedirectState();
        } else {
            this.setState({ enableHosInterimDataSubmission: retval.toLowerCase() === 'true' });
        }
    }

    private SetUserActions = (userActions: Map<string, boolean>) => {
        this.setState({
            userAction: {
                canViewArt: userActions[VIEW_ART],
                canUploadHedis: userActions[UPLOAD_DOCUMENT_HEDIS],
                canUploadNonHedis: userActions[UPLOAD_DOCUMENT_NONHEDIS],
                canPlanLock: userActions[PLAN_LOCK],
                canPlanUnlock: userActions[UNLOCK_PLAN_LOCK],
                canAuditLock: userActions[AUDIT_LOCK],
                canAuditUnlock: userActions[UNLOCK_AUDIT_LOCK],
                canMarkFinalHedis: userActions[MARK_FINAL_HEDIS],
                canMarkFinalCahps: userActions[MARK_FINAL_CAHPS],
                canMarkFinalHos: userActions[MARK_FINAL_HOS],
                canSubmissionGenericAdmin: userActions[SUBMISSION_GENERIC_ADMIN],
                canAssignAuditDesignation: userActions[ASSIGN_AUDIT_DESIGNATION],
                canSignAttestationHedis: userActions[SIGN_ATTESTATION_HEDIS],
                canSignAttestationHos: userActions[SIGN_ATTESTATION_HOS],
                canDownloadResultHedis: userActions[DOWNLOAD_RESULTS_HEDIS],
                canDownloadResultCahps: userActions[DOWNLOAD_RESULTS_CAHPS],
                canDownloadResultHos: userActions[DOWNLOAD_RESULTS_HOS],
                canManageHedisUser: userActions[MANAGE_HEDIS_USER],
                canManagePublicReporting: userActions[MANAGE_PUBLIC_REPORTING],
                canViewDownloadErrorsHedis: userActions[VIEW_DOWNLOAD_ERRORS_HEDIS],
                canViewDownloadErrorsNonHedis: userActions[VIEW_DOWNLOAD_ERRORS_NONHEDIS],
                canViewResultReportsHedis: userActions[VIEW_RESULTS_REPORTS_HEDIS]
            }
        });
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const auditorListingColumns = [
            {
                name: "Email",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Audit Level",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "First Name",
                options: {
                    filter: false,
                    sort: false
                }
            },
            {
                name: "Last Name",
                options: {
                    filter: false,
                    sort: false
                }
            },
            {
                name: "Actions",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var tipTxt = "Delete on " + tableMeta.rowData[1] + " Level"
                        return (
                            <div>
                                {
                                    this.state.userAction.canManageHedisUser ? <>
                                        <Tooltip title={tipTxt} aria-label={tipTxt}>
                                            <IconButton onClick={() => this.deleteAuditorIconClick(tableMeta)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </> : null
                                }
                            </div>
                        );
                    },
                    download: false
                }
            }
        ];
        const uploadedFilesColumns =
            [

                {
                    name: "Saved Name",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "Original Name",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "Component",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "ValidationStatus",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Create User",
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "Create Date",
                    options: {
                        filter: false,
                    }
                },
            ];

        const submissionActivityColumns =
            [
                {
                    name: "Date",
                    options: {
                        filter: false,
                        customBodyRender: value => {
                            return (
                                <Moment format="YYYY/MM/DD">
                                    {value}
                                </Moment>
                            );
                        }
                    }
                },
                {
                    name: "User",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Message",
                    options: {
                        filter: false,
                    }
                },
            ];
        const detailedSubmissionColumns =
            [
                {
                    name: "Key",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Value",
                    options: {
                        filter: true,
                    }
                }
            ];
        const vendorMeasureGuidColumns =
            [
                {
                    name: "Measure",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "Guid",
                    options: {
                        filter: true,
                    }
                }
            ];
        const { classes } = this.props;
        if (this.state.isMainTabLoading) {
            return <div className={classes.flexGrow}>
                {Renderings.renderLoadingSkeleton(this.state.currentSubId, classes)}
            </div>
        }
        else {
            if (!this.state.submissionData.hasSubmissionAccess) {
                return <div className={this.props.classes.mt2}><UserMessage text={<Typography variant='body2' color='inherit'>Access denied</Typography>} variant={MessageType.Error} /></div>
            }
            else {
                var steps = SubmissionStepsHedis;
                const isHedis = this.state.submissionData.component.includes("DST") || this.state.submissionData.component.includes("HEDIS");
                if (this.state.submissionData.component.includes("CAHPS")) {
                    steps = SubmissionStepsCahps
                }
                if (this.state.submissionData.component.includes("HOS")) {
                    steps = SubmissionStepsHos
                }

                //  const activeStepNumber = this.state.submissionData.activeStepNumber.toString();
                let stepIndexNumber = this.GetActualStepIndex();
                const tabValue = this.state.currentTabVal;
                const accountOption = this.state.allAccounts.map(m => ({
                    label: m,
                    value: m
                }));
                const accountLevelOption = [{ label: "Submission Level", value: "submission" }, { label: "Organization Level", value: "organization" }];
                const reactSelectStyles = {
                    container: (base, state) => ({
                        ...base,
                        opacity: state.isDisabled ? ".5" : "1",
                        backgroundColor: "transparent",
                        zIndex: "999"
                    })
                };
                var seedTime = 400;
                return (
                    <div className={classes.flexGrow}>
                        <Fade
                            in={!this.state.isMainTabLoading}
                            {...(!this.state.isMainTabLoading
                                ? { timeout: seedTime }
                                : {})}
                        >
                            {Renderings.renderTopDisplayGrid(this.state.submissionData, this.state.currentSubId, classes)}
                        </Fade>
                        <Fade
                            in={!this.state.isMainTabLoading}
                            {...(!this.state.isMainTabLoading
                                ? { timeout: 2 * seedTime }
                                : {})}
                        >
                            <AppBar position="static">
                                <Tabs value={tabValue} onChange={this.handleTabChange}>
                                    {TabInfo.map((item, i) => {
                                        if ((i === AUDIT_REVIEW_TOOL_TAB && (!isHedis || !this.state.userAction.canViewArt || this.state.submissionData.validationStatus === ValidationStatusTypes.NotRun || this.state.submissionData.validationStatus === ValidationStatusTypes.SchemaError || this.state.submissionData.validationStatus === ValidationStatusTypes.Fatal)) ||
                                            (i === AUDITORS_TAB && (!this.state.userAction.canManageHedisUser || !isHedis || !this.state.submissionData.isAuditRequired)) ||
                                            (i === ALL_DOWNLOADS_TAB && !(this.state.userAction.canDownloadResultHedis && isHedis) && !((this.state.userAction.canDownloadResultCahps || this.state.userAction.canDownloadResultHos) && !isHedis)) ||
                                            (i === ADMIN_TAB && !this.state.isSysAdmin) ||
                                            (i === CLEAR_ALL_MEASURES && (this.state.submissionData.activeStepNumber !== PLAN_LOCK_STEP || !this.state.userAction.canUploadHedis)) ||
                                            (item.HedisOnly && !isHedis)) {
                                            return null;
                                        }
                                        else {
                                            return (<Tab label={item.Label} value={i} key={i} />);
                                        }
                                    })}
                                </Tabs>
                            </AppBar>
                        </Fade>
                        <Fade
                            in={!this.state.isMainTabLoading}
                            {...(!this.state.isMainTabLoading
                                ? { timeout: 3 * seedTime }
                                : {})}
                        >
                            {
                                tabValue === SUBMISSION_TAB ?
                                    <div>
                                        <TabContainer>
                                            {this.state.isGenericActionHappening || (this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete) ?
                                                <div><LinearProgress /></div> : null}
                                            <Grid container={true} className={classes.flexGrow} spacing={2}>
                                                <Grid item={true} xs={12}>
                                                    <Stepper activeStep={stepIndexNumber}>
                                                        {steps.map((label) => {
                                                            const props = {};
                                                            const labelProps = {};
                                                            if (label.Number === AUDIT_LOCK_STEP && !this.state.submissionData.isAuditRequired) {
                                                                return null;
                                                            }
                                                            else {
                                                                return (
                                                                    <Step key={label.Number} {...props} className={classes.primaryBlue}>
                                                                        <StepLabel {...labelProps}>{label.Name}</StepLabel>
                                                                    </Step>
                                                                );
                                                            }
                                                        })}
                                                    </Stepper>
                                                </Grid>

                                                <Grid container spacing={2} className={classes.submissionHomeContent}>
                                                    {this.renderActiveComponent()}
                                                </Grid>
                                            </Grid>
                                        </TabContainer>
                                    </div>
                                    : tabValue === AUDIT_REVIEW_TOOL_TAB ?
                                        <AuditReviewToolViewTab subId={this.state.currentSubId}
                                            productLine={this.state.submissionData.productLine}
                                            component={this.state.submissionData.component}
                                            specialProject={this.state.submissionData.specialProject}
                                            isReadOnly={!(this.state.submissionData.activeStepNumber === AUDIT_LOCK_STEP && this.state.userAction.canAuditLock)}
                                            canViewResultsReportsHedis={this.state.userAction.canViewResultReportsHedis}
                                            isAuditRequired={this.state.submissionData.isAuditRequired ?? false} />
                                        : tabValue === AUDITORS_TAB ?
                                            <div>
                                                <TabContainer>
                                                    {this.state.userAction.canManageHedisUser ? <>
                                                        <Typography variant='subtitle1' color='primary' className={classes.tabTitle}>
                                                            Assign User as Submission Auditor
                                                        </Typography>
                                                        <Paper className={classes.paper}>
                                                            <ErrorDialog
                                                                showError={this.state.errorMsg !== ""}
                                                                errorMsg={this.state.errorMsg}
                                                                buttonHandler={() => this.setState({ errorMsg: "" })}
                                                            />

                                                            <table><tbody><tr><td style={{ width: '350px' }}>
                                                                <Select
                                                                    styles={reactSelectStyles}
                                                                    options={accountOption}
                                                                    placeholder="Select an account"
                                                                    isMulti={false}
                                                                    matchProp="label"
                                                                    simpleValue={true}
                                                                    value={this.state.selectedAccount}
                                                                    closeOnSelect={false}
                                                                    searchable={true}
                                                                    onChange={this.selectedAccountChange.bind(this)}
                                                                />
                                                            </td>
                                                                <td style={{ width: '200px' }}>
                                                                    <Select
                                                                        styles={reactSelectStyles}
                                                                        options={accountLevelOption}
                                                                        placeholder="Select account level"
                                                                        isMulti={false}
                                                                        matchProp="label"
                                                                        simpleValue={true}
                                                                        value={this.state.selectedAccountLevel}
                                                                        closeOnSelect={false}
                                                                        searchable={true}
                                                                        onChange={this.selectedAccountLevelChange.bind(this)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Button variant='contained' color='secondary' onClick={() => {
                                                                        this.onClickAddAuditor(this.state.selectedAccount.value, this.state.selectedAccountLevel.value)
                                                                    }} className={classes.button} startIcon={<AddCircle />} disabled={this.state.selectedAccount === ""}>
                                                                        ASSIGN AUDITOR
                                                                    </Button>
                                                                </td></tr></tbody></table>
                                                        </Paper>
                                                    </> : null}
                                                    <Paper className={classes.paper}>
                                                        {this.state.isAuditorTabLoading ?
                                                            <LoadingDataTable titleText={"*"} />
                                                            : Renderings.renderAuditorsTable(this.state.users, auditorListingColumns)}
                                                    </Paper>
                                                </TabContainer>
                                            </div>
                                            : tabValue === ALL_DOWNLOADS_TAB ?
                                                this.state.isAllDownloadsTabLoading ?
                                                    <LoadingDisplay size={300} progressText="." />
                                                    :
                                                    <div>
                                                        <TabContainer>
                                                            {isHedis ?
                                                                <FormControl>
                                                                    <FormControlLabel
                                                                        control={<Checkbox
                                                                            checked={this.state.showAllDownloads}
                                                                            onChange={() => this.setState({ showAllDownloads: !this.state.showAllDownloads })}
                                                                            color="primary"
                                                                        />}
                                                                        label="Show All Archived Files"
                                                                    />
                                                                </FormControl>
                                                                : null}
                                                            {AllDownloadsTab.renderAllDownloadsTable(this.state.showAllDownloads ? this.state.allDownloads : this.state.allDownloads.filter(x => !x[4]), this.downloadFile, classes)}
                                                        </TabContainer>
                                                    </div>

                                                : tabValue === UPLOAD_HISTORY_TAB ?
                                                    this.state.isHistoryTabLoading ?
                                                        <LoadingDataTable titleText={"*"} />
                                                        :
                                                        <Fade in={true} {...({ timeout: SeedTime })}>
                                                            <div>
                                                                <TabContainer>
                                                                    <MUIDataTable
                                                                        title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Uploaded Files</Typography>}
                                                                        data={this.state.history}
                                                                        columns={uploadedFilesColumns}
                                                                        options={this.uploadFilesOptions}
                                                                    />
                                                                </TabContainer>
                                                            </div>
                                                        </Fade>
                                                    : tabValue === CLEAR_ALL_MEASURES ?
                                                        <div>
                                                            <TabContainer>
                                                                <Grid container spacing={3}>
                                                                    <Grid key={1} item={true} xs={4} className={classes.gridDisplay}>
                                                                        <Card className={classes.card}>
                                                                            <CardContent>
                                                                                <div>
                                                                                    <UserMessage text={<Typography variant='body2' color='inherit'>All previously uploaded files will be cleared and submission will move to import stage.</Typography>} variant={MessageType.Warning} />
                                                                                </div>
                                                                            </CardContent>
                                                                            <CardActions>
                                                                                <Fab disabled={this.state.submissionData.didValidationError && !this.state.userAction.canUploadHedis} variant="extended" color="primary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, clearMeasuresOpen: true } }) }}>
                                                                                    Clear
                                                                                </Fab>
                                                                                <Dialog
                                                                                    open={this.state.dialogStatus.clearMeasuresOpen}
                                                                                    aria-labelledby="alert-dialog-title"
                                                                                    aria-describedby="alert-dialog-description"
                                                                                    TransitionComponent={Transition}
                                                                                >
                                                                                    <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.clearMeasuresTitle}</Typography></DialogTitle>
                                                                                    <DialogContent>
                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                            <Typography className={classes.secondaryText}>{this.state.dialogStatus.clearMeasuresText}</Typography>
                                                                                        </DialogContentText>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, clearMeasuresOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                                                            Cancel
                                                                                        </Button>
                                                                                        <Button onClick={() => this.unSubmitFile()} autoFocus disabled={this.state.isGenericActionHappening}>
                                                                                            Continue
                                                                                        </Button>
                                                                                    </DialogActions>
                                                                                </Dialog>
                                                                            </CardActions>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </TabContainer>
                                                        </div>
                                                        : tabValue === ADMIN_TAB ?
                                                            <div>
                                                                <TabContainer>
                                                                    <Grid container spacing={3}>
                                                                        <Grid key={1} item={true} xs={4} className={classes.gridDisplay}>
                                                                            <Card className={classes.card}>
                                                                                <CardContent>
                                                                                    <Typography className={classes.primaryBlue}>
                                                                                        Reprocess submission file
                                                                                    </Typography>
                                                                                </CardContent>
                                                                                <CardActions >
                                                                                    <Button variant='contained' color='primary' aria-label="Reprocess" onClick={() => this.reprocessSubmission()}>Reprocess</Button>
                                                                                </CardActions>
                                                                            </Card>
                                                                        </Grid>
                                                                        <Grid key={2} item={true} xs={4} className={classes.gridDisplay}>
                                                                            {this.state.userAction.canManagePublicReporting ? <>
                                                                                <Card className={classes.card}>
                                                                                    <CardContent>
                                                                                        <Typography className={classes.primaryBlue}>
                                                                                            Manage attestation flags
                                                                                        </Typography>
                                                                                        <FormControl>
                                                                                            <FormGroup>
                                                                                                <FormControlLabel className={classes.noBottomMargin}
                                                                                                    control={
                                                                                                        <Checkbox color="primary"
                                                                                                            checked={this.state.submissionData.isPublicReported === true}
                                                                                                            onChange={(e, checked) => this.handleAttestationFlagChange('PublicReporting', checked)}
                                                                                                        />
                                                                                                    }
                                                                                                    label="Public Reporting"
                                                                                                />
                                                                                                <FormControlLabel className={classes.noBottomMargin}
                                                                                                    control={
                                                                                                        <Checkbox color="primary"
                                                                                                            checked={this.state.submissionData.somc}
                                                                                                            onChange={(e, checked) => this.handleAttestationFlagChange('Somc', checked)}

                                                                                                        />
                                                                                                    }
                                                                                                    label="SOMC"
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </FormControl>
                                                                                    </CardContent>
                                                                                    <CardActions>
                                                                                        <Button variant='contained' color={'primary'} disabled={this.state.isAdminUpdatingAttestFlags} aria-label="Save changes" onClick={() => this.updateSubmissionAttestationFlags()}>Save Changes</Button>
                                                                                        {this.state.isAdminUpdatingAttestFlags && <CircularProgress color="primary" size={20} />}
                                                                                        {this.state.isFlagUpdateSuccessful && <img src={success} height='28' width='28' alt='Flags updated succesfully' />}
                                                                                    </CardActions>
                                                                                </Card>
                                                                            </> : null
                                                                            }
                                                                        </Grid>
                                                                        <Grid key={3} item={true} xs={6} className={classes.gridDisplay}>
                                                                            <Card className={classes.card}>
                                                                                <CardContent>
                                                                                    <Typography className={classes.primaryBlue}>
                                                                                        Submission activities
                                                                                    </Typography>
                                                                                    {this.state.isSubmissionActivitiesLoading ?
                                                                                        <LoadingDataTable />
                                                                                        :
                                                                                        <>
                                                                                            <MUIDataTable
                                                                                                title={null}
                                                                                                data={this.state.submissionActivities}
                                                                                                columns={submissionActivityColumns}
                                                                                                options={this.submissionActivityOptions}
                                                                                            />
                                                                                        </>}
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Grid>
                                                                        <Grid key={4} item={true} xs={6} className={classes.gridDisplay}>
                                                                            <Card className={classes.card}>
                                                                                <CardContent>
                                                                                    <Typography className={classes.primaryBlue}>
                                                                                        Detailed Submission Detail
                                                                                    </Typography>
                                                                                    {this.state.isDetailedSubmissionDetailLoading ?
                                                                                        <LoadingDataTable />
                                                                                        :
                                                                                        <>
                                                                                            <MUIDataTable
                                                                                                title={null}
                                                                                                data={this.state.detailedSubmissionMeta}
                                                                                                columns={detailedSubmissionColumns}
                                                                                                options={this.detailedSubmissionOptions}
                                                                                            />
                                                                                        </>}
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Grid>
                                                                        <Grid key={4} item={true} xs={6} className={classes.gridDisplay}>
                                                                            <Card className={classes.card}>
                                                                                <CardContent>
                                                                                    <Typography className={classes.primaryBlue}>
                                                                                        Vendor Measure Guids
                                                                                    </Typography>
                                                                                    {this.state.isVendorMeasureGuidsLoading ?
                                                                                        <LoadingDataTable />
                                                                                        :
                                                                                        <>
                                                                                            <MUIDataTable
                                                                                                title={null}
                                                                                                data={this.state.vendorMeasureGuids}
                                                                                                columns={vendorMeasureGuidColumns}
                                                                                                options={this.vendorMeasureGuidOptions}
                                                                                            />
                                                                                        </>}
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/* </Paper> */}
                                                                </TabContainer>
                                                            </div>
                                                            :
                                                            <div>
                                                                <TabContainer>
                                                                    Default
                                                                </TabContainer>
                                                            </div>
                            }
                        </Fade>
                    </div>
                );
            }
        }
    }

    public handleTabChange = async (event, index: number) => {
        this.setState({ currentTabVal: index });
        await this.RefreshModel(index);
    };

    private GetActualStepIndex(): number {
        var steps = SubmissionStepsHedis;
        if (this.state.submissionData.component.includes("CAHPS")) {
            steps = SubmissionStepsCahps
        }
        if (this.state.submissionData.component.includes("HOS")) {
            steps = SubmissionStepsHos
        }

        let stepIndexNumber = 0;
        if (this.state.submissionData.activeStepNumber !== 0) {
            stepIndexNumber = steps.findIndex(x => x.Number === this.state.submissionData.activeStepNumber);

            //adjust the step index for audit not required subs (skipping audit lock stage)
            if (!this.state.submissionData.isAuditRequired && this.state.submissionData.activeStepNumber >= MARK_FINAL_HEDIS_HOS_STEP) {
                stepIndexNumber--;
            }
        }

        // Always move past the last step - this will make the last step be checked off
        if (stepIndexNumber + 1 === steps.length) {
            stepIndexNumber = stepIndexNumber + 1;
        }
        return stepIndexNumber;
    }

    private async RefreshModel(index) {
        switch (index) {
            case SUBMISSION_TAB:
                await this.getSubmissionSummary(false);
                break;
            case AUDITORS_TAB:
                this.getUserData();
                this.getAccounts();
                break;
            case ADMIN_TAB:
                this.getSubmissionActivities();
                this.getDetailedSubmissionDetails();
                this.getVendorMeasureGuids();
                break;
            case ALL_DOWNLOADS_TAB:
                this.getAllDownloads();
                break;
            case UPLOAD_HISTORY_TAB:
                this.getSubmissionFileHistory();
                break;
        }
    }
    private async getSubmissionActivities() {
        this.setState({ isSubmissionActivitiesLoading: true });
        const retval: IEvent[] | AjaxError = await ReportsService.GetSubmissionEvents$(this.props.user, this.state.submissionData.subId, this.state.submissionData.component).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                submissionActivities: retval.map(item => {
                    return [
                        item.timestamp,
                        item.user,
                        item.message
                    ];
                })
            });
        }
        this.setState({ isSubmissionActivitiesLoading: false });
    }

    private async getDetailedSubmissionDetails() {
        this.setState({ isDetailedSubmissionDetailLoading: true });
        const retval = await SubmissionsService.GetDetailedSubmission$(this.props.user, this.state.submissionData.subId.toString(), this.state.submissionData.component).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                detailedSubmissionMeta: retval.metadatas.map(x => {
                    return [
                        x.key,
                        x.value
                    ];
                })
            });
        }
        this.setState({ isDetailedSubmissionDetailLoading: false });
    }

    private async getVendorMeasureGuids() {
        this.setState({ isVendorMeasureGuidsLoading: true });
        const retval: KeyValuePair[] = await SubmissionsService.GetVendorMeasureGuids$(this.props.user, this.state.submissionData.vendorId.toString()).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                vendorMeasureGuids: retval.map(x => {
                    return [
                        x.key,
                        x.value
                    ];
                })
            });
        }
        this.setState({ isVendorMeasureGuidsLoading: false });
    }

    private async GetAllAuthorizedUserActions() {
        var result = await UserManagerService.GetAllAuthorizedUserActions$(this.props.user, SUB_ID, this.state.submissionData.subId).toPromise();
        return result;
    }

    private async RefreshAuditorsList() {
        const apiCall = SubmissionsService.GetSubmissionAuditors$(this.props.user, this.state.submissionData.orgId, this.state.submissionData.subId);
        const userResponse = await apiCall.toPromise();
        if (userResponse instanceof AjaxError || !userResponse) {
            this.setRedirectState();
        }
        else {
            const usersData = userResponse.map(
                item => {
                    return [
                        item.roleUser.email,
                        item.roleUser.roleType,
                        item.salesforceUser.firstName,
                        item.salesforceUser.lastName,
                        item.roleUser.email
                    ]
                }
            );
            this.setState({ users: usersData });
        }
    }

    private async deleteAuditorIconClick(tableMeta: any) {
        try {
            await SubmissionsService.UpdateAuditor$(this.props.user, this.state.submissionData.orgId, this.state.submissionData.subId, tableMeta.rowData[0], tableMeta.rowData[1], "delete").toPromise();
        }
        catch (ex) {
            const error = ex as AjaxError;
            this.setState({ errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error) });
        }

        this.RefreshAuditorsList();
    }
    private selectedAccountChange(newValue): void {
        this.setState({ selectedAccount: newValue });
    }
    private selectedAccountLevelChange(newValue): void {
        this.setState({ selectedAccountLevel: newValue });
    }
    public async onClickAddAuditor(userName: string, accountLevel: string) {
        try {
            await SubmissionsService.UpdateAuditor$(this.props.user, this.state.submissionData.orgId, this.state.submissionData.subId, userName, accountLevel, "add").toPromise();
        }
        catch (ex) {
            const error = ex as AjaxError;
            this.setState({ errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error) });
        }
        this.setState({ selectedAccount: "", selectedAccountLevel: "" });
        this.RefreshAuditorsList();
    }

    private getUserData = async (): Promise<void> => {
        this.setState({ isAuditorTabLoading: true });
        const apiCall = SubmissionsService.GetSubmissionAuditors$(this.props.user, this.state.submissionData.orgId, this.state.submissionData.subId);
        const userResponse: ISubmissionUserPayload[] = await apiCall.toPromise();
        if (userResponse instanceof AjaxError || !userResponse) {
            this.setRedirectState();
        }
        else {
            const usersData = userResponse.map(
                item => {
                    return [
                        item.roleUser.email,
                        item.roleUser.roleType,
                        item.salesforceUser.firstName,
                        item.salesforceUser.lastName,
                        item.roleUser.email
                    ]
                }
            );

            this.setState({
                users: usersData,
                isAuditorTabLoading: false
            });
        }
    }

    private getAccounts = async (): Promise<void> => {
        const apiCall = AdministrationService.GetAllAuditors$(this.props.user);
        const userResponse = await apiCall.toPromise();
        if (userResponse instanceof AjaxError || !userResponse) {
            this.setRedirectState();
        }
        else {
            this.setState({ allAccounts: userResponse });
        }
    }

    private fetchValidationData = async (): Promise<void> => {
        if (this.state.submissionData.activeStepNumber === PLAN_LOCK_STEP || this.state.submissionData.activeStepNumber === MARK_FINAL_CAHPS_STEP || this.state.submissionData.activeStepNumber === ATTESTATION_HOS_STEP) {
            const apiCall = SubmissionsService.GetValidationData$(this.props.user, this.state.currentSubId, this.state.submissionData);
            const validations = await apiCall.toPromise();
            if (validations instanceof AjaxError || !validations) {
                this.setRedirectState();
            }
            else {
                this.setState({ validation: validations });
            }
        }

    }

    private getSubmissionSummary = async (refresh: boolean): Promise<void> => {
        if (refresh) {
            this.setState({ isGenericActionHappening: true });
        }
        else if (this.state.submissionData == null) {
            this.setMainTabLoadingState(true);
        }
        const apiCall = SubmissionsService.GetSubmissionSummary$(this.props.user, this.state.currentSubId, this.state.component);

        var tmpValidation = {
            submissionId: -1,
            urlString: "",
            headerUrlString: "",
            detailUrlString: "",
            validationText: "",
            validationErrors: []
        };
        //Clear out the validation display.
        this.setState({ validation: tmpValidation });

        const subSummary = await apiCall.toPromise();
        if (subSummary instanceof AjaxError || !subSummary) {
            this.setRedirectState();
        }
        else {
            if (subSummary.hasSubmissionAccess) {
                this.setState({
                    submissionData: subSummary,
                    isOrgAdmin: AuthorizationPolicies.IsAdminForOrg(this.props.user, subSummary.orgId.toString())
                });
            }
            else {
                this.setMainTabLoadingState(false);
            }

            if (refresh) {
                this.setState({ isGenericActionHappening: false });
            }
            else {
                this.setMainTabLoadingState(false);
            }
            if (subSummary.hasSubmissionAccess) {
                this.fetchValidationData();
            }
        }
    }

    private setMainTabLoadingState = (loading: boolean) => {
        this.setState({ isMainTabLoading: loading });
    }

    private getSubmissionFileHistory = async (): Promise<void> => {
        this.setState({ isHistoryTabLoading: true });
        const apiCall = SubmissionsService.GetUploadedDocsForSubmission$(this.props.user, this.state.submissionData.subId, this.state.component);
        const subHistory: IFilesUploadReportPayload[] = await apiCall.toPromise();
        if (subHistory instanceof AjaxError || !subHistory) {
            this.setRedirectState();
        }
        else {
            const historyData = subHistory.map(
                item => {
                    return [
                        item.savedName,
                        item.originalName,
                        item.component,
                        item.validationStatus,
                        item.createUser,
                        item.createDate,
                    ]
                }
            );

            this.setState({
                history: historyData,
                isHistoryTabLoading: false
            });
        }
    }

    private getAllDownloads = () => {
        this.setState({ isAllDownloadsTabLoading: true });
        const allDownloadsTableData = this.state.submissionData.submissionDownloads.map(
            item => {
                return [
                    item.displayName,
                    item.uploadDay + " " + item.uploadTime,
                    item.uploadUser,
                    item.key,
                    item.isArchived
                ]
            }
        );

        this.setState({
            allDownloads: allDownloadsTableData,
            isAllDownloadsTabLoading: false
        });
    }

    private downloadFile = async (key: string): Promise<void> => {
        var fileUrl = await this.getDownloadUrl(key);
        window.location.href = fileUrl;
    }

    private getDownloadUrl = async (key: string): Promise<string> => {
        const apiCall = SubmissionsService.GetSubmissionLink$(
            this.props.user,
            this.state.submissionData.subId,
            this.state.component,
            this.state.submissionData.isFileUploaded,
            this.state.submissionData.isFileProcessed,
            this.state.submissionData.activeStepNumber,
            key,
            this.state.submissionData.productLine
        );
        const linkUrl = await apiCall.toPromise();

        return linkUrl;
    }

    private renderActiveComponent() {
        const { classes } = this.props;
        var adjustedStepNumber = this.state.submissionData.activeStepNumber;
        const isCahps = this.state.component.includes("CAHPS");
        const isHOS = this.state.component.includes("HOS");
        const isHedis = this.state.component.includes("HEDIS") || this.state.component.includes("DST");
        const isCahpsOnly = this.state.submissionData.isCahpsOnlySubmission;
        const canPublicReport = this.state.userAction.canManagePublicReporting;
        const isVendor = this.state.userAction.canUploadNonHedis;
        const canSignHedis = this.state.userAction.canSignAttestationHedis;
        const canReportOrSignHedis = canPublicReport || canSignHedis;
        const isHedisSignable = !this.state.submissionData.hedisSubInMarkFinalOrEarlier;
        var lockTitle = "";
        if (isCahps) {
            lockTitle = "Mark Final";
        }
        else if (isHedis) {
            lockTitle = "Plan Lock";
        }
        else if (isHOS) {
            lockTitle = "Continue";
        }

        switch (adjustedStepNumber) {
            case IMPORT_SUBMISSION_STEP:
            case MARK_FINAL_CAHPS_STEP:
            case ATTESTATION_HOS_STEP:
            case PLAN_LOCK_STEP:
                //Note:  Returning a multi part frament rather than a single node, as it very much impacts rendering.
                return <>
                    {(isHedis && this.state.userAction.canUploadHedis) || (!isHedis && this.state.userAction.canUploadNonHedis) ? <>
                        <Grid key={1} item={true} xs={6} className={classes.gridDisplay}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.primaryBlue + " " + classes.marginBottom} >
                                        File Management
                                    </Typography>
                                    {this.state.submissionData.isFileUploadAllowed ?
                                        <div>
                                            {this.state.uploadSection && this.state.uploadSection.uploadValidationMessages && this.state.uploadSection.uploadValidationMessages.length > 0 ?
                                                <div>
                                                    <UserMessage text={this.getValidationMessageList()} variant={MessageType.Error} />
                                                </div> :
                                                ""}
                                            <div>
                                                <Dropzone
                                                    accept={[".csv", ".xml", ".txt"]}
                                                    onDrop={this.onDrop.bind(this)}
                                                    onDropAccepted={() => this.setState({ uploadSection: { ...this.state.uploadSection, dropzoneStatusStyle: classes.stripes } })}
                                                    onDropRejected={() => this.setState({ uploadSection: { ...this.state.uploadSection, dropzoneStatusStyle: classes.rejectStripes } })}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div
                                                            {...getRootProps()}
                                                            className={classes.DropzoneContainer + " " + classNames(classes.dropZone, this.state.uploadSection.dropzoneStatusStyle)}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                                                                <Grid item={true} xs={12}>
                                                                    <Typography className={classes.dropzoneText} variant="h6" color="textPrimary">
                                                                        Drag and drop file here
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item={true} xs={12}>
                                                                    <Typography className={classes.dropzoneText} variant="h6" color="textPrimary">
                                                                        or
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item={true} xs={12}>
                                                                    <Fab className={classes.squareFab + " " + classes.dropzoneBtn} variant="extended" color="primary">
                                                                        Select File
                                                                    </Fab>
                                                                </Grid>
                                                                <Grid item={true} xs={12}>
                                                                    <Typography className={classes.dropzoneText} variant="subtitle1" color="textPrimary">
                                                                        Allowable format : {this.state.submissionData.requiredFileName} <br />
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {this.state.uploadSection.uploadCompletionPercent > 0 ? <Typography>Status:  </Typography> : null}
                                                            </td>
                                                            <td>
                                                                {this.state.uploadSection.uploadCompletionPercent > 0 && this.state.uploadSection.uploadCompletionPercent !== 100 ? <CircularProgress variant="determinate" value={this.state.uploadSection.uploadCompletionPercent} /> : ""}
                                                                <Typography className={classes.darkOrangeColor}>{this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete ? "Processing" : null}</Typography>
                                                                <Typography className={classes.greenColor}>{this.state.uploadSection.uploadCompletionPercent === 100 && this.state.uploadSection.fileProcessingComplete ? "Complete" : null}</Typography>
                                                            </td>
                                                            <td>
                                                                {this.state.uploadSection.uploadCompletionPercent === 100 ? <ProcessingDisplay completed={this.state.uploadSection.fileProcessingComplete} /> : null}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : <Typography color={"secondary"}>{this.state.submissionData.fileUploadNotAllowedReason}</Typography>}
                                </CardContent>
                            </Card>
                        </Grid>
                    </> : null
                    }

                    {this.state.submissionData.isFileProcessed && (!this.state.submissionData.didValidationError || this.state.isSysAdmin) ?
                        <Grid key={3} item={true} xs={6} className={classes.gridDisplay}>
                            {this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete ?
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography className={classes.primaryBlue + " " + classes.marginBottom}>
                                            {lockTitle}
                                        </Typography>
                                        <LoadingDataTable />
                                    </CardContent>
                                    <CardActions>
                                        <Fab disabled variant="extended">Apply Plan Lock</Fab>
                                    </CardActions>
                                </Card> :
                                <Fade in={!(this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete)}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.primaryBlue + " " + classes.marginBottom}>
                                                {lockTitle}
                                            </Typography>
                                            <Typography variant='body1' color='secondary' className={classes.marginTop} >
                                                {
                                                    (this.state.submissionData.didValidationError ?
                                                        (this.state.isSysAdmin && !isHOS ? "This submission has Validation Error(s).  You may only lock it because you are a NCQA Admin." :
                                                            isCahps || isHOS ? "Your submission has Validation Error(s).  You may not proceed to the next stage." : "Your submission has Validation Error(s).  You may not Plan Lock.") : null)
                                                }
                                            </Typography>
                                            <Typography variant='body1' color='secondary'>
                                                {
                                                    !this.state.submissionData.didValidationError && this.state.submissionData.didValidationWarning ?
                                                        <>Your submission has encountered Validation warnings. Please review all warnings and verify the data are correct before proceeding.</> : null
                                                }
                                            </Typography>
                                            <Typography variant='body1' color='primary' className={classes.mt1}>
                                                {isHedis && !this.state.submissionData.didValidationError && this.state.userAction.canPlanLock ?
                                                    <>Your data has been validated and ready for plan lock!

                                                        Apply the plan lock after you have reviewed your data through the Auditor Review Table or Submissions Downloads.
                                                        Your auditor will review once the lock is applied.
                                                    </>
                                                    : null
                                                }
                                                {isCahps && !this.state.userAction.canMarkFinalCahps ?
                                                    <>Waiting for Vendor Admin or Vendor User to apply Mark Final.</> : null
                                                }
                                                {isHOS && !this.state.submissionData.didValidationError ? <>
                                                    {this.state.userAction.canSignAttestationHos ?
                                                        <>Please perform attestation and mark final processes using the bulk operation.</>
                                                        :
                                                        <>Only your organization's Designated Signer may sign the attestation and mark files final.
                                                            Please have the Designated Signer log into IDSS, sign the attestation, and mark submissions final.</>
                                                    }
                                                </> : null
                                                }
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {isCahps && this.state.userAction.canMarkFinalCahps ? this.renderCanMarkFinal() : null}
                                            {isHedis && this.state.userAction.canPlanLock ?
                                                <div>
                                                    <Fab disabled={this.state.submissionData.didValidationError && !this.state.isSysAdmin} variant="extended" color="primary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, planLockOpen: true } }) }}>
                                                        Apply Plan Lock
                                                    </Fab>
                                                    <Dialog
                                                        open={this.state.dialogStatus.planLockOpen}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                        TransitionComponent={Transition}
                                                    >
                                                        <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.planLockTitle}</Typography></DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                <Typography className={classes.secondaryText}>{this.state.dialogStatus.planLockText}</Typography>
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, planLockOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={() => this.executePlanAction(SubmissionActionTypes.PlanLock)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                                Continue
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                                : null
                                            }
                                        </CardActions>
                                    </Card></Fade>}
                        </Grid> : null}

                    {this.state.submissionData.isFileProcessed && ((isHedis && this.state.userAction.canViewDownloadErrorsHedis) || (!isHedis && this.state.userAction.canViewDownloadErrorsNonHedis)) ?
                        <Grid key={2} item={true} xs={6} className={classes.gridDisplay}>
                            <Card className={classes.card}>
                                {this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete ?
                                    <CardContent>
                                        <LoadingDataTable titleText={"Validations"} />
                                    </CardContent> :
                                    (
                                        this.state.validation.validationErrors.length > 0 ?
                                            <div>
                                                {this.renderValdiationTable(this.state.validation)}
                                            </div>
                                            :
                                            <CardContent>
                                                <Typography className={classes.primaryBlue + " " + classes.marginBottom}>
                                                    Validations
                                                </Typography>
                                                {this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete ?
                                                    <LoadingDataTable titleText={"*"} /> :
                                                    isCahps && this.state.validationResult.validationSuccessful
                                                        && this.state.validationResult.errors.length === 0
                                                        && this.state.validationResult.fatalities.length === 0
                                                        ?
                                                        <div>
                                                            <TextareaAutosize
                                                                name="validationText"
                                                                className={classes.fullWidth}
                                                                id="multiline-filled-static"
                                                                disabled={true}
                                                                rowsMax={25}
                                                                value="No errors found."
                                                            />
                                                        </div> :

                                                        this.renderValidationComponent(this.state.submissionData, this.state.validation)
                                                }
                                            </CardContent>
                                    )
                                }
                            </Card>
                        </Grid> : null
                    }



                    {(adjustedStepNumber !== IMPORT_SUBMISSION_STEP) && (!isCahps || !this.state.submissionData.didValidationError) ?
                        <Grid key={4} item={true} xs={6} className={classes.gridDisplay}>
                            {this.renderSubmissionDownloads(isHedis)}
                        </Grid> : null}
                    <Grid key={5} item={true} xs={4} className={classes.gridDisplay}>
                        {this.renderSubmissionDetails()}
                    </Grid>
                    {isCahps ?
                        <Grid key={6} item={true} xs={2}>
                        </Grid> : null}
                </>
            case AUDIT_LOCK_STEP:
                return <>
                    {this.state.userAction.canAuditLock && this.state.submissionData.isAuditRequired ? <>
                        <Grid key={1} item={true} xs={4} className={classes.gridDisplay}>
                            {this.state.submissionData.isFileProcessed ?
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography className={classes.primaryBlue}>
                                            Audit Lock
                                        </Typography>
                                        <br />
                                        {this.state.userAction.canAuditLock ?
                                            <Typography variant='body1' color='primary'>
                                                Submission data are ready to be reviewed.

                                                Use the Submission Downloads or Bulk downloads section (on the left panel) to review reports.
                                                The Auditor Review Table should be used to make any audit designation changes.
                                            </Typography> : null
                                        }
                                        {!(this.state.userAction.canPlanUnlock || this.state.userAction.canAuditLock) ?
                                            <Typography variant='body1' color='primary'>
                                                {this.state.dialogStatus.auditLockNoActionText}
                                            </Typography> : null
                                        }
                                    </CardContent>
                                    <CardActions>
                                        {this.state.userAction.canAuditLock ?
                                            <>
                                                <Fab variant="extended" color="primary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, auditLockOpen: true } }) }}>
                                                    Apply Audit Lock
                                                </Fab>
                                                <Dialog
                                                    open={this.state.dialogStatus.auditLockOpen}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    TransitionComponent={Transition}
                                                >
                                                    <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.auditLockTitle}</Typography></DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <Typography className={classes.secondaryText}>{this.state.dialogStatus.auditLockText}</Typography>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, auditLockOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={() => this.executePlanAction(SubmissionActionTypes.AuditLock)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                            Continue
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </> : null
                                        }
                                        {this.state.userAction.canPlanUnlock ?
                                            this.renderUndoPlanLock() : null
                                        }
                                    </CardActions>
                                </Card> : null}
                        </Grid>
                    </> : null}
                    <Grid key={2} item={true} xs={4} className={classes.gridDisplay}>
                        {this.renderSubmissionDownloads(isHedis)}
                    </Grid>

                    <Grid key={3} item={true} xs={4} className={classes.gridDisplay}>
                        {this.renderSubmissionDetails()}
                    </Grid>
                </>
            case MARK_FINAL_HEDIS_HOS_STEP:
                return <>
                    {(isHedis && (this.state.userAction.canMarkFinalHedis || this.state.userAction.canAuditUnlock)) || (isHOS) ?
                        <Grid key={6} item={true} xs={4} className={classes.gridDisplay}>
                            {this.state.submissionData.isFileProcessed ?
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant='h6' color='primary'>
                                            Finalization
                                        </Typography>
                                        <Typography variant='body1' color='primary' className={classes.mt1}>
                                            {isHedis && this.state.userAction.canMarkFinalHedis ?
                                                <>Your Submission has been Audit locked.

                                                    You may proceed with marking your submission final after you have reviewed the Auditor Review Table.
                                                    Once marked final, the attestation process will begin, and your signer will complete the submission process.
                                                </>
                                                : null
                                            }
                                        </Typography>
                                    </CardContent>
                                    {isHedis ?
                                        <CardActions>
                                            <div>
                                                {this.state.userAction.canMarkFinalHedis ?
                                                    <Fab variant="extended" color="primary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalOpen: true } }) }}>
                                                        Apply Mark Final
                                                    </Fab> : null
                                                }
                                                <Dialog
                                                    open={this.state.dialogStatus.markFinalOpen}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    TransitionComponent={Transition}
                                                >
                                                    <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.markFinalTitle}</Typography></DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <Typography className={classes.secondaryText}>{this.state.dialogStatus.markFinalText}</Typography>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={() => this.executePlanAction(SubmissionActionTypes.MarkFinal)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                            Continue
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                            {this.state.userAction.canAuditUnlock && this.state.submissionData.isAuditRequired ?
                                                <div>
                                                    <Fab variant="extended" color="secondary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, auditLockUndoOpen: true } }) }}>
                                                        Undo Audit Lock
                                                    </Fab>
                                                    <Dialog
                                                        open={this.state.dialogStatus.auditLockUndoOpen}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                        TransitionComponent={Transition}
                                                    >
                                                        <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.auditLockUndoTitle}</Typography></DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                <Typography className={classes.secondaryText}>{this.state.dialogStatus.auditLockUndoText}</Typography>
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, auditLockUndoOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={() => this.executePlanAction(SubmissionActionTypes.AuditUnlock)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                                Continue
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div> : null
                                            }
                                            {this.state.userAction.canPlanUnlock && !this.state.submissionData.isAuditRequired ?
                                                this.renderUndoPlanLock() : null
                                            }

                                        </CardActions>
                                        :
                                        <CardContent>
                                            {this.state.userAction.canSubmissionGenericAdmin && !this.state.enableHosInterimDataSubmission ?
                                                <div>
                                                    <Fab variant="extended" color="secondary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, hosAttestationUndoOpen: true } }) }}>
                                                        Undo Attestation
                                                    </Fab>
                                                    <Dialog
                                                        open={this.state.dialogStatus.hosAttestationUndoOpen}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                        TransitionComponent={Transition}
                                                    >
                                                        <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>Undo Attestation</Typography></DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                <Typography className={classes.secondaryText}>Are you sure you want to Undo Attestation for this Submission?</Typography>
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, hosAttestationUndoOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={() => this.executePlanAction(SubmissionActionTypes.AttestationUndo)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                                Continue
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                                : null}
                                            <Typography variant='body1' color='primary'>
                                                {this.state.userAction.canMarkFinalHos ? "Please perform mark final processes using the bulk operation."
                                                    : "Only your organization's Designated Signer may mark files final. Please have the Designated Signer log into IDSS and mark submissions final."
                                                }
                                            </Typography>
                                        </CardContent>
                                    }
                                </Card> : null}
                        </Grid>
                        : null}
                    <Grid key={2} item={true} xs={4} className={classes.gridDisplay}>
                        {this.renderSubmissionDownloads(isHedis)}
                    </Grid>
                    <Grid key={3} item={true} xs={4} className={classes.gridDisplay}>
                        {this.renderSubmissionDetails()}
                    </Grid>


                </>
            case ATTESTATION_CAHPS_STEP:
            case ATTESTATION_HEDIS_STEP:
                return <>
                    {(
                        (isHedis && (canReportOrSignHedis)) ||
                        (isCahps) ||
                        (isHOS && (canPublicReport || this.state.userAction.canSignAttestationHos)
                        )

                    ) ? <>
                        <Grid key={6} item={true} xs={(this.state.submissionData.isFileProcessed && this.state.attestation.attestationStarted) ? 8 : 4} className={classes.gridDisplay}>
                            {this.state.submissionData.isFileProcessed ?
                                this.state.attestation.attestationStarted && (!isCahps || isCahpsOnly) && (canReportOrSignHedis) ?
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <AttestationSteps
                                                submissionId={this.state.submissionData.subId}
                                                component={this.state.submissionData.component}
                                                title={this.state.attestation.attestationTitle}
                                                description={this.state.attestation.attestationText}
                                                isPublicReporting={this.state.submissionData.isPublicReported}
                                                canManagePublicReporting={this.state.userAction.canManagePublicReporting}
                                                canEsignAttestation={this.state.userAction.canSignAttestationHedis}
                                                onComplete={this.handleAttestationStepsComplete}
                                                onCancel={this.handleAttestationStepsCancel}
                                                isLtss={this.state.submissionData.specialProject === "LTSS"}
                                                isYesAllowed={!(this.state.submissionData.specialProject === "LTSS" || this.state.submissionData.productLine === "Exchange")}
                                            />
                                        </CardContent>
                                    </Card>
                                    :
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography className={classes.primaryBlue}>
                                                Attestation
                                            </Typography>
                                            {!SharedUtility.IsNullOrUndefined$(this.state.submissionData.isPublicReported) && !SharedUtility.IsNullOrUndefined$(this.state.submissionData.reportableEvents) ?
                                                <React.Fragment>
                                                    <Typography variant='body1' color='primary' className={classes.mt2}>
                                                        Public Reporting: <strong>{this.state.submissionData.isPublicReported ? 'Yes' : 'No'}</strong>
                                                    </Typography>
                                                </React.Fragment> : null}
                                            {/* isHedis - This is true when a submission contains HEDIS/DST component */}
                                            {/* isCahps - This is true when a submission contains HEDIS & CAHPS components */}
                                            {/* isCahpsOnly - This is true when a submission contains only CAHPS component */}
                                            {/* isVendor - This is true when a user has vendoruser access or a NCQA ADMIN */}
                                            {/* canPublicReport - This is true when a user has Public Reporting access or a NCQA ADMIN */}
                                            {
                                                (isCahps && isVendor && !canPublicReport)
                                                    ? <div>
                                                        <Typography variant='body1' color='primary' className={classes.mt2}>
                                                            Submission has been marked final. No further actions are needed from survey vendors.
                                                        </Typography>
                                                    </div> : null
                                            }
                                            {
                                                (isCahps && !canPublicReport && !isVendor)
                                                    ? <div>
                                                        <Typography variant='body1' color='primary' className={classes.mt2}>
                                                            No further action needed.
                                                        </Typography>
                                                    </div> : null
                                            }
                                            {
                                                (isCahps && !isCahpsOnly && canPublicReport && isHedisSignable)
                                                    ? <div>
                                                        <Typography variant='body1' color='primary' className={classes.mt2}>
                                                            To sign your attestation, please go to HEDIS submission home page.
                                                        </Typography>
                                                    </div> : null
                                            }
                                            {
                                                (isCahps && !isCahpsOnly && canPublicReport && !isHedisSignable)
                                                    ? <div>
                                                        <Typography variant='body1' color='primary' className={classes.mt2}>
                                                            HEDIS submission stage Mark Final must be completed to start the attestation.
                                                        </Typography>
                                                    </div> : null
                                            }
                                            <Typography variant='body1' color='primary' className={classes.mt2}>
                                                All the system generated files are available for download from "All Downloads" tab.
                                            </Typography>
                                            {
                                                ((isHedis || (isCahps && isCahpsOnly)) && !canPublicReport) ?
                                                    <Typography variant='body1' color='primary' className={classes.mt2}>
                                                        {this.state.dialogStatus.attestationNoActionText}
                                                    </Typography> : null
                                            }
                                        </CardContent>
                                        <CardActions>
                                            {
                                                (isCahps && isCahpsOnly && canPublicReport)
                                                    ? <div>
                                                        <Fab variant="extended" color="primary" onClick={() => { this.setState({ attestation: { ...this.state.attestation, attestationStarted: true } }) }}>
                                                            {SharedUtility.IsNullOrUndefined$(this.state.submissionData.reportableEvents) ? "Start Attestation" : "Change Attestation"}
                                                        </Fab>
                                                    </div> : null
                                            }
                                            {
                                                (isHedis && (canPublicReport || canSignHedis)) ||
                                                    (isHOS && (canPublicReport || this.state.userAction.canSignAttestationHos))
                                                    ?
                                                    <div>
                                                        <Fab variant="extended" color="primary" onClick={() => { this.setState({ attestation: { ...this.state.attestation, attestationStarted: true } }) }}>
                                                            {SharedUtility.IsNullOrUndefined$(this.state.submissionData.reportableEvents) ? "Start Attestation" : "Change Attestation"}
                                                        </Fab>
                                                    </div>
                                                    : null
                                            }
                                            {this.state.userAction.canSubmissionGenericAdmin ?
                                                <div>
                                                    <Fab variant="extended" color="secondary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalUndoOpen: true } }) }}>
                                                        UnMark Final
                                                    </Fab>
                                                    <Dialog
                                                        open={this.state.dialogStatus.markFinalUndoOpen}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                        TransitionComponent={Transition}
                                                    >
                                                        <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.markFinalUndoTitle}</Typography></DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                <Typography className={classes.secondaryText}>{this.state.dialogStatus.markFinalUndoText}</Typography>
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalUndoOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={() => this.executePlanAction(SubmissionActionTypes.MarkFinalUnlock)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                                Continue
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div> : null
                                            }
                                        </CardActions>
                                    </Card>

                                : null
                            }
                        </Grid >
                    </> :
                        <Grid key={6} item={true} xs={4} className={classes.gridDisplay}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.primaryBlue}>
                                        Attestation
                                    </Typography>
                                    <Typography variant='body1' color='primary'>
                                        No further actions are needed on your part. Designated organization users should proceed to complete the submission.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {isCahps ?
                        <Grid key={2} item={true} xs={6} className={classes.gridDisplay}>
                            {this.renderSubmissionDownloads(isHedis)}
                        </Grid> : null
                    }
                    <Grid key={3} item={true} xs={4} className={classes.gridDisplay}>
                        {this.renderSubmissionDetails()}
                    </Grid>
                </>
            case COMPLETE_STEP:
                return <>
                    <Grid key={6} item={true} xs={6} className={classes.gridDisplay}>
                        <Card className={classes.card}>
                            <CardContent>
                                <ConfettiExplosion />
                                <div>
                                    {
                                        isHedis ?
                                            <Typography variant='body1' color='primary'>
                                                Congratulations! Submission process complete. <br /> <br />
                                                Download final files from the "All downloads" tab or "Bulk Downloads" on the left panel.
                                            </Typography> :
                                            <Typography variant='body1' color='primary'>
                                                Submission process complete.
                                            </Typography>
                                    }
                                </div>
                            </CardContent>
                            <CardActions>
                                {this.state.userAction.canSubmissionGenericAdmin ?
                                    <div>
                                        <Fab variant="extended" color="secondary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, attestationUndoOpen: true } }) }}>
                                            Rollback
                                        </Fab>
                                        <Dialog
                                            open={this.state.dialogStatus.attestationUndoOpen}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            TransitionComponent={Transition}
                                        >
                                            <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.attestationUndoTitle}</Typography></DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    <Typography className={classes.secondaryText}>{this.state.dialogStatus.attestationUndoText}</Typography>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, attestationUndoOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                                    Cancel
                                                </Button>
                                                {isCahps || isHOS ?
                                                    <Button onClick={() => this.executePlanAction(SubmissionActionTypes.MarkFinalUnlock)} autoFocus disabled={this.state.isGenericActionHappening}>
                                                        Continue
                                                    </Button> :
                                                    <Button onClick={() => this.handleAttestationUndo()} autoFocus disabled={this.state.isGenericActionHappening}>
                                                        Continue
                                                    </Button>}
                                            </DialogActions>
                                        </Dialog>
                                    </div> : null
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    {isCahps ?
                        <Grid key={2} item={true} xs={6} className={classes.gridDisplay}>
                            {this.renderSubmissionDownloads(isHedis)}
                        </Grid> : null
                    }

                    <Grid key={3} item={true} xs={3} className={classes.gridDisplay}>
                        {this.renderSubmissionDetails()}
                    </Grid>
                </>
            default:
                return <div>This is not a valid stage</div >;

        }
    }

    private renderUndoPlanLock() {
        const { classes } = this.props;
        return (
            <>
                <Fab variant="extended" color="secondary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, planLockUndoOpen: true } }) }}>
                    Undo Plan Lock
                </Fab>
                <Dialog
                    open={this.state.dialogStatus.planLockUndoOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title"><Typography className={classes.primaryBlueSmall}>{this.state.dialogStatus.planLockUndoTitle}</Typography></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography className={classes.secondaryText}>{this.state.dialogStatus.planLockUndoText}</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, planLockUndoOpen: false } }) }} className={classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.executePlanAction(SubmissionActionTypes.PlanUnlock)} autoFocus disabled={this.state.isGenericActionHappening}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    private validationColumnWithTooltip(value: string, tableMeta: MUIDataTableMeta) {
        let indicatorKey: string = tableMeta.rowData[4];
        let ttStr: string = (indicatorKey === null || indicatorKey.length === 0) ? tableMeta.rowData[5] : ("[IndicatorKey:" + indicatorKey + "] " + tableMeta.rowData[5]);
        return (
            <LightTooltip title={ttStr}>
                <div>{value}</div>
            </LightTooltip>
        );
    }
    private renderValdiationTable(validation: IValidationResponsePayload) {
        let showMeasureCode: boolean = this.state.validation.validationErrors.filter(x => x.measureCode !== null && x.measureCode.length > 0).length > 0;
        let showShortIndicatorName: boolean = this.state.validation.validationErrors.filter(x => x.shortIndicatorName !== null && x.shortIndicatorName.length > 0).length > 0;
        let showSeverity: boolean = this.state.validation.validationErrors.filter(x => x.severity !== null && x.severity.length > 0).length > 0;
        let showRuleName: boolean = this.state.validation.validationErrors.filter(x => x.ruleName !== null && x.ruleName.length > 0).length > 0;
        const validationErrorsColumns: MUIDataTableColumnDef[] = [
            {
                label: "Measure",
                name: "MeasureCode",
                options: {
                    filter: showMeasureCode,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return this.validationColumnWithTooltip(value, tableMeta);
                    },
                    display: (showMeasureCode ? 'true' : 'false') as ('true' | 'false' | 'excluded'),
                }
            },
            {
                label: "Indicator",
                name: "ShortIndicatorName",
                options: {
                    filter: showShortIndicatorName,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return this.validationColumnWithTooltip(value, tableMeta);
                    },
                    display: (showShortIndicatorName ? 'true' : 'false') as ('true' | 'false' | 'excluded'),
                }
            },
            {
                name: "Severity",
                options: {
                    filter: showSeverity,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (showRuleName ? this.validationColumnWithTooltip(value, tableMeta) : <div>{value}</div>);
                    },
                    display: (showSeverity ? 'true' : 'false') as ('true' | 'false' | 'excluded'), //column not show for CAHPS
                }
            },
            {
                label: "Rule Name",
                name: "RuleName",
                options: {
                    filter: showRuleName,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return this.validationColumnWithTooltip(value, tableMeta);
                    },
                    display: (showRuleName ? 'true' : 'false') as ('true' | 'false' | 'excluded'),
                }
            },
            {
                name: "IndicatorKey",
                options: {
                    display: 'excluded',
                    filter: false
                }
            },
            {
                name: "Message",
                options: {
                    // Based on ruleName
                    display: (showRuleName ? 'excluded' : 'true') as ('true' | 'false' | 'excluded'),
                    filter: false
                }
            },
            {
                name: "Details",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div>

                                <table>
                                    <tbody>
                                        <tr >
                                            <td>
                                                <Tooltip title="Show Details" aria-label="validationDetail">
                                                    <IconButton size='small' aria-label="validationDetail" onClick={() => this.ShowValidationDialog(tableMeta.rowData[0], tableMeta.rowData[1], tableMeta.rowData[2], tableMeta.rowData[3], tableMeta.rowData[4], tableMeta.rowData[5])}>
                                                        <PageviewOutlinedIcon fontSize="inherit"></PageviewOutlinedIcon>
                                                    </IconButton >
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        );
                    },
                    display: (showRuleName ? 'true' : 'false') as ('true' | 'false' | 'excluded'),
                },
            }
        ];
        const validationErrorsOptions: MUIDataTableOptions = {
            filterType: "multiselect" as any,
            sort: true,
            filter: this.state.submissionData.component.includes("DST") || this.state.submissionData.component.includes("HEDIS"),
            print: false,
            download: !(this.state.submissionData.component.includes("DST") || this.state.submissionData.component.includes("HEDIS")),
            downloadOptions: {
                filename: 'Validations.csv',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            },
            viewColumns: false,
            selectableRows: "none",
            caseSensitive: false,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
        };
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={this.state.validationDialogOpen} onClose={() => this.CloseValidationDialog()} className={classes.dialogM} TransitionComponent={Grow}>
                    <DialogTitle >
                        <Typography className={classes.primaryBlueMedium}>
                            Validation Details
                        </Typography>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid xs={10} item={true} className={classes.flexGrow + " " + classes.fullWidth}>
                            {this.state.currentValidationItem !== null && this.state.currentValidationItem.measureCode !== null && this.state.currentValidationItem.measureCode.length > 0 ?
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Measure Code</InputLabel>
                                    <Typography variant="subtitle1" display="block" gutterBottom>
                                        {this.state.currentValidationItem.measureCode}
                                    </Typography>
                                </Box> : null
                            }
                            {this.state.currentValidationItem !== null && this.state.currentValidationItem.shortIndicatorName !== null && this.state.currentValidationItem.shortIndicatorName.length > 0 ?
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Short Indicator Name</InputLabel>
                                    <Typography variant="subtitle1" display="block" gutterBottom>
                                        {this.state.currentValidationItem.shortIndicatorName}
                                    </Typography>
                                </Box> : null
                            }
                            {this.state.currentValidationItem !== null && this.state.currentValidationItem.indicatorKey !== null && this.state.currentValidationItem.indicatorKey.length > 0 ?
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Indicator Key</InputLabel>
                                    <Typography variant="subtitle1" display="block" gutterBottom>
                                        {this.state.currentValidationItem.indicatorKey}
                                    </Typography>
                                </Box> : null
                            }
                            {this.state.currentValidationItem !== null && this.state.currentValidationItem.severity !== null && this.state.currentValidationItem.severity.length > 0 ?
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Severity</InputLabel>
                                    <Typography variant="subtitle1" display="block" gutterBottom>
                                        {this.state.currentValidationItem.severity}
                                    </Typography>
                                </Box> : null
                            }
                            {this.state.currentValidationItem !== null && this.state.currentValidationItem.ruleName !== null && this.state.currentValidationItem.ruleName.length > 0 ?
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Rule Name</InputLabel>
                                    <Typography variant="subtitle1" display="block" gutterBottom>
                                        {this.state.currentValidationItem.ruleName}
                                    </Typography>
                                </Box> : null
                            }
                            {this.state.currentValidationItem !== null && this.state.currentValidationItem.message !== null && this.state.currentValidationItem.message.length > 0 ?
                                <Box flexGrow={1} className={classes.defaultMargin}>
                                    <InputLabel shrink>Message</InputLabel>
                                    <Typography variant="subtitle2" display="block" gutterBottom>
                                        {this.state.currentValidationItem.message}
                                    </Typography>
                                </Box> : null
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.CloseValidationDialog()}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={<span><Typography className={classes.primaryBlue + " " + classes.marginBottom}>Validations</Typography></span>}
                            data={validation.validationErrors.map((v) => {
                                return [
                                    v.measureCode,
                                    v.shortIndicatorName,
                                    v.severity,
                                    v.ruleName,
                                    v.indicatorKey,
                                    v.message
                                ]
                            })}
                            columns={validationErrorsColumns}
                            options={validationErrorsOptions}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
    private ShowValidationDialog = (measureCode: string, shortIndicatorName: string, severity: string, ruleName: string, indicatorKey: string, message: string) => {
        this.setState({
            validationDialogOpen: true,
            currentValidationItem: {
                measureCode: measureCode,
                shortIndicatorName: shortIndicatorName,
                severity: severity,
                ruleName: ruleName,
                indicatorKey: indicatorKey,
                message: message
            }
        });
    }
    private CloseValidationDialog() {
        this.setState({
            validationDialogOpen: false,
        });
    }
    private renderValidationComponent(payload: ISubmissionHomePayload, validation: IValidationResponsePayload) {
        const { classes } = this.props;
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {(payload.didValidationError || payload.didValidationWarning) ? <>
                        <Button className={classes.linkButtonClear} onClick={() => { this.downloadValidationReport(payload, validation) }}>Download Validation Report</Button>
                    </> : null}
                </Grid>
                <Grid item xs={12}>
                    {this.renderValidationByType(payload, validation)}
                </Grid>
            </Grid>
        );
    }

    private renderValidationByType = (payload: ISubmissionHomePayload, validation: IValidationResponsePayload) => {
        const { classes } = this.props;
        return <div>
            <TextareaAutosize
                name="validationText"
                className={classes.fullWidth}
                id="multiline-filled-static"
                disabled={true}
                rowsMax={25}
                value={validation.validationText}
            />
        </div>
    }

    private downloadValidationReport = (payload: ISubmissionHomePayload, validation: IValidationResponsePayload) => {
        var subId = payload.subId;
        var validationText = validation.validationText;
        var fileName = '';
        if (this.state.component.includes("CAHPS")) {
            fileName = this.state.submissionData.submissionDownloads.filter(x => x.displayName === "ValidationResults")[0].originalName;
        }
        else {
            fileName = `VALIDATION${subId}.CSV`;
        }
        saveAs(new Blob([validationText], { type: 'text/plain' }), fileName);
    }

    private renderCanMarkFinal() {
        const isCahps = this.state.component.includes("CAHPS");
        const isHOS = this.state.component.includes("HOS");
        const isHedis = this.state.component.includes("HEDIS") || this.state.component.includes("DST");
        return <div>
            {(isHedis && this.state.userAction.canMarkFinalHedis) || (isCahps && this.state.userAction.canMarkFinalCahps) || (isHOS && this.state.userAction.canMarkFinalHos) ?
                <div>
                    {
                        isHedis ? <Typography variant='body1' color='primary'>
                            Your Submission has been Audit locked.

                            You may proceed with marking your submission final after you have reviewed the Auditor Review Table.
                            Once marked final, the attestation process will begin, and your signer will complete the submission process.
                        </Typography> : null
                    }
                    <Fab variant="extended" disabled={!this.state.isSysAdmin && this.state.submissionData.didValidationError} color="primary" onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalOpen: true } }) }}>
                        Apply Mark Final
                    </Fab>
                    <Dialog
                        open={this.state.dialogStatus.markFinalOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        TransitionComponent={Transition}
                    >
                        <DialogTitle id="alert-dialog-title"><Typography className={this.props.classes.primaryBlueSmall}>{this.state.dialogStatus.markFinalTitle}</Typography></DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography className={this.props.classes.secondaryText}>{this.state.dialogStatus.markFinalText}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalOpen: false } }) }} className={this.props.classes.disabledButton} disabled={this.state.isGenericActionHappening}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.executePlanAction(SubmissionActionTypes.MarkFinal)} autoFocus disabled={this.state.isGenericActionHappening}>
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div> : null
            }
        </div>
    }

    public onDrop(files) {
        var validForUpload = true;
        this.setState({ uploadSection: { ...this.state.uploadSection, uploadCompletionPercent: 0 } });
        this.setState({ uploadSection: { ...this.state.uploadSection, fileProcessingComplete: false } });
        const data = new FormData();
        this.setState({ uploadSection: { ...this.state.uploadSection, uploadValidationMessages: [] } });
        if (files.length === 0) {
            this.setState({ uploadSection: { ...this.state.uploadSection, uploadValidationMessages: [this.CreateValidationMessage('Please select a valid file')] } });
            validForUpload = false;
        }

        files.forEach(file => {
            if (submissionType === "DST") {
                if (file.name === null || !file.name.toLocaleLowerCase().includes(this.state.submissionData.productLine.toLocaleLowerCase())
                    || !file.name.toLocaleLowerCase().includes(this.state.submissionData.subId.toString().toLocaleLowerCase())) {
                    this.setState({ uploadSection: { ...this.state.uploadSection, uploadValidationMessages: [this.CreateValidationMessage('Invalid file name (need to include product line and submission Id for the current submission).')] } });
                    validForUpload = false;
                    return;
                }
            }
            else {
                if (file.name === null || !file.name.toLocaleLowerCase().includes(this.state.submissionData.subId.toString().toLocaleLowerCase())) {
                    this.setState({ uploadSection: { ...this.state.uploadSection, uploadValidationMessages: [this.CreateValidationMessage('Invalid file name.  Submission ID must match the current submission.')] } });
                    validForUpload = false;
                    return;
                }
            }
            data.append("files", file, file.name);
        });

        let submissionType = this.state.component;
        if (submissionType === "DST") {
            submissionType = "HEDIS";
        }

        if (validForUpload) {
            const req = request.post(process.env.REACT_APP_MYWEBSITE_BASESERVICEURL + "/api/bulkupload/uploadfiles");
            req.query({ submissionType: submissionType });
            req.query({ submissionId: this.state.submissionData.subId });
            req.set({ 'Authorization': 'Bearer ' + this.props.user.access_token });

            // show upload progress 
            req.on('progress', event => {
                const percent = Math.floor(event.percent);
                if (!isNaN(percent)) {
                    if (percent >= 100) {
                        this.setState({ uploadSection: { ...this.state.uploadSection, uploadCompletionPercent: 100 } });
                    } else {
                        this.setState({ uploadSection: { ...this.state.uploadSection, uploadCompletionPercent: percent } });
                    }
                }
            });
            this.setState({ uploadSection: { ...this.state.uploadSection, uploadFiles: files } });
            // send file(s) to server
            req.send(data)
                .end((err, res) => this.postUploadFiles(err, res));
        }
    }

    private handleAttestationFlagChange(attestationFlag, checked) {
        switch (attestationFlag) {
            case "PublicReporting":
                this.setState({ submissionData: { ...this.state.submissionData, isPublicReported: checked } });
                break;
            case "Somc":
                this.setState({ submissionData: { ...this.state.submissionData, somc: checked } });
                break;
            default:
                break;
        }
    }

    private async postUploadFiles(err, res) {
        if (err) {
            this.setState({ uploadSection: { ...this.state.uploadSection, uploadValidationMessages: [this.CreateValidationMessage("Upload not Successful")] } });
        }
        else {
            this.setState({ validationResult: res.body.uploadDetail.validationResult });
            if (res.body.success) {
                this.setState({
                    uploadSection: {
                        ...this.state.uploadSection,
                        fileProcessingComplete: true,
                        uploadSuccessful: true
                    }
                });
            }
            else {
                this.setState({
                    uploadSection: {
                        ...this.state.uploadSection,
                        fileProcessingComplete: true,
                        uploadSuccessful: false,
                        uploadValidationMessages: res.body.uploadDetail.validationResult.messages
                    }
                });
            }


            await this.getSubmissionSummary(true);
        }
    }

    private executePlanAction = async (action: SubmissionActionTypes): Promise<void> => {
        this.setState({ isGenericActionHappening: true });
        const apiCall = SubmissionsService.ExecutePlanAction$(this.props.user, this.state.currentSubId, this.state.submissionData.component, action);
        const userResponse = await apiCall.toPromise();
        if (userResponse instanceof AjaxError || !userResponse) {
            this.setRedirectState();
            this.setState({ isGenericActionHappening: false });
        }
        else {
            this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalUndoOpen: false } });
            this.setState({ dialogStatus: { ...this.state.dialogStatus, markFinalOpen: false } });
            this.setState({ dialogStatus: { ...this.state.dialogStatus, planLockOpen: false } });
            this.setState({ dialogStatus: { ...this.state.dialogStatus, auditLockUndoOpen: false } });
            this.setState({ dialogStatus: { ...this.state.dialogStatus, auditLockOpen: false } });
            this.setState({ dialogStatus: { ...this.state.dialogStatus, planLockUndoOpen: false } });
            this.setState({ attestation: { ...this.state.attestation, attestationStarted: false } });
            this.setState({ dialogStatus: { ...this.state.dialogStatus, attestationUndoOpen: false } });

            await this.getSubmissionSummary(true);
            this.setState({ isGenericActionHappening: false });
        }
    }

    private async unSubmitFile() {
        this.setState({ isGenericActionHappening: true });
        const retval = await SubmissionsService.UnSubmitFile$(this.props.user, this.state.submissionData.subId, this.state.submissionData.component).toPromise();
        this.setState({ uploadSection: { ...this.state.uploadSection, uploadCompletionPercent: 0 } });
        this.setState({ uploadSection: { ...this.state.uploadSection, fileProcessingComplete: false } });
        this.setState({ uploadSection: { ...this.state.uploadSection, uploadValidationMessages: [] } });
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            this.setState({ dialogStatus: { ...this.state.dialogStatus, clearMeasuresOpen: false } });
            this.setState({ currentTabVal: 0 });
            await this.getSubmissionSummary(true);
            this.setState({ isGenericActionHappening: false });
            console.log(this.state);
            alert("All measures data from this submission has been cleared.")
        }
    }

    private async reprocessSubmission() {
        const groupId = uuidv4();
        const retval = await SubmissionsService.ReprocessSubmission$(this.props.user, this.state.submissionData.subId, this.state.submissionData.component, groupId).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
    }

    private async updateSubmissionAttestationFlags() {
        this.setState({ isAdminUpdatingAttestFlags: true });
        const attestationFlags: ISubmissionAttestationFlags = {
            submissionId: this.state.submissionData.subId,
            component: this.state.submissionData.component,
            isPublicReported: this.state.submissionData.isPublicReported || false,
            somc: this.state.submissionData.somc || false,
            sourceLastModified: this.state.submissionData.attestationModifiedDate
        };
        const retval = await SubmissionsService.UpdateSubmissionAttestationFlags$(this.props.user, attestationFlags).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            this.setState({ isFlagUpdateSuccessful: true },
                () => setTimeout(() => {
                    this.setState({ isFlagUpdateSuccessful: false });
                }, 3000));
            await this.getSubmissionSummary(true);
        }

        this.setState({ isAdminUpdatingAttestFlags: false });
    }

    private renderSubmissionDetails = () => {
        const { classes } = this.props;
        return (Renderings.renderSubmissionSummary(this.state.auths, this.state.submissionData, classes))
    }

    private renderSubmissionDownloads = (isHedis: boolean) => {
        const { classes } = this.props;
        const showValidation = (isHedis && this.state.userAction.canViewDownloadErrorsHedis) || (!isHedis && this.state.userAction.canViewDownloadErrorsNonHedis);
        return (isHedis && this.state.userAction.canDownloadResultHedis) || (!isHedis && (this.state.userAction.canDownloadResultCahps || this.state.userAction.canDownloadResultHos)) ? <>
            <Card className={classes.card}>
                {this.state.uploadSection.uploadCompletionPercent === 100 && !this.state.uploadSection.fileProcessingComplete ?
                    <CardContent>
                        <Typography className={classes.primaryBlue + " " + classes.marginBottom}>
                            Submission Downloads
                        </Typography>
                        <LoadingDataTable titleText="*" />
                    </CardContent>
                    :
                    <CardContent>
                        <Typography className={classes.primaryBlue}>
                            Submission Downloads
                        </Typography>
                        <Typography variant='body2' color='inherit'>
                            Contains available downloads
                        </Typography>
                        <GridList cellHeight={60} cols={1}>
                            {this.state.submissionData.submissionDownloads.filter(x => !x.isArchived && !x.isAllDownloadsOnly && !x.isLicense && (showValidation || !x.displayName.toLocaleLowerCase().includes("validation"))).map((tile, i) => (
                                <GridListTile key={"Download" + i} >
                                    <Grid container={true} spacing={1}>
                                        <Grid item={true} xs={9}>
                                            <Typography variant='body2' color="inherit"  >
                                                <Button
                                                    color='primary'
                                                    style={{ fontWeight: 'bold', backgroundColor: 'transparent', color: 'DimGray' }}
                                                    className={classes.button}
                                                    onClick={() => this.downloadFile(tile.key)}
                                                    startIcon={<InsertDriveFile />}
                                                >
                                                    {tile.displayName}
                                                </Button>
                                            </Typography>
                                            <Typography variant='body2' color="textSecondary" style={{ paddingLeft: '10px' }}>
                                                {tile.explanation}
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={3}>
                                            <Typography variant='subtitle2' color="inherit" style={{ fontWeight: 'bold', paddingTop: '7px' }}>{tile.uploadDay}</Typography>
                                            <Typography variant='body2' color="inherit">{tile.uploadTime}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider></Divider>
                                </GridListTile>
                            ))}
                        </GridList>
                        {this.state.submissionData.submissionDownloads.length === 0 ?
                            <Typography variant='body1' color='secondary'>
                                You have no downloads available.
                            </Typography> : null}
                    </CardContent>
                }
            </Card>
        </> : null

    }

    private handleAttestationStepsComplete = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        event.preventDefault();
        this.executePlanAction(SubmissionActionTypes.Attestation);
        this.setState({ attestation: { ...this.state.attestation, attestationSigned: true } });

    }


    private handleAttestationStepsCancel = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        event.preventDefault();

        this.setState({ attestation: { ...this.state.attestation, attestationStarted: false } });
    }

    private handleAttestationUndo = async () => {
        this.setState({ isGenericActionHappening: true });
        const apiCall = SubmissionsService.UndoSubmissionAttestation$(this.props.user, this.state.currentSubId, this.state.component);
        const undoResponse = await apiCall.toPromise();
        if (undoResponse instanceof AjaxError || !undoResponse) {
            this.setRedirectState();
        }
        else {
            await this.executePlanAction(SubmissionActionTypes.AttestationUndo);
        }
        this.setState({ isGenericActionHappening: false });
    }

    private CreateValidationMessage = (message: string): ValidationMessage => {
        return { message: message, severity: 4 };
    }

    private getValidationMessageList = () => {
        const { classes } = this.props;
        const isCahps = this.state.component.includes("CAHPS");
        var message = 'Validation issues found';
        if (isCahps) {
            message = 'Validation errors found';
        }
        if (this.state.uploadSection.uploadValidationMessages.length > 1
            || this.state.uploadSection.uploadValidationMessages.filter(m => m.message === "Revise" || m.message === "Review").length >= 1) {
            return (<div>{message}</div>);
        }
        else {
            return (
                <ul className={classes.validationMessageList}>
                    {this.state.uploadSection.uploadValidationMessages.map(function (valMessage, i) {
                        return (<li key={"message" + i} dangerouslySetInnerHTML={{ __html: SharedUtility.NewLinesToBreaks$(valMessage.message) }}></li>)
                    })}
                </ul>
            );
        }

    }
}

// REDUX CONTAINER
const MeasurerViewerViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(SubmissionHome);

// STYLES CONTAINER
const SubmissionHomePage = withStyles(styles)(MeasurerViewerViewWithState);

export default SubmissionHomePage;


