import * as actionTypes from '../SubmissionListing/Action';

const initialState = {
    selectedSubmissionColumns: [],
    showAllSubmissions: false,
    rowsPerPageSelected: 25,
    storedFilterList: null
};

const SubmissionListingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_COLUMN:
            return Object.assign({}, state, { selectedSubmissionColumns: state.selectedSubmissionColumns.concat(action.payload) });
        case actionTypes.REMOVE_COLUMN:
            return Object.assign({}, state, { selectedSubmissionColumns: state.selectedSubmissionColumns.filter(c => c !== action.payload) });
        case actionTypes.SET_INITIAL_COLUMNS:
            return Object.assign({}, state, { selectedSubmissionColumns: action.payload });
        case actionTypes.SET_ALL_SUBMISSIONS:
            return Object.assign({}, state, { showAllSubmissions: action.payload });
        case actionTypes.SET_SUBMISSION_PER_PAGE:
            return Object.assign({}, state, { rowsPerPageSelected: action.payload });
        case actionTypes.SET_TABLE_FILTER:
            return Object.assign({}, state, { storedFilterList: action.payload})
        default:
            return state;
    }
};

export default SubmissionListingReducer;