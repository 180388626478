export interface IProductTypeTranslationString {
    CAHPS: string;
    HOS: string;
    SubmissionRedesign: string;
}

export class ProductTranslator {
    public static getProductTypeName(productType: string, translationInterface: IProductTypeTranslationString): string {
        switch (productType) {
            case "CAHPS":
            case "AdultCAHPS":
            case "ChildCAHPS":
            case "ChildCAHPSCCC":
                return translationInterface.CAHPS;
            case "HOS":
            case "HOSFollowup":
            case "HOSBaseline":
                return translationInterface.HOS;
            case "HEDISLegacy":
            case "DST":
            case "ECDS":
            case "SubmissionRedesign":
                return translationInterface.SubmissionRedesign
            default:
                return "UNKNOWN OPTION SELECTED " + productType;
        }
    }
}

export class AllowableFileTypesText implements IProductTypeTranslationString {
    CAHPS: string = "Allowable file type:  .csv";
    HOS: string = "Allowable file type:  .txt";
    SubmissionRedesign: string = "Allowable format:  <<Productline>><<SubmissionId>>.xml";
}

export class AllowableFileTypes implements IProductTypeTranslationString {
    CAHPS: string = ".csv";
    HOS: string = ".txt";
    SubmissionRedesign: string = ".xml";
}

//#region Plan Lock

export class PlanLockButtonTitle implements IProductTypeTranslationString {
    CAHPS: string = "Lock CAHPS Submission";
    HOS: string = "Lock HOS Submission";
    SubmissionRedesign: string = "Lock HEDIS Submission";
}

export class PlanLockButtonMainText implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to lock this CAHPS Submission?";
    HOS: string = "Are you sure you want to lock this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to lock this HEDIS Submission?";
}

export class PlanLockButtonTitleUndo implements IProductTypeTranslationString {
    CAHPS: string = "Unlock CAHPS Submission";
    HOS: string = "Unlock HOS Submsission";
    SubmissionRedesign: string = "Unlock HEDIS Submission";
}

export class PlanLockButtonMainTextUndo implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to unlock this CAHPS Submission?";
    HOS: string = "Are you sure you want to unlock this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to unlock this HEDIS Submission?";
}

//#endregion

//#region Audit Lock


export class AuditLockButtonTitle implements IProductTypeTranslationString {
    CAHPS: string = "Audit Lock CAHPS Submission";
    HOS: string = "Audit Lock HOS Submsission";
    SubmissionRedesign: string = "Audit Lock HEDIS Submission";
}

export class AuditLockButtonMainText implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to Audit lock this CAHPS Submission?";
    HOS: string = "Are you sure you want to Audit lock this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to Audit lock this HEDIS Submission?";
}
export class AuditLockButtonUndoTitle implements IProductTypeTranslationString {
    CAHPS: string = "Audit Unlock CAHPS Submission";
    HOS: string = "Audit Unlock HOS Submsission";
    SubmissionRedesign: string = "Audit Unlock HEDIS Submission";
}

export class AuditLockButtonUndoMainText implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to Audit unlock this CAHPS Submission?";
    HOS: string = "Are you sure you want to Audit unlock this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to Audit unlock this HEDIS Submission?";
}

export class AuditLockNoActionText implements IProductTypeTranslationString {
    CAHPS: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
    HOS: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
    SubmissionRedesign: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
}

//#endregion

//#region Mark Final


export class MarkFinalButtonTitle implements IProductTypeTranslationString {
    CAHPS: string = "Mark Final CAHPS Submission";
    HOS: string = "Mark Final HOS Submsission";
    SubmissionRedesign: string = "Mark Final HEDIS Submission";
}

export class MarkFinalButtonMainText implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to mark this CAHPS submission final?";
    HOS: string = "Are you sure you want to mark this HOS submission final?";
    SubmissionRedesign: string = "Are you sure want to mark this HEDIS submission final?";
}

export class MarkFinalButtonTitleUndo implements IProductTypeTranslationString {
    CAHPS: string = "Undo Mark Final CAHPS Submission";
    HOS: string = "Undo Mark Final HOS Submsission";
    SubmissionRedesign: string = "Undo Mark Final HEDIS Submission";
}

export class MarkFinalButtonMainTextUndo implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to Undo Mark Final this CAHPS Submission?";
    HOS: string = "Are you sure you want to Undo Mark Final this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to Undo Mark Final this HEDIS Submission?";
}

export class MarkFinalNoActionText implements IProductTypeTranslationString {
    CAHPS: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
    HOS: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
    SubmissionRedesign: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
}

//#endregion

//#region Attestation


export class AttestationButtonTitle implements IProductTypeTranslationString {
    CAHPS: string = "Attestation CAHPS Submission";
    HOS: string = "Attestation HOS Submsission";
    SubmissionRedesign: string = "Attestation HEDIS Submission";
}

export class AttestationButtonMainText implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to Attest to this CAHPS Submission?";
    HOS: string = "Are you sure you want to Attest to this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to Attest to this HEDIS Submission?";
}

export class AttestationButtonTitleUndo implements IProductTypeTranslationString {
    CAHPS: string = "Undo Mark Final CAHPS Submission";
    HOS: string = "Undo Mark Final HOS Submsission";
    SubmissionRedesign: string = "Undo Attestation HEDIS Submission";
}

export class AttestationButtonMainTextUndo implements IProductTypeTranslationString {
    CAHPS: string = "Are you sure you want to Undo Mark Final this CAHPS Submission?";
    HOS: string = "Are you sure you want to Undo Mark Final this HOS Submission?";
    SubmissionRedesign: string = "Are you sure you want to Undo Attestation for this HEDIS Submission?";
}

export class AttestationNoActionText implements IProductTypeTranslationString {
    CAHPS: string = "Designated organization users should proceed to complete the submission.";
    HOS: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
    SubmissionRedesign: string = "No further actions are needed on your part. Designated organization users should proceed to complete the submission.";
}

//#endregion
//#region Clear Measures

export class ClearMeasureButtonTitle implements IProductTypeTranslationString {
    SubmissionRedesign: string = "Clear HEDIS Submission";
    CAHPS: string = "";
    HOS: string = "";
}

export class ClearMeasureButtonMainText implements IProductTypeTranslationString {
    SubmissionRedesign: string = "Are you sure you want to clear this submission measure data?";
    CAHPS: string = "";
    HOS: string = "";
}


//#endregion