import * as React from 'react';
import { connect } from "react-redux";
import { IGenericUserProps, genericMapStateToProps } from '../../DataClasses/GenericUser';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import { SubmissionType } from '../../DataClasses/SharedData';
import { RouteComponentProps } from 'react-router';
import {
    Grid, Typography, Card, CardContent, Stepper, Step, StepLabel, StepContent, Button, Fab
    , Dialog, DialogTitle, DialogContent, DialogActions, Divider, Checkbox, TextField, Tooltip,
    FormControl, FormGroup, FormControlLabel
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { AjaxError } from 'rxjs/ajax';
import SubmissionsService from '../Services/SubmissionsService';
import { Warning as WarningIcon } from '@material-ui/icons';
import SendIcon from '@material-ui/icons/Send';
import { ISelectedSubmissions, ISelectedSubmissionsHolder } from '../../DataClasses/SubmissionData';
import LoadingDataTable from '../Shared/LoadingDataTable';
import { Redirect } from "react-router-dom";
import { HOS_ATTESTATIONITEM1, HOS_ATTESTATIONITEM2 } from '../Shared/Constants'

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    }
});

interface ILocalState {
    isOrgsLoading: boolean;
    isSubsLoading: boolean;
    isSaved: boolean;
    currentStep: number;
    attestingVendor: string;
    orgData: any[];
    attestationLanguage: Map<string, string>;
    selectedOrganizationIndexes: number[];
    submissionData: any[];
    selectedSubmissionIndexes: number[];
    attestationType: SubmissionType;
    attestationInProgress: boolean;
    attestationDialogOpen: boolean;
    attestationItem1Checked: boolean;
    attestationItem2Checked: boolean;
    attester: string;
    vendorName: string;
    redirect: boolean;
}

type IStylesProps = WithStyles<typeof styles>;

class BulkAttestationView extends React.Component<IGenericUserProps & IStylesProps & RouteComponentProps<{ attestationType: SubmissionType.HOS }>, ILocalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOrgsLoading: true,
            isSubsLoading: false,
            isSaved: false,
            currentStep: 0,
            attestingVendor: "",
            orgData: [],
            attestationLanguage: null,
            selectedOrganizationIndexes: [],
            submissionData: [],
            selectedSubmissionIndexes: [],
            attestationType: this.props.match.params.attestationType,
            attestationInProgress: false,
            attestationDialogOpen: false,
            attestationItem1Checked: false,
            attestationItem2Checked: false,
            attester: "",
            vendorName: "",
            redirect: false
        };
    }

    public async componentDidMount() {
        await this.RefreshModel();
    }

    public async componentDidUpdate(prevProps) {
        if (this.props.match.params.attestationType !== prevProps.match.params.attestationType) {
            this.setState({ attestationType: this.props.match.params.attestationType })
            this.ResetState();
        }
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;

        const submissionListColumns = [
            {
                label: "Sub ID",
                name: "submissionId",
                options: {
                    filter: true
                }
            },
            {
                label: "Product Line",
                name: "productLine",
                options: {
                    filter: true
                }
            },
            {
                label: "Component",
                name: "component",
                options: {
                    filter: true
                }
            },
            {
                label: "Org ID",
                name: "orgId",
                options: {
                    filter: true
                }
            },
            {
                label: "Org Name",
                name: "orgName",
                options: {
                    filter: true
                }
            },
            {
                label: "Validation Status",
                name: "validationStatus",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === 'NoIssues')
                            return <div className={`${classes.Default} ${classes.Success}`}>{value}</div>;
                        else if (value === 'Warning')
                            return <div className={`${classes.Default} ${classes.Warning}`}>{value}</div>;
                        else
                            return value;
                    }
                }
            },
            {
                label: "IsAttested",
                name: "isAttested",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === "No")
                            return <div className={`${classes.Default} ${classes.Warning}`}>{value}</div>;
                        else
                            return value;
                    }
                }
            }
        ];

        const submissionListingOptions = {
            sort: true,
            filterType: "multiselect" as any,
            print: false,
            download: false,
            viewColumns: false,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000],
            selectableRowsOnClick: true,
            rowsSelected: this.state.selectedSubmissionIndexes,
            onRowSelectionChange: (affectedRows, allSelectedRows, rowsSelected) => {
                this.setState({
                    selectedSubmissionIndexes: allSelectedRows.map(item => item.dataIndex)
                });
            },
            isRowSelectable: (dataIndex, allRows) => {
                return (this.state.submissionData[dataIndex].validationStatus === "NoIssues" || this.state.submissionData[dataIndex].validationStatus === "Warning") && this.state.submissionData[dataIndex].isAttested === "No";
            },
            customToolbarSelect: () => { return null }
        };

        return <Grid container={true} className={classes.gridRowFlex} spacing={2}>
            <Grid item={true} xs={12}>
                <Typography className={classes.submissionTitle}>
                    Bulk Attestation ({this.state.attestationType.toUpperCase()})
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Card>
                    <CardContent>
                        <div className={classes.root}>
                            <Stepper activeStep={this.state.currentStep} orientation="vertical">
                                <Step key="SelectSubStep">
                                    <StepLabel>Select Submissions</StepLabel>
                                    <StepContent>
                                        {this.state.isSubsLoading ? <LoadingDataTable /> :
                                            (this.state.submissionData.length > 0 ?
                                                <MUIDataTable
                                                    title={null}
                                                    data={this.state.submissionData}
                                                    columns={submissionListColumns}
                                                    options={submissionListingOptions}
                                                />
                                                : <Typography variant='body1' color="inherit">
                                                    <WarningIcon className={classes.mb1Neg} color="error" /> The organization(s) selected does not have any submissions, please go back and select another.
                                                </Typography>
                                            )
                                        }

                                        <div className={classes.actionsContainer}>
                                            <div>
                                                {true ? null :
                                                    <Button
                                                        onClick={() => this.GoBackToSelectOrg()}
                                                        className={classes.button} >
                                                        Back
                                                    </Button>
                                                }
                                                <Fab variant="extended" color="secondary" onClick={() => this.openAttestationDialog()} disabled={this.state.selectedSubmissionIndexes.length < 1 || this.state.attestationInProgress} className={classes.fab}>
                                                    Attestation
                                                </Fab>
                                                {
                                                    this.state.isSaved ? <Typography variant='h6' color='primary'>
                                                        Your selections have been saved.
                                                    </Typography> : null
                                                }

                                                <Dialog open={this.state.attestationDialogOpen} classes={{ paper: classes.dialogM }}>
                                                    <DialogTitle id="max-width-dialog-title">
                                                        HOS Data Attestation Statement
                                                    </DialogTitle>
                                                    <Divider />
                                                    <DialogContent>
                                                        <br />
                                                        <FormControl required>
                                                            {this.state.attestationLanguage && this.state.attestationLanguage !== null ?
                                                                <>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox color="primary"
                                                                                    checked={this.state.attestationItem1Checked}
                                                                                    onChange={() => this.setState({ attestationItem1Checked: !this.state.attestationItem1Checked })}
                                                                                />
                                                                            }
                                                                            label={this.state.attestationLanguage[HOS_ATTESTATIONITEM1].replace('{0}', this.state.attestingVendor)}
                                                                        />
                                                                        <br />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox color="primary"
                                                                                    checked={this.state.attestationItem2Checked}
                                                                                    onChange={() => this.setState({ attestationItem2Checked: !this.state.attestationItem2Checked })}
                                                                                />
                                                                            }
                                                                            label={this.state.attestationLanguage[HOS_ATTESTATIONITEM2]}
                                                                        />
                                                                    </FormGroup>
                                                                </>
                                                                : null}
                                                            <br /><br />
                                                            <Typography variant='body1' color='secondary' className={classes.marginTop} >
                                                                * Please accept the above statements, complete the fields below, and click the "Submit" button to complete the HOS Data Attestation
                                                            </Typography>
                                                            <br />
                                                            <TextField value={this.state.attester} label="Name of data representative" variant="outlined" onChange={e => this.onAttesterChange(e)} />
                                                            <br />
                                                            <TextField value={this.state.vendorName} label="Survey vendor organization name" variant="outlined" onChange={e => this.onVendorNameChange(e)} />
                                                        </FormControl>
                                                        <br /><br /><br />
                                                        <Tooltip title={!this.state.attestationItem1Checked || !this.state.attestationItem2Checked || this.state.attester === "" || this.state.vendorName === "" ? "Accept all the statements and complete all the fields to enable the button" : ""}>
                                                            <span>
                                                                <Fab className={classes.fab} variant="extended" color="secondary" onClick={() => this.AttestationSelected()} disabled={!this.state.attestationItem1Checked || !this.state.attestationItem2Checked || this.state.attester === "" || this.state.vendorName === ""}>
                                                                    <SendIcon className={classes.extendedIcon} />
                                                                    Submit
                                                                </Fab>
                                                            </span>
                                                        </Tooltip>
                                                    </DialogContent>
                                                    <Divider />
                                                    <DialogActions>
                                                        <Button
                                                            className={classes.disabledButton}
                                                            onClick={() => this.cancelAttestation()}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        //}
    }

    private openAttestationDialog() {
        this.setState({ isSaved: false })
        this.setState({ attestationDialogOpen: true })
    }

    private onAttesterChange(e) {
        this.setState({ attester: e.target.value })
    }
    private onVendorNameChange(e) {
        this.setState({ vendorName: e.target.value })
    }
    private cancelAttestation() {
        this.setState({ attestationDialogOpen: false, attestationItem1Checked: false, attestationItem2Checked: false, attester: "", vendorName: "" })
    }

    private async RefreshModel() {
        await this.LoadSubmissionsForOrg();
        await this.GetHosSubmissionsAttestationLanguage();
    }

    private async ResetState() {
        this.setState({
            currentStep: 0,
            orgData: [],
            selectedOrganizationIndexes: [],
            submissionData: [],
            selectedSubmissionIndexes: []
        });
        await this.RefreshModel();
    }

    private async LoadSubmissionsForOrg() {
        this.setState({ isSubsLoading: true });
        const subRetVal = await SubmissionsService.GetSubmissionsOfTypeForOrgsByAction$(
            this.props.user,
            [] as any,
            this.state.attestationType,
            "attestation"
        ).toPromise();

        if (subRetVal instanceof AjaxError || !subRetVal) {
            this.setRedirectState();
        }
        else {
            const subData: any[] = (subRetVal.response as any).map(item => {
                return {
                    submissionId: item.subId,
                    productLine: item.productLine,
                    component: item.component,
                    orgId: item.orgId,
                    orgName: item.orgName,
                    validationStatus: item.validationStatus,
                    isAttested: item.isAttested ? 'Yes' : 'No'
                };
            });

            this.setState({
                submissionData: subData
            }, () => this.setState({ isSubsLoading: false }));
        }
    }

    private GoBackToSelectOrg() {
        this.setState({
            currentStep: 0,
            submissionData: [],
            selectedSubmissionIndexes: []
        });
    }

    private AttestationSelected() {
        const submissions: ISelectedSubmissions[] = this.state.selectedSubmissionIndexes.map(i => {
            let sub = this.state.submissionData[i];
            return {
                submissionId: sub.submissionId,
                productLine: sub.productLine,
                component: sub.component
            };
        });
        if (this.state.attestationType !== SubmissionType.HOS)
            throw new Error("Not Implemented for" + this.state.attestationType.toString());

        this.BulkAttestation({
            submissions: submissions,
            fileTypes: []
        });
    }

    private async BulkAttestation(bulkSubs: ISelectedSubmissionsHolder) {
        this.setState({ attestationInProgress: true });
        const retval = await SubmissionsService.SubmissionsBulkAttestation$(
            this.props.user,
            bulkSubs,
            this.state.attester,
            this.state.vendorName
        ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        }
        else {
            this.setState({ isSaved: true })
            this.LoadSubmissionsForOrg();
            this.setState({
                selectedSubmissionIndexes: [], attestationInProgress: false,
                attestationDialogOpen: false, attestationItem1Checked: false, attestationItem2Checked: false, attester: "", vendorName: ""
            });
        }
    }

    private async GetHosSubmissionsAttestationLanguage() {
        const retVal = await SubmissionsService.GetHosSubmissionsAttestationLanguage$(this.props.user).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {
            this.setState({
                attestationLanguage: retVal
            });
        }
    }
}

// REDUX CONTAINER
const BulkAttestationViewWithState = connect<IGenericUserProps>(genericMapStateToProps)(BulkAttestationView);

// STYLES CONTAINER
const BulkAttestationPage = withStyles(styles)(BulkAttestationViewWithState);

export default BulkAttestationPage;