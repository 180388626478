import { UserManager, UserManagerSettings } from "oidc-client";

// Pull the modifiable configuration from the .env files
const authority = process.env.REACT_APP_OPENID_BASEURL!.toLowerCase();
const redirectUri = process.env.REACT_APP_OPENID_REDIRECTURL;
const silentRenewUri = process.env.REACT_APP_OPENID_SILENTRENEWURL;
const postLogoutUri = process.env.REACT_APP_OPENID_POSTLOGOUTURL;
const clientId = process.env.REACT_APP_OPENID_CLIENTID;
const apiId = process.env.REACT_APP_OPENID_APIID;

// User Manager Settings
const userManagerConfig: UserManagerSettings = {
    authority,
    automaticSilentRenew: true,
    client_id: clientId,
    loadUserInfo: true,
    post_logout_redirect_uri: postLogoutUri,
    redirect_uri: redirectUri,
    response_type: 'token id_token',
    scope: `openid profile  ${apiId}`,
    silent_redirect_uri: silentRenewUri
  };

// Initialize
const ConfiguredUserManager = new UserManager(userManagerConfig);

export default ConfiguredUserManager;