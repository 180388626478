import { User, UserManager } from "oidc-client";
import * as React from "react";
import { Redirect } from "react-router-dom";

// ATTRIBUTE PROPS
interface IProps {
    userManager: UserManager;
}

interface IState {
    isError: boolean;
    redirect: boolean;
    redirectUrl: string | null;
}

// VIEW
class Callback extends React.Component<IProps, IState> {
    public state: IState = {
        isError: false,
        redirect: false,
        redirectUrl: null
    };

    constructor(props: IProps) {
        super(props);

        this.signInRedirectCallback()
            .then(user => this.onRedirectSuccess(user))
            .catch(error => this.onRedirectError(error));
    }

    public render() {
        const { redirect, redirectUrl, isError } = this.state;

        if (redirect) {
            if (redirectUrl) {
                return <Redirect to={redirectUrl} />;
            } else {
                return <Redirect to="/dashboard" />;
            }
        }

        if (isError) {
            return <Redirect to="/" />;
        }

        return null;
    }

    private signInRedirectCallback = (
        url?: string | undefined
    ): Promise<User> => {
        return this.props.userManager.signinRedirectCallback(url);
    };

    private onRedirectSuccess = (user: User) => {
        if (user && user.state) {
            this.setState({ redirect: true, redirectUrl: user.state });
        } else {
            this.setState({ redirect: true });
        }
    };

    private onRedirectError = (error: any) => {
        this.setState({ isError: true });
    };
}

export default Callback;
