import * as React from "react";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import { Typography, Fade, Paper, Button } from "@material-ui/core"; 
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import AdministrationService from "../Services/AdministrationService";
import * as validator from "validator";
import { AjaxError } from "rxjs/ajax";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import DeleteIcon from '@material-ui/icons/Delete';
import { AddCircle } from "@material-ui/icons";
import AlertDialog from "../Shared/AlertDialog";
import { Redirect } from "react-router-dom";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { SeedTime } from "../../Configuration/Setting";
import ErrorDialog from '../Shared/ErrorDialog';

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

type IStylesProps = WithStyles<typeof styles>;

export interface IDialogProperties {
    description: string;
    title: string;
    user: string;
}

export interface IAuditorsListViewStateStore {
    auditors: any[];
    isLoading: boolean;
    filterText: string;
    errorMsg: string;
    isProcessing: boolean;
    deleteAuditorConfirmDialogOpen: boolean;
    deleteAuditorDialog: IDialogProperties,
    redirect: boolean,
    auditorEmail: string
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 3 * 3 }}>
            {props.children}
        </Typography>
    );
}

// VIEW
class AuditorsView extends React.Component<
    IGenericUserProps & IStylesProps,
    IAuditorsListViewStateStore
    > {
    constructor(props: IGenericUserProps & IStylesProps) {
        super(props);
        this.state = {
            auditors: [],
            isLoading: true,
            filterText: "",     
            deleteAuditorConfirmDialogOpen: false,
            deleteAuditorDialog: {
                description: "",
                title: "",
                user: ""
            },
            errorMsg: "",
            redirect: false,
            isProcessing: false,
            auditorEmail: ""
        };
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
        const auditorListOptions: MUIDataTableOptions = {
            filterType: "checkbox",
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: "none",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
        };

            const auditorListColumns = [
                {
                    name: "Auditor",
                    width: 600,
                    options: {
                        filter: true
                    }
                },                
                {
                    name: "Action",
                    width: 50,
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div>
                                    <DeleteIcon className={classes.actionIcon} onClick={() => this.ShowAuditorDeleteDialog(tableMeta.rowData[0])}></DeleteIcon>
                                </div>
                            );
                        }
                    }
                }
            ];
            
        return (
            <>
                <TabContainer>
                    <Paper className={classes.paper} style={{ width: '703px' }}>
                        <ErrorDialog
                            showError={this.state.errorMsg !== ""}
                            errorMsg={this.state.errorMsg}
                            buttonHandler={() => this.setState({ errorMsg: "" })}
                        />

                        <table><tbody><tr><td style={{ width:'550px', paddingRight:'5px' }}>
                            <input
                                type="text"
                                value={this.state.auditorEmail}
                                onChange={this.handleTextChange}
                                style={{ width: '550px', height: '35px' }}
                                placeholder="Enter auditor email"
                            />
                        </td>
                            <td>
                                <Button variant='contained' color='secondary' onClick={() => {
                                    this.addAuditor(this.state.auditorEmail)
                                }} className={classes.button} startIcon={<AddCircle />} disabled={this.state.auditorEmail === ""}>
                                    Add Auditor
                                    </Button>
                            </td></tr></tbody></table>
                    </Paper>
                    <Paper className={classes.paper} style={{ width: '703px' }}>
                        {this.state.isLoading ? <LoadingDataTable titleText={"*"} /> :
                            <Fade in={true} {...({ timeout: SeedTime })}><div>
                                <MUIDataTable
                                    title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Auditors</Typography>}
                                    data={this.state.auditors}
                                    columns={auditorListColumns}
                                    options={auditorListOptions}
                                /></div></Fade>}
                        <AlertDialog
                            showDialog={this.state.deleteAuditorConfirmDialogOpen}
                            title={this.state.deleteAuditorDialog.title}
                            description={this.state.deleteAuditorDialog.description}
                            button1Text="Delete Auditor"
                            button2Text="Cancel"
                            button1Handler={this.DeleteAuditor}
                            button2Handler={() => this.CloseDeleteAuditorConfirmDialog()}
                            isButton1Clicked={this.state.isProcessing}
                        />
                    </Paper>
                </TabContainer>               
            </>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retVal = await AdministrationService.GetAllAuditors$(
            this.props.user
        ).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {
            const auditors = retVal.map(item => {
                return [item];
            });            
            this.setState({
                auditors: auditors
            });

            this.setState({ isLoading: false });
        }
    }

    private validate = (userEmail: string): boolean => {
        var error = "";
        if (validator.isEmpty(userEmail)) {
            error = "Required";
        } else if (!validator.isLength(userEmail, { min: 1, max: 100 })) {
            error = "Email can be no longer than 100 characters";
        } else if (!validator.isEmail(userEmail)) {
            error = "Email is not in a valid format";
        }

        this.setState({ errorMsg: error });
        if (error === "") {
            return true;
        }
        return false;
    };

    private handleTextChange = (event: any) => {
        this.setState({ auditorEmail: event.target.value.trim() });
    }

    private addAuditor = async (userEmail: string) => {
        if (!this.validate(userEmail)) {
            return false;
        }
        try {
            this.setProcessingState(true);
            const addResp = await AdministrationService.AddAuditor(
                this.props.user,
                this.state.auditorEmail
            ).toPromise();

            if (addResp instanceof AjaxError || !addResp) {
                this.setRedirectState();
            }
            else {
                this.RefreshModel();
                this.setState({ auditorEmail: "" });
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }

    private DeleteAuditor = async () => {
        try {
            this.setProcessingState(true);
            const deleteResp = await AdministrationService.DeleteAuditor(
                this.props.user,
                this.state.deleteAuditorDialog.user
            ).toPromise();

            if (deleteResp instanceof AjaxError || !deleteResp) {
                this.setRedirectState();
            }
            else {
                this.RefreshModel();
                this.CloseDeleteAuditorConfirmDialog();
            }
        } catch (ex) {
        } finally {
            this.setProcessingState(false);
        }
    }   

    private setProcessingState = (value: boolean) => {
        this.setState({ isProcessing: value });
    }

    private ShowAuditorDeleteDialog = (userEmail) => {
        this.setState({
            deleteAuditorConfirmDialogOpen: true,
            deleteAuditorDialog: {
                title: "Delete Auditor " + userEmail + "?",
                description: "Are you sure you'd like to delete auditor " + userEmail + "?",
                user: userEmail
            }
        });
    }

    private CloseDeleteAuditorConfirmDialog = () => {
        this.setState({
            deleteAuditorConfirmDialogOpen: false
        });
    }    
}

// REDUX CONTAINER
const AuditorListPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(AuditorsView);

// STYLES CONTAINER
const AuditorListPage = withStyles(styles)(AuditorListPageWithState);

export default AuditorListPage;
