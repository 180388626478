import * as React from "react";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import { Typography, Fade, Paper, Button, CircularProgress } from "@material-ui/core"; 
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { IGenericUserProps, genericMapStateToProps } from "../../DataClasses/GenericUser";
import { Redirect } from "react-router-dom";
import { SeedTime } from "../../Configuration/Setting";
import LoadingDataTable from "../Shared/LoadingDataTable";
import SubmissionsService from "../Services/SubmissionsService";
import { AjaxError } from "rxjs/ajax";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    progress: {
        verticalAlign: 'middle',
        paddingLeft: '5px'
    },
    spacer: {
        paddingLeft: '5px'
    },
    paperStyle: {
        flexGrow: 1,
        padding: theme.spacing(2, 2),
    },
    extractTable: {
        paddingBottom: '10px',
        border: '1px solid #eeeeee',
        borderTop: 'none',
        boxShadow: '0px 1px 1px 1px #eeeeee',
        borderCollapse: 'collapse',
        borderSpacing: '0',
        '& tbody tr': {
            borderBottom: '1px solid #eeeeee',
        },
        '& tbody td': {
            verticalAlign: 'middle',
            paddingLeft: theme.spacing(1),
        },
        '& thead tr': {
            borderBottom: '1px solid #eeeeee',
        },
        '& thead th': {
            color: '#4f758b',
            textAlign: 'left',
            padding: theme.spacing(1),
            fontSize: '1.1em'
        },
        '& thead th:nth-child(2)': {
            textAlign: 'center'
        },
        '& thead th:nth-child(3)': {
            textAlign: 'center'
        },
        '& tbody td:nth-child(2)': {
            textAlign: 'center',
            fontWeight: 'bold' 
        },
    }
});

type IStylesProps = WithStyles<typeof styles>;

export interface FileExtractOption {
    value: string, 
    label: string,
    hidden: boolean
}

export interface IDataExtractState {
    isLoading: boolean,
    redirect: boolean,
    isProcessing: boolean,
    isExtractionSuccessful: boolean,
    data: Map<string, number>,
    errorMessage: string,
    selectedDataExtractionType: string,
    extractOptions: FileExtractOption[],
    processingState: string
}

// VIEW
class SubmissionDataExtractView extends React.Component<
    IGenericUserProps & IStylesProps,
    IDataExtractState
    > {

    private extractOptions: FileExtractOption[] = [
        { value: 'All', label: 'Extract imported submissions', hidden: true },
        { value: 'NoErrors', label: 'Extract submissions with any of the following Validation Status: NoIssues, Warnings', hidden: true },
        {
            value: 'NoSchemaErrors', label: 'Extract submissions with any of the following Validation Statuses: NoIssues, Warnings, Errors, ErrorsAndWarnings',
            hidden: true
        },
        { value: 'PlanLocked', label: 'Extract plan locked submissions', hidden: true },
        { value: 'AuditLocked', label: 'Extract audit locked submissions', hidden: true },
        { value: 'Attested', label: 'Extract attested submissions', hidden: true },
    ];

    constructor(props: IGenericUserProps & IStylesProps) {
        super(props);
        this.state = {
            isLoading: true,
            redirect: false,
            isProcessing: false,
            isExtractionSuccessful: false,
            data: null,
            errorMessage: "",
            selectedDataExtractionType: "",
            extractOptions: this.extractOptions,
            processingState: "extraction"
        };
    }

    public async componentDidMount() {
        // This method runs when the component is first added to the page
        await this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }
        const { classes } = this.props;
                   
        return (
            <>
                {this.state.isLoading ? <LoadingDataTable titleText={"*"} /> :
                    <Fade in={true} {...({ timeout: SeedTime })}>
                        <Paper className={classes.paperStyle}>
                            <Typography className={classes.primaryBlue}>
                                Submission Data Extract
                            </Typography>                          
                            <table className={classes.extractTable}>
                                <thead>
                                    <th>Description</th>
                                    <th>Count</th>
                                    <th>Action</th>
                                    <th>Verify</th>
                                </thead>
                                <tbody>
                                    {this.state.extractOptions.map((e, index) =>
                                      <tr key={index}>
                                        <td>{e.label}</td>
                                        <td>{this.state.data[e.value]}</td>
                                        <td>
                                            <Button className={classes.squareFab}
                                                onClick={() => this.extractSubmissions(e.value)} disabled={this.state.isProcessing}>
                                                Extract
                                            </Button>
                                        </td>
                                        <td>
                                            {!e.hidden ?
                                                <Button className={classes.squareFab}
                                                    onClick={() => this.verifyDestinationCount(e.value)} disabled={this.state.isProcessing}>
                                                    Verify
                                                </Button> :
                                                null
                                            }
                                        </td>
                                    </tr>
                                    )
                                }                               
                               </tbody>
                            </table>
                            <span style={{ paddingTop: '7px' }}>&nbsp;</span>
                            {this.state.isProcessing ?
                                <>
                                    <Typography variant='body1' color='secondary'>
                                        <span className={classes.progress}><CircularProgress color="primary" size={22} /></span>
                                        <span className={classes.spacer} style={{ whiteSpace: 'pre-line' }}>
                                            Data {this.state.processingState} in progress... Please wait...
                                        </span>
                                    </Typography>
                                </>
                                :
                                null
                            }                           
                            {this.state.errorMessage !== "" ? 
                                <Typography variant='body1' color='secondary'>
                                    <span style={{ whiteSpace: 'pre-line' }}>{this.state.errorMessage}</span>
                                </Typography>
                                : null
                            }
                            {this.state.errorMessage === "" && this.state.isExtractionSuccessful ?
                                <Typography variant='body1' color='primary'>
                                    <span className={classes.spacer} style={{ whiteSpace: 'pre-line' }}>
                                        Data extraction successful! After few minutes please click the "Verify" button to verify the destination files count.
                                    </span>
                                </Typography>
                                :
                                null
                            }
                        </Paper>
                    </Fade>
                }
            </>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retVal = await SubmissionsService.GetSubmissionDataExtractStatistics$(this.props.user).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {                      
            this.setState({
                data: retVal,
                isLoading: false
            });
        }
    }

    private async extractSubmissions(dataExtractionType: string) {
        this.setState({ isExtractionSuccessful: false, errorMessage: "", processingState: "extraction" });
        this.setProcessingState(true);
        const retVal = await SubmissionsService.ExtractSubmissions$(this.props.user, dataExtractionType).toPromise();
        if (retVal instanceof AjaxError || !retVal) {
            this.setRedirectState();
        }
        else {
            //update the extract options to show the appropriate Verify button
            let allOptions: FileExtractOption[] =  JSON.parse(JSON.stringify(this.state.extractOptions));
            for(let option of allOptions){
                option.hidden = true;
            }
            let updatedExtractOptions = allOptions.map(option => {
                if (option.value === dataExtractionType) {
                    return Object.assign({}, option, {
                        hidden: false
                    });
                }
                return option;
            });
            this.setState({ extractOptions: updatedExtractOptions, isExtractionSuccessful: true });
            this.setProcessingState(false);
        }       
    }

    private async verifyDestinationCount(dataExtractionType: string) {
        var errorMessage = "No issues found!";
        this.setState({ isExtractionSuccessful: false, errorMessage: "", processingState: "verification" });
        this.setProcessingState(true);
        const retVal = await SubmissionsService.VerifyDataExtraction$(this.props.user, dataExtractionType).toPromise();
        if (retVal instanceof AjaxError) {
            this.setRedirectState();
        }
        else {            
            this.setProcessingState(false);            
            if (retVal !== "") {
                errorMessage = retVal;
            }
            this.setState({ errorMessage: errorMessage });
        }
    }

    private setProcessingState = (value: boolean) => {
        this.setState({ isProcessing: value });
    }
}

// REDUX CONTAINER
const SubmissionDataExtractPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(SubmissionDataExtractView);

// STYLES CONTAINER
const SubmissionDataExtractPage = withStyles(styles)(SubmissionDataExtractPageWithState);

export default SubmissionDataExtractPage;
