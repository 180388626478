import * as React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { green } from '@material-ui/core/colors';
import idssStyle from "../../Configuration/SharedStyling";
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { MessageType } from '../../DataClasses/SharedData';

const variantIcon = {
    Success: CheckCircleIcon,
    Warning: WarningIcon,
    Error: ErrorIcon,
    Information: InfoIcon,
};

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: '#eeaf30'
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        padding: theme.spacing(2),
        borderRadius: "5px"
    },
});

type IStylesProps = WithStyles<typeof styles>;

interface ILocalProps {
    text: React.ReactElement;
    variant: MessageType;
}

const MessageView: React.StatelessComponent<ILocalProps & IStylesProps> = ({ text, variant, classes }) => {
    const Icon = variantIcon[variant];
    let variantClass = "";
    switch (variant) {
        case MessageType.Warning:
            variantClass = classes.warning;
            break;
        case MessageType.Error:
            variantClass = classes.error;
            break;
        case MessageType.Information:
            variantClass = classes.info;
            break;
        case MessageType.Success:
            variantClass = classes.success;
            break;
    }

    return (
        <span className={classes.message + " " + variantClass}>
            <Icon className={classes.icon + " " + classes.iconVariant} />
            {text}
        </span>
    );
};

// STYLES CONTAINER
const UserMessage = withStyles(styles)(MessageView);

export default UserMessage;