import * as React from "react";
import "@material-ui/core";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogContentText,
  Tooltip,
  IconButton,
  Typography,
  Fade,
} from "@material-ui/core";
import { connect } from "react-redux";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import "froala-editor/js/plugins/colors.min.js";

import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  IGenericUserProps,
  genericMapStateToProps,
} from "../../DataClasses/GenericUser";
import idssStyle from "../../Configuration/SharedStyling";
import { IAnnouncementPayload } from "../../DataClasses/AnnouncementData";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumnDef,
} from "mui-datatables";
import AnnouncementsService from "../Services/AnnouncementsService";
import { AjaxError } from "rxjs/ajax";
import { Delete, Edit } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import froalaBaseConfig from "../../Utilities/BaseFroalaConfig";
import FroalaEditor from "react-froala-wysiwyg";
import SharedUtility from "../../Utilities/SharedUtility";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { SeedTime } from "../../Configuration/Setting";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface IAnnouncementsListStateStore {
  payload: IAnnouncementPayload[];
  isLoading: boolean;
  openEditDialog: boolean;
  openDeleteDialog: boolean;
  editingItemId: number;
  editingStartDate: string;
  editingStopDate: string;
  editingDisplayOrder: string;
  fConfig: any;
  fModel: string;
  redirect: boolean;
}

type IStylesProps = WithStyles<typeof styles>;

class AnnouncementsListView extends React.Component<
  IGenericUserProps & IStylesProps,
  IAnnouncementsListStateStore
> {
  private tableData: any;
  private announcementsOptions: MUIDataTableOptions = {
    filter: true,
    filterType: "dropdown",
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
  };

  constructor(props: any) {
    super(props);

    let config: any = {
      toolbarButtons: [
        "bold",
        "textColor",
        "italic",
        "underline",
        "insertLink",
        "undo",
        "redo",
      ],
      attribution: false,
    };
    this.state = {
      payload: [],
      isLoading: true,
      openEditDialog: false,
      openDeleteDialog: false,
      editingItemId: -1,
      editingStartDate: "",
      editingStopDate: "",
      editingDisplayOrder: "-1",
      fConfig: config,
      fModel: "",
      redirect: false
    };
  }

  public componentDidMount() {
    this.RefreshModel();
  }

  private redirectToErrorPage() {
    return <Redirect to='/errorpage' />;
  }

  private setRedirectState() {
    this.setState({ redirect: true });
  }

  public render() {
    if (this.state.redirect) {
      return this.redirectToErrorPage();
    }
    const { classes } = this.props;
    const announcementsColumns: MUIDataTableColumnDef[] = [
      {
        name: "Id",
        options: {
          filter: true,
        },
      },
      {
        name: "Text",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <FroalaEditorView model={value} config={{ ...froalaBaseConfig }} />
          ),
        },
      },
      {
        name: "StartDate",
        options: {
          filter: true,
        },
      },
      {
        name: "StopDate",
        options: {
          filter: true,
        },
      },
      {
        name: "DisplayOrder",
        options: {
          filter: true,
        },
      },
      {
        name: "CreateUser",
        options: {
          filter: true,
        },
      },
      {
        name: "CreateDate",
        options: {
          filter: true,
        },
      },
      {
        name: "Edit",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log("Displaying value");
            console.log(value);
            return (
              <>
                <Tooltip
                  title="Edit Announcement"
                  aria-label="editAnnouncement"
                >
                  <IconButton size="medium" aria-label="editAnnouncement">
                    <Edit
                      onClick={() => this.editDialogOpen(value)}
                      fontSize="inherit"
                    />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Tooltip
                  title="Delete Announcement"
                  aria-label="deleteAnnouncement"
                >
                  <IconButton size="medium" aria-label="deleteAnnouncement">
                    <Delete
                      onClick={() => this.deleteDialogOpen(value)}
                      fontSize="inherit"
                    />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      },
    ];

    this.tableData = this.state.payload.map((item) => {
      return [
        item.id,
        item.text,
        SharedUtility.ToLocaleDateString$(item.startDate),
        SharedUtility.ToLocaleDateString$(item.stopDate),
        item.displayOrder,
        item.lastChangedBy,
        SharedUtility.ToLocaleDateString$(item.lastChangedOn),
        item.id,
        item.id,
      ];
    });
    return (
      <Grid container={true} className={classes.gridRowFlex} spacing={2}>
        <Grid item={true} xs={12}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={this.addNewAnnounementBtnClicked.bind(this)}
              className={classes.mb2}
            >
              Add New Announcement
            </Button>
            {this.state.isLoading ? (
              <LoadingDataTable titleText={"*"} />
            ) : (
              <Fade in={true} {...{ timeout: SeedTime }}>
                <div>
                  <MUIDataTable
                    title={
                      <Typography
                        className={
                          classes.primaryBlue +
                          " " +
                          classes.muiTableTitleMargin
                        }
                      >
                        Manage Announcement
                      </Typography>
                    }
                    data={this.tableData}
                    columns={announcementsColumns}
                    options={this.announcementsOptions}
                  />
                </div>
              </Fade>
            )}
          </div>
          <Dialog open={this.state.openEditDialog}>
            <DialogTitle id="max-width-dialog-title">
              Add/Edit Announcement <i>(ID: {this.state.editingItemId})</i>
            </DialogTitle>
            <DialogContent>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <TextField
                        variant="outlined"
                        label="Display Order"
                        value={this.state.editingDisplayOrder}
                        onChange={(e) => this.handleDisplayOrderChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                      <div>
                        <i>
                          <small>
                            * The Start/Stop Dates are not required. Leave the
                            field(s) empty if you don't want it.
                          </small>
                        </i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            invalidDateMessage={
                              this.state.editingStartDate
                                ? "Invalid Date Format"
                                : ""
                            }
                            margin="normal"
                            id="start-date-picker-inline"
                            label="Start Date"
                            value={this.state.editingStartDate}
                            onChange={(e) => this.handleStartDateChange(e)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            autoOk={true}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            invalidDateMessage={
                              this.state.editingStopDate
                                ? "Invalid Date Format"
                                : ""
                            }
                            margin="normal"
                            id="stop-date-picker-inline"
                            label="Stop Date"
                            value={this.state.editingStopDate}
                            onChange={(e) => this.handleEndDateChange(e)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            autoOk={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>Text:</td>
                  </tr>
                  <tr>
                    <td>
                      <FroalaEditor
                        config={{ ...froalaBaseConfig, ...this.state.fConfig }}
                        model={this.state.fModel}
                        onModelChange={(e) => this.setState({ fModel: e })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  this.state.fModel === "" ||
                  (!(
                    this.state.editingStartDate === "" ||
                    isNaN(Date.parse(this.state.editingStartDate)) ||
                    this.state.editingStopDate === "" ||
                    isNaN(Date.parse(this.state.editingStopDate))
                  ) &&
                    new Date(this.state.editingStartDate) >
                    new Date(this.state.editingStopDate))
                }
                onClick={this.handleEditDialogSave.bind(this)}
              >
                Save
              </Button>
              <Button
                className={classes.disabledButton}
                onClick={this.handleEditDialogClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openDeleteDialog}
            onClose={this.deleteDialogClose.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete this announcement?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The item will be removed from announcements list.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleDeleteAnnouncement.bind(this)}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
              <Button
                onClick={this.deleteDialogClose.bind(this)}
                color="primary"
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
  private deleteDialogClose() {
    this.setState({ openDeleteDialog: false, editingItemId: -1 });
  }
  private async handleDeleteAnnouncement() {
    await AnnouncementsService.DeleteAnnouncement$(
      this.props.user,
      this.state.editingItemId
    ).toPromise();
    await this.RefreshModel();
    this.deleteDialogClose();
  }
  private async handleEditDialogSave() {
    await AnnouncementsService.SaveAnnoucement$(
      this.props.user,
      this.state.editingItemId,
      this.state.editingDisplayOrder,
      this.state.editingStartDate,
      this.state.editingStopDate,
      this.state.fModel
    ).toPromise();
    await this.RefreshModel();
    this.handleEditDialogClose();
  }
  private handleEditDialogClose() {
    this.setState({
      openEditDialog: false,
      editingDisplayOrder: "-1",
      editingStartDate: "",
      editingStopDate: "",
      fModel: "",
      editingItemId: -1,
    });
  }
  private handleDisplayOrderChange(e) {
    if (Number(e.target.value) && !e.target.value.includes("."))
      this.setState({ editingDisplayOrder: e.target.value });
  }

  private handleStartDateChange(date) {
    if (!isNaN(Date.parse(date))) {
      this.setState({ editingStartDate: date ? date.toISOString() : "" });
    } else {
      this.setState({ editingStartDate: date });
    }
  }

  private handleEndDateChange(date) {
    if (!isNaN(Date.parse(date))) {
      this.setState({ editingStopDate: date ? date.toISOString() : "" });
    } else {
      this.setState({ editingStopDate: date });
    }
  }

  private editDialogOpen(value: any) {
    console.log(this.state.payload);
    var item = this.state.payload.filter((x) => x.id.toString() === value.toString())[0];
    console.log("found item");
    console.log(item);

    this.setState({
      editingItemId: item.id,
      editingStartDate: SharedUtility.ToLocaleDateString$(item.startDate),
      editingStopDate: SharedUtility.ToLocaleDateString$(item.stopDate),
      editingDisplayOrder: item.displayOrder.toString(),
      fModel: item.text,
      openEditDialog: true,
    });
  }
  private deleteDialogOpen(value) {
    this.setState({ editingItemId: value, openDeleteDialog: true });
  }
  private addNewAnnounementBtnClicked() {
    this.setState({
      editingItemId: -1,
      editingStartDate: null,
      editingStopDate: null,
      editingDisplayOrder: "-1",
      fModel: "",
      openEditDialog: true,
    });
  }

  private async RefreshModel() {
    this.setState({ isLoading: true });
    const retval = await AnnouncementsService.GetAnnouncementsDetail$(
      this.props.user
    ).toPromise();
    if (retval instanceof AjaxError || !retval) {
      this.setRedirectState();
    } else {
      this.setState({ payload: retval });
    }
    this.setState({ isLoading: false });
  }
}

// REDUX CONTAINER
const AnnouncementsListPageWithState = connect<IGenericUserProps>(
  genericMapStateToProps
)(AnnouncementsListView);

// STYLES CONTAINER
const AnnouncementsListPage = withStyles(styles)(
  AnnouncementsListPageWithState
);

export default AnnouncementsListPage;
