import { User } from "oidc-client";
import * as React from "react";
import { connect } from "react-redux";
import RootState from "../../State/Root/RootState";
import LoginButton from "../Authentication/LoginButton";
import NavMenuText from './NavMenuText';
import { Grid, Button, Typography } from '@material-ui/core';
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from "react-router";
import logo from '../../Images/logo@2x.png';
import idssStyle from "../../Configuration/SharedStyling";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme), ...headerStyles() });



// REDUX PROPS
interface IHeaderStateProps {
    user: User | null
}
const headerStyles = () => createStyles({

})
const mapStateToProps = (state: RootState): IHeaderStateProps => ({
    user: state.AuthenticationReducer.user
});



type IStylesProps = WithStyles<typeof styles>;

// VIEW
class HeaderView extends React.Component<IHeaderStateProps & IStylesProps> {
    public render() {
        const { classes } = this.props;
        let authLinks: JSX.Element;
        if (this.props.user) {
            authLinks = (
                <NavMenuText />

            );
        }
        else {
            authLinks = (
                <Grid container={true} className={classes.loginGrid}>
                    <Grid item={true} className={classes.flexGrow}>
                        <img src={logo} alt="IDSS logo" />
                    </Grid>
                    <Grid item={true} className={classes.flexGrow}>
                        <Typography variant="h4" gutterBottom>
                            Welcome to IDSS
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.flexGrow}>
                        <Button variant='contained' color="inherit">
                            <LoginButton />
                        </Button>
                    </Grid>
                </Grid>
            );
        }

        return (
            <div>
                    {authLinks}
            </div>
        );
    }
}


// REDUX CONTAINER
const HeaderPropState = withRouter(connect<IHeaderStateProps, {}, RouteComponentProps<{}>>(mapStateToProps)(HeaderView));

// STYLES CONTAINER
const HeaderState = withStyles(styles)(HeaderPropState);

// REDUX CONTAINER
export default HeaderState;
