import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import AuthorizedRoute from "../../Components/Authorization/AuthorizedRoute";
import AllUsersPage from "../Pages/AllUsers";
import Dashboard from "../Pages/Dashboard";
import HelpPage from "../Pages/Help";
import Home from "../Pages/Home";
import ManageUserPage from "../Pages/ManageUser";
import BulkUploadPage from "../Pages/BulkUpload";
import BulkDownloadPage from "../Pages/BulkDownload";
import BulkMarkFinalPage from "../Pages/BulkMarkFinal";
import BulkAttestationPage from "../Pages/BulkAttestation";
import ReportsPage from "../Pages/Reports";
import UserRolesPage from "../Pages/ManageUserRoles";
import AvailableSubmissionsPage from "../Pages/SubmissionListing";
import FilesUploadReportPage from "../Pages/Reports/FilesUploadReport";
import AnnouncementsListPage from "../Pages/AnnouncementsList";
import AppInfoPage from "../Pages/AppInfo";
import ImportSubmissionDataPage from "../Pages/ImportSubmissionData";
import MeasureViewerPage from "../Pages/MeasureViewer";
import SubmissionHome from "../Pages/SubmissionHome";
import LogsViewPage from "../Pages/Logs";
import Administration from "../Pages/Administration";
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from "react-router";
import { IsUserPolicy, IsLoggedInPolicy, IsGlobalAdminPolicy, IsOrgOrGlobalAdmin } from "../../Configuration/AuthorizationPolicies";
import UsersListPage from "../Pages/UsersList";
import idssStyle from "../../Configuration/SharedStyling";
import HosVendorSubmissionStatusReportPage from "../Pages/Reports/HosVendorSubmissionStatusReport";
import CahpsVendorSubmissionStatusReportPage from "../Pages/Reports/CahpsVendorSubmissionStatusReport";
import ActivityReportPage from "../Pages/Reports/ActivityReport";
import SubmissionsDataSummaryReportPage from "../Pages/Reports/SubmissionsDataSummaryReport";
import GenericUploaderPage from "../Pages/GenericRedesignUploader";
import BulkUserPage from "../Pages/BulkUserManagement";
import ValidationReport from "../Pages/Reports/ValidationReport";
import SubmissionCountOnMeasureReport from  "../Pages/Reports/SubmissionCountOnMeasureReport";
import VendorMeasureGuidListPage from "../Pages/VendorMeasureGuidList";
import AccountPage from "../Pages/Reports/AccountPage";
import AccreditationStatusReportPage from "../Pages/Reports/AccreditationStatusReport";

interface IMainProps {
    defaultProps: string
}

const mapStateToProps = (): IMainProps => {
    return ({
        defaultProps: "",
    });
};

// STYLES
const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme)
});

type IStylesProps = WithStyles<typeof styles>;


// VIEW
class MainView extends React.Component<IStylesProps & IMainProps> {
    public render() {

        const { classes } = this.props;
        const ErrorPage = () => {
            return (
                <div className={classes.errorBlock}>
                    <p>An error occurred please try again later</p>
                </div>
            );
        };
        return (
            <div className={classes.minimumSpacing} > 
                <Switch>
                    <Route exact={true} path='/' component={Home} />
                    <AuthorizedRoute exact={true} path='/dashboard' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={Dashboard} />
                    <AuthorizedRoute path='/reportspage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={ReportsPage} />
                    <AuthorizedRoute path='/alluserspage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsGlobalAdminPolicy} component={AllUsersPage} />
                    <AuthorizedRoute path='/helppage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={HelpPage} />
                    <AuthorizedRoute path='/bulkupload/:uploadType' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={BulkUploadPage} />
                    <AuthorizedRoute path='/bulkdownload/:downloadType' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={BulkDownloadPage} />
                    <AuthorizedRoute path='/bulkmarkfinal/:markFinalType' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={BulkMarkFinalPage} />
                    <AuthorizedRoute path='/bulkattestation/:attestationType' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={BulkAttestationPage} />
                    <AuthorizedRoute path='/manageuserpage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={ManageUserPage} />
                    <AuthorizedRoute path='/availablesubmissionpage/:productLine?/:stage?/:orgId?' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={AvailableSubmissionsPage} />
                    <AuthorizedRoute path='/filesuploadreportpage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={FilesUploadReportPage} />
                    <AuthorizedRoute path='/announcementslistpage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={AnnouncementsListPage} />
                    <AuthorizedRoute path='/appinfopage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={AppInfoPage} />
                    <AuthorizedRoute path='/vendormeasureguidlistpage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={VendorMeasureGuidListPage} />
                    <AuthorizedRoute path='/importSubmissionData/:subId' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={ImportSubmissionDataPage} />
                    <AuthorizedRoute path='/measureViewer/:subId/:component/:productLine' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={MeasureViewerPage} />
                    <AuthorizedRoute path='/submissionHome/:subId/:component' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={SubmissionHome} />
                    <AuthorizedRoute path='/userrolespage/:userEmail' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={UserRolesPage} />
                    <AuthorizedRoute path='/administration' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsGlobalAdminPolicy} component={Administration} />
                    <AuthorizedRoute path='/logs' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsGlobalAdminPolicy} component={LogsViewPage} />
                    <AuthorizedRoute path='/userslistpage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsOrgOrGlobalAdmin} component={UsersListPage} />
                    <AuthorizedRoute path='/hosvendorsubmissionstatusreportpage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={HosVendorSubmissionStatusReportPage} />
                    <AuthorizedRoute path='/cahpsvendorsubmissionstatusreportpage' authenticationPolicy={IsLoggedInPolicy}  authorizationPolicy={IsUserPolicy} component={CahpsVendorSubmissionStatusReportPage} /> 
                    <AuthorizedRoute path='/activityreportpage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={ActivityReportPage} />
                    <AuthorizedRoute path='/accountpage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={AccountPage} />
                    <AuthorizedRoute path='/accreditationstatusreport' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={AccreditationStatusReportPage} />
                    <AuthorizedRoute path='/submissionsdatasummaryreportpage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={SubmissionsDataSummaryReportPage} />
                    <AuthorizedRoute path='/genericuploads' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={GenericUploaderPage} />
                    <AuthorizedRoute path='/bulkuserpage' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsGlobalAdminPolicy} component={BulkUserPage} />
                    <AuthorizedRoute path='/validationreport' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={ValidationReport} />
                    <AuthorizedRoute path='/submissioncountonmeasurereport' authenticationPolicy={IsLoggedInPolicy} authorizationPolicy={IsUserPolicy} component={SubmissionCountOnMeasureReport} />
                    <Route path='/errorpage' component={ErrorPage} />
                </Switch>
            </div>
        );
    }
}

// REDUX CONTAINER
const MainPropsState = withRouter(connect<IMainProps, {}, RouteComponentProps<{}>>(mapStateToProps)(MainView));

// STYLES CONTAINER
const Main = withStyles(styles)(MainPropsState);


export default Main;



