import * as React from "react";
import { connect } from "react-redux";
import idssStyle from "../../Configuration/SharedStyling";
import {
  Grid,
  Card,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Fade,
  CircularProgress,
  Button,
  CardContent,
} from "@material-ui/core";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import UserManagerService from "../Services/UserManagerService";
import { AjaxError } from "rxjs/ajax";
import {
  IGenericUserProps,
  genericMapStateToProps
} from "../../DataClasses/GenericUser";
import { Formik, FormikHelpers, FormikErrors, FormikProps } from "formik";
import * as validator from "validator";
import { allUsersMachine } from '../../Components/Machines/AllUsersMachine';
import { State, EventObject, interpret } from "xstate";
import AlertDialog from "../Shared/AlertDialog";
import ErrorDialog from "../Shared/ErrorDialog";
import { IUserRolePayload } from "../../DataClasses/UsersData";
import { IOrgSummary } from "../../DataClasses/SubmissionData";
import Select from "react-select";
import ExceptionSerivce from "../Services/ExceptionService";
import SubmissionsService from "../Services/SubmissionsService";
import LoadingDataTable from "../Shared/LoadingDataTable";
import { SeedTime } from "../../Configuration/Setting";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    spacer: {
        paddingLeft: '10px'
    },
    progress: {
        verticalAlign: 'middle',
        paddingLeft: '5px'
    }
});

// REDUX PROPS
interface IAllUsersViewStateStore {
  data: any[];
  isLoading: boolean;
  stateMachine: State<Record<string, any>, EventObject>;
  manageUsersPayload: IUserRolePayload[];
  filterText: string;
  targetUser: string;
  allOrgs: IOrgSummary[];
  allVendors: IOrgSummary[];
  allSysGroups: string[];
  selectedSysGroup: any;
  selectedOrgs: any;
  selectedRoles: any;
  allRoles: string[];
  inputSubIds: string;
  errorMsg: string;
  redirect: boolean;
  isProcessing: boolean;
  isUserLockedOut: boolean;
}

interface IAddUserForm {
  email: string;
  firstName: string;
  lastName: string;
}


const subListingOptions: MUIDataTableOptions = {
  filterType: "checkbox",
  sort: true,
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: "none",
  rowsPerPage: 25,
  rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
};

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class AllUsersView extends React.Component<
  IGenericUserProps & IStylesProps,
  IAllUsersViewStateStore
  > {
  constructor(props: IGenericUserProps & IStylesProps) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      stateMachine: allUsersMachine.initialState,
      manageUsersPayload: [],
      targetUser: "",
      filterText: "",
      allOrgs: [],
      allVendors: [],
      allSysGroups: [],
      selectedSysGroup: "",
      selectedOrgs: "",
      selectedRoles: "",
      allRoles: [],
      inputSubIds: "",
      errorMsg: "",
      redirect: false,
      isProcessing: false,
      isUserLockedOut: false
    };
  }

  service = interpret(allUsersMachine).onTransition(stateMachine =>
    this.setState({ stateMachine })
  );

  private userOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    sort: true,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
  };

  private userColumns = [
    {
      name: "Entity ID",
      options: {
        filter: true
      }
    },
    {
        name: "Org/Vendor",
        options: {
            filter: true
        }
    },
    {
      name: "Role Type",
      options: {
        filter: true
      }
    },
    {
      name: "Role Name",
      options: {
        filter: true
      }
    },

    {
      name: "Action",
      options: {
        customBodyRender: value => {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.deleteButtonClick(value)}
              >
                Delete
              </Button>
            </div>
          );
        }
      }
    }
  ];

  public async componentDidMount() {
    // This method runs when the component is first added to the page
    await this.RefreshModel();
    await this.RefreshManageUsersModel();

    // Start the State machine
    this.service.start();
  }

  public async componentWillUnmount() {

    // Stop the State machine
    this.service.stop();
  }
    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
     if (this.state.redirect) {
         return this.redirectToErrorPage();
     }

      const { classes } = this.props;
      const subListingColumns = [
        {
          name: "Users",
          options: {
            filter: true,
            customBodyRender: value => {
              return (
                <div>
                  <Button className={classes.linkButton} onClick={() => this.DoManageUser(value)}>{value}</Button>
                </div>
              );
            }
          }
        }
      ];

      return (
        <Grid container={true} className={classes.gridRowFlex} spacing={2}>
          <Grid key={1} item={true}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" color="primary">
                  Add Users
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.service.send('OpenUserDialog')}
                  className={classes.minspacing}
                >
                  Add
                </Button>
                <Formik<IAddUserForm>
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: ""
                  }}
                  enableReinitialize={true}
                  onSubmit={this.onSubmit}
                  validate={this.validate}
                >
                    {props => this.getForm(props, classes)}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
          <Grid key={2} item={true}>
            <div className={classes.card}>
              {this.state.isLoading?<LoadingDataTable titleText={"*"}/>:
            <Fade in={!this.state.isLoading} {...(!this.state.isLoading
                ? { timeout: SeedTime }
                : {})}><div>
              <MUIDataTable
                title={"Available Users"}
                data={this.state.data}
                columns={subListingColumns}
                options={subListingOptions}
              /></div>
              </Fade>
              }
            </div>
          </Grid>
          {this.renderManageUsers()}
        </Grid>
      );
  }

  public renderManageUsers() {
    const { classes } = this.props;
    const tableData = this.state.manageUsersPayload.map(item => {
      return [
        item.entityId,
        item.entityName,
        item.roleType,
        item.roleName,
        item.entityId + "," + item.roleType + "," + item.roleName
      ];
    });
    const sysGroupOption = this.state.allSysGroups.map(m => ({
      label: m,
      value: m
    }));
    const orgOption = this.state.allOrgs.map(m => ({
      label: m.orgId + "-" + m.orgName,
      value: m.orgId
    }));
    const vendorOption = this.state.allVendors.map(m => ({
      label: m.orgId + "-" + m.orgName,
      value: m.orgId
    }));
    const roleOption = this.state.allRoles.map(m => ({
      label: m,
      value: m
    }));
    let addRoles = this.state.stateMachine.matches("addRolesToUserDialogOpen");
    let delleteAll = this.state.stateMachine.matches("deleteAllAlertDialogOpen");
    let manageUsers = this.state.stateMachine.matches("manageUserOpen");
    if (addRoles || delleteAll || manageUsers) {
      return <Grid key={3} item={true}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" color="primary">
              Manage Users ({this.state.targetUser})
            </Typography>

            <Button className={classes.minspacing}
              variant="contained"
              color="primary"
              onClick={() => this.service.send("OpenDeleteAllAlertDialog")}
            >
              Delete All
        </Button>
        <span className={classes.spacer }></span>
        <Button
              variant="contained"
              color="primary"
              onClick={() => this.service.send("OpenAddRolesToUserDialog")}
            >
                      Add
        </Button>
        <span className={classes.spacer}></span>
        {!this.state.isUserLockedOut ?                       
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.updateUser('lock')} disabled={this.state.isProcessing}>
                Lock User (Testing)
        </Button>
                : 
        <Button
            variant="contained"
            color="primary"
            onClick={() => this.updateUser('unlock')} disabled={this.state.isProcessing}>
                            Unlock User
        </Button>}
        {this.state.isProcessing ? <span className={classes.progress}><CircularProgress color="primary" size={22} /></span> : null}
            <Dialog open={this.state.stateMachine.matches("addRolesToUserDialogOpen")} onClose={() => this.service.send("CloseAddRolesToUserDialog")} classes={{ paper: classes.dialogM }} >
              <DialogTitle id="max-width-dialog-title">
                Add Roles to the User ( {this.state.targetUser}):
              </DialogTitle>
              <DialogContent>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "240px" }}>Select System Group:</td>
                      <td style={{ width: "80%" }}>
                        <Select
                          options={sysGroupOption}
                          placeholder="Select a System Group"
                          isMulti={false}
                          matchProp="label"
                          simpleValue={true}
                          value={this.state.selectedSysGroup}
                          closeOnSelect={false}
                          searchable={true}
                          onChange={this.selectedSysGroupChange.bind(this)}
                        />
                      </td>
                    </tr>
                    <tr hidden={this.state.selectedSysGroup === ""}>
                      <td>Select Role(s):</td>
                      <td>
                        <Select
                          options={roleOption}
                          placeholder="Select role(s)"
                          isMulti={true}
                          matchProp="label"
                          simpleValue={true}
                          value={this.state.selectedRoles}
                          closeOnSelect={false}
                          searchable={true}
                          onChange={this.selectedRolesChange.bind(this)}
                        />
                      </td>
                    </tr>
                    <tr
                      hidden={this.state.selectedSysGroup.value !== "organization"}
                    >
                      <td style={{ width: "240px" }}>Select Organization(s):</td>
                      <td style={{ width: "80%" }}>
                        <Select
                          options={orgOption}
                          placeholder="Select organization(s) [OrgId-OrgName]"
                          isMulti={true}
                          matchProp="label"
                          simpleValue={true}
                          value={this.state.selectedOrgs}
                          closeOnSelect={false}
                          searchable={true}
                          onChange={this.selectedOrgsChange.bind(this)}
                        />
                      </td>
                    </tr>
                    <tr hidden={this.state.selectedSysGroup.value !== "vendor"}>
                      <td style={{ width: "240px" }}>Select Vendor(s):</td>
                      <td style={{ width: "80%" }}>
                        <Select
                          options={vendorOption}
                          placeholder="Select vendor(s) [OrgId-OrgName]"
                          isMulti={true}
                          matchProp="label"
                          simpleValue={true}
                          value={this.state.selectedOrgs}
                          closeOnSelect={false}
                          searchable={true}
                          onChange={this.selectedOrgsChange.bind(this)}
                        />
                      </td>
                    </tr>
                    <tr hidden={this.state.selectedSysGroup.value !== "submission"}>
                      <td style={{ width: "240px" }}>SubIds (comma separated):</td>
                      <td>
                        <input
                          value={this.state.inputSubIds}
                          onChange={e => this.inputSubIdsChange(e)}
                          style={{ width: "100%" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.button}
                  onClick={this.handleSave}
                  disabled={
                    !(
                      (this.state.selectedSysGroup.value === "organization" &&
                        this.state.selectedRoles !== "" &&
                        this.state.selectedOrgs !== "") ||
                      (this.state.selectedSysGroup.value === "submission" &&
                        this.state.selectedRoles !== "" &&
                        this.state.inputSubIds !== "") ||
                      (this.state.selectedSysGroup.value === "system" &&
                        this.state.selectedRoles !== "") ||
                      (this.state.selectedSysGroup.value === "vendor" &&
                        this.state.selectedRoles !== "" &&
                        this.state.selectedOrgs !== "")
                    )
                  }
                >
                  Save
            </Button>
                <Button className={classes.disabledButton} onClick={this.handleClose}>
                  Cancel
            </Button>
              </DialogActions>
            </Dialog>
            <AlertDialog
              showDialog={this.state.stateMachine.matches("deleteAllAlertDialogOpen")}
              title="Delete Confirmation"
              description="Are you sure you want to delete all the roles for this user?"
              button1Text="Yes"
              button2Text="No"
              button1Handler={this.buttonYesHandler}
              button2Handler={() => this.service.send("CloseDeleteAllAlertDialog")}
            />
            <ErrorDialog
              showError={this.state.errorMsg !== ""}
              errorMsg={this.state.errorMsg}
              buttonHandler={() => this.setState({ errorMsg: "" })}
            />
            <MUIDataTable
              title={"Assigned Roles"}
              data={tableData}
              columns={this.userColumns}
              options={this.userOptions}
            />
          </CardContent>
        </Card>
      </Grid>
    }
    else {
      return null;
    }

  }


  //#region Manage Users Region

  private async RefreshManageUsersModel() {

    try {
      const allOrgsRetval = await SubmissionsService.GetOrgForSubmissionsSummary$(
        this.props.user
      ).toPromise();
      this.setState({ allOrgs: allOrgsRetval });
    } catch (ex) {
        const error = ex as AjaxError;
        this.setRedirectState();  
      this.setState({
        errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
      });
    }
    const allVendorsRetval = await SubmissionsService.GetOrgForVendorsSummary$(
      this.props.user
    ).toPromise();
      if (allVendorsRetval instanceof AjaxError || !allVendorsRetval) {
          this.setRedirectState();
    } else {
      this.setState({ allVendors: allVendorsRetval });
    }
    const allSysGroupRetval = await UserManagerService.GetAllSysGroups$(
      this.props.user
    ).toPromise();
      if (allSysGroupRetval instanceof AjaxError || !allSysGroupRetval) {
          this.setRedirectState();
    } else {
      this.setState({ allSysGroups: allSysGroupRetval });
    }
    //     this.setState({ isLoading: false });
  }

  selectedOrgsChange(newValue): void {
    this.setState({ selectedOrgs: newValue });
  }
  async selectedSysGroupChange(newValue) {
    this.setState({
      selectedSysGroup: newValue,
      selectedOrgs: "",
      selectedRoles: "",
      inputSubIds: ""
    });
    const rolesRetval = await UserManagerService.GetAllRolesPerSysGroup$(
      this.props.user,
      newValue.value
    ).toPromise();
      if (rolesRetval instanceof AjaxError || !rolesRetval) {
          this.setRedirectState();
    } else {
      this.setState({ allRoles: rolesRetval });
    }
  }
  selectedRolesChange(newValue): void {
    this.setState({ selectedRoles: newValue });
  }
  inputSubIdsChange(e: any): void {
    this.setState({ inputSubIds: e.target.value });
  }
  async deleteButtonClick(userRoleInfo: string) {
    try {
      await UserManagerService.DeleteUserRole$(
        this.props.user,
        this.state.targetUser,
        userRoleInfo
      ).toPromise();
    } catch (ex) {
      const error = ex as AjaxError;
      this.setRedirectState();
      this.setState({
        errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
      });
    }
    await this.RefreshRolesForUser();
  }
  private buttonYesHandler = async () => {
    this.service.send("CloseDeleteAllAlertDialog");
    // dispatch delete all user roles action
    await UserManagerService.DeleteAllUserRoles$(
      this.props.user,
      this.state.targetUser
    ).toPromise();
    await this.RefreshRolesForUser();
  };
  handleClose = () => {
    this.service.send("CloseAddRolesToUserDialog");
    this.setState({
      selectedOrgs: "",
      selectedRoles: "",
      selectedSysGroup: "",
      inputSubIds: ""
    });
  };

  handleSave = async () => {
    await UserManagerService.AddUserToRoles$(
      this.props.user,
      this.state.targetUser,
      this.state.selectedSysGroup.value,
      this.state.selectedRoles.map(p => p.value).join(","),
      this.state.selectedOrgs === ""
        ? ""
        : this.state.selectedOrgs.map(p => p.value).join(","),
      this.state.inputSubIds,
      false
    ).toPromise();
    await this.RefreshRolesForUser();
    this.service.send("CloseAddRolesToUserDialog");
    this.setState({
      selectedOrgs: "",
      selectedRoles: "",
      selectedSysGroup: "",
      inputSubIds: ""
    });
  };

  private async RefreshRolesForUser() {

    try {
      const retval = await UserManagerService.GetRolesForUser$(
        this.props.user,
        this.state.targetUser
        ).toPromise();
      this.setState({ manageUsersPayload: retval });
    } catch (ex) {
      const error = ex as AjaxError;
      this.setRedirectState();
      this.setState({
        errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
      });
    }
  }

  //#endregion 

    private async DoManageUser(targetName: string) {
        this.setState({ isUserLockedOut: false });
        this.setState(
          { targetUser: targetName },
          () => {
            this.RefreshRolesForUser();
            this.service.send("OpenManageUser");
          }
        );

      //get user locked out status
      const retVal = await UserManagerService.IsUserLockedOut$(
          this.props.user,
          targetName
      ).toPromise();
      this.setState({ isUserLockedOut: retVal });
  }
  private async RefreshModel() {
    this.setState({ isLoading: true });
    const retval = await UserManagerService.GetAllUsers$(
      this.props.user
    ).toPromise();
    if (retval instanceof AjaxError || !retval) {
          this.setRedirectState();
    } else {
      const data = retval.map(item => {
        return [item];
      });
      this.setState({ data: data });
    }
    this.setState({ isLoading: false });
  }


  private getForm = (formikProps: FormikProps<IAddUserForm>, classes: any): JSX.Element => {
    const {
      handleSubmit,
      handleReset,
      handleChange,
      handleBlur,
      touched,
      errors,
      values,
      isSubmitting,
      isValid,
      submitForm
    } = formikProps;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Dialog open={this.state.stateMachine.matches('addUserDialogOpen')} onClose={handleReset}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus={false}
                fullWidth={true}
                label="Email"
                name="email"
                margin="dense"
                type="text"
                required={true}
                error={touched.email && Boolean(errors.email)}
                helperText={
                  touched.email && Boolean(errors.email)
                    ? errors.email
                    : undefined
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={isSubmitting}
              />
            </DialogContent>
            <DialogActions>
              <Fade
                in={isSubmitting}
                style={{ transitionDelay: isSubmitting ? "800ms" : "0ms" }}
                unmountOnExit={true}
              >
                <CircularProgress color="secondary" size={20} />
              </Fade>

              <Button
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                Submit
              </Button>
              <Button
                className={classes.disabledButton}
                onClick={() => this.service.send('CloseUserDialog')}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  };

  private validate = (values: IAddUserForm): FormikErrors<IAddUserForm> => {
    const errors: FormikErrors<IAddUserForm> = {};

    // EMAIL
    //1. Require
    // 2. Max length = 255
    // 3. Valid
    if (validator.isEmpty(values.email)) {
      errors.email = "Required";
    } else if (!validator.isLength(values.email, { min: 1, max: 75 })) {
      errors.email = "Email can be no longer than 75 characters";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Email is not in a valid format";
    }
    return errors;
  };

  private onSubmit = async (
    values: IAddUserForm,
    formikHelpers: FormikHelpers<IAddUserForm>
  ): Promise<void> => {
    const { setSubmitting } = formikHelpers;
    try {
      const retval = await UserManagerService.AddNewUserToSystem$(
        this.props.user,
        values.email
      ).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
      }

      // Close the dialog
      this.service.send('CloseUserDialog');
    } catch (ex) {
    } finally {
      setSubmitting(false);
      this.RefreshModel();
    }
    };

    async updateUser(type: string) {
        try {
            this.setState({ isProcessing: true });
            if (type === "lock") {
                await UserManagerService.LockUser$(
                    this.props.user,
                    this.state.targetUser
                ).toPromise();
                this.setState({ isUserLockedOut: true });
            }
            else if (type === "unlock") {
                await UserManagerService.UnLockUser$(
                    this.props.user,
                    this.state.targetUser
                ).toPromise();
                this.setState({ isUserLockedOut: false });
            }
        } catch (ex) {
            const error = ex as AjaxError;
            this.setRedirectState();
            this.setState({
                errorMsg: ExceptionSerivce.StandardAjaxExceptionHandler(error)
            });
        }
        finally {
            this.setState({ isProcessing: false });
        }
    }
}

// REDUX CONTAINER
const AllUsersPageWithState = connect<IGenericUserProps>(
  genericMapStateToProps
)(AllUsersView);

// STYLES CONTAINER
const AllUsersPage = withStyles(styles)(AllUsersPageWithState);

export default AllUsersPage;
