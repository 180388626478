import { Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';


interface ILoadingProps {
    size?: number,
    progressText?: string
}

const LoadingDisplay: React.StatelessComponent<ILoadingProps> = ({ size = 500, progressText = "" }) => {
    return (<Grid container={true} alignItems={'center'} justifyContent={'center'}>
        <Grid key={1} item={true} xs={12}>
            {progressText !== "" ?
                <div>
                    <Typography variant='h2' color='primary'>
                        {progressText === "*" ?
                            <Skeleton width={"30%"} />
                            : progressText}
                    </Typography></div> : null
            }
        </Grid>
        <Grid key={2} item={true} xs={12}>
            <Skeleton width='100%' height={size} />
        </Grid>
    </Grid>
    )
};

export default LoadingDisplay;