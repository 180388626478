import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, Divider, ListItemIcon, ListItemText, IconButton, Drawer } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon, SupervisorAccount as SupervisorAccountIcon, Computer, Receipt, Settings, GroupAdd as GroupAddIcon, Help as HelpIcon, MoveToInbox as MoveToInboxIcon, Poll as PollIcon, ListAlt as ListAltIcon, Home as HomeIcon, Work as WorkIcon, AssignmentTurnedIn as AssignmentTurnedInIcon } from '@material-ui/icons';
import idssStyle from "../../Configuration/SharedStyling";
import logo from '../../Images/logo@2x.png';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { SubmissionType } from "../../DataClasses/SharedData";
import { ISubmissionAllowance } from "../../DataClasses/SubmissionData";
import { IMenuActionAllowance } from "../../DataClasses/ActionAllowanceData";
import classNames from 'classnames'
import { IAdministrationPayload } from "../../DataClasses/AdministrationData";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme), ...sidebarStyles() });

// REDUX PROPS
interface ILocalProps {
    uploadavailabilities: ISubmissionAllowance
    menuItemAvailabilities: IMenuActionAllowance,
    isNcqaAdmin: boolean,
    isUserAdmin: boolean,
    toggleSidebarAction: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>,
    adminConfigOptions: IAdministrationPayload
}

interface ILocalState {
    isSidebarOpened: boolean;
    bulkDownloadsOpen: boolean;
    bulkAttestationOpen: boolean;
    bulkMarkFinalOpen: boolean;
}

const sidebarStyles = () => createStyles({
    collapsedLogoButtonContainer: {
        flexDirection: 'column-reverse' 
    } 
})

type IStylesProps = WithStyles<typeof styles>;


const AdapterLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
    <NavLink innerRef={ref as any} {...props} activeClassName="Mui-selected" exact/>
));


// VIEW
class SidebarView extends React.Component<ILocalProps & IStylesProps, ILocalState> {
    constructor(props) {
        super(props);

        this.state = {
            isSidebarOpened: true,
            bulkDownloadsOpen: false,
            bulkAttestationOpen: false,
            bulkMarkFinalOpen: false
        }
    }

    public render() {
        const { classes } = this.props;
        return (
            <Drawer
                variant="permanent"
                className={(classNames(classes.drawer, {
                    [classes.drawerOpen]: this.state.isSidebarOpened,
                    [classes.drawerClose]: !this.state.isSidebarOpened
                })) + "sideBarRoot" }
                classes={{
                    paper: classNames({
                        [classes.drawerOpen]: this.state.isSidebarOpened,
                        [classes.drawerClose]: !this.state.isSidebarOpened
                    })
                }}
                open={this.state.isSidebarOpened}
            >
                <List className={classes.sidebarList}>
                    <ListItem className={classes.sidebarLogo}>
                        <Grid className={this.state.isSidebarOpened ? "" : classes.collapsedLogoButtonContainer} container>
                            <Grid item xs={this.state.isSidebarOpened ? 10 : 12}>
                                {!this.state.isSidebarOpened ?
                                    null : 
                                    <img src={logo} className={classes.appLogo} alt="IDSS Logo" />}
                            </Grid>
                            <Grid item xs={this.state.isSidebarOpened ? 2 : 12}>
                                <IconButton className={this.state.isSidebarOpened ? classes.collapseBtn : classes.collapseBtnSmall} onClick={(ev) => this.toggleSidebarOpen(ev)}>
                                    {this.state.isSidebarOpened ? <ArrowBackIosIcon fontSize="small" /> : <ArrowForwardIosIcon fontSize="small" />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider />

                    <ListItem button key="dashboard" to='/dashboard' component={AdapterLink}>
                        <ListItemIcon>{<HomeIcon />}</ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItem>

                    <ListItem button key="submissions" to='/availablesubmissionpage' component={AdapterLink}>
                        <ListItemIcon>{<ListAltIcon />}</ListItemIcon>
                        <ListItemText primary={"Submissions"} />
                    </ListItem>

                    {this.props.menuItemAvailabilities.allowsBulkDownloadHedis || this.props.menuItemAvailabilities.allowsBulkDownloadCahps || this.props.menuItemAvailabilities.allowsBulkDownloadHos ?
                        <ListItem button onClick={()=>this.setState({bulkDownloadsOpen:!this.state.bulkDownloadsOpen})}>
                            <ListItemIcon>{<MoveToInboxIcon />}</ListItemIcon>
                            <ListItemText primary={"Bulk Downloads"} />
                        </ListItem> : null
                    }
                    {this.props.menuItemAvailabilities.allowsBulkDownloadHedis && this.state.isSidebarOpened  && this.state.bulkDownloadsOpen ?
                        <ListItem button key={"bulkDownloads" + SubmissionType.HEDIS} to={'/bulkdownload/' + SubmissionType.HEDIS} component={AdapterLink}>
                            <ListItemText className={classes.subNavLink} secondary={SubmissionType.HEDIS} /> 
                        </ListItem> : null
                    }
                    {this.props.menuItemAvailabilities.allowsBulkDownloadCahps && this.state.isSidebarOpened && this.state.bulkDownloadsOpen?
                        <ListItem button key={"bulkDownloads" + SubmissionType.CAHPS} to={'/bulkdownload/' + SubmissionType.CAHPS} component={AdapterLink}>
                            <ListItemText className={classes.subNavLink} secondary={SubmissionType.CAHPS} />
                        </ListItem> : null
                    }
                    {this.props.menuItemAvailabilities.allowsBulkDownloadHos && this.state.isSidebarOpened
                        && this.state.bulkDownloadsOpen && this.props.adminConfigOptions.allowsHosSubmission ?
                        <ListItem button key={"bulkDownloads" + SubmissionType.HOS} to={'/bulkdownload/' + SubmissionType.HOS} component={AdapterLink}>
                            <ListItemText className={classes.subNavLink} secondary={SubmissionType.HOS} />
                        </ListItem> : null
                    }

                    {this.props.uploadavailabilities.allowsHosSubmission && this.props.adminConfigOptions.allowsHosSubmission
                        && !this.props.adminConfigOptions.enableHosInterimDataSubmission ?
                        <>
                            {this.props.menuItemAvailabilities.allowsBulkAttestationHos ?
                                <ListItem button onClick={() => this.setState({ bulkAttestationOpen: !this.state.bulkAttestationOpen })}>
                                    <ListItemIcon>{<WorkIcon />}</ListItemIcon>
                                    <ListItemText primary={"Bulk Attestation"} />
                                </ListItem> : null
                            }
                            {this.props.menuItemAvailabilities.allowsBulkAttestationHos && this.state.isSidebarOpened && this.state.bulkAttestationOpen ?
                                <ListItem button key={"bulkAttestation" + SubmissionType.HOS} to={'/bulkattestation/' + SubmissionType.HOS} component={AdapterLink}>
                                    <ListItemText className={classes.subNavLink} secondary={SubmissionType.HOS} />
                                </ListItem> : null
                            }
                        </> : null
                    }

                    {(this.props.uploadavailabilities.allowsHosSubmission || this.props.uploadavailabilities.allowsCahpsSubmission)
                        && (this.props.adminConfigOptions.allowsCahpsSubmission || this.props.adminConfigOptions.allowsHosSubmission) ?
                        <>
                            {this.props.menuItemAvailabilities.allowsBulkMarkFinalCahps || this.props.menuItemAvailabilities.allowsBulkMarkFinalHos ?
                                <ListItem button onClick={() => this.setState({ bulkMarkFinalOpen: !this.state.bulkMarkFinalOpen })}>
                                    <ListItemIcon>{<AssignmentTurnedInIcon />}</ListItemIcon>
                                    <ListItemText primary={"Bulk Mark Final"} />
                                </ListItem> : null
                            }
                            {this.props.uploadavailabilities.allowsCahpsSubmission && this.props.menuItemAvailabilities.allowsBulkMarkFinalCahps
                                && this.state.isSidebarOpened && this.state.bulkMarkFinalOpen && this.props.adminConfigOptions.allowsCahpsSubmission ?
                                <ListItem button key={"bulkMarkFinal" + SubmissionType.CAHPS} to={'/bulkmarkfinal/' + SubmissionType.CAHPS} component={AdapterLink}>
                                    <ListItemText className={classes.subNavLink} secondary={SubmissionType.CAHPS} />
                                </ListItem> : null
                            }
                            {this.props.uploadavailabilities.allowsHosSubmission && this.props.menuItemAvailabilities.allowsBulkMarkFinalHos
                                && this.state.isSidebarOpened && this.state.bulkMarkFinalOpen && this.props.adminConfigOptions.allowsHosSubmission ?
                                <ListItem button key={"bulkMarkFinal" + SubmissionType.HOS} to={'/bulkmarkfinal/' + SubmissionType.HOS} component={AdapterLink}>
                                    <ListItemText className={classes.subNavLink} secondary={SubmissionType.HOS} />
                                </ListItem> : null
                            }
                        </> : null
                    }
                    <ListItem button key="reportspage" to='/reportspage' component={AdapterLink}>
                        <ListItemIcon>{<PollIcon />}</ListItemIcon>
                        <ListItemText primary={"Reports"} />
                    </ListItem>
                    <ListItem button key="helppage" to='/helppage' component={AdapterLink}>
                        <ListItemIcon>{<HelpIcon />}</ListItemIcon>
                        <ListItemText primary={"Help"} />
                    </ListItem>

                    {this.props.isNcqaAdmin || this.props.menuItemAvailabilities.allowsUserManagement?
                        <div>
                            <ListItem button key="userslistpage" to='/userslistpage' component={AdapterLink}>
                                <ListItemIcon>{<GroupAddIcon />}</ListItemIcon>
                                <ListItemText primary={"Users"} />
                            </ListItem>
                        </div> : null
                    }
                    {this.props.isNcqaAdmin ?
                        <div>
                            <ListItem button key="administration" to='/administration' component={AdapterLink}>
                                <ListItemIcon className={classes.redColor}>{<Settings />}</ListItemIcon>
                                <ListItemText className={classes.redColor} primary={"Admin"} />
                            </ListItem>
                            <ListItem button key="logs" to='/logs' component={AdapterLink}>
                                <ListItemIcon className={classes.redColor}>{<Receipt />}</ListItemIcon>
                                <ListItemText className={classes.redColor} primary={"Logs"} />
                            </ListItem>
                            <ListItem button component="a" key="hangfire" href='/idssv2/hangfire' target='_blank'>
                                <ListItemIcon className={classes.redColor}>{<Computer />}</ListItemIcon>
                                <ListItemText className={classes.redColor} primary={"Hangfire"} />
                            </ListItem >
                            <ListItem button key="alluserspage" to='/alluserspage' component={AdapterLink}>
                                <ListItemIcon className={classes.redColor}>{<SupervisorAccountIcon />}</ListItemIcon>
                                <ListItemText className={classes.redColor} primary={"Users (Admin Only)"} />
                            </ListItem>
                            <ListItem button key="bulkuserpage" to='/bulkuserpage' component={AdapterLink}>
                                <ListItemIcon className={classes.redColor}>{<SupervisorAccountIcon />}</ListItemIcon>
                                <ListItemText className={classes.redColor} primary={"Bulk User Update"} />
                            </ListItem>
                        </div> : null}
                </List>
            </Drawer>
        );
    }

    toggleSidebarOpen = (ev) => {
        this.setState({
            isSidebarOpened: !this.state.isSidebarOpened
        });

        this.props.toggleSidebarAction(ev);
    }
}

// STYLES CONTAINER
const Sidebar = withStyles(styles)(SidebarView);

// REDUX CONTAINER
export default Sidebar;
