import "core-js/stable";
import "regenerator-runtime/runtime";
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './Components/Root/App';
import ConfiguredUserManager from './Configuration/AuthenticationConfiguration';
import './index.css';
import * as AuthActions from "./State/Authentication";
import RootReducer from './State/Root/RootReducer';
import RootStore from './State/Root/RootStore';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import idssTheme from './Configuration/ThemeConfiguration';
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/link.min.js";

// This is a hack to trick typescript
// https://github.com/zalmoxisus/redux-devtools-extension/issues/492
declare module 'redux' {
  export type GenericStoreEnhancer = any;
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

// User Manager
const userManager = ConfiguredUserManager;

// Epics
const epicMiddleware = createEpicMiddleware();

// Redux
const store = createStore(RootReducer, composeWithDevTools(applyMiddleware(epicMiddleware))) as RootStore;

// Redux persist
// Sometimes, the OIDC User Manager signout can redirect a user to the sign out page before Redux Persist is able to persist the user removal to storage
// So, the callback is called everytime a rehydrate occurs
// On the rehydrate, check to see if OIDC has removed the user, and if it has and the user still exists in Redux, then dispatch an action to sign the user out
const persistor = persistStore(store, undefined, () => {
  userManager.getUser().then((user) => {
    if (!user && store.getState().AuthenticationReducer.user) {
      store.dispatch(AuthActions.userSignedOut());
    }
  });
});

const theme = idssTheme;

// Epic instantiation
// Typing the root epic sucks, but seems to be a simple fix for typing errors

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <StylesProvider generateClassName={generateClassName}>
          <MuiThemeProvider theme={theme}>
            <App store={store} userManager={userManager} />
          </MuiThemeProvider>
        </StylesProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement
);