import * as React from "react";
import { connect } from "react-redux";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import BackNavigation from '../../Shared/BackNavigation';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import LoadingDisplay from "../../Shared/Loading";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../../Services/ReportsService";
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface ISubmissionsDataSummaryReportState {
    hedisData: any;
    cahpsData: any;
    hosData: any;
    isLoading: boolean;
    redirect: boolean
}

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class SubmissionsDataSummaryReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    ISubmissionsDataSummaryReportState
> {
    private reportOptions: MUIDataTableOptions = {
        filter: false,
        filterType: "dropdown",
        print: false,
        pagination: false,
        viewColumns: false,
        selectableRows: "none",
        downloadOptions: {
            filename: 'SubmissionsDataSummaryReport.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        }
    }

    constructor(props: any) {
        super(props);
        this.state = {
            hedisData: [],
            cahpsData: [],
            hosData: [],
            isLoading: true,
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;

        const reportColumns =
            [
                {
                    name: "Description",
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (this.findTerm(value, 'plan')
                                || this.findTerm(value, 'audit')
                                || this.findTerm(value, 'final')) {
                                return <span className={`${classes.submissionsDataHighlight}`}>{value}</span>;
                            }
                            else {
                                return value;
                            }
                        }
                    }
                },
                {
                    name: "Count",
                    options: {
                        filter: false,
                    }
                },
            ];

        return (<>
            <Grid container={true} className={classes.mt2}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <BackNavigation text="Back to Reports" href="/reportspage" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid key={1} item={true} xs={4} className={classes.gridDisplay}>
                    <Card className={classes.card}>
                        <CardContent>
                            {this.state.isLoading ? <LoadingDisplay /> :
                                <MUIDataTable
                                    title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>Hedis</Typography>}
                                    data={this.state.hedisData}
                                    columns={reportColumns}
                                    options={this.reportOptions}
                                />
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid key={2} item={true} xs={4} className={classes.gridDisplay}>
                    <Card className={classes.card}>
                        <CardContent>
                            {this.state.isLoading ? <LoadingDisplay /> :
                                <MUIDataTable
                                    title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>CAHPS</Typography>}
                                    data={this.state.cahpsData}
                                    columns={reportColumns}
                                    options={this.reportOptions}
                                />
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid key={3} item={true} xs={4} className={classes.gridDisplay}>
                    <Card className={classes.card}>
                        <CardContent>
                            {this.state.isLoading ? <LoadingDisplay /> :
                                <MUIDataTable
                                    title={<Typography className={classes.primaryBlue + " " + classes.muiTableTitleMargin}>HOS</Typography>}
                                    data={this.state.hosData}
                                    columns={reportColumns}
                                    options={this.reportOptions}
                                />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval: any | AjaxError = await ReportsService.GetSubmissionsDataSummary$(this.props.user).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            var hedisData = retval.filter(v => !v.description.toLocaleLowerCase().includes("cahps") && !v.description.toLocaleLowerCase().includes("hos"));
            var cahpsData = retval.filter(v => v.description.toLocaleLowerCase().includes("cahps"));
            var hosData = retval.filter(v => v.description.toLocaleLowerCase().includes("hos"));
            this.setState({
                hedisData: this.getData(hedisData),
                cahpsData: this.getData(cahpsData),
                hosData: this.getData(hosData)
            });
        }
        this.setState({ isLoading: false });
    }

    private getData(data: any): any {
        var mappedData = data.map(item => {
            return [
                item.description,
                item.count
            ];
        });
        return mappedData;
    }

    private findTerm(value, term): boolean {
        if (value.toLocaleLowerCase().includes(term)) {
            return true;
        }
        return false;
    }
}

// REDUX CONTAINER
const SubmissionsDataSummaryReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(SubmissionsDataSummaryReportView);

// STYLES CONTAINER
const SubmissionsDataSummaryReportPage = withStyles(styles)(
    SubmissionsDataSummaryReportPageWithState
);

export default SubmissionsDataSummaryReportPage;