import * as React from "react";
import { connect } from "react-redux";
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import idssStyle from "../../../Configuration/SharedStyling";
import {
    IGenericUserProps,
    genericMapStateToProps
} from "../../../DataClasses/GenericUser";
import { Grid } from "@material-ui/core";
import BackNavigation from '../../Shared/BackNavigation';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import LoadingDataTable from "../../Shared/LoadingDataTable";
import { IEvent } from "../../../DataClasses/DbData";
import { AjaxError } from "rxjs/ajax";
import ReportsService from "../../Services/ReportsService";
import Moment from 'react-moment';
import { Redirect } from "react-router-dom";

const styles = (theme: Theme) => createStyles({ ...idssStyle(theme) });

interface IActivityReportState {
    data: any[];
    isLoading: boolean;
    redirect: boolean;
}

const reportColumns =
    [
        {
            name: "Date",
            options: {
                filter: false,
                customBodyRender: value => {
                    return (
                        <Moment format="YYYY/MM/DD">
                            {value}
                        </Moment>
                    );
                }
            }
        },
        {
            name: "User",
            options: {
                filter: true,
            }
        },
        {
            name: "Org ID",
            options: {
                filter: true,
            }
        },
        {
            name: "Sub ID",
            options: {
                filter: true,
            }
        },
        {
            name: "Component",
            options: {
                filter: true,
            }
        },
        {
            name: "Message",
            options: {
                filter: false,
            }
        },
    ]

type IStylesProps = WithStyles<typeof styles>;

// VIEW
class ActivityReportView extends React.Component<
    IGenericUserProps & IStylesProps,
    IActivityReportState
> {
    private reportOptions: MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        print: false,
        viewColumns: false,
        selectableRows: "none",
        downloadOptions: {
            filename: 'ActivityReport.csv',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [10, 15, 20, 25, 50, 75, 100, 500, 1000]
    }

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            redirect: false
        };
    }

    public componentDidMount() {
        this.RefreshModel();
    }

    private redirectToErrorPage() {
        return <Redirect to='/errorpage' />;
    }

    private setRedirectState() {
        this.setState({ redirect: true });
    }

    public render() {
        if (this.state.redirect) {
            return this.redirectToErrorPage();
        }

        const { classes } = this.props;
        return (

            <Grid container={true} className={classes.gridRowFlex} spacing={0}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true}>
                            <BackNavigation text="Back to Reports" href="/reportspage" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className={classes.card}>
                        {this.state.isLoading ? <LoadingDataTable titleText={"*"} count={8} /> :
                            <MUIDataTable
                                title={"Activities Report"}
                                data={this.state.data}
                                columns={reportColumns}
                                options={this.reportOptions}
                            />
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }

    private async RefreshModel() {
        this.setState({ isLoading: true });
        const retval: IEvent[] | AjaxError = await ReportsService.GetEventListCaller$(this.props.user).toPromise();
        if (retval instanceof AjaxError || !retval) {
            this.setRedirectState();
        } else {
            this.setState({
                data: retval.map(item => {
                    return [
                        item.timestamp,
                        item.user,
                        item.orgId,
                        item.subId,
                        item.displayComponent,
                        item.message
                    ];
                })
            });
        }
        this.setState({ isLoading: false });
    }
}

// REDUX CONTAINER
const ActivityReportPageWithState = connect<IGenericUserProps>(
    genericMapStateToProps
)(ActivityReportView);

// STYLES CONTAINER
const ActivityReportPage = withStyles(styles)(
    ActivityReportPageWithState
);

export default ActivityReportPage;