import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import idssStyle from "../../Configuration/SharedStyling";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { orange, green } from '@material-ui/core/colors';
import { CircularProgress } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';

interface IProcessingProps {
    completed?: boolean
}

const styles = (theme: Theme) => createStyles({
    ...idssStyle(theme),
    validationMessageList: {
        margin: "0",
        listStyle: "none"
    },
    pgRoot: {
        display: 'flex',
        alignItems: 'left',
    },
    pgWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    pgFabProgress: {
        color: orange[500],
        position: 'absolute',
        top: -11,
        left: -3,
        zIndex: 1,
    },
    pgButtonProgress: {
        color: orange[500],
        position: 'absolute',
        top: -9,
        left: 0,
    },
    pgButtonCompleted: {
        color: green[600],
        position: 'absolute',
        top: -9,
        left: 0,
    }
});
type IStylesProps = WithStyles<typeof styles>;
const Processing = (props: IProcessingProps & IStylesProps) => {
    return (
        <Grow in={true} {...{ timeout: 500 }}>
            <div className={props.classes.pgRoot}>
                {(props.completed !== null && props.completed) ?
                    <div className={props.classes.pgWrapper}>
                        <CheckCircleOutlineIcon className={props.classes.pgButtonCompleted} />
                    </div> :
                    <div className={props.classes.pgWrapper}>
                        <AutorenewIcon className={props.classes.pgButtonProgress} fontSize="small" />
                        <CircularProgress size={23} className={props.classes.pgFabProgress} />
                    </div>
                }
            </div>
        </Grow>
    )
};
/*  Keep as reference
        {false?
                <div className={classes.pgRoot}>
                    <div className={classes.pgWrapper}>
                            <Fab className={classes.pgButtonProgress} size={"small"}>
                            <AutorenewIcon/>
                        </Fab>
                        <CircularProgress size={40} className={classes.pgFabProgress} />
                    </div>
                </div>: null
        }
*/

const ProcessingDisplay = withStyles(styles)(Processing);
export default ProcessingDisplay;